import {
    GET_ALL_SHARED_DESIRES_ERROR,
    GET_ALL_SHARED_DESIRES_REQUEST,
    GET_ALL_SHARED_DESIRES_SUCCESS,
} from '../actions/SharedDesireActionTypes';

const sharedDesiresReducer = (
    state = {
        sharedDesires: [],
        wishlist: null,
        owner: null,
        error: null,
        isLoading: false,
        sharedWith: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_SHARED_DESIRES_REQUEST:
            return {
                ...state,
                sharedDesires: [],
                wishlist: null,
                owner: null,
                isLoading: true,
            };
        case GET_ALL_SHARED_DESIRES_SUCCESS:
            return {
                ...state,
                sharedDesires: action.sharedDesires,
                wishlist: action.wishlist,
                owner: action.owner,
                isLoading: false,
                sharedWith: action.sharedWith,
            };
        case GET_ALL_SHARED_DESIRES_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default sharedDesiresReducer;
