import AddIcon from '@mui/icons-material/Add';
import { emphasize, Fab, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import withTheme from '@mui/styles/withTheme';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DialogDivider from '../../../../mixins/components/divider/DialogDivider';
import withResponsiveInformation from '../../../../mixins/components/ResponsiveInformation';
import CreateWishlistDialog from '../../../../wishlist/create/components/CreateWishlistDialog';
import {
    create,
    getAll,
} from '../../../../wishlist/main/stateManagement/actions/WishlistActions';
import {
    closeMoveDesireDialog,
    moveDesire,
    openMoveDesireDialog,
} from '../../../stateManagement/actions/DesireActions';
import CancelButton from '../CancelButton';
import WishlistItem from './WishlistItem';

const SlideTransition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

const GrowTransition = React.forwardRef((props, ref) => (
    <Grow ref={ref} {...props} />
));

const styles = (theme) => ({
    title: {
        color: theme.palette.primary.contrastText,
        fontFamily: "'Merienda One' !important",
        fontSize: '1.25rem',
        '& .MuiDialogTitle-root': {
            paddingBottom: '0 !important',
        },
        marginTop: theme.spacing(1),
    },
    dialogContent: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(1),
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxHeight: '50vh',
        },
    },
    '& .MuiButton-outlinedPrimary': {
        color: theme.palette.common.darkBackground,
    },
    listItemAddIcon: {
        justifyContent: 'center',
    },
    addIcon: {
        color: theme.palette.primary.main,
    },
    fab: {
        backgroundColor: theme.palette.common.accentColor,
        '&:hover': {
            backgroundColor: emphasize(theme.palette.common.accentColor, 0.15),
        },
    },
});

class MoveDesireDialog extends Component {
    state = { isLoading: false, createWishlistDialogIsOpen: false };

    componentDidMount() {
        this._isMounted = true;
        this.setState({ isLoading: true });
        this.props.getLists().then(() => {
            this._isMounted &&
                this.setState({
                    isLoading: false,
                });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleCancelButtonClick = () => this.props.closeDialog();

    handleMoveClick = (newWishlistId) => {
        const {
            closeDialog,
            selectedDesire,
            moveWish,
            enqueueSnackbar,
            wishlists,
        } = this.props;

        this.setState({ ...this.state, isLoading: true });
        moveWish(newWishlistId, selectedDesire)
            .then(() => {
                const [newDestination] = wishlists.filter(
                    (wishlist) => wishlist.id === newWishlistId
                );
                enqueueSnackbar(
                    <FormattedMessage
                        id={'wish.moved'}
                        values={{ title: newDestination?.title }}
                    />,
                    {
                        variant: 'default',
                        autoHideDuration: 6000,
                    }
                );
            })
            .catch((err) => {
                console.warn(err);
                this.setState({ ...this.state, isLoading: false });
                closeDialog();
            });
    };

    handleAddWishlistClick = () => {
        const { closeDialog, selectedDesire } = this.props;
        this.setState({ ...this.state, createWishlistDialogIsOpen: true });
        closeDialog(selectedDesire);
    };

    handleCloseCreateWishlist = () => {
        const { selectedDesire, openDialog } = this.props;
        this.setState({ ...this.state, createWishlistDialogIsOpen: false });
        openDialog(selectedDesire);
    };

    handleCreateWishlist = (model) =>
        this.props.createWishlist(model).then(this.handleCloseCreateWishlist);

    render() {
        const {
            theme,
            classes,
            open,
            closeDialog,
            isMobile,
            wishlists,
            wishlistId,
        } = this.props;
        const { isLoading, createWishlistDialogIsOpen } = this.state;

        return (
            <div>
                <CreateWishlistDialog
                    open={createWishlistDialogIsOpen}
                    onSubmit={this.handleCreateWishlist}
                    onClose={this.handleCloseCreateWishlist}
                />
                <Dialog
                    open={open}
                    onClose={() => closeDialog()}
                    PaperProps={{
                        style: {
                            backgroundColor: theme.palette.primary.main,
                            width: isMobile ? '100%' : '600px',
                            maxHeight: 'none',
                        },
                    }}
                    fullScreen={isMobile}
                    TransitionComponent={
                        isMobile ? SlideTransition : GrowTransition
                    }
                >
                    {isLoading && <LinearProgress color="secondary" />}
                    <DialogTitle>
                        <Typography align="center" className={classes.title}>
                            <FormattedMessage id={'wish.move'} />
                        </Typography>
                        <DialogDivider />
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <List>
                            {wishlists
                                .filter(({ id }) => id !== wishlistId)
                                .map(
                                    ({ id, title, wishCount, note, image }) => (
                                        <ListItem key={id}>
                                            <WishlistItem
                                                title={title}
                                                wishCount={wishCount}
                                                note={note}
                                                image={image}
                                                moveDesire={() =>
                                                    this.handleMoveClick(id)
                                                }
                                            />
                                        </ListItem>
                                    )
                                )}
                            <ListItem
                                key={'addWishlist'}
                                className={classes.listItemAddIcon}
                            >
                                <Tooltip
                                    title={
                                        <FormattedMessage id={'wishlist.add'} />
                                    }
                                    aria-label="add"
                                    enterDelay={300}
                                >
                                    <Fab
                                        className={classes.fab}
                                        timeout={500}
                                        onClick={this.handleAddWishlistClick}
                                    >
                                        <AddIcon className={classes.addIcon} />
                                    </Fab>
                                </Tooltip>
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton
                            text="wishlist.cancel"
                            onClick={this.handleCancelButtonClick}
                            color="secondary"
                        />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authentication.user.uid,
        open: state.desires.moveDialogIsOpen,
        selectedDesire: state.desires.selectedDesire,
        wishlistId: state.desires.wishlist.id,
        wishlists: state.wishlist.items,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: (desire) => dispatch(closeMoveDesireDialog(desire)),
        openDialog: (desire) => dispatch(openMoveDesireDialog(desire)),
        moveWish: (newWishlistId, desire) =>
            dispatch(moveDesire(newWishlistId, desire)),
        getLists: () => dispatch(getAll()),
        createWishlist: (model) => dispatch(create(model)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withSnackbar(
        withResponsiveInformation(
            withStyles(styles)(withTheme(MoveDesireDialog))
        )
    )
);
