import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import WishlistCard from '../../main/components/card/WishlistCard';
import withWishlist from '../../main/hocs/Wishlist';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4),
        },
        paddingBottom: theme.spacing(4),
    },
});

class WishListList extends Component {
    state = {
        markedAsRemoved: null,
    };

    handleMarkAsRemoved = (item) => this.setState({ markedAsRemoved: item });

    handleRemove = (item) => this.props.onItemRemove(item);

    renderItem(key, item) {
        const { profile, onItemEdit, onItemShare, onItemClick, selectedItem } =
            this.props;
        const willbeRemoved = this.state.markedAsRemoved === item;
        return (
            <Fade
                key={key}
                timeout={500}
                in={!willbeRemoved}
                onExited={() => this.handleRemove(item)}
            >
                <Grid sx={{ minWidth: '314px' }} item xs={12} sm={6} md={4}>
                    <WishlistCard
                        profile={profile}
                        item={item}
                        isSelected={item.id === selectedItem?.id ?? null}
                        onItemClick={onItemClick}
                        onEditClick={onItemEdit}
                        onShareClick={onItemShare}
                        onRemoveClick={() => this.handleMarkAsRemoved(item)}
                    />
                </Grid>
            </Fade>
        );
    }

    render() {
        const { classes, items } = this.props;
        return (
            <Container className={classes.root} maxWidth="lg">
                <Grid
                    container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                >
                    {items.map((item, key) => this.renderItem(key, item))}
                </Grid>
            </Container>
        );
    }
}

export default withWishlist(withStyles(styles)(WishListList));
