import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import LinearProgress from '@mui/material/LinearProgress';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import withTheme from '@mui/styles/withTheme';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import validator from 'validator';
import PrimaryButton from '../../../../mixins/components/buttons/PrimaryButton';
import DialogDivider from '../../../../mixins/components/divider/DialogDivider';
import withResponsiveInformation from '../../../../mixins/components/ResponsiveInformation';
import { firebaseFunctions } from '../../../../networking/firebase/FirebaseConfig';
import ImageService from '../../../../profile/main/services/ImageService';
import {
    closeEditDialog,
    editDesire,
} from '../../../stateManagement/actions/DesireActions';
import CancelButton from '../CancelButton';
import NoteGroup from '../create/NoteGroup';
import Stepper from '../Stepper';
import { styles } from '../styles/DesireDialogStyles';
import WishField from '../textFields/WishField';

const SlideTransition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

const GrowTransition = React.forwardRef((props, ref) => (
    <Grow ref={ref} {...props} />
));

const initialDialogState = {
    title: '',
    note: '',
    disableButton: false,
    activeStep: 0,
    images: [],
    isLoading: false,
};

class EditWishlistDialog extends Component {
    state = initialDialogState;

    componentDidUpdate({ selectedDesire: previousDesire }) {
        const { selectedDesire } = this.props;

        selectedDesire &&
            !_.isEqual(selectedDesire, previousDesire) &&
            this.handleEnter();
    }

    handleEnter = () => {
        const { selectedDesire } = this.props;

        const imageUrl = selectedDesire?.imageUrl;
        const url = selectedDesire?.url;

        this.setState({
            title: selectedDesire?.title ?? '',
            images: imageUrl ? [imageUrl] : [],
            note: selectedDesire.note ?? '',
            isLoading: true,
        });
        validator.isURL(url) && this.parseUrl(url);
    };

    parseUrl = (url) => {
        const parseUrl = firebaseFunctions.httpsCallable('parseUrlCall');
        parseUrl({ url: url, puppeteerDisabled: false })
            .then((result) => {
                const resultImages = result.data.images;
                const originImage = this.state.images;
                const newImages = originImage
                    .concat(resultImages)
                    .filter(
                        (elem, index, self) => index === self.indexOf(elem)
                    );

                this.setState({
                    isLoading: false,
                    images: newImages,
                });
            })
            .catch((error) => {
                console.error('could not parse url', error);
                this.setState({
                    isLoading: false,
                });
            });
    };

    handleImageChange = (num) =>
        this.setState({ activeStep: this.state.activeStep + num });

    handleWishChange = ({ target: { value } }) =>
        this.setState({ title: value, disableButton: !value });

    handleSecondaryButtonClick = () => {
        this.resetDialog();
        this.props.closeDialog();
    };

    handlePrimaryButtonClick = () => {
        const { closeDialog, userId, wishlistId, selectedDesire, edit } =
            this.props;
        const { images, activeStep, title, note } = this.state;
        const imageUrl = images[activeStep] ?? '';

        const clearedImg = images.filter((img) => img !== imageUrl);

        clearedImg.forEach(
            (image) =>
                (image || '').includes('firebasestorage') &&
                ImageService.deleteImage(image)
        );

        edit({
            userId,
            wishlistId,
            id: selectedDesire.id,
            title,
            imageUrl,
            note,
        });

        closeDialog();
        this.resetDialog();
    };

    resetDialog = () => this.setState(initialDialogState);

    onUpload = (uploadUrl) => {
        if (!this.state.images.includes(uploadUrl)) {
            const images = [...this.state.images, uploadUrl];
            this.setState({
                images,
                activeStep: images.length - 1,
            });
        }
    };

    render() {
        const { theme, classes, open, closeDialog, isMobile } = this.props;
        const { images, activeStep, isLoading, title, disableButton, note } =
            this.state;

        return (
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        this.resetDialog();
                        closeDialog();
                    } else {
                        closeDialog();
                    }
                }}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.primary.main,
                        width: '400px',
                        maxHeight: 'none',
                    },
                }}
                fullScreen={isMobile}
                TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                }
            >
                {isLoading && <LinearProgress color="secondary" />}
                <DialogTitle>
                    <Typography align="center" className={classes.title}>
                        <FormattedMessage id={'wish.edit'} />
                    </Typography>
                    <DialogDivider />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form noValidate>
                        <WishField
                            onChange={this.handleWishChange}
                            id="wish.wish"
                            value={title}
                            autoFocus
                        />
                        <NoteGroup
                            noteValue={note}
                            onNoteChange={(note) => this.setState({ note })}
                        />
                    </form>
                    {images.length > 0 && (
                        <Grid container direction="row" justifyContent="center">
                            <Stepper
                                fullScreen={isMobile}
                                images={images}
                                activeStep={activeStep}
                                addPictureToState={this.onUpload}
                                onNext={() => this.handleImageChange(1)}
                                onBack={() => this.handleImageChange(-1)}
                                jumpTo={this.handleImageChange}
                            />
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} className={classes.buttons}>
                        <Grid item xs={6}>
                            <CancelButton
                                text="wishlist.cancel"
                                onClick={this.handleSecondaryButtonClick}
                                color="secondary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                text={'wishlist.save'}
                                onClick={this.handlePrimaryButtonClick}
                                disabled={disableButton}
                            />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.authentication.user.uid,
        open: state.desires.editDialogIsOpen,
        selectedDesire: state.desires.selectedDesire,
        wishlistId: state.desires.wishlist.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeEditDialog()),
        edit: (desire) => dispatch(editDesire(desire)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withResponsiveInformation(withStyles(styles)(withTheme(EditWishlistDialog))));
