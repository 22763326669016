export default class Profile {
    constructor(id, name, email, profileImage, signInMethod) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.profileImage = profileImage;
        this.signInMethod = signInMethod;
    }

    static createDefault() {
        return new Profile('', '', '', null);
    }

    static fromData({
        id = '',
        displayName: name = '',
        email = '',
        profileImageURL: profileImage = null,
    }) {
        return new Profile(id, name, email, profileImage);
    }
}
