import { firebaseInstance } from '../../networking/firebase/FirebaseConfig';

class NotificationService {
    constructor(firebaseInstance) {
        this.firebaseInstance = firebaseInstance;
    }

    saveToken = (userId, token) => {
        const pushtokenRef = this.firebaseInstance
            .database()
            .ref(`pushtokens/${userId}`);
        const firebaseNode = pushtokenRef.push();
        const model = {
            id: firebaseNode.key,
            enabled: true,
            token,
        };
        return firebaseNode.set(model);
    };
}

export default new NotificationService(firebaseInstance);
