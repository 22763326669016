import {
    CREATE_WISHLIST_ERROR,
    CREATE_WISHLIST_REQUEST,
    CREATE_WISHLIST_SUCCESS,
    EDIT_WISHLIST_ERROR,
    EDIT_WISHLIST_REQUEST,
    EDIT_WISHLIST_SUCCESS,
    GET_ALL_WISHLIST_ERROR,
    GET_ALL_WISHLIST_REQUEST,
    GET_ALL_WISHLIST_SUCCESS,
    REGISTER_OBSERVER_WISHLIST,
    REMOVE_WISHLIST_ERROR,
    REMOVE_WISHLIST_REQUEST,
    REMOVE_WISHLIST_SUCCESS,
    RESTORE_WISHLIST_ERROR,
    RESTORE_WISHLIST_REQUEST,
    RESTORE_WISHLIST_SUCCESS,
    UNREGISTER_OBSERVER_WISHLIST,
} from './WishlistActionTypes';
import WishlistService from '../../services/WishlistService';
import wishlistImages from '../../components/imageStepper/WishlistImages';

export const registerObserver = (callback) => {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const currentUser = getState().authentication.user;
            dispatch({
                type: REGISTER_OBSERVER_WISHLIST,
                currentUser,
            });
            WishlistService.registerObserver(currentUser, callback);
            callback();
            resolve();
        });
};

export const unregisterObserver = (callback) => {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const currentUser = getState().authentication.user;
            dispatch({
                type: UNREGISTER_OBSERVER_WISHLIST,
                currentUser,
            });
            WishlistService.unregisterObserver(currentUser, callback);
            resolve();
        });
};

export const getAll = () => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: GET_ALL_WISHLIST_REQUEST,
        currentUser,
    });
    try {
        const result = await WishlistService.getAll(currentUser);
        dispatch({
            type: GET_ALL_WISHLIST_SUCCESS,
            items: result,
        });
        return result;
    } catch (error) {
        dispatch({
            type: GET_ALL_WISHLIST_ERROR,
            error,
        });
    }
};

export const create = (wishlistCreateModel) => async (dispatch, getState) => {
    const {
        authentication: { user: currentUser },
        wishlist: { items },
    } = getState();
    dispatch({
        type: CREATE_WISHLIST_REQUEST,
        model: wishlistCreateModel,
        currentUser,
    });
    try {
        const result = await WishlistService.create(
            currentUser,
            wishlistCreateModel
        );
        items.unshift(result);
        dispatch({
            type: CREATE_WISHLIST_SUCCESS,
            items,
        });
        return result;
    } catch (error) {
        dispatch({
            type: CREATE_WISHLIST_ERROR,
            error,
        });
    }
};

export const createDefaultWishlist = (title) => {
    const model = {
        title,
        note: '',
        image: wishlistImages['GENERAL_1'].label,
    };
    return create(model);
};

export const edit = (wishlist, wishlistEditModel) => async (
    dispatch,
    getState
) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: EDIT_WISHLIST_REQUEST,
        model: wishlistEditModel,
        currentUser,
    });
    try {
        const result = await WishlistService.edit(
            currentUser,
            wishlist,
            wishlistEditModel
        );
        dispatch({
            type: EDIT_WISHLIST_SUCCESS,
            item: result,
        });
        return result;
    } catch (error) {
        dispatch({
            type: EDIT_WISHLIST_ERROR,
            error,
        });
    }
};

export const remove = (wishlist) => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: REMOVE_WISHLIST_REQUEST,
        wishlist,
        currentUser,
    });
    try {
        const result = await WishlistService.remove(currentUser, wishlist);
        dispatch({
            type: REMOVE_WISHLIST_SUCCESS,
            item: result,
        });
        return result;
    } catch (result_1) {
        dispatch({
            type: REMOVE_WISHLIST_ERROR,
            error: result_1,
        });
    }
};

export const restore = (wishlist) => {
    return async (dispatch, getState) => {
        const currentUser = getState().authentication.user;
        dispatch({
            type: RESTORE_WISHLIST_REQUEST,
            wishlist,
            currentUser,
        });
        try {
            const result = await WishlistService.restore(currentUser, wishlist);
            dispatch({
                type: RESTORE_WISHLIST_SUCCESS,
                item: result,
            });
            return result;
        } catch (error) {
            dispatch({
                type: RESTORE_WISHLIST_ERROR,
                error,
            });
        }
    };
};
