import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { logout } from '../../statemanagement/actions/AuthenticationActions';

const styles = (theme) => ({
    text: {
        letterSpacing: '1px',
        fontSize: '0.875rem',
        cursor: 'pointer',
    },
    box: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
});

class LogoutLink extends Component {
    handleLinkClick = (e) => {
        const { logout, closeSnackbar } = this.props;
        e.preventDefault(e);
        closeSnackbar();
        logout();
    };

    render() {
        const { classes } = this.props;
        return (
            <Box mt={0} color="text.secondary" className={classes.box}>
                <Link className={classes.text} onClick={this.handleLinkClick}>
                    <FormattedMessage id={'profile.logout'} />
                </Link>
            </Box>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    };
};

export default withSnackbar(
    connect(null, mapDispatchToProps)(withStyles(styles)(LogoutLink))
);
