import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide, show } from '../../stateManagement/actions/PrimaryAppBarActions';

const withPrimaryAppbar = (WrappedComponent) => {
    class PrimaryAppbar extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            showPrimaryAppBar: () => dispatch(show()),
            hidePrimaryAppBar: () => dispatch(hide()),
        };
    };

    return connect(null, mapDispatchToProps)(PrimaryAppbar);
};

export default withPrimaryAppbar;
