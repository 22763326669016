import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import AppConstants from '../../../app/constants/AppConstants';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    checkbox: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(0),
    },
    link: {
        color: theme.palette.common.link,
    },
});

class PrivacyCheckbox extends Component {
    render() {
        const { classes, checked, onChange } = this.props;
        return (
            <FormControlLabel
                className={classes.checkbox}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        value="privacy policy checkbox"
                        color="primary"
                    />
                }
                label={
                    <Typography variant="body2" component="p">
                        <FormattedMessage id={'auth.privacy-label-1'} />
                        &nbsp;
                        <Link
                            href={AppConstants.privacyPolicy}
                            component={'a'}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.link}
                        >
                            <FormattedMessage
                                id={'auth.privacy-policy-login'}
                            />
                        </Link>
                        {' / '}
                        <Link
                            href={AppConstants.termsOfUse}
                            component={'a'}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.link}
                        >
                            <FormattedMessage id={'auth.terms-of-use'} />
                        </Link>
                        &nbsp;
                        <FormattedMessage id={'auth.privacy-label-2'} />
                    </Typography>
                }
            />
        );
    }
}

export default withStyles(styles)(PrivacyCheckbox);
