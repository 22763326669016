import { CHANGE_LOCALIZATION } from '../actions/LocalizationActionTypes';
import localizationServiceInstance from '../../service/LocalizationService';

let initialState = localizationServiceInstance.initialize();

const LocalizationReducer = (state = initialState, action) => {
    if (action.type === CHANGE_LOCALIZATION) {
        return action.value;
    } else {
        return state;
    }
};

export default LocalizationReducer;
