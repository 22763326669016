import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import clsx from 'clsx';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';
import Tooltip from '@mui/material/Tooltip';
import { FormattedMessage } from 'react-intl';
import MovingTextContainer from '../../../desires/components/resources/movingTextContainer/MovingTextContainer';
import LeaveIcon from '../../../resources/WishlistIcons/leave-white-48dp.svg';

const styles = (theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    wishlist: {
        fontFamily: theme.typography.appBarFontFamily,
    },
    owner: {
        fontFamily: theme.typography.appBarSubtitle,
    },
    box: {
        width: '100%',
    },
    textOverflow: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    leaveIcon: {
        maxHeight: '24px',
    },
});

class SharedDesireSAppbar extends Component {
    handleBackClick = () => {
        this.props.history.push('/friendswishlist');
    };

    handleRemoveClick = () => {
        const { removeFriendsWishlist, wishlist } = this.props;
        removeFriendsWishlist(wishlist);
        this.handleBackClick();
    };

    render() {
        const { classes, wishlist, owner, isMobile } = this.props;
        const whileFetching = wishlist == null || owner == null; // evil twins
        return (
            <AppBar position="fixed" color="secondary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={this.handleBackClick}
                        size="large">
                        <ArrowBackIcon />
                    </IconButton>
                    <Box className={clsx(classes.box, classes.textOverflow)}>
                        <Typography
                            variant="h6"
                            className={clsx(
                                classes.wishlist,
                                classes.textOverflow
                            )}
                        >
                            {whileFetching ? (
                                <Skeleton
                                    animation="wave"
                                    width={isMobile ? '20%' : '40%'}
                                    variant="text"
                                />
                            ) : (
                                <MovingTextContainer dividerForTiming={5}>
                                    {wishlist.title}
                                </MovingTextContainer>
                            )}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={clsx(
                                classes.owner,
                                classes.textOverflow
                            )}
                        >
                            {whileFetching ? (
                                <Skeleton
                                    width={isMobile ? '10%' : '20%'}
                                    variant="text"
                                />
                            ) : (
                                owner?.displayName
                            )}
                        </Typography>
                    </Box>{' '}
                    <Tooltip
                        title={<FormattedMessage id={'share.leave'} />}
                        aria-label="delete"
                        enterDelay={300}
                    >
                        <IconButton onClick={this.handleRemoveClick} size="large">
                            <img
                                className={classes.leaveIcon}
                                src={LeaveIcon}
                                alt={'leave wishlist'}
                            />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withResponsiveInformation(
    withRouter(withStyles(styles)(SharedDesireSAppbar))
);
