import {
    SHARE_WISHLIST_REQUEST,
    SHARE_WISHLIST_SUCCESS,
} from '../actions/WishlistShareActionTypes';

const wishlistShareReducer = (
    state = {
        link: null,
    },
    action
) => {
    switch (action.type) {
        case SHARE_WISHLIST_REQUEST:
            return {
                link: null,
            };
        case SHARE_WISHLIST_SUCCESS:
            return {
                link: action.item.shareUrlShort,
            };
        default:
            return state;
    }
};

export default wishlistShareReducer;
