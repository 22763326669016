import withTheme from '@mui/styles/withTheme';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AppleButton from '../../../../authentication/components/buttons/appleButton/AppleButton';
import FacebookButton from '../../../../authentication/components/buttons/facebookButton/FacebookButton';
import GoogleButton from '../../../../authentication/components/buttons/googleButton/GoogleButton';
import withResponsiveInformation from '../../../../mixins/components/ResponsiveInformation';

const styles = (theme) => ({
    input: {
        display: 'block',
        '& label.Mui-focused': {
            color: theme.palette.grey[500],
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid' + theme.palette.grey[500],
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid' + theme.palette.grey[500],
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: '2px solid' + theme.palette.grey[500],
        },
        marginTop: theme.spacing(2),
        color: theme.palette.common.white,
        '& .MuiInputBase-input': {
            color: theme.palette.grey[500],
        },
        '& .MuiInput-root': {
            color: theme.palette.grey[500],
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.grey[500],
            fontSize: '0.875rem',
        },
    },
    icon: {
        color: theme.palette.common.secondaryAccentColor,
    },
    button: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
        letterSpacing: '1px',
    },
    title: {
        color: theme.palette.common.white,
    },
    content: {
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1),
    },
    email: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
    },
    errorIcon: {
        color: '#cc5c5cba',
        fontSize: '20px',
        marginRight: '8px',
    },
    errorMessage: {
        color: '#cc5c5cba',
    },
    socialButton: {
        marginTop: theme.spacing(2),
    },
});

const SlideTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GrowTransition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

class ReenterPasswordDialog extends Component {
    state = { showPassword: false, password: '' };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const { password } = this.state;
        onSubmit(password);
    };

    getPasswordReauthentication = () => {
        const { classes, intl, error } = this.props;
        const { showPassword } = this.state;

        return (
            <div>
                <FormattedMessage id={'profileDialog.editText'} />
                <FormControl error={true} className={classes.input}>
                    <Input
                        id="standard-password-input"
                        label="Password"
                        autoComplete="current-password"
                        autoFocus
                        placeholder={intl.formatMessage({
                            id: 'auth.password',
                        })}
                        type={showPassword ? 'text' : 'password'}
                        onChange={this.handlePasswordChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    className={classes.icon}
                                    size="large">
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        startAdornment={
                            error ? (
                                <ErrorIcon className={classes.errorIcon} />
                            ) : null
                        }
                    />
                </FormControl>
            </div>
        );
    };

    appleReauthentication = () => {
        const { classes, handleAppleSubmit } = this.props;
        return (
            <div>
                <FormattedMessage id={'profileDialog.editTextSocial'} />
                <AppleButton
                    className={classes.socialButton}
                    onClick={() => handleAppleSubmit()}
                />
            </div>
        );
    };

    facebookReauthentication = () => {
        const { classes, handleFacebookSubmit } = this.props;
        return (
            <div>
                <FormattedMessage id={'profileDialog.editTextSocial'} />
                <FacebookButton
                    className={classes.socialButton}
                    onClick={() => handleFacebookSubmit()}
                />
            </div>
        );
    };

    googleReauthentication = () => {
        const { classes, handleGoogleSubmit } = this.props;
        return (
            <div>
                <FormattedMessage id={'profileDialog.editTextSocial'} />
                <GoogleButton
                    className={classes.socialButton}
                    onClick={() => handleGoogleSubmit()}
                />
            </div>
        );
    };

    getReauthenticationType = () => {
        switch (this.props.signInMethod) {
            case 'apple.com':
                return this.appleReauthentication();
            case 'google.com':
                return this.googleReauthentication();
            case 'facebook.com':
                return this.facebookReauthentication();
            default:
                return this.getPasswordReauthentication();
        }
    };

    render() {
        const { theme, classes, open, onClose, isMobile, error } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.common.darkBackground,
                    },
                }}
                fullScreen={isMobile}
                TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                }
            >
                <DialogTitle className={classes.title}>
                    <FormattedMessage id={'profile.edit'} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        component="div"
                        className={classes.content}
                    >
                        {this.getReauthenticationType()}
                        <Collapse in={error}>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                className={classes.errorMessage}
                            >
                                <FormattedMessage id={'profile.password'} />
                            </Typography>
                        </Collapse>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} className={classes.button}>
                        <FormattedMessage id={'dialog.cancel'} />
                    </Button>
                    <Button
                        onClick={this.handleSubmit}
                        className={classes.button}
                    >
                        <FormattedMessage id={'dialog.ok'} />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withResponsiveInformation(
    withStyles(styles)(withTheme(injectIntl(ReenterPasswordDialog)))
);
