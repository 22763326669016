const appConstants = {
    company: 'x-root Software GmbH',
    imprint: 'https://www.starsnoopy.de/impressum',
    privacyPolicy: 'https://www.starsnoopy.de/datenschutz',
    copyRight: 'https://www.x-root.de/',
    releaseYear: new Date().getFullYear(),
    termsOfUse: 'https://www.starsnoopy.de/nutzungsbedingungen',
};

export default appConstants;
