import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    accept,
    decline,
    initAnalytics,
} from '../../stateManagement/actions/ConsentActions';

const withConsentBanner = (WrappedComponent) => {
    class ConsentBanner extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state) => {
        return {
            analyticsConsent: state.analyticsConsent,
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            accept: () => dispatch(accept()),
            decline: () => dispatch(decline()),
            initAnalytics: () => dispatch(initAnalytics()),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(ConsentBanner);
};

export default withConsentBanner;
