export default class DesireCreationModel {
    constructor(
        title,
        userId,
        wishlistId,
        url,
        imageUrl,
        boughtByUserId,
        reservedByUserId,
        note
    ) {
        this.userId = userId;
        this.backgroundColor = '#FFFEFF';
        this.created = Date.now();
        this.deleted = null;
        this.id = null;
        this.imageUrl = imageUrl;
        this.title = title;
        this.url = url;
        this.wishlistId = wishlistId;
        this.boughtByUserId = boughtByUserId;
        this.reservedByUserId = reservedByUserId;
        this.note = note;
    }
}
