import {
    GET_ALL_SHARED_WISHLIST_REQUEST,
    GET_ALL_SHARED_WISHLIST_ERROR,
    GET_ALL_SHARED_WISHLIST_SUCCESS,
    RESET_SHARED_WISHLIST_STORE,
} from '../actions/SharedWishlistActionTypes';

const sharedWishlistReducer = (
    state = {
        sharedWishlists: [],
        owners: [],
        error: null,
        loading: true,
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_SHARED_WISHLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_SHARED_WISHLIST_SUCCESS:
            return {
                ...state,
                sharedWishlists: action.sharedWishlists,
                owners: action.owners,
                loading: false,
            };
        case GET_ALL_SHARED_WISHLIST_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case RESET_SHARED_WISHLIST_STORE:
            return {
                ...state,
                sharedWishlists: [],
                owners: [],
                error: null,
            };
        default:
            return state;
    }
};

export default sharedWishlistReducer;
