import WishlistShareService from '../../services/WishlistShareService';
import {
    SHARE_WISHLIST_ERROR,
    SHARE_WISHLIST_REQUEST,
    SHARE_WISHLIST_SUCCESS,
} from './WishlistShareActionTypes';
import WishlistService from '../../../main/services/WishlistService';

export const share = (wishlist, intl) => {
    return (dispatch, getState) => {
        const currentUser = getState().authentication.user;
        dispatch({
            type: SHARE_WISHLIST_REQUEST,
            item: wishlist,
            currentUser,
        });
        return WishlistService.getByUserIdAndId(currentUser.uid, wishlist.id)
            .then((wl) =>
                wl.shareUrlShort
                    ? wl
                    : WishlistShareService.share(currentUser, wl, intl)
            )
            .then((result) => {
                dispatch({
                    type: SHARE_WISHLIST_SUCCESS,
                    item: result,
                });
                return result;
            })
            .catch((result) => {
                dispatch({
                    type: SHARE_WISHLIST_ERROR,
                    error: result,
                });
                throw result;
            });
    };
};
