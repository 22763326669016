import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { emphasize } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { firebaseMessaging } from '../../networking/firebase/FirebaseConfig';
import { withSnackbar } from 'notistack';
import { Fragment } from 'react';
import { closeActionSnackbarButton } from '../../snackbar/CustomSnackbarProvider';
import NotificationService from '../service/notificationService';
import withProfile from '../../profile/main/components/hocs/Profile';

const styles = (theme) => ({
    button: {
        fontSize: 12,
    },
    accept: {
        marginRight: '10px',
        border: `1px solid ${theme.palette.common.white}`,
        color: theme.palette.common.white,
        '&:hover': {
            color: emphasize(theme.palette.secondary.main),
        },
    },
});

class PushConsent extends Component {
    state = { isVisible: false };

    componentDidUpdate() {
        this.showSnackbar();
    }

    showSnackbar = () => {
        const {
            enqueueSnackbar,
            closeSnackbar,
            classes,
            profile: {
                data: { id },
            },
        } = this.props;

        if (
            !this.state.isVisible &&
            id &&
            firebaseMessaging &&
            Notification.permission === 'default' &&
            !localStorage.getItem('notification-consent')
        ) {
            this.setState({ isVisible: true });
            enqueueSnackbar(<FormattedMessage id={'notification.consent'} />, {
                variant: 'info',
                action: (key) => (
                    <Fragment>
                        <Button
                            key="accept"
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={() => this.handleAcceptButtonClick(key)}
                            className={clsx(classes.button, classes.accept)}
                        >
                            <FormattedMessage id={'notification.accept'} />
                        </Button>
                        {closeActionSnackbarButton(() => {
                            this.setState({ isVisible: false });
                            closeSnackbar(key);
                            localStorage.setItem('notification-consent', 0);
                        })}
                    </Fragment>
                ),
            });
        }
    };

    handleAcceptButtonClick = (key) => {
        const {
            closeSnackbar,
            profile: {
                data: { id },
            },
        } = this.props;

        this.setState({ isVisible: false });
        closeSnackbar(key);
        localStorage.setItem('notification-consent', 1);
        Notification.requestPermission()
            .then(
                (permission) =>
                    permission === 'granted' && firebaseMessaging.getToken()
            )
            .then((token) => token && NotificationService.saveToken(id, token))
            .catch(() => console.warn('error during saving push token'));
    };

    render() {
        return this.props.children;
    }
}

export default withProfile(withSnackbar(withStyles(styles)(PushConsent)));
