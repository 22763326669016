import {
    AUTHENTICATION_INITIALIZE_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
} from '../actions/AuthenticationActionTypes';

const DEFAULT_STATE = {
    user: null,
    isAuthenticated: false,
};

const authenticationReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATION_INITIALIZE_SUCCESS:
            return {
                ...state,
                user: action.user,
                isAuthenticated: action.user !== null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                isAuthenticated: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            };
        default:
            return state;
    }
};

export default authenticationReducer;
