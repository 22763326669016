import { firebaseInstance } from '../../../networking/firebase/FirebaseConfig';

const calculateNewSize = (height, width, maxHeight = 1024, maxWidth = 1024) => {
    let newHeight = null;
    let newWidth = null;

    if (height > maxHeight) {
        const percentage = maxHeight / height;
        newHeight = height * percentage;
        newWidth = width * percentage;
        if (newWidth > maxWidth) {
            const percentage = maxWidth / newWidth;
            newHeight = height * percentage;
            newWidth = width * percentage;
        }
    }

    if (newWidth === null && width > maxWidth) {
        const percentage = maxWidth / width;
        newHeight = height * percentage;
        newWidth = width * percentage;
    }
    return { height: newHeight, width: newWidth };
};

const resizeImage = (file, maxHeight, maxWidth) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.name = file.name;
        reader.size = file.size;
        reader.onload = function (event) {
            const img = new Image();
            img.src = event.target.result;
            img.name = event.target.name;
            img.size = event.target.size;

            img.onload = function (el) {
                if (img.width > maxWidth || img.height > maxHeight) {
                    const { height, width } = calculateNewSize(
                        img.height,
                        img.width,
                        maxHeight,
                        maxWidth
                    );

                    const elem = document.createElement('canvas');

                    elem.width = width;
                    elem.height = height;

                    const ctx = elem.getContext('2d');
                    ctx.drawImage(el.target, 0, 0, width, height);

                    ctx.canvas.toBlob((blob) => {
                        const canvasFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        });
                        resolve(canvasFile);
                    });
                } else {
                    resolve(file);
                }
            };
        };
    });
};

class ImageService {
    constructor(firebaseInstance) {
        this.firebaseInstance = firebaseInstance;
    }

    uploadImage = async (file, userId) => {
        const storageRef = this.firebaseInstance.storage().ref();

        const resizedImage = await resizeImage(file, 500, 500);

        if (this.isFileImage(resizedImage)) {
            const metadata = {
                contentType: file.type,
            };
            return storageRef
                .child(`avatars/${userId}.jpg`)
                .put(resizedImage, metadata)
                .then((data) => {
                    return data.ref.getDownloadURL().then((downloadURL) => {
                        console.info('File available at', downloadURL);
                        return this.persistImage(downloadURL);
                    });
                });
        }
        return Promise.reject({ reason: 'file is not an image' });
    };

    uploadDesireImage = async (file, userId, desireId) => {
        const storageRef = this.firebaseInstance.storage().ref();

        const resizedImage = await resizeImage(file, 1024, 1024);

        if (this.isFileImage(resizedImage)) {
            const metadata = {
                contentType: file.type,
            };

            const data = await storageRef
                .child(`wishImages/${userId}/${desireId}.png`)
                .put(resizedImage, metadata);

            return await data.ref.getDownloadURL();
        }
        return Promise.reject({ reason: 'file is not an image' });
    };

    deleteImage = (url) => {
        try {
            url && this.firebaseInstance.storage().refFromURL(url).delete();
        } catch (e) {
            console.warn(`error deleting image: ${e}`);
        }
    };

    persistImage = (profileImageURL) => {
        const currentUser = this.firebaseInstance.auth().currentUser;
        const userId = currentUser.uid;

        const updatedProfile = {
            profileImageURL,
        };

        return this.firebaseInstance
            .database()
            .ref(`users/${userId}`)
            .update(updatedProfile)
            .catch((error) => {
                console.error(
                    'could not persist imageURL in firebase db',
                    error
                );
            });
    };

    isFileImage = (file) => {
        return file && file['type'].split('/')[0] === 'image';
    };
}

export default new ImageService(firebaseInstance);
