import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AppConstants from '../../../app/constants/AppConstants';

const styles = (theme) => ({
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
    },
    copyright: {
        fontSize: '0.8rem',
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
});

class DrawerFooter extends Component {
    render() {
        const { classes, textColor } = this.props;
        return (
            <footer className={classes.footer}>
                <Divider className={classes.divider} />
                <Container maxWidth="sm">
                    <Typography
                        variant="body2"
                        color={textColor}
                        align="center"
                    >
                        <Link
                            color={textColor}
                            href={AppConstants.imprint}
                            rel="noopener noreferrer"
                            target="_blank"
                            underline="hover"
                        >
                            {<FormattedMessage id={'auth.imprint'} />}
                        </Link>
                        {'  |  '}
                        <Link
                            color={textColor}
                            href={AppConstants.privacyPolicy}
                            rel="noopener noreferrer"
                            target="_blank"
                            underline="hover"
                        >
                            {<FormattedMessage id={'auth.privacy-policy'} />}
                        </Link>
                    </Typography>
                </Container>
                <Container maxWidth="sm">
                    <Typography
                        className={classes.copyright}
                        variant="body2"
                        color={textColor}
                        align="center"
                    >
                        {'© '}
                        <Link
                            color={textColor}
                            href={AppConstants.copyRight}
                            rel="noopener noreferrer"
                            target="_blank"
                            underline="hover"
                        >
                            {` ${AppConstants.company} `}
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Container>
            </footer>
        );
    }
}

export default withStyles(styles)(DrawerFooter);
