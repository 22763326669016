const de = {
    'analytics.accept': 'akzeptieren',
    'analytics.decline': 'ablehnen',
    'analytics.text':
        'Hi, ja auch StarSnoopy nutzt Cookies und Analysetechnologien. Das ist wirklich super wichtig, denn nur so können wir dein Nutzererlebnis stets verbessern. Danke für deine Unterstützung ❤',

    'about.version': 'Version {versionNumber}',
    'about.starsnoopy': 'StarSnoopy',
    'about.facebook': 'Facebook',
    'about.gotToFacebook': 'zur Facebookseite',
    'about.termsOfUse': 'Nutzungsbedingungen',
    'about.gotoTermsOfUse': 'Nutzungsbedingungen',
    'about.starSnoopyHomepage': 'StarSnoopy Website',
    'about.gotToStarSnoopy': 'Website',
    'about.feedback': 'Feedback',
    'about.sendFeedback': 'Feedback senden',
    'about.share': 'Empfehlen',
    'about.tracking': 'Analysetechnologie verwenden',

    '404.Oops':
        'HOPPLA! {newLine} Was ist denn da passiert? Kein Problem, ich check das für Dich!',
    '404.redirect': 'Hier kommst Du erstmal wieder {redirectToWishlist}.',
    '404.redirectToWishlist': 'zurück zur Wunschlistenseite',
    'auth.login': 'anmelden',
    'auth.login-with-questionmark': 'anmelden?',
    'auth.register': 'registrieren',
    'auth.register-with-questionmark': 'registrieren?',
    'auth.password-reset': 'zurücksetzen',
    'auth.signin-with-apple': 'mit apple anmelden',
    'auth.signin-with-facebook': 'mit facebook anmelden',
    'auth.signin-with-google': 'mit google anmelden',
    'auth.forgot-password': 'PASSWORT VERGESSEN?',
    'auth.name': 'Name',
    'auth.email': 'E-Mail',
    'auth.password': 'Passwort',
    'auth.password-repeat': 'Passwort wiederholen',
    'auth.privacy-policy': 'Datenschutz',
    'auth.imprint': 'Impressum',
    'auth.account-exists':
        'Es existiert bereits ein Konto mit derselben E-Mail-Adresse, aber unterschiedlicher Anmeldemethode.',
    'auth.google-account-exists':
        'Es existiert bereits ein Google-Konto mit derselben E-Mail-Adresse, aber unterschiedlicher Anmeldemethode.',
    'auth.email-account-exists':
        'Es existiert bereits ein E-mail/Passwort-Konto mit derselben E-Mail-Adresse, aber unterschiedlicher Anmeldemethode.',
    'auth.facebook-account-exists':
        'Es existiert bereits ein Facebook-Konto mit derselben E-Mail-Adresse, aber unterschiedlicher Anmeldemethode.',
    'auth/wrong-password':
        'Die E-Mail-Adresse oder das Passwort ist nicht korrekt.',

    'auth.email-already-in-use':
        'Die E-Mail-Adresse wird bereits von einem anderen Konto verwendet.',
    'auth.invalid-email': 'Die eingegebene E-Mail-Adresse ist ungültig.',
    'auth.weak-password': 'Das Passwort muss mindestens 6 Zeichen lang sein.',
    'auth.passwords-not-matching': 'Das Passwörter stimmen nicht überein.',
    'auth.social-authentification-fail': 'Anmeldung fehlgeschlagen.',
    'auth.too-many-requests':
        'Wir haben alle Anfragen von diesem Gerät aufgrund ungewöhnlicher Aktivitäten blockiert. Versuchen Sie es später noch einmal.',
    'auth.user-not-found': 'Benutzer nicht gefunden.',
    'auth.error-default': 'Anmeldung fehlgeschlagen.',
    'auth.no-name': 'Bitte gib Deinen Namen ein.',
    'auth.write-fail': 'Upps! Da ist was schief gelaufen.',

    'auth.privacy-label-1': 'Ja, ich habe die',
    'auth.privacy-label-2': 'gelesen und stimme dieser zu.',
    'auth.privacy-policy-login': 'Datenschutzerklärung',
    'auth.terms-of-use': 'Nutzungsbedingungen',

    'auth.privacy-error': 'Bitte stimmen Sie der Datenschutzerklärung  zu.',
    'auth.verify': 'Bestätigungsmail wurde versendet',
    'auth.verifyHint':
        'Bereit für große Wunschmagie? Fehlt nur noch eine Bestätigung deiner E-Mail-Adresse!',

    'login.info': 'Wünschen, teilen, überraschen!',

    'nav.my-wishlist': 'Meine Wunschlisten',
    'nav.shared-wishlist': 'Wünsche meiner Freunde',
    'nav.profile': 'Profil',

    'desireOfHeart.name': 'Herzenswunsch',
    'desireOfHeart.new': 'Neuer Herzenswunsch',
    'desireOfHeart.create': 'Herzenswunsch erstellen',
    'desireLink': 'Link zu',

    'settings.german': 'Deutsch',
    'settings.english': 'English',
    'settings.french': 'Français',
    'settings.logout': 'Abmelden',
    'settings.language': 'Sprache',
    'settings.profile': 'Profil',
    'settings.faq': 'FAQ',
    'settings.about': 'Über uns',

    'sharedWishlistCard.bought': 'Gekauft von {username}',
    'sharedWishlistCard.reserved': 'Reserviert von {username}',

    'passwordDialog.title': 'Eine E-Mail wurde an Dich geschickt.',
    'passwordDialog.content':
        ' Klicke auf den Link in dieser E-Mail, um Dein Passwort zurückzusetzen und kehre danach hierher zurück um Dich anzumelden.',
    'passwordDialog.email-send': 'E-Mail gesendet',
    'dialog.ok': 'ok',
    'dialog.cancel': 'Abbrechen',

    'delete.timeout': 'Zeitüberschreitung. Bitte wiederhole den Vorgang.',
    'delete.fail': 'Upps! Da ist was schief gelaufen.',

    'profile.done': 'fertig',
    'profile.logout': 'Abmelden',
    'profile.delete': 'Account löschen',
    'profile.success': 'Ihre Profilinformation wurde erfolgereich geändert.',
    'profile.edit': 'E-Mail ändern',
    'profile.password': 'Falsches Passwort',
    'profile.save': 'speichern',

    'profileDialog.deleteAccount': 'Konto Löschen',
    'profileDialog.operation':
        'Für diese sensible Operation musst Du dich erneut anmelden.',
    'profileDialog.deleteText':
        'Möchtest Du wirklich Dein StarSnoopy-Konto löschen? Diese Aktion kann nicht rückgängig gemacht!',
    'profileDialog.editText':
        'Für diese sensible Operation musst Du dein Passwort erneut eingeben:',
    'profileDialog.editTextSocial':
        'Für diese sensible Operation musst Du dich erneut anmelden.',

    'wishlist.default': 'Meine erste Wunschliste',
    'wishlist.add': 'Wunschliste erstellen',
    'wishlist.newWish': 'Neue Wunschliste',
    'wishlist.name': 'Wunschlisten-Name',
    'wishlist.note': 'Wunschlisten-Notiz',
    'wishlist.cancel': 'abbrechen',
    'wishlist.create': 'erstellen',
    'wishlist.next': 'weiter',
    'wishlist.back': 'zurück',
    'wishlist.error': 'Bitte gib einen Wunschlisten-Namen ein.',
    'wishlist.save': 'speichern',
    'wishlist.changeWish': 'Wunschliste bearbeiten',
    'wishlist.wishlist': 'Wunschliste',
    'wishlist.deleted': 'gelöscht',
    'wishlist.edit': 'Wunschliste bearbeiten',
    'wishlist.deleteTooltip': 'Wunschliste löschen',
    'wishlist.share': 'Wunschliste teilen',
    'wishlist.shareOnFacebook': 'Auf Facebook teilen',
    'wishlist.shareOnTwitter': 'Auf Twitter teilen',
    'wishlist.shareWithMail': 'Per E-Mail teilen',
    'wishlist.shareWithMailSubject': 'Meine Wunschliste',
    'wishlist.shareWithMailBody': "Sieh' dir meine Wunschliste auf {link} an!",
    'wishlist.settings': 'Einstellungen',
    'wishlist.empty':
        'Soo viele wunderbare Wünsche und noch keine Wunschliste?',
    'wishlist.start': 'Lass uns direkt loslegen!',
    'wishlist.pushButton':
        'Klicke auf das + Symbol, um deine erste Wunschliste anzulegen!',
    'wishlist.ps':
        'P.S. Wunschlisten kannst du mit Deinen Liebsten teilen! Halte hierfür einfach Ausschau nach diesem Symbol.',
    'wishlist.shareTitle': 'Teilen',
    'wishlist.copy': 'kopieren',
    'wishlist.copiedLink': 'Link in die Zwischenablage kopiert',
    'wishlist.shareDownloadQrCode': 'Download QR-Code',
    'wishlist.shareCopyQrCode': 'QR-Code in die Zwischenablage kopieren',
    'wishlist.copiedQR': 'QR-Code in die Zwischenablage kopiert',
    'wishlist.socialMetaTagTitle': 'Wunschliste betreiten | "{wishlistName}"',
    'wishlist.socialMetaTagDescription':
        'Wünsche gehen in Erfüllung, wenn sie geteilt werden.',
    'wishlist.404scrapper':
        'Da ist wohl etwas schiefgelaufen. Probier es doch mit einem anderen Link!',
    'wishlist.orCreateDesireOfHeart': 'Oder erstelle einen Wunsch ohne URL!',
    'wishlist.downloadQRCodeAsPdf': 'QR Code als PDF downloaden',
    'wishlist.showQRCode': 'QR-Code anzeigen',
    'wishlist.hideQRCode': 'QR-Code ausblenden',
    'wishlist.qrCodeText':
        'Versende diesen QR-Code zu Deiner Wunschliste an Deine Familie und Freunde, oder drucke ihn aus:',
    'wishlist.addingOwnWishlist':
        'Du kannst nicht Deiner eigenen Wunschliste beitreten.',
    'wishlist.invalidUrl': 'ungütlige URL',
    'wishlist.updateOwnImageQuestion': 'Kein geeignetes Bild gefunden?',
    'wishlist.noURLQuestion': 'Keine URL zum Wunsch?',

    'wish.edit': 'Wunsch bearbeiten',
    'wish.delete': 'Wunsch entfernen',
    'wish.move': 'Wunsch verschieben',
    'wish.buy': 'Jetzt kaufen',
    'wish.new': 'Neuer Wunsch',
    'wish.created': 'Wunsch erstellt',
    'wish.moved': 'Wunsch nach {title} verschoben',
    'wish.url': 'URL',
    'wish.insertUrl': 'URL einfügen',
    'wish.wish': 'Wunsch',
    'wish.create': 'Wunsch hinzufügen',
    'wish.continue': 'weiter',
    'wish.empty': 'Diese Wunschliste ist noch leer.',
    'wish.pushButton': 'Um dir etwas neues zu wünchen, drücke auf +',
    'wish.undoSnackbar': 'Wunsch gelöscht',
    'wish.uploadImage': 'Hier klicken um ein eigenes Bild hochzuladen',
    'wishl.note': 'Notiz: ',
    'wish.createNoteText': '+ Notiz hinzufügen',
    'wish.createNote': 'Notiz hinzufügen',
    'wish.removeNote': 'Notiz entfernen',
    'wish.displayNote': 'Notiz anzeigen',
    'wish.displayTitle': 'Wunschtitel anzeigen',
    'wish.displayMenu': 'Menü anzeigen',

    'share.left': 'verlassen',
    'share.of': 'von',
    'share.empty': 'Es hat noch niemand seine Wunschliste mit dir geteilt.',
    'share.invite': 'Lade Freunde ein, StarSnoopy zu nutzen.',
    'share.inviteButton': 'Freunde einladen',
    'share.leave': 'Wunschliste verlassen',
    'share.reserve': 'Reservieren',
    'share.buyed': 'Als gekauft markieren',
    'share.release': 'Freigeben',

    'starsnoopy.homepage': 'https://www.starsnoopy.de/',

    'app.changeLanguage': 'Sprache ändern',
    'app.default': '',
    'app.undo': 'rückgängig',
    'app.playStore':
        'Übrigens, StarSnoopy gibt es auch als App! Hol sie dir kostenlos!',
    'app.allow': 'Ja, ok!',
    'app.deny': 'Lieber nicht',
    'app.insertUrl': 'URL aus der Zwischenablage einfügen',
    'app.askForClipboardReadPermissionQuestion':
        'Noch einfacher Wünsche anlegen! Dürfen wir dir helfen?',
    'app.askForClipboardReadPermissionExplanation':
        'Dafür müssen wir lediglich Links aus deiner Zwischenablage abrufen können.',
    'app.redirectToWishlist': 'Zur Wunschliste',
    'app.close': 'Schließen',

    'notification.consent':
        'Bleib up to date über die aktuellsten Wünsche deiner Liebsten. Aktiviere dafür die Mitteilungen.',
    'notification.accept': 'Aktivieren',

    'notification.newWish': 'Updates zur Wunschliste',

    'notification.reservedNoneLeft': `{person_name} hat einen Wunsch aus der Liste {wishlist_title} reserviert. Es sind keine unerfüllten Wünsche mehr übrig.`,
    'notification.reservedOneLeft': `{person_name} hat einen Wunsch aus der Liste {wishlist_title} reserviert. Es ist noch {wishes_left} unerfüllter Wunsch übrig.`,
    'notification.reservedMultipleLeft': `{person_name} hat einen Wunsch aus der Liste {wishlist_title} reserviert. Es sind noch {wishes_left} unerfüllte Wünsche übrig.`,

    'notification.boughtNoneLeft': `{person_name} hat einen Wunsch aus der Liste {wishlist_title} erfüllt. Es sind keine unerfüllten Wünsche mehr übrig.`,
    'notification.boughtOneLeft': `{person_name} hat einen Wunsch aus der Liste {wishlist_title} erfüllt. Es ist noch {wishes_left} unerfüllter Wunsch übrig.`,
    'notification.boughtMultipleLeft': `{person_name} hat einen Wunsch aus der Liste {wishlist_title} erfüllt. Es sind noch {wishes_left} unerfüllte Wünsche übrig.`,
};
export default de;
