import React, { Component } from 'react';
import {
    getAll,
    create,
    remove,
    restore,
    registerObserver,
    unregisterObserver,
} from '../../stateManagement/actions/SharedWishlistActions';
import { connect } from 'react-redux';

const withSharedWishlist = (WrappedComponent) => {
    class SharedWishlist extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            getAllSharedWishlists: () => dispatch(getAll()),
            createSharedWishlist: (model) => dispatch(create(model)),
            removeSharedWishlist: (sharedWishlist) =>
                dispatch(remove(sharedWishlist)),
            restoreSharedWishlist: (sharedWishlist) =>
                dispatch(restore(sharedWishlist)),
            registerSharedWishlistObserver: (callback) =>
                dispatch(registerObserver(callback)),
            unregisterSharedWishlistObserver: (callback) =>
                dispatch(unregisterObserver(callback)),
        };
    };

    const mapStateToProps = (state) => {
        return {
            sharedWishlist: state.sharedWishlist,
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(SharedWishlist);
};

export default withSharedWishlist;
