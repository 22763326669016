import React, { Component } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '../../../mixins/components/icons/Icon';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    text: {
        fontFamily: theme.typography.sideBarFontFamily,
        color: theme.palette.primary.contrastText,
    },
    icon: {
        marginTop: '4px',
    },
    root: {
        minHeight: '72px',
        color: theme.palette.primary.contrastText,
    },
});

class DrawerListItem extends Component {
    render() {
        const { classes, item, active, onClick } = this.props;
        return (
            <ListItem
                button
                className={classes.root}
                selected={active}
                onClick={onClick}
                component={Link}
                to={item.path}
            >
                <ListItemIcon className={classes.icon}>
                    <div>
                        <Icon icon={item.icon} alt={item.iconHint} />
                    </div>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography className={classes.text}>
                            <FormattedMessage id={item.titleMessageId} />
                        </Typography>
                    }
                />
            </ListItem>
        );
    }
}

export default withStyles(styles)(DrawerListItem);
