import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const animatedClassName = 'animatedCardHeaderItem';
const propertyLeftTitleName = '--left-title';
const propertyTransitionDurationName = '--transition-duration-title';

const styles = (theme) => ({
    animation: {
        left: `var(${propertyLeftTitleName})`,
        transitionDuration: `var(${propertyTransitionDurationName})`,
        position: 'relative',
        transitionProperty: 'left',
        transitionTimingFunction: 'ease-in-out',
        transitionDelay: '5s',
    },
});

const isOverflown = ({
    clientWidth,
    clientHeight,
    scrollWidth,
    scrollHeight,
}) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

const addAnimationIfOverflown = (classes, dividerForTiming = 5) => {
    const elements = Array.from(
        document.getElementsByClassName(animatedClassName)
    );

    if (elements.length === 0) {
        setTimeout(() => addAnimationIfOverflown(classes), 1000);
        return;
    }

    elements
        .filter(
            (element) =>
                !element.classList.contains(classes.animation) &&
                isOverflown(element)
        )
        .forEach((element) => {
            element.addEventListener('transitionend', () => {
                updateTransition(element);
            });
            element.classList.add(classes.animation);
            const duration = calculateMovement(element) / dividerForTiming;

            const style = document.documentElement.style;
            style.setProperty(propertyTransitionDurationName, duration + 's');
            style.setProperty(propertyLeftTitleName, '0%');

            updateTransition(element);
        });
};

const calculateMovement = (element) => {
    return (
        ((element.scrollWidth - element.clientWidth) / element.clientWidth) *
        100
    );
};

function updateTransition(element) {
    const style = document.documentElement.style;
    const defaultPosition = '0%';
    const percentValue = -1 * calculateMovement(element) + '%';

    if (style.getPropertyValue(propertyLeftTitleName) === percentValue) {
        style.setProperty(propertyLeftTitleName, defaultPosition);
    } else if (
        style.getPropertyValue(propertyLeftTitleName) === defaultPosition
    ) {
        style.setProperty(propertyLeftTitleName, percentValue);
    } else {
        style.setProperty(propertyLeftTitleName, defaultPosition);
    }
}

function removeCssCustomProperties() {
    const style = document.documentElement.style;
    style.setProperty(propertyTransitionDurationName, null);
    style.setProperty(propertyLeftTitleName, null);
}

class MovingTextContainer extends Component {
    componentDidMount() {
        const { classes, dividerForTiming } = this.props;
        addAnimationIfOverflown(classes, dividerForTiming);
    }

    componentWillUnmount() {
        removeCssCustomProperties();
    }
    render() {
        const { foreignClassName } = this.props;
        return (
            <div className={clsx(animatedClassName, foreignClassName)}>
                {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles)(MovingTextContainer);
