import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import withPrimaryAppbar from '../menu/appBar/primaryAppBar/components/hocs/PrimaryAppbar';
import ResponsiveFooter from '../wishlist/main/components/ResponsiveFooter';
import AboutAppbar from './appBar/AboutAppbar';
import AboutContent from './content/AboutContent';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        'overflow-x': 'hidden',
        'overflow-y': 'auto',
    },
    responsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
        marginTop: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
});

class AboutPage extends Component {
    componentDidMount() {
        const { hidePrimaryAppBar } = this.props;
        hidePrimaryAppBar();
    }

    componentWillUnmount() {
        const { showPrimaryAppBar } = this.props;
        showPrimaryAppBar();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <main className={classes.responsive}>
                    <AboutAppbar />
                    <AboutContent />
                </main>
                <ResponsiveFooter textColor="textPrimary" />
            </div>
        );
    }
}

export default withPrimaryAppbar(withStyles(styles)(AboutPage));
