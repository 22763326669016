import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import LoginPage from '../../../authentication/components/page/LoginPage';
import ProfilePage from '../../../profile/main/components/page/ProfilePage';
import WishListPage from '../../../wishlist/page/components/WishListPage';
import SharedWishListPage from '../../../sharedWishlist/components/page/SharedWishListPage';
import SharedWishListCreationPage from '../../../sharedWishlist/components/page/SharedWishListCreationPage';
import SharedDesiresPage from '../../../sharedDesires/components/page/SharedDesiresPage';
import FourZeroFour from '../../../FourZeroFour/FourZeroFour';
import AboutPage from '../../../about/AboutPage';
import DesiresPage from '../../../desires/components/page/DesiresPage';

function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={LoginPage} />
                <ProtectedRoute
                    path="/wishlist"
                    exact
                    component={WishListPage}
                />
                <ProtectedRoute path="/profile" exact component={ProfilePage} />
                <ProtectedRoute path="/about" exact component={AboutPage} />
                <ProtectedRoute
                    path="/friendswishlist"
                    exact
                    component={SharedWishListPage}
                />
                <ProtectedRoute
                    path="/wishlist/:wishlistId/owner/:ownerId"
                    component={SharedWishListCreationPage}
                />
                <ProtectedRoute
                    path="/:wishlistId/owner/:ownerId"
                    component={SharedWishListCreationPage}
                />
                <ProtectedRoute
                    path="/friendswishlist/:wishlistId/"
                    component={SharedDesiresPage}
                />
                <ProtectedRoute
                    path="/wishlist/:id"
                    exact
                    component={DesiresPage}
                />
                <ProtectedRoute component={FourZeroFour}>
                    {window.location.pathname === '/' && (
                        <Redirect to="/wishlist" />
                    )}
                </ProtectedRoute>
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
