import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import { LocalizationTypes } from '../components/LocalizationTypes';

const storeLocalization = ({ flag }) => {
    localStorage.setItem('localizationFlag', flag);
};

const getLocalizationFromNavigator = () => {
    const { languages } = navigator;
    const [languageFlag = 'en'] = (languages || [])
        .map((loc) => loc.substr(0, 2))
        .filter((lang) =>
            LocalizationTypes.map(({ flag }) => flag).includes(lang)
        );

    const [localizationType = LocalizationTypes[0]] = LocalizationTypes.filter(
        ({ flag }) => flag === languageFlag
    );

    return localizationType;
};

export const loadLocalization = () => {
    const flag = localStorage.getItem('localizationFlag');
    if (!flag) {
        return getLocalizationFromNavigator();
    }
    return LocalizationTypes.find((localization) => localization.flag === flag);
};

class LocalizationService {
    initialize() {
        const result = loadLocalization();
        moment.locale(result.flag);
        return result;
    }

    change(value) {
        return new Promise((resolve) => {
            moment.locale(value.flag);
            storeLocalization(value);
            resolve({ value });
        });
    }
}

export default new LocalizationService();
