import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon from '../../../../../mixins/components/icons/Icon';
import MenuTabSliderItems from '../../../../model/MenuItems';

const styles = (theme) => ({
    tabRoot: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    indicator: {
        backgroundColor: theme.palette.common.accentColor,
    },
    tabs: {
        '& .Mui-selected': {
            color: theme.palette.primary.contrastText,
        },
    },
    tabItem: {
        fontFamily: theme.typography.tabFontFamily,
        color: theme.palette.primary.contrastText,
    },
});

class MenuTabSlider extends Component {
    renderMenuItem(item) {
        const { classes } = this.props;
        return (
            <Tab
                label={<FormattedMessage id={item.titleMessageId} />}
                icon={<Icon icon={item.icon} alt={item.iconHint} />}
                key={item.titleMessageId}
                className={classes.tabItem}
                component={Link}
                to={item.path}
            />
        );
    }

    render() {
        const { classes, selectedItem } = this.props;
        const indexOfSelectedItem = selectedItem
            ? MenuTabSliderItems.indexOf(selectedItem)
            : 0;
        return (
            <Tabs
                value={indexOfSelectedItem}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
                classes={{
                    root: classes.tabRoot,
                    indicator: classes.indicator,
                }}
            >
                {MenuTabSliderItems.map((item) => this.renderMenuItem(item))}
            </Tabs>
        );
    }
}

export default withStyles(styles)(MenuTabSlider);
