import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import PrimaryButton from '../../../mixins/components/buttons/PrimaryButton';
import SecondaryButton from '../../../mixins/components/buttons/SecondaryButton';
import Divider from '../../../mixins/components/Divider';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';
import EmailField from '../../../mixins/components/textFields/EmailField';
import PasswordRepeatField from '../../../mixins/components/textFields/PasswordRepeatField';
import PrimaryPasswordField from '../../../mixins/components/textFields/PrimaryPasswordField';
import UsernameField from '../../../mixins/components/textFields/UsernameField';
import UserMessage from '../../../mixins/components/userMessage/UserMessage';
import AppleButton from '../buttons/appleButton/AppleButton';
import FacebookButton from '../buttons/facebookButton/FacebookButton';
import GoogleButton from '../buttons/googleButton/GoogleButton';
import PrivacyCheckbox from '../checkbox/PrivacyCheckbox';
import Logo from '../logo/Logo';
import PasswordResetLink from './passwordForgotten/PasswordResetLink';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '100%',
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
});

const LOGIN_STATE = {
    rightButtonMessageId: 'auth.login',
    leftButtonMessageId: 'auth.register-with-questionmark',
    showUserNameField: false,
    showPasswordField: true,
    showPasswordResetLink: true,
    showPwRepeatField: false,
    showSocialLogin: true,
    showPrivacyCheckbox: false,
};

const PASSWORD_RESET_STATE = {
    rightButtonMessageId: 'auth.password-reset',
    leftButtonMessageId: 'auth.login-with-questionmark',
    showUserNameField: false,
    showPasswordResetLink: false,
    showPwRepeatField: false,
    showPasswordField: false,
    showSocialLogin: false,
    showPrivacyCheckbox: false,
};

const REGISTER_STATE = {
    rightButtonMessageId: 'auth.register',
    leftButtonMessageId: 'auth.login-with-questionmark',
    showUserNameField: true,
    showPasswordField: true,
    showPasswordResetLink: false,
    showPwRepeatField: true,
    showSocialLogin: true,
    showPrivacyCheckbox: true,
};

class Login extends Component {
    state = {
        name: '',
        email: '',
        password: '',
        repeatedPassword: '',
        privacyChecked: false,
        pageState: LOGIN_STATE,
    };

    setLogin() {
        this.handlePageStateChange(LOGIN_STATE);
    }

    setPasswordReset() {
        this.handlePageStateChange(PASSWORD_RESET_STATE);
    }

    setRegister() {
        this.handlePageStateChange(REGISTER_STATE);
    }

    handleEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    handleNameChange = ({ target }) => {
        this.setState({ name: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleRepeatedPasswordChange = ({ target }) => {
        this.setState({ repeatedPassword: target.value });
    };

    handlePasswordResetClick = (event) => {
        event.preventDefault();
        this.setPasswordReset();
    };

    handlePageStateChange = (pageState) => {
        const { onPageStateChange } = this.props;
        this.setState({ pageState: pageState });
        if (onPageStateChange) {
            onPageStateChange(pageState);
        }
    };

    handlePrivacyChange = (event) => {
        this.setState({ privacyChecked: event.target.checked });
    };

    handleLogin = () => {
        const { onLogin } = this.props;
        const { email, password, pageState, privacyChecked } = this.state;
        if (onLogin) {
            onLogin({
                way: 'standard',
                email,
                password,
                pageState,
                privacyChecked,
            });
        }
    };

    handleAppleButtonClick = () => {
        if (this.state.pageState === REGISTER_STATE) {
            this.handleSocialRegister('apple');
        } else {
            this.handleSocialLogin('apple');
        }
    };

    handleFacebookButtonClick = () => {
        const { pageState } = this.state;
        if (pageState === REGISTER_STATE) {
            this.handleSocialRegister('facebook');
        } else {
            this.handleSocialLogin('facebook');
        }
    };

    handleGoogleButtonClick = () => {
        const { pageState } = this.state;
        if (pageState === REGISTER_STATE) {
            this.handleSocialRegister('google');
        } else {
            this.handleSocialLogin('google');
        }
    };

    handleSocialLogin = (way) => {
        const { onLogin } = this.props;
        onLogin &&
            onLogin({
                way,
            });
    };

    handleSocialRegister = (way) => {
        const { onRegister } = this.props;
        const { privacyChecked } = this.state;
        if (onRegister) {
            onRegister({
                way,
                privacyChecked,
            });
        }
    };

    handlePasswordReset = () => {
        const { onPasswordReset } = this.props;
        const { email } = this.state;
        if (onPasswordReset) {
            onPasswordReset({ email: email });
        }
    };

    handleRegister = async () => {
        const { onRegister } = this.props;
        const {
            email,
            password,
            repeatedPassword,
            name,
            privacyChecked,
        } = this.state;

        if (onRegister) {
            await onRegister({
                way: 'standard',
                email,
                password,
                repeatedPassword,
                name,
                privacyChecked,
            });
        }
    };

    handleRightButtonClick = (e) => {
        const { pageState } = this.state;
        e.preventDefault();
        switch (pageState) {
            case LOGIN_STATE:
                this.handleLogin();
                break;
            case PASSWORD_RESET_STATE:
                this.handlePasswordReset();
                break;
            case REGISTER_STATE:
                this.handleRegister();
                break;
            default:
                break;
        }
    };

    handleLeftButtonClick = (e) => {
        const { pageState } = this.state;
        e.preventDefault();
        switch (pageState) {
            case LOGIN_STATE:
                this.setRegister();
                break;
            case PASSWORD_RESET_STATE:
            case REGISTER_STATE:
                this.setLogin();
                break;
            default:
                break;
        }
    };

    render() {
        const { classes, error, width } = this.props;
        const { pageState, privacyChecked } = this.state;
        const hasError = error != null;

        return (
            <div className={classes.root}>
                {(width === 'xs' || width === 'sm') && <Logo />}
                <form className={classes.form} noValidate>
                    <Paper className={classes.paper}>
                        <Collapse in={pageState.showUserNameField}>
                            <UsernameField onChange={this.handleNameChange} />
                            <Divider />
                        </Collapse>
                        <EmailField onChange={this.handleEmailChange} />
                        <Collapse in={pageState.showPasswordField}>
                            <Divider />
                            <PrimaryPasswordField
                                onChange={this.handlePasswordChange}
                            />
                        </Collapse>
                        <Collapse in={pageState.showPwRepeatField}>
                            <Divider />
                            <PasswordRepeatField
                                onChange={this.handleRepeatedPasswordChange}
                            />
                        </Collapse>
                        <Collapse in={hasError}>
                            <Divider />
                            {hasError && (
                                <UserMessage messageId={error.messageId} />
                            )}
                        </Collapse>
                    </Paper>
                    <Collapse in={pageState.showPrivacyCheckbox}>
                        <PrivacyCheckbox
                            checked={privacyChecked}
                            onChange={this.handlePrivacyChange}
                        />
                    </Collapse>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <SecondaryButton
                                text={pageState.leftButtonMessageId}
                                onClick={this.handleLeftButtonClick}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                text={pageState.rightButtonMessageId}
                                onClick={this.handleRightButtonClick}
                            />
                        </Grid>
                    </Grid>
                    <Fade in={pageState.showSocialLogin}>
                        <div>
                            <AppleButton
                                onClick={this.handleAppleButtonClick}
                            />
                            <FacebookButton
                                onClick={this.handleFacebookButtonClick}
                            />
                            <GoogleButton
                                onClick={this.handleGoogleButtonClick}
                            />
                        </div>
                    </Fade>
                    <PasswordResetLink
                        open={pageState.showPasswordResetLink}
                        onClick={this.handlePasswordResetClick}
                    />
                </form>
            </div>
        );
    }
}

export default withResponsiveInformation(withStyles(styles)(Login));
