import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import leafs from '../resources/images/leafs.png';

const styles = () => ({
    divider: {
        width: '60%',
    },
});

class DividerPicture extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <img className={classes.divider} src={leafs} alt="leafs" />
            </Grid>
        );
    }
}

export default withStyles(styles)(DividerPicture);
