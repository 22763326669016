import React, { Component } from 'react';
import { connect } from 'react-redux';
import {edit, getCurrentUserProfile, uploadImage, reset} from '../../stateManagment/actions/ProfileActions';

const withProfile = (WrappedComponent) => {
    class Profile extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            getCurrentUserProfile: () => dispatch(getCurrentUserProfile()),
            uploadImage: (file) => dispatch(uploadImage(file)),
            edit: (name, email) => dispatch(edit(name, email)),
            resetStore: () => dispatch(reset()),
        };
    };

    const mapStateToProps = (state) => {
        return {
            profile: state.profile,
            showUserInfo: state.profile.showUserInfo,
            error: state.profile.error,
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(Profile);
};

export default withProfile;
