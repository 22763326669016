import { firebaseInstance } from '../../../networking/firebase/FirebaseConfig';
import { DynamicLinkParameters } from '../../../dynamicLink/model/DynamicLinkParameters';
import { DynamicLinkCreator } from '../../../dynamicLink/model/DynamicLinkCreator';
import config from '../../../config';
import WishlistImages from '../../../wishlist/main/components/imageStepper/WishlistImages';

class WishlistShareService {
    constructor(firebaseInstance) {
        this.firebaseInstance = firebaseInstance;
        this.domain = config.getLink();
        this.dynamicParams = config.getDynamicLinksParams();
    }

    createInfoObjectFromUserIdAndWishListId = (
        userId,
        wishlistId,
        wishlistName,
        shareLinkImage,
        intl
    ) => {
        const dynamicLinkParameters = new DynamicLinkParameters(
            `${this.dynamicParams.domainUriPrefix}`,
            `${this.domain}${wishlistId}/owner/${userId}`,
            `${this.dynamicParams.iosBundleIdentifier}`,
            `${this.dynamicParams.androidPackageName}`,
            'App',
            'Web',
            'Wishlist',
            intl.formatMessage(
                {
                    id: 'wishlist.socialMetaTagTitle',
                },
                { wishlistName }
            ),
            intl.formatMessage({
                id: 'wishlist.socialMetaTagDescription',
            }),
            shareLinkImage
        );
        return new DynamicLinkCreator().createInfoObjectFromParameters(
            dynamicLinkParameters
        );
    };

    share(user, wishlist, intl) {
        const [shareLinkImage] = Object.values(WishlistImages)
            .filter((image) => image.label === wishlist.image)
            .map(({ shareLinkImage }) => shareLinkImage);

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const infoObject = this.createInfoObjectFromUserIdAndWishListId(
                user.uid,
                wishlist.id,
                wishlist.title,
                shareLinkImage,
                intl
            );
            xhr.open(
                'POST',
                `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${this.dynamicParams.apiKey}`
            );
            xhr.send(JSON.stringify(infoObject));
            xhr.addEventListener('load', () => {
                const model = {
                    shareUrlShort: JSON.parse(xhr.responseText)['shortLink'],
                    shared: true,
                };

                this.firebaseInstance
                    .database()
                    .ref(`/wishlists/${user.uid}/${wishlist.id}`)
                    .update(model)
                    .then(() => {
                        resolve({ wishlist, ...model });
                    });
            });
        });
    }
}

export default new WishlistShareService(firebaseInstance);
