import React, { Component } from 'react';
import {
    create,
    edit,
    getAll,
    registerObserver,
    remove,
    restore,
    unregisterObserver,
} from '../stateManagement/actions/WishlistActions';
import { share } from '../../share/stateManagement/actions/WishlistShareActions';
import { connect } from 'react-redux';

const withWishlist = (WrappedComponent) => {
    class Wishlist extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            getAllWishlists: () => dispatch(getAll()),
            createWishlist: (model) => dispatch(create(model)),
            editWishlist: (wishlist, model) => dispatch(edit(wishlist, model)),
            removeWishlist: (wishlist) => dispatch(remove(wishlist)),
            restoreWishlist: (wishlist) => dispatch(restore(wishlist)),
            shareWishlist: (wishlist, intl) => dispatch(share(wishlist, intl)),
            registerWishlistObserver: (callback) =>
                dispatch(registerObserver(callback)),
            unregisterWishlistObserver: (callback) =>
                dispatch(unregisterObserver(callback)),
        };
    };

    const mapStateToProps = (state) => {
        return {
            sharedWishlist: state.wishlistShare,
            wishlist: state.wishlist,
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(Wishlist);
};

export default withWishlist;
