import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';

const StyledTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#FBFAE9',
        },
        '& .MuiInput-underline:after': {
            border: 0,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#FBFAE9',
                border: 0,
            },
            '&:hover fieldset': {
                borderColor: '#FBFAE9',
                border: 0,
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FBFAE9',
                border: 0,
            },
        },
    },
})(TextField);

export default StyledTextField;
