import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';

const styles = (theme) => ({
    resizer: {
        width: '2px',
        backgroundColor: '#9e9e9e',
        cursor: 'col-resize',
    },
    icon: {
        transform: 'rotate(90deg)',
        background: '#9e9e9e',
        borderRadius: '100px',
        color: '#FBFAE9',
        top: 'calc(100vh / 2 + 36px)',
        position: 'absolute',
        marginLeft: '-11px',
    },
});

class Resizer extends Component {
    resizer;
    leftSide;
    rightSide;
    mousePositionX = 0;
    leftWidth = 0;

    startHandler = (e) => {
        e.preventDefault();
        if (e.type === 'touchstart') {
            this.mousePositionX = e.touches[0].clientX;
        } else {
            this.mousePositionX = e.clientX;
        }
        this.leftWidth = this.leftSide.getBoundingClientRect().width;

        if (e.type === 'touchstart') {
            document.addEventListener('touchmove', this.moveHandler);
            document.addEventListener('touchend', this.endHandler);
        } else {
            document.addEventListener('mousemove', this.moveHandler);
            document.addEventListener('mouseup', this.endHandler);
        }
    };

    moveHandler = (e) => {
        e.preventDefault();
        let dx;
        if (e.type === 'touchmove') {
            dx = e.touches[0].clientX - this.mousePositionX;
        } else {
            dx = e.clientX - this.mousePositionX;
        }
        const x = this.leftWidth + dx;
        const parentWidth =
            this.resizer.parentNode.getBoundingClientRect().width;

        const { width } = this.props;
        const mindWidth = width === 'md' || width === 'lg' ? 400 : 620;

        let lw;
        if (x < mindWidth) {
            lw = mindWidth;
        } else if (parentWidth - x < mindWidth) {
            lw = parentWidth - mindWidth;
        } else {
            lw = x;
        }

        const newLeftWidth = (lw * 100) / parentWidth;

        this.leftSide.style.width = `${newLeftWidth}%`;
        this.rightSide.style.width = `${100 - newLeftWidth}%`;

        this.resizer.style.cursor = 'col-resize';
        document.body.style.cursor = 'col-resize';

        this.leftSide.style.userSelect = 'none';
        this.leftSide.style.pointerEvents = 'none';

        this.rightSide.style.userSelect = 'none';
        this.rightSide.style.pointerEvents = 'none';
    };

    endHandler = (e) => {
        e.preventDefault();
        this.resizer.style.removeProperty('cursor');
        document.body.style.removeProperty('cursor');

        this.leftSide.style.removeProperty('user-select');
        this.leftSide.style.removeProperty('pointer-events');

        this.rightSide.style.removeProperty('user-select');
        this.rightSide.style.removeProperty('pointer-events');

        if (e.type === 'touchend') {
            document.removeEventListener('touchmove', this.moveHandler);
            document.removeEventListener('touchend', this.endHandler);
        } else {
            document.removeEventListener('mousemove', this.moveHandler);
            document.removeEventListener('mouseup', this.endHandler);
        }
    };

    componentDidMount() {
        this.resizer = document.getElementById('resizer');
        this.leftSide = this.resizer.previousElementSibling;
        this.rightSide = this.resizer.nextElementSibling;

        this.leftSide.style.width = `50%`;

        this.resizer.addEventListener('mousedown', this.startHandler);
        this.resizer.addEventListener('touchstart', this.startHandler);
    }

    componentWillUnmount() {
        this.leftSide.style.width = `100%`;
    }

    render() {
        const { classes } = this.props;
        return (
            <div id="resizer" className={classes.resizer}>
                <UnfoldMoreIcon className={classes.icon} />
            </div>
        );
    }
}

export default withResponsiveInformation(withStyles(styles)(Resizer));
