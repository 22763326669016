import AnalyticsService from '../../../consent/service/AnalyticsService';
import ImageService from '../../../profile/main/services/ImageService';
import ProfileService from '../../../profile/main/services/ProfileService';
import SharedWishlistService from '../../../sharedWishlist/services/SharedWishlistService';
import WishlistService from '../../../wishlist/main/services/WishlistService';
import DesireCreationModel from '../../model/DesireCreationModel';
import DesireService from '../../service/DesireService';
import {
    CLOSE_CREATE_DIALOG,
    CLOSE_EDIT_DIALOG,
    CLOSE_MOVE_DIALOG,
    CREATE_DESIRE,
    DELETE_DESIRE,
    GET_ALL_DESIRES_ERROR,
    GET_ALL_DESIRES_REQUEST,
    GET_ALL_DESIRES_SUCCESS,
    OPEN_CREATE_DIALOG,
    OPEN_EDIT_DIALOG,
    OPEN_MOVE_DIALOG,
    REGISTER_OBSERVER_DESIRES,
    RESTORE_DESIRE,
    UNREGISTER_OBSERVER_DESIRES,
    UPDATE_DESIRE,
    UPDATE_DESIRE_ARRAY,
} from './DesireActionTypes';

export const registerObserver =
    (wishlistId, reloadCallback) => (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const currentUser = getState().authentication.user;
            dispatch({
                type: REGISTER_OBSERVER_DESIRES,
            });
            WishlistService.getByUserIdAndId(currentUser.uid, wishlistId)
                .then((wishlist) => {
                    if (wishlist === null) {
                        reject('wishlist is null');
                        return;
                    }

                    AnalyticsService.viewWishlist(
                        wishlist.title,
                        wishlist.image,
                        'own_wishlist'
                    );

                    DesireService.registerObserver(
                        currentUser.uid,
                        wishlistId,
                        (desire) => {
                            if (desire.deleted) {
                                reloadCallback();
                            } else {
                                dispatch({ type: UPDATE_DESIRE, desire });
                            }
                        }
                    );
                    resolve();
                })
                .catch((e) => reject(e));
        });

export const unregisterObserver =
    (wishlistId, callback) => (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const currentUser = getState().authentication.user;
            dispatch({
                type: UNREGISTER_OBSERVER_DESIRES,
            });
            WishlistService.getByUserIdAndId(currentUser.uid, wishlistId)
                .then((wishlist) => {
                    if (wishlist === null) {
                        reject();
                        return;
                    }
                    DesireService.unregisterObserver(
                        currentUser.uid,
                        wishlistId,
                        callback
                    );
                    resolve();
                })
                .catch(() => reject());
        });

export const getAllFlat = (wishlistId) => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: GET_ALL_DESIRES_REQUEST,
        currentUser,
    });
    try {
        const desires = await DesireService.getAll(currentUser.uid, wishlistId);

        const wishlist = await WishlistService.getByUserIdAndId(
            currentUser.uid,
            wishlistId
        );

        const { sharedWith } = wishlist;
        const sharedWithIds = sharedWith ? Object.keys(sharedWith) : [];

        const profilesOfSharedWith = await Promise.all(
            sharedWithIds.map((id) => ProfileService.getById(id))
        );
        const result = {
            desires,
            wishlist,
            sharedWith: profilesOfSharedWith,
        };
        dispatch({
            type: GET_ALL_DESIRES_SUCCESS,
            ...result,
        });
        return result;
    } catch (error) {
        dispatch({
            type: GET_ALL_DESIRES_ERROR,
            error,
        });
    }
};

export const createDesire =
    (desireCreationModel) => async (dispatch, getState) => {
        const {
            authentication: {
                user: { uid },
            },
            desires: { wishlist, desires },
        } = getState();

        desireCreationModel.sortIndex = desires.length;

        const createdDesire = await DesireService.createDesire(
            desireCreationModel
        );

        const wishCount = ++desires.length;

        await WishlistService.setWishCount(uid, wishlist.id, wishCount);

        AnalyticsService.addToWishlist(
            createdDesire.title,
            createdDesire.url,
            'own_wish',
            wishlist.image,
            'in_app_browser'
        );

        await updateWishCountForAllSharedWith(wishlist, wishCount);

        dispatch({ type: CREATE_DESIRE, desire: createdDesire });

        return createdDesire.id;
    };

export const editDesire = (desireToEdit) => async (dispatch, getState) => {
    const { uid } = getState().authentication.user;

    if (/data:image\/(png|jpe?g);base64/.test(desireToEdit.imageUrl)) {
        const res = await fetch(desireToEdit.imageUrl);
        const blob = await res.blob();

        desireToEdit.imageUrl = await ImageService.uploadDesireImage(
            new File([blob], `${desireToEdit.id}.png`, { type: 'image/png' }),
            uid,
            desireToEdit.id
        );
    }

    await DesireService.editDesire(
        uid,
        desireToEdit.wishlistId,
        desireToEdit.id,
        desireToEdit.title,
        desireToEdit.imageUrl,
        desireToEdit.note
    );

    dispatch({ type: UPDATE_DESIRE, desire: desireToEdit });

    return desireToEdit;
};

export const deleteDesire = (desire) => async (dispatch, getState) => {
    const {
        authentication: {
            user: { uid },
        },
        desires: {
            wishlist: { id: wishlistId },
            wishlist,
            desires,
        },
    } = getState();

    desire && AnalyticsService.deleteWish(desire.title, desire.url, 'own_wish');

    const wishCount = --desires.length;

    await DesireService.deleteDesire(uid, wishlistId, desire.id);
    await WishlistService.setWishCount(uid, wishlistId, wishCount);
    await updateWishCountForAllSharedWith(wishlist, wishCount);

    dispatch({ type: DELETE_DESIRE, desireId: desire.id });

    return desire;
};

export const undoDelete = (restoredDesire) => async (dispatch, getState) => {
    const {
        authentication: {
            user: { uid },
        },
        desires: {
            wishlist: { id: wishlistId },
            wishlist,
            desires,
        },
    } = getState();

    const wishCount = ++desires.length;

    await DesireService.undoDelete(uid, wishlistId, restoredDesire.id);
    await WishlistService.setWishCount(uid, wishlistId, wishCount);
    await updateWishCountForAllSharedWith(wishlist, wishCount);

    dispatch({ type: RESTORE_DESIRE, restoredDesire });

    restoredDesire &&
        AnalyticsService.restoreWish(
            restoredDesire.title,
            restoredDesire.url,
            'own_wish'
        );

    return restoredDesire;
};

export const openEditDialog = (desire) => {
    return {
        type: OPEN_EDIT_DIALOG,
        desire,
    };
};

export const closeEditDialog = () => {
    return {
        type: CLOSE_EDIT_DIALOG,
    };
};

export const openCreateDialog = () => {
    return {
        type: OPEN_CREATE_DIALOG,
    };
};

export const closeCreateDialog = () => {
    return {
        type: CLOSE_CREATE_DIALOG,
    };
};

export const openMoveDesireDialog = (desire) => {
    return { type: OPEN_MOVE_DIALOG, desire };
};

export const closeMoveDesireDialog = (desire) => {
    return { type: CLOSE_MOVE_DIALOG, desire };
};

export const moveDesire =
    (
        newWishlistId,
        { id, title, url, imageUrl, boughtByUserId, reservedByUserId, note }
    ) =>
    async (dispatch, getState) => {
        const {
            authentication: {
                user: { uid },
            },
            desires: {
                wishlist: { id: oldWishlistId },
                wishlist: oldWishlist,
                desires,
            },
        } = getState();

        const desireCreationModel = new DesireCreationModel(
            title,
            uid,
            newWishlistId,
            url,
            imageUrl,
            boughtByUserId ?? null,
            reservedByUserId ?? null,
            note ?? null
        );

        dispatch({
            type: CLOSE_MOVE_DIALOG,
        });

        const newWishlist = await WishlistService.getByUserIdAndId(
            uid,
            newWishlistId
        );

        desireCreationModel.sortIndex = newWishlist.wishCount + 1;

        await DesireService.createDesire(desireCreationModel);
        await WishlistService.setWishCount(
            uid,
            newWishlistId,
            ++newWishlist.wishCount
        );
        await updateWishCountForAllSharedWith(
            newWishlist,
            ++newWishlist.wishCount
        );

        await DesireService.deleteDesire(uid, oldWishlistId, id);
        await WishlistService.setWishCount(
            uid,
            oldWishlistId,
            --desires.length
        );
        await updateWishCountForAllSharedWith(oldWishlist, --desires.length);

        dispatch({ type: DELETE_DESIRE, desireId: id });
    };

export const updateDesiresSortIndex =
    (wishlistId, desireArray) => async (dispatch, getState) => {
        const {
            authentication: {
                user: { uid },
            },
        } = getState();

        const updatedArray = await DesireService.updateDesiresSortIndex(
            uid,
            wishlistId,
            desireArray
        );

        dispatch({ type: UPDATE_DESIRE_ARRAY, desires: updatedArray });

        return updatedArray;
    };

const updateWishCountForAllSharedWith = async (wishlist, wishCount) => {
    const { sharedWith } = wishlist;
    const sharedWithIds = sharedWith ? Object.keys(sharedWith) : [];

    if (sharedWithIds.length > 0) {
        try {
            return await Promise.all(
                sharedWithIds.forEach((userId) =>
                    SharedWishlistService.setWishCount(
                        userId,
                        wishlist.id,
                        wishCount
                    )
                )
            );
        } catch (e) {
            console.error(e);
        }
    }
};
