import AnalyticsService from '../../../consent/service/AnalyticsService';
import ProfileService from '../../../profile/main/services/ProfileService';
import { affiliate } from '../../../service/AffiliateService';
import WishlistService from '../../../wishlist/main/services/WishlistService';
import SharedDesireService from '../../service/SharedDesireService';
import {
    GET_ALL_SHARED_DESIRES_ERROR,
    GET_ALL_SHARED_DESIRES_REQUEST,
    GET_ALL_SHARED_DESIRES_SUCCESS,
    REGISTER_OBSERVER_SHARED_DESIRES,
    UNREGISTER_OBSERVER_SHARED_DESIRES,
} from './SharedDesireActionTypes';

export { remove as removeWishlist } from '../../../sharedWishlist/stateManagement/actions/SharedWishlistActions';

export const registerObserver = (wishlistId, callback) => {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const currentUser = getState().authentication.user;
            dispatch({
                type: REGISTER_OBSERVER_SHARED_DESIRES,
            });
            SharedDesireService.getSharedWishlist(currentUser.uid, wishlistId)
                .then((wishlist) => {
                    if (wishlist === null) {
                        reject();
                        return;
                    }

                    AnalyticsService.viewWishlist(
                        wishlist.title,
                        wishlist.image,
                        'shared_wishlist'
                    );

                    SharedDesireService.registerObserver(
                        wishlist.ownerId,
                        wishlistId,
                        callback
                    );
                    resolve();
                })
                .catch(() => reject());
        });
};

export const unregisterObserver = (wishlistId, callback) => {
    return (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const currentUser = getState().authentication.user;
            dispatch({
                type: UNREGISTER_OBSERVER_SHARED_DESIRES,
            });
            SharedDesireService.getSharedWishlist(currentUser.uid, wishlistId)
                .then((wishlist) => {
                    if (wishlist === null) {
                        reject();
                        return;
                    }
                    SharedDesireService.unregisterObserver(
                        wishlist.ownerId,
                        wishlistId,
                        callback
                    );
                    resolve();
                })
                .catch(() => reject());
        });
};

export const getAllFlat = (wishlistId) => {
    return async (dispatch, getState) => {
        const currentUser = getState().authentication.user;
        dispatch({
            type: GET_ALL_SHARED_DESIRES_REQUEST,
            currentUser,
        });
        try {
            const wishlist = await SharedDesireService.getSharedWishlist(
                currentUser.uid,
                wishlistId
            );

            const { sharedWith } = await WishlistService.getByUserIdAndId(
                wishlist.ownerId,
                wishlist.id
            );
            const sharedWithIds = sharedWith ? Object.keys(sharedWith) : [];

            const profilesOfSharedWith = await Promise.all(
                sharedWithIds.map((id) => ProfileService.getById(id))
            );

            const sharedDesires = await SharedDesireService.getAll(
                wishlist.ownerId,
                wishlistId
            );
            const sharedWishlist = await SharedDesireService.getSharedWishlist(
                currentUser.uid,
                wishlistId
            );
            const shareOwner = await SharedDesireService.getOwner(
                wishlist.ownerId
            );
            const result = {
                sharedDesires,
                wishlist: sharedWishlist,
                owner: shareOwner,
                sharedWith: profilesOfSharedWith,
            };
            dispatch({
                type: GET_ALL_SHARED_DESIRES_SUCCESS,
                ...result,
            });
            return result;
        } catch (error) {
            console.warn(error);
            dispatch({
                type: GET_ALL_SHARED_DESIRES_ERROR,
                error,
            });
        }
    };
};

export const reserved = (desire) => async (dispatch, getState) => {
    const currentUserId = getState().authentication.user.uid;
    const ownerId = getState().sharedDesires.owner.id;

    try {
        const updatedDesire = await SharedDesireService.reserved(
            ownerId,
            currentUserId,
            desire
        );
        return updatedDesire;
    } catch (error) {
        console.error(`error while trying to update desire: ${error}`);
    }
};

export const bought = (desire) => async (dispatch, getState) => {
    const currentUserId = getState().authentication.user.uid;
    const ownerId = getState().sharedDesires.owner.id;

    try {
        const updatedDesire = await SharedDesireService.bought(
            ownerId,
            currentUserId,
            desire
        );

        return updatedDesire;
    } catch (error) {
        console.error(`error while trying to update desire: ${error}`);
    }
};

export const release = (desire) => async (dispatch, getState) => {
    const ownerId = getState().sharedDesires.owner.id;

    try {
        const updatedDesire = await SharedDesireService.release(
            ownerId,
            desire
        );
        return updatedDesire;
    } catch (error) {
        console.error(`error while trying to update desire: ${error}`);
    }
};

export const affiliateUrl = (url) => () => {
    const { originalUrl, affiliateUrl } = affiliate(url);
    return affiliateUrl ?? originalUrl;
};
