import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DesireAppbar from '../DesireAppbar';

export default function ResponsiveAppbar(props) {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <DesireAppbar
            desktop={desktop}
            title={props.title}
            item={props.wishlist}
            removeWishlistAction={props.removeWishlistAction}
        />
    );
}
