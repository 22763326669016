import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import withSharedWishlist from '../hocs/SharedWishlist';
import ResponsiveFooter from '../../../wishlist/main/components/ResponsiveFooter';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class SharedWishListCreationPage extends Component {
    componentDidMount() {
        const {
            history,
            match: { params },
        } = this.props;
        const { createSharedWishlist, enqueueSnackbar, intl } = this.props;
        createSharedWishlist(params)
            .then((_) => {
                history.push(`/friendswishlist/${params.wishlistId}`);
            })
            .catch((error) => {
                history.push(`/friendswishlist`);
                enqueueSnackbar(
                    intl.formatMessage({ id: 'wishlist.addingOwnWishlist' }),
                    {
                        variant: 'default',
                        autoHideDuration: 6000,
                    }
                );
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ResponsiveFooter textColor="textPrimary" />
            </div>
        );
    }
}

export default withSnackbar(
    injectIntl(
        withSharedWishlist(
            withRouter(withStyles(styles)(SharedWishListCreationPage))
        )
    )
);
