import { SubtitlesOff } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginRight: theme.spacing(2),
    },
    icon: {
        color: '#2B2A2AF0',
        marginTop: theme.spacing(1),
        height: '30px',
        width: '30px',
    },
    noteDiv: {
        height: '30px',
        width: '30px',
    },
    noteIcon: {
        color: '#2B2A2AF0',
        marginBottom: theme.spacing(1),
        height: '100%',
        width: '100%',
    },
    position: {
        display: 'flex',
        flexDirection: 'column',
        width: '35px',
        alignItems: 'end',
    },
}));

export default function MenuListComposition(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.position}>
            <div className={classes.noteDiv}>
                {props.desire?.note && (
                    <Tooltip
                        key="wish.displayNote"
                        title={
                            <FormattedMessage
                                id={
                                    props.noteVisible
                                        ? 'wish.displayTitle'
                                        : 'wish.displayNote'
                                }
                            />
                        }
                        aria-label="displayNote"
                        enterDelay={300}
                    >
                        <IconButton
                            className={classes.noteIcon}
                            aria-controls={'display-note'}
                            aria-haspopup="true"
                            onClick={props.handleNoteClick}
                            size="large"
                        >
                            {props.noteVisible ? (
                                <SubtitlesOff />
                            ) : (
                                <SubtitlesIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            <Tooltip
                key="wish.displayMenu"
                title={<FormattedMessage id="wish.displayMenu" />}
                aria-label="displayMenu"
                enterDelay={300}
            >
                <IconButton
                    className={classes.icon}
                    aria-controls={'menu-list'}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="large"
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-list"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.actions.map((action, index) => {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                action.onClick();
                                handleClose();
                            }}
                            disabled={action.disabled}
                        >
                            <FormattedMessage id={action.id} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
