import Button from '@mui/material/Button';
import { emphasize } from '@mui/material/styles';
import { Facebook } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: '#3B5998',
        padding: '7px',
        justifyContent: 'left',
        paddingLeft: '22px',
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: emphasize('#3B5998', 0.15),
        },
    },
    icon: {
        fontSize: '24px !important',
    },
});

class FacebookButton extends Component {
    render() {
        const { classes, onClick } = this.props;

        return (
            <Button
                fullWidth
                variant="contained"
                className={classes.button}
                onClick={onClick}
                startIcon={<Facebook className={classes.icon} />}
            >
                <FormattedMessage id={'auth.signin-with-facebook'} />
            </Button>
        );
    }
}

export default withStyles(styles)(FacebookButton);
