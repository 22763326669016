import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import StyledTextField from './base/StyledTextField';

const styles = (theme) => ({
    input: {
        margin: theme.spacing(0, 0, 0, 0),
        padding: theme.spacing(0, 0, 0, 0),
        '& .Mui-disabled': {
            color: theme.palette.primary.contrastText,
        },
    },
});

class UsernameField extends Component {
    render() {
        const { classes, intl, disabled, value, onChange } = this.props;
        return (
            <StyledTextField
                InputLabelProps={{
                    required: false,
                }}
                disabled={disabled}
                margin="normal"
                variant="outlined"
                required
                value={value}
                fullWidth
                id="name"
                name="name"
                className={classes.input}
                placeholder={intl.formatMessage({ id: 'auth.name' })}
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AccountCircleOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
                inputProps={{ maxLength: 200 }}
            />
        );
    }
}

export default injectIntl(withStyles(styles)(UsernameField));
