import { Button } from '@mui/material';
import { withSnackbar } from 'notistack';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

class AutoPasteWrapper extends Component {
    _mounted = false;

    state = {
        wantsToReadClipboard:
            (localStorage.getItem('clipboard-api-consent') ?? 'true') ===
            'true',
        clipboardReadSupported: true,
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getClipboardReadPermissionAndParse = (parseUrl) => {
        // works only for chromium based browsers
        const { enqueueSnackbar, closeSnackbar } = this.props;
        const { wantsToReadClipboard } = this.state;

        if (wantsToReadClipboard) {
            this._mounted &&
                navigator?.permissions
                    ?.query?.({ name: 'clipboard-read' })
                    .then?.(async ({ state }) => {
                        if (state === 'granted') {
                            try {
                                await this.getClipboardContentAndParse(
                                    parseUrl
                                );
                            } catch (error) {
                                console.warn('error reading clipboard');
                            }
                        } else if (state === 'prompt') {
                            enqueueSnackbar(
                                <Fragment>
                                    <div>
                                        <strong>
                                            <FormattedMessage
                                                id={
                                                    'app.askForClipboardReadPermissionQuestion'
                                                }
                                            />
                                        </strong>
                                        <br />
                                        <FormattedMessage
                                            id={
                                                'app.askForClipboardReadPermissionExplanation'
                                            }
                                        />
                                    </div>
                                </Fragment>,
                                {
                                    key: 'api-request-snackbar',
                                    variant: 'info',
                                    preventDuplicate: true,
                                    action: (key) => (
                                        <Fragment>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                sx={{
                                                    color: '#FBFAE9',
                                                    backgroundColor: '#e87985',
                                                    border: `2px solid #FBFAE9`,
                                                    minWidth: '80px',
                                                    fontSize: '14px',
                                                }}
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'clipboard-api-consent',
                                                        true
                                                    );
                                                    this.setState({
                                                        wantsToReadClipboard: true,
                                                    });

                                                    closeSnackbar(key);
                                                    this.getClipboardContentAndParse(
                                                        parseUrl
                                                    );
                                                }}
                                            >
                                                <FormattedMessage
                                                    id={'app.allow'}
                                                />
                                            </Button>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                sx={{
                                                    color: '#FBFAE9',
                                                    fontSize: 12,
                                                    marginLeft: '10px',
                                                }}
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'clipboard-api-consent',
                                                        false
                                                    );
                                                    this.setState({
                                                        wantsToReadClipboard: false,
                                                    });
                                                    closeSnackbar(key);
                                                }}
                                            >
                                                <FormattedMessage
                                                    id={'app.deny'}
                                                />
                                            </Button>
                                        </Fragment>
                                    ),
                                }
                            );
                        } else {
                            this.setState({
                                clipboardReadSupported: false,
                            });
                            return;
                        }
                        this.setState({
                            clipboardReadSupported: true,
                        });
                    })
                    .catch?.(() =>
                        this.setState({
                            clipboardReadSupported: false,
                        })
                    );
        }
    };

    getClipboardContentAndParse = async (parseUrl) => {
        // don't use this function directly - use instead the function that uses the permission API => askForClipboardReadPermission()
        try {
            const copiedUrl = await navigator.clipboard.readText?.();

            parseUrl(copiedUrl);
        } catch (err) {
            console.error('Failed to read clipboard contents: ');
        }
    };

    render() {
        const { wantsToReadClipboard, clipboardReadSupported } = this.state;

        return (
            <Fragment>
                {React.cloneElement(this.props.children, {
                    wantsToReadClipboard,
                    clipboardReadSupported,
                    getClipboardReadPermissionAndParse:
                        this.getClipboardReadPermissionAndParse,
                })}
            </Fragment>
        );
    }
}

export default withSnackbar(AutoPasteWrapper);
