import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import AuthenticationProvider from '../../authentication/components/provider/AuthenticationProvider';
import LocalizationProvider from '../../localization/components/LocalizationProvider';
import withResponsiveInformation from '../../mixins/components/ResponsiveInformation';
import Router from '../../navigation/components/router/Router';
import CustomSnackbarProvider from '../../snackbar/CustomSnackbarProvider';
import appStore from '../stateManagment/store/AppStore';
import theme from '../theme/Theme';
import './pinkSnackbar.css';

const store = appStore();

class App extends Component {
    render() {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <LocalizationProvider>
                            <AuthenticationProvider>
                                <CustomSnackbarProvider>
                                    <CssBaseline />
                                    <Router />
                                </CustomSnackbarProvider>
                            </AuthenticationProvider>
                        </LocalizationProvider>
                    </Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default withResponsiveInformation(App);
