import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    text: {
        letterSpacing: '1px',
    },

    box: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
});

class PasswordResetLink extends Component {
    render() {
        const { open, onClick, classes } = this.props;

        return (
            <Fade in={open}>
                <Box mt={0} color="text.secondary" className={classes.box}>
                    <Link
                        className={classes.text}
                        href=""
                        variant="body2"
                        onClick={onClick}
                        underline="hover"
                    >
                        <FormattedMessage id={'auth.forgot-password'} />
                    </Link>
                </Box>
            </Fade>
        );
    }
}

export default withStyles(styles)(PasswordResetLink);
