import Badge from '@mui/material/Badge';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';

const styles = (theme) => ({
    badge: {
        '& .MuiBadge-anchorOriginTopRightRectangular': {
            boxShadow:
                '0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12)',
        },
        '& .MuiBadge-colorError': {
            backgroundColor: theme.palette.common.accentColor,
        },
        display: 'flex',
    },
});

class WishCounter extends Component {
    render() {
        const { classes, children, count, foreignClassName } = this.props;
        return (
            <Badge
                color="error"
                badgeContent={count}
                className={clsx(classes.badge, foreignClassName)}
                max={99}
            >
                {children}
            </Badge>
        );
    }
}

export default withStyles(styles)(WishCounter);
