import PersonIcon from '@mui/icons-material/Person';
import { CardHeader, Divider, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import withAuthentication from '../../../authentication/components/hocs/Authentication';
import AnalyticsService from '../../../consent/service/AnalyticsService';
import MenuListComposition from '../../../desires/components/card/MenuListComposition';
import DEFAULT_IMAGE from '../../../desires/components/resources/placeholder/wish_placeholder@3x.png';
import withSharedDesires from '../hocs/SharedDesires';

const styles = (theme) => ({
    card: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: '100%',
    },
    cardWithCorner: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: '100%',
        '&::before': {
            content: '""',
            position: 'relative',
            margin: '-20px',
            width: '40px',
            height: '40px',
            transform: 'rotate(45deg)',
            backgroundColor: '#F47F8C',
            float: 'right',
            'z-index': 1,
        },
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    header: {
        '& .MuiCardHeader-content': {
            display: 'grid',
        },
        '& .MuiCardHeader-title': {
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            overflow: 'hidden',
            fontSize: '0.9rem',
            display: '-webkit-box',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
            '-moz-box-orient': 'vertical',
        },
        '& .MuiCardHeader-action': {
            margin: theme.spacing(0),
        },
        padding: theme.spacing(1),
    },
    icon: {
        color: '#2B2A2AF0',
        marginLeft: theme.spacing(1),
    },
    opacity: {
        filter: 'brightness(0.3)',
        opacity: '0.7',
    },
    avatarBig: {
        marginBottom: theme.spacing(2),
        width: 80,
        height: 80,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
    },
    iconBig: {
        fontSize: 50,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    grid: {
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 100%))',
        justifyContent: 'center',
        alignContent: 'center',
        position: 'absolute',
        zIndex: 1,
    },
    backgroundGrey: {
        backgroundColor: 'grey',
    },
    relative: {
        position: 'relative',
    },
    blockedText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: '10px',
        paddingRight: '10px',
        color: 'white',
    },
});

class SharedDesireCard extends Component {
    state = {
        open: true,
        profileImage: null,
        profileName: null,
        isBought: false,
        noteVisible: false,
    };

    componentDidMount() {
        const {
            item,
            sharedDesires: { sharedWith },
        } = this.props;
        const { reservedByUserId, boughtByUserId } = item;

        if (boughtByUserId) {
            const [user] = sharedWith?.filter(
                ({ id }) => id === boughtByUserId
            );
            this.setState({
                ...this.state,
                profileImage: user?.profileImage,
                profileName: user?.name ?? 'User',
                isBought: true,
            });
        } else if (reservedByUserId) {
            const [user] = sharedWith?.filter(
                ({ id }) => id === reservedByUserId
            );
            this.setState({
                ...this.state,
                profileImage: user?.profileImage,
                profileName: user?.name ?? 'User',
                isBought: false,
            });
        }
    }

    handleOpenItemUrl = () => {
        const { item, affiliateUrl } = this.props;

        AnalyticsService.viewWish(
            item.title,
            item.url,
            'shared_wish',
            'external'
        );

        window.open(affiliateUrl(item.url));
    };

    handleReservedMenu = () => {
        const { reserved, item } = this.props;
        AnalyticsService.reserveWish(item.title, item.url, 'shared_wish');
        reserved(item);
    };

    handleBoughtMenu = () => {
        const { bought, item } = this.props;
        AnalyticsService.markAsBought(item.title, item.url, 'shared_wish');
        bought(item);
    };

    handleReleaseMenu = () => {
        const { release, item } = this.props;
        item.reservedByUserId &&
            AnalyticsService.releaseWish(item.title, item.url, 'shared_wish');
        item.boughtByUserId &&
            AnalyticsService.removeMarkAsBought(
                item.title,
                item.url,
                'shared_wish'
            );
        release(item);
    };

    isReservedOrBought = () => {
        const {
            item: { reservedByUserId, boughtByUserId },
        } = this.props;
        return reservedByUserId || boughtByUserId;
    };

    getActionArea = () => {
        const { classes, item: desire} = this.props;
        return (
            <div
                className={
                    this.isReservedOrBought()
                        ? clsx(
                              classes.flexCenter,
                              this.isReservedOrBought() &&
                                  classes.backgroundGrey,
                              classes.relative
                          )
                        : classes.flexCenter
                }
            >
                {this.isReservedOrBought() && this.getBlockedContent()}
                <CardActionArea onClick={this.handleOpenItemUrl}>
                    <CardMedia
                        className={clsx(
                            this.isReservedOrBought() && classes.opacity,
                            classes.cardMedia
                        )}
                        image={
                            desire.imageUrl ? desire.imageUrl : DEFAULT_IMAGE
                        }
                        title={desire && desire.title}
                    />
                </CardActionArea>
            </div>
        );
    };

    getBoughtReservedText = () => {
        const { profileName, isBought } = this.state;
        return (
            <FormattedMessage
                id={
                    isBought
                        ? 'sharedWishlistCard.bought'
                        : 'sharedWishlistCard.reserved'
                }
                values={{
                    username: profileName,
                }}
            />
        );
    };

    getBlockedContent = () => {
        const { classes } = this.props;
        const { profileImage } = this.state;
        return (
            <div className={classes.grid}>
                <div className={classes.flexCenter}>
                    <Avatar
                        className={classes.avatarBig}
                        fontSize="large"
                        src={profileImage}
                    >
                        {!profileImage && (
                            <PersonIcon className={classes.iconBig} />
                        )}
                    </Avatar>
                </div>
                <div className={classes.flexCenter}>
                    <Tooltip
                        title={this.getBoughtReservedText()}
                        aria-label="desire card title"
                        enterDelay={300}
                    >
                        <Typography className={classes.blockedText}>
                            {this.getBoughtReservedText()}
                        </Typography>
                    </Tooltip>
                </div>
            </div>
        );
    };

    getActions = () => {
        const { item: desire, authentication } = this.props;

        const isReserved = desire.hasOwnProperty('reservedByUserId');
        const isBought = desire.hasOwnProperty('boughtByUserId');
        let disablemenu = false;

        if (isReserved) {
            disablemenu = desire?.reservedByUserId !== authentication.user.uid;
        }

        if (isBought) {
            disablemenu = desire?.boughtByUserId !== authentication.user.uid;
        }

        return [
            {
                id: 'share.reserve',
                onClick: this.handleReservedMenu,
                disabled: isReserved || isBought || disablemenu,
            },
            {
                id: 'share.buyed',
                onClick: this.handleBoughtMenu,
                disabled: isBought || disablemenu,
            },
            {
                id: 'wish.buy',
                onClick: this.handleOpenItemUrl,
                disabled: false,
            },
            {
                id: 'share.release',
                onClick: this.handleReleaseMenu,
                disabled: !(isReserved || isBought) || disablemenu,
            },
        ];
    };

    render() {
        const { classes, item: desire, isMarketingWishlist } = this.props;
        const { noteVisible } = this.state;

        const date = new Date();
        date.setDate(date.getDate() - 3);

        const isNewItem = desire.created > date;

        return (
            <Card
                className={isNewItem ? classes.cardWithCorner : classes.card}
                elevation={3}
            >
                {this.getActionArea()}
                <Divider />
                <Tooltip
                    title={desire && noteVisible ? desire.note : desire.title}
                    enterDelay={1000}
                >
                    <div>
                        <CardHeader
                            className={classes.header}
                            title={
                                desire && noteVisible
                                    ? desire.note
                                    : desire.title
                            }
                            sx={{
                                cursor: 'default',
                                '& .MuiCardHeader-title': {
                                    fontWeight:
                                        desire && noteVisible
                                            ? 'normal'
                                            : 'bold',
                                },
                            }}
                            action={
                                    !isMarketingWishlist &&  <MenuListComposition
                                    desire={desire}
                                    actions={this.getActions()}
                                    noteVisible={noteVisible}
                                    handleNoteClick={() =>
                                        this.setState({
                                            noteVisible: !noteVisible,
                                        })
                                    }
                                />
                            }
                        />
                    </div>
                </Tooltip>
            </Card>
        );
    }
}

export default withAuthentication(
    withSharedDesires(withStyles(styles)(SharedDesireCard))
);
