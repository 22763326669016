import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import {withStyles} from '@mui/styles';
import React, {Component} from 'react';
import {AvatarGroup} from "@mui/lab";

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: '80vw',
        marginBottom: '5vh',
        [theme.breakpoints.down('xs')]: {
            marginTop: '15vh',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50vw',
        },
    },
    rootEmpty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: '80vw',
        marginBottom: '5vh',
        [theme.breakpoints.up('md')]: {
            maxWidth: '50vw',
        },
    },
});

class SharedWith extends Component {
    getProfiles = () => {
        const {sharedWith} = this.props;

        return sharedWith?.map(({profileImage, name}) => {
            return (
                <Tooltip
                    key={name}
                    title={name}
                    aria-label="share"
                    enterDelay={300}
                >
                    <Avatar
                        fontSize="small"
                        src={profileImage}
                    >
                        {!profileImage && <PersonIcon/>}
                    </Avatar>
                </Tooltip>
            );
        });
    };

    render() {
        const {classes, isEmpty} = this.props;
        return (
            <div className={isEmpty ? classes.rootEmpty : classes.root}>
                <AvatarGroup max={9}>
                    {this.getProfiles()}
                </AvatarGroup>
            </div>
        );
    }
}

export default withStyles(styles)(SharedWith);
