import {
    LOGOUT_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
} from '../actions/AuthenticationActionTypes';

const logoutReducer = (
    state = {
        isLoading: false,
        error: null,
    },
    action
) => {
    switch (action.type) {
        case LOGOUT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case LOGOUT_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default logoutReducer;
