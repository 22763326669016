import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import AppConstants from '../../app/constants/AppConstants';

const styles = (theme) => ({
    mobile: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
});

class Copyright extends Component {
    render() {
        const { textColor, classes } = this.props;
        return (
            <Typography variant="body2" color={textColor} align="center">
                <span className={classes.mobile}>{'Copyright © '}</span>
                <Link
                    color={textColor}
                    href={AppConstants.copyRight}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                >
                    {AppConstants.company}
                </Link>
                <span>{` ${AppConstants.releaseYear}.`}</span>
            </Typography>
        );
    }
}

export default withStyles(styles)(Copyright);
