export class DynamicLinkParameters {
    constructor(
        domainUriPrefix,
        link,
        iosBundleId,
        androidPackageName,
        utmSource,
        utmMedium,
        utmContent,
        socialTitle = '',
        socialDescription = '',
        socialImageLink = ''
    ) {
        this.domainUriPrefix = domainUriPrefix;
        this.link = link;
        this.androidPackageName = androidPackageName;
        this.iosBundleId = iosBundleId;
        this.utmSource = utmSource;
        this.utmMedium = utmMedium;
        this.utmContent = utmContent;
        this.socialTitle = socialTitle;
        this.socialDescription = socialDescription;
        this.socialImageLink = socialImageLink;
    }
}
