const fr = {
    'analytics.accept': 'accepter',
    'analytics.decline': 'déclin',
    'analytics.text':
        "Salut, oui StarSnoopy utilise aussi des cookies et des technologies d'analyse. C'est très important car c'est la seule façon pour nous d'améliorer continuellement votre expérience d'utilisateur. Merci pour votre soutien ❤",

    '404.Oops':
        "Oups ! {newLine} Que s'est-il passé ici ? Pas de problème, je vais vérifier ça pour vous !",
    '404.redirect': 'Ici, vous retournez à {redirectToWishlist}.',
    '404.redirectToWishlist': 'la page de votre liste de souhaits',
    'about.version': 'Version {versionNumber}',
    'about.starsnoopy': 'StarSnoopy',
    'about.facebook': 'Facebook',
    'about.gotToFacebook': 'sur Facebook',
    'about.termsOfUse': "Conditions d'utilisation",
    'about.gotoTermsOfUse': "Conditions d'utilisation",
    'about.starSnoopyHomepage': 'Site web StarSnoopy',
    'about.gotToStarSnoopy': 'Site web',
    'about.feedback': 'Feedback',
    'about.sendFeedback': 'envoyer un feedback',
    'about.share': 'Recommander',
    'about.tracking': 'Utiliser des technologies analytiques',

    'auth.login': 'Se connecter avec',
    'auth.login-with-questionmark': 'Se connecter?',
    'auth.register': "S'inscrire",
    'auth.register-with-questionmark': "S'inscrire ?",
    'auth.password-reset': 'reset',
    'auth.signin-with-apple': 'Se connecter avec apple',
    'auth.signin-with-facebook': 'Se connecter avec facebook',
    'auth.signin-with-google': 'Se connecter avec google',
    'auth.forgot-password': 'Mot de passe oublié ?',
    'auth.name': "Nom d'utilisateur",
    'auth.email': 'Adresse électronique',
    'auth.password': 'Mot de passe',
    'auth.password-repeat': 'Répéter le mot de passe',
    'auth.privacy-policy': 'Politique de confidentialité',
    'auth.imprint': 'mentions légales',
    'auth.account-exists':
        'Un compte existe déjà avec la même adresse électronique mais des identifiants différents.',
    'auth.google-account-exists':
        'Un compte Google existe déjà avec la même adresse électronique mais des identifiants différents.',
    'auth.email-account-exists':
        'Un compte e-mail/mot de passe existe déjà avec la même adresse e-mail mais des identifiants différents.',
    'auth.facebook-account-exists':
        'Un compte facebook existe déjà avec la même adresse électronique mais des identifiants différents.',
    'auth/wrong-password':
        "L'adresse électronique ou le mot de passe est incorrect.",

    'auth.email-already-in-use':
        "L'adresse électronique est déjà utilisée par un autre compte.",
    'auth.invalid-email': "L'adresse électronique saisie n'est pas valide.",
    'auth.weak-password':
        'Le mot de passe doit comporter au moins 6 caractères.',
    'auth.passwords-not-matching': 'Les mots de passe ne correspondent pas.',
    'auth.social-authentification-fail': 'La connexion a échoué.',
    'auth.too-many-requests':
        "Nous avons bloqué toutes les demandes provenant de cet appareil en raison d'une activité inhabituelle. Essayez de nouveau plus tard.",
    'auth.user-not-found': 'Utilisateur non trouvé.',
    'auth.error-default': 'La connexion a échoué.',
    'auth.no-name': 'Veuillez entrer votre nom.',
    'auth.write-fail': 'Oups! Quelque chose a mal tourné.',

    'auth.privacy-label-1': "Oui, j'ai lu le",
    'auth.privacy-label-2': "et de se mettre d'accord.",
    'auth.privacy-policy-login': 'politique de protection de la vie privée',
    'auth.terms-of-use': "conditions d'utilisation",

    'auth.privacy-error': 'Veuillez accepter la politique de confidentialité.',
    'auth.verify': 'un courrier de confirmation a été envoyé',
    'auth.verifyHint':
        'Prêt pour la magie des vœux ? La seule chose qui manque est une confirmation de votre adresse e-mail!',

    'desireOfHeart.name': 'Désir du cœur',
    'desireOfHeart.new': 'Nouveau désir du cœur',
    'desireOfHeart.create': 'Créer le désir du cœur',
    'desireLink': 'Lien vers',

    'login.info': 'Souhaiter, partager, surprendre!',

    'nav.my-wishlist': "Mes listes d'envies",
    'nav.shared-wishlist': 'Envies de mes amis',
    'nav.profile': 'Profil',

    'settings.german': 'Deutsch',
    'settings.english': 'English',
    'settings.french': 'Français',
    'settings.logout': 'Se déconnecter',
    'settings.language': 'Langue',
    'settings.profile': 'Profil',
    'settings.faq': 'FAQ',
    'settings.about': 'À propos de',

    'sharedWishlistCard.bought': 'acheté par {username}',
    'sharedWishlistCard.reserved': 'réservé par {username}',

    'passwordDialog.title': 'Un e-mail vous a été envoyé.',
    'passwordDialog.content':
        'Cliquez sur le lien contenu dans ce courriel pour réinitialiser votre mot de passe, puis revenez ici pour vous connecter.',
    'passwordDialog.email-send': 'E-mail envoyé',
    'dialog.ok': 'ok',
    'dialog.cancel': 'annuler',

    'profile.done': 'fait',
    'profile.logout': 'Logout',
    'profile.delete': 'supprimer le compte',
    'profile.success':
        'Les informations de votre profil ont été modifiées avec succès.',
    'profile.edit': 'Modifier le courrier électronique',
    'profile.password': 'mauvais mot de passe',
    'profile.save': 'Sauvegarder',

    'delete.timeout': 'Temps mort. Veuillez répéter le processus.',
    'delete.fail': 'Oups! Quelque chose a mal tourné.',

    'profileDialog.deleteAccount': 'Supprimer le compte',
    'profileDialog.operation':
        'Pour cette opération délicate, vous devez vous reconnecter.',
    'profileDialog.deleteText':
        'Vous voulez vraiment supprimer votre compte StarSnoopy? Cette action ne peut être annulée!',
    'profileDialog.editText':
        'Pour cette opération délicate, vous devez saisir à nouveau votre mot de passe:',
    'profileDialog.editTextSocial':
        'Pour cette opération délicate, vous devez vous connecter à nouveau.',

    'wishlist.default': 'Ma première liste de souhaits',
    'wishlist.add': 'ajouter une liste de souhaits',
    'wishlist.newWish': 'nouvelle liste de souhaits',
    'wishlist.name': 'Nom de la liste de souhaits',
    'wishlist.note': 'Note sur la liste de souhaits',
    'wishlist.cancel': 'annuler',
    'wishlist.create': 'créer',
    'wishlist.next': 'suivant',
    'wishlist.back': 'retour',
    'wishlist.error': 'Veuillez entrer un nom de liste de souhaits.',
    'wishlist.save': 'save',
    'wishlist.changeWish': 'Modifier la liste de souhaits',
    'wishlist.wishlist': 'liste de souhaits',
    'wishlist.deleted': 'supprimé',
    'wishl.note': 'note: ',

    'wishlist.edit': 'modifier la liste de souhaits',
    'wishlist.deleteTooltip': 'supprimer la liste de souhaits',
    'wishlist.share': "liste de souhaits d'actions",
    'wishlist.shareOnFacebook': 'partager sur Facebook',
    'wishlist.shareOnTwitter': 'partager sur Twitter',
    'wishlist.shareWithMail': 'partager avec Mail',
    'wishlist.shareWithMailSubject': 'Ma liste de souhaits',
    'wishlist.shareWithMailBody':
        'Jetez un oeil à ma liste de souhaits sur {link}!',
    'wishlist.settings': 'paramètres',
    'wishlist.empty':
        'Tant de merveilleux vœux et toujours pas de liste de vœux?',
    'wishlist.start': 'Allons droit au but!',
    'wishlist.pushButton':
        'Cliquez sur le symbole + pour créer votre première liste de souhaits!',
    'wishlist.ps':
        'P.S. vous pouvez partager vos listes de souhaits avec vos proches ! Il suffit de chercher ce symbole.',
    'wishlist.shareTitle': 'Partager',
    'wishlist.copy': 'copier',
    'wishlist.copiedLink': 'Lien copié dans le presse-papiers',
    'wishlist.shareDownloadQrCode': 'Télécharger le code QR',
    'wishlist.shareCopyQrCode': 'Copier le code QR dans le presse-papiers',
    'wishlist.copiedQR': 'Code QR copié dans le presse-papiers',
    'wishlist.downloadQRCodeAsPdf': 'télécharger le QR Code en PDF',
    'wishlist.showQRCode': 'Afficher le code QR',
    'wishlist.hideQRCode': 'Cacher le code QR',
    'wishlist.qrCodeText':
        'Envoyez ce code QR pour votre liste de souhaits à vos amis et à votre famille, ou imprimez-le:',
    'wishlist.404scrapper':
        'Quelque chose a dû mal tourner. Essayez avec un autre lien!',
    'wishlist.orCreateDesireOfHeart': 'Ou créez un souhait sans URL!',
    'wishlist.socialMetaTagTitle':
        'Rejoindre la liste de souhaits | "{wishlistName}"',
    'wishlist.socialMetaTagDescription':
        "Les souhaits se réalisent lorsqu'ils sont partagés.",
    'wishlist.addingOwnWishlist':
        'Vous ne pouvez pas rejoindre votre propre liste de souhaits.',
    'wishlist.invalidUrl': 'URL non valide',
    'wishlist.updateOwnImageQuestion': `Aucune image appropriée n'a été trouvée?`,
    'wishlist.noURLQuestion': `Pas d'URL pour le souhait?`,

    'wish.edit': `Souhait d'édition`,
    'wish.delete': 'Supprimer le souhait',
    'wish.buy': 'Acheter maintenant',
    'wish.new': 'Nouveau souhait',
    'wish.created': 'Souhait créé',
    'wish.moved': 'Souhait déplacé vers {title}',
    'wish.url': 'URL',
    'wish.insertUrl': "Insérer l'URL",
    'wish.wish': 'Souhaitez',
    'wish.create': 'ajouter un souhait',
    'wish.continue': 'continuer',
    'wish.empty': 'Cette liste de souhaits est toujours vide.',
    'wish.pushButton': 'Pour faire un nouveau souhait, appuyez sur +',
    'wish.undoSnackbar': 'souhait supprimé',
    'wish.uploadImage': 'Cliquez ici pour télécharger votre propre photo',
    'wish.move': 'déplacer',
    'wish.createNoteText': '+ Ajouter une note',
    'wish.createNote': 'Ajouter une note',
    'wish.removeNote': 'Supprimer la note',
    'wish.displayNote': 'Afficher la note',
    'wish.displayTitle': 'Afficher le nom du souhait',
    'wish.displayMenu': 'Afficher le menu',

    'share.left': 'à gauche',
    'share.of': 'de',
    'share.empty': "Personne n'a partagé sa liste de souhaits avec vous",
    'share.invite': 'Inviter des amis à utiliser StarSnoopy',
    'share.inviteButton': 'Inviter des amis',
    'share.leave': 'liste de souhaits de congé',
    'share.reserve': 'Réservez',
    'share.buyed': 'Marquer comme acheté',
    'share.release': 'Débloquer',

    'starsnoopy.homepage': 'https://www.starsnoopy.com/',

    'notification.consent':
        'Tenez-vous au courant des derniers souhaits de vos proches. Activez les notifications à cet effet.',
    'notification.accept': 'Activer',

    'app.changeLanguage': 'Changer de langue',
    'app.default': '',
    'app.undo': 'À propos',
    'app.allow': 'Oui!',
    'app.deny': 'Plutôt pas',
    'app.insertUrl': `insérer l'URL du presse-papiers`,
    'app.askForClipboardReadPermissionQuestion':
        'Créer des vœux encore plus facilement! Pouvons-nous vous aider?',
    'app.askForClipboardReadPermissionExplanation':
        'Pour ce faire, il nous suffit de pouvoir récupérer les liens dans votre presse-papiers.',
    'app.playStore': `Au fait, StarSnoopy est également disponible sous forme d'application! Obtenez-le gratuitement!`,
    'app.redirectToWishlist': 'Aller à la liste de souhaits',
    'app.close': 'Fermer',

    'notification.reservedNoneLeft': `{person_name} a réservé une envie de la liste '{wishlist_title}'. Il n'y a plus d'envies non réalisé.`,
    'notification.reservedOneLeft': `{person_name} a réservé une envie de la liste '{wishlist_title}'. Il reste encore {wishes_left} envies non réalisé.`,
    'notification.reservedMultipleLeft': `{person_name} a réservé une envie de la liste '{wishlist_title}'. Il reste encore {wishes_left} envie non réalisé.`,

    'notification.boughtNoneLeft': `{person_name} a exaucé une envie de la liste '{wishlist_title}'. Il n'y a plus d'envies non réalisé.`,
    'notification.boughtOneLeft': `{person_name} a exaucé une envie de la liste '{wishlist_title}'. Il reste encore {wishes_left} envies non réalisé.`,
    'notification.boughtMultipleLeft': `{person_name} a exaucé une envie de la liste '{wishlist_title}'. Il reste encore {wishes_left} envie non réalisé.`,
};

export default fr;
