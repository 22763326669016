import React, { Component } from 'react';
import PrimaryAppbar from '../../menu/appBar/primaryAppBar/components/PrimaryAppbar';
import Drawer from '../../menu/drawer/components/Drawer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProfileDeletion from '../../profile/deletion/components/ProfileDeletion';
import ConsentBannerWrapper from '../../consent/components/ConsentBannerWrapper';

class AppContentWrapper extends Component {
    render() {
        const { children, isPrimaryAppBarVisible } = this.props;
        return (
            <React.Fragment>
                {isPrimaryAppBarVisible && <PrimaryAppbar />}
                {children}
                <Drawer />
                <ProfileDeletion />
                <ConsentBannerWrapper />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isPrimaryAppBarVisible: state.primaryAppBar.isVisible,
        shouldShowProfileDeletionDialog: state.profileDeletion.shouldShowDialog,
    };
};

export default connect(mapStateToProps, null)(withRouter(AppContentWrapper));
