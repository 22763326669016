import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Button, CircularProgress, Collapse } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { emphasize } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import withTheme from '@mui/styles/withTheme';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import QRCode from 'qrcode.react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AnalyticsService from '../../../consent/service/AnalyticsService';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';
import robinIcon from '../../../resources/Robin/Robin.png';
import ReadOnlyField from '../../main/components/textFields/ReadOnlyField';
import { createPdf } from '../pdf/ShareLinkPdfCreator';

const styles = (theme) => ({
    button: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
        letterSpacing: '1px',
    },
    title: {
        color: theme.palette.common.white,
    },
    content: {
        color: theme.palette.common.white,
    },
    email: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    shareButton: {
        margin: theme.spacing(1),
        marginTop: 0,
        width: '60px',
        height: '60px',
    },
    facebookButton: {
        color: theme.palette.common.white,
        backgroundColor: '#3B5998',
        '&:hover': {
            backgroundColor: emphasize('#3B5998', 0.15),
        },
    },
    shareIcon: {
        fontSize: '45px !important',
    },
    mailButton: {
        color: theme.palette.text.primary,
        backgroundColor: 'rgb(224, 224, 224)',
        '&:hover': {
            backgroundColor: emphasize('#e8e8e8', 0.15),
        },
    },
    shareButtons: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'left',
        paddingLeft: theme.spacing(2),
    },
    twitterButton: {
        color: theme.palette.common.white,
        backgroundColor: '#1da1f2',
        '&:hover': {
            backgroundColor: emphasize('#63bcf2', 0.15),
        },
    },
    dialogContent: {
        maxHeight: '80px',
        overflowY: 'hidden',
        marginBottom: '24px',
    },
    qrCode: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    qrWrapper: {
        paddingBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
    },
    qrCodeText: {
        color: theme.palette.common.white,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textAlign: 'center',
    },
    whiteBorder: {
        border: '10px solid white',
    },
    generatePDFLoading: {
        color: theme.palette.secondary.main,
        position: 'absolute',
    },
    qrCodeDownloadCopyDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: 'row',
        marginBottom: '20px',
    },
    qrCodeDownloadCopyButton: {
        width: '250px',
        margin: '5px',
    },
});

const SlideTransition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

const GrowTransition = React.forwardRef((props, ref) => (
    <Grow ref={ref} {...props} />
));
const qrCodeId = 'shareLinkQrCode';

class ShareLinkDialog extends Component {
    state = { isGeneratingPDF: false, qrCodeVisible: false };

    componentDidMount() {
        const { wishlist } = this.props;
        wishlist &&
            AnalyticsService.shareWishlist(
                wishlist.title,
                wishlist.image,
                'own_wishlist'
            );
    }

    getMailSubject = () => {
        const { intl } = this.props;
        return intl.formatMessage({
            id: 'wishlist.shareWithMailSubject',
        });
    };

    getMailBody = () => {
        const { intl, link } = this.props;
        return intl.formatMessage(
            {
                id: 'wishlist.shareWithMailBody',
            },
            { link: link }
        );
    };

    getFacebookShareButton = () => {
        const { classes, link } = this.props;
        const facebookShareLink =
            'https://facebook.com/sharer.php?display=popup&u=' +
            encodeURIComponent(link);
        const options = 'toolbar=0,status=0,resizable=1,width=626,height=436';
        return (
            <Tooltip
                title={<FormattedMessage id={'wishlist.shareOnFacebook'} />}
                aria-label="share"
                enterDelay={300}
            >
                <IconButton
                    className={clsx(
                        classes.facebookButton,
                        classes.shareButton
                    )}
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    onClick={() =>
                        window.open(facebookShareLink, 'sharer', options)
                    }
                    size="large"
                >
                    <FacebookIcon className={classes.shareIcon} />
                </IconButton>
            </Tooltip>
        );
    };

    getMailShareButton = () => {
        const { classes } = this.props;
        return (
            <Tooltip
                title={<FormattedMessage id={'wishlist.shareWithMail'} />}
                aria-label="share"
                enterDelay={300}
            >
                <IconButton
                    className={clsx(classes.mailButton, classes.shareButton)}
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    href={`mailto:?subject=${encodeURIComponent(
                        this.getMailSubject()
                    )}&body=${encodeURIComponent(this.getMailBody())}`}
                    size="large"
                >
                    <EmailIcon className={classes.shareIcon} />
                </IconButton>
            </Tooltip>
        );
    };

    getTwitterShareButton = () => {
        const { classes } = this.props;
        return (
            <Tooltip
                title={<FormattedMessage id={'wishlist.shareOnTwitter'} />}
                aria-label="share"
                enterDelay={300}
            >
                <IconButton
                    className={clsx(classes.twitterButton, classes.shareButton)}
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    onClick={() =>
                        window.open(
                            'https://twitter.com/intent/tweet?text=' +
                                encodeURIComponent(this.getMailBody())
                        )
                    }
                    size="large"
                >
                    <TwitterIcon className={classes.shareIcon} />
                </IconButton>
            </Tooltip>
        );
    };

    getQrCode = () => {
        const { link, classes, isMobile, enqueueSnackbar } = this.props;

        return (
            <Collapse in={this.state.qrCodeVisible}>
                <Typography className={classes.qrCodeText}>
                    <FormattedMessage id={'wishlist.qrCodeText'} />
                </Typography>
                <div className={classes.qrWrapper}>
                    <QRCode
                        id={qrCodeId}
                        style={{
                            maxWidth: isMobile ? '90vw' : 350,
                            maxHeight: isMobile ? '90vw' : 350,
                        }}
                        renderAs={'canvas'}
                        size={1024}
                        value={link}
                        fgColor={'#709c9b'}
                        bgColor={'#FBFAE9'}
                        level={'H'}
                        includeMargin={true}
                        imageSettings={{
                            src: robinIcon,
                            x: null,
                            y: null,
                            height: 350,
                            width: 350,
                            excavate: true,
                        }}
                    />
                </div>
                <div className={classes.qrCodeDownloadCopyDiv}>
                    <Button
                        variant="outlined"
                        className={classes.qrCodeDownloadCopyButton}
                        onClick={() => {
                            const canvas = document.getElementById(qrCodeId);
                            const lnk = document.createElement('a');
                            lnk.download =
                                (this.props.wishlist?.title ?? '') +
                                '_starsnoopy_qr_code_' +
                                new Date().getTime();
                            lnk.href = canvas.toDataURL('image/png;base64');
                            lnk.click();
                        }}
                    >
                        <FormattedMessage id="wishlist.shareDownloadQrCode" />
                    </Button>
                    {navigator.clipboard?.write && (
                        <Button
                            variant="outlined"
                            className={classes.qrCodeDownloadCopyButton}
                            onClick={async () => {
                                const canvas =
                                    document.getElementById(qrCodeId);

                                await canvas.toBlob(async function (blob) {
                                    const item = new window.ClipboardItem({
                                        'image/png': blob,
                                    });
                                    await navigator.clipboard.write([item]);
                                    enqueueSnackbar(
                                        <FormattedMessage id="wishlist.copiedQR" />,
                                        {
                                            variant: 'default',
                                            autoHideDuration: 6000,
                                        }
                                    );
                                });
                            }}
                        >
                            <FormattedMessage id="wishlist.shareCopyQrCode" />
                        </Button>
                    )}
                </div>
            </Collapse>
        );
    };

    getQrCodeDownloadButton = () => {
        const { classes, wishlist, link } = this.props;

        return (
            <Tooltip
                title={<FormattedMessage id={'wishlist.downloadQRCodeAsPdf'} />}
                aria-label="share"
                enterDelay={300}
            >
                <IconButton
                    className={clsx(classes.mailButton, classes.shareButton)}
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    onClick={() => {
                        this.setState({ isGeneratingPDF: true });
                        createPdf(wishlist?.title ?? '', link).then(() =>
                            this.setState({ isGeneratingPDF: false })
                        );
                    }}
                    size="large"
                >
                    {this.state.isGeneratingPDF && (
                        <CircularProgress
                            size={50}
                            className={classes.generatePDFLoading}
                            disableShrink
                        />
                    )}
                    <PictureAsPdfIcon className={classes.shareIcon} />
                </IconButton>
            </Tooltip>
        );
    };

    getShowQrCodeButton = () => {
        const { classes } = this.props;
        const { qrCodeVisible } = this.state;

        return (
            <Tooltip
                title={
                    <FormattedMessage
                        id={
                            qrCodeVisible
                                ? 'wishlist.hideQRCode'
                                : 'wishlist.showQRCode'
                        }
                    />
                }
                aria-label="share"
                enterDelay={300}
            >
                <IconButton
                    className={clsx(classes.mailButton, classes.shareButton)}
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    onClick={() => {
                        this.setState({
                            qrCodeVisible: !qrCodeVisible,
                        });
                    }}
                    size="large"
                >
                    <QrCodeScannerIcon className={classes.shareIcon} />
                </IconButton>
            </Tooltip>
        );
    };

    handleCloseClick = () => {
        const { onClose } = this.props;
        this.setState({ qrCodeVisible: false });
        onClose();
    };

    render() {
        const {
            theme,
            classes,
            open,
            isMobile,
            link = '',
            onCopyClick,
        } = this.props;

        return (
            <Dialog
                open={open}
                onClose={() => this.handleCloseClick()}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.common.darkBackground,
                        width: '600px',
                    },
                }}
                fullScreen={isMobile}
                TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                }
            >
                <DialogTitle className={classes.title}>
                    <FormattedMessage id={'wishlist.shareTitle'} />
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={this.handleCloseClick}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <div className={classes.shareButtons}>
                    {this.getMailShareButton()}
                    {this.getFacebookShareButton()}
                    {this.getTwitterShareButton()}
                    {this.getQrCodeDownloadButton()}
                    {this.getShowQrCodeButton()}
                </div>
                <DialogContent className={classes.dialogContent}>
                    <ReadOnlyField value={link} onCopyClick={onCopyClick} />
                </DialogContent>
                {<div className={classes.qrCode}>{this.getQrCode()}</div>}
            </Dialog>
        );
    }
}

export default injectIntl(
    withResponsiveInformation(
        withSnackbar(withStyles(styles)(withTheme(ShareLinkDialog)))
    )
);
