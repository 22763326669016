import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LeaveIcon from '../../../resources/WishlistIcons/leave-white-48dp.svg';
import WishlistCardHeader from '../../../wishlist/main/components/card/WishlistCardHeader';
import WishlistCardImage from '../../../wishlist/main/components/card/WishlistCardImage';
import WishCounter from '../../../wishlist/main/components/wishCounter/WishCounter';

const styles = (theme) => ({
    card: {
        minHeight: '202px',
        minWidth: '282px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        padding: '4px',
        transition: 'box-shadow 0.2 ease-in-out',
        boxShadow:
            '0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12)',
        '&:hover ': {
            boxShadow:
                '0 6px 6px -3px rgba(0,0,0,0.2), 0 10px 14px 1px rgba(0,0,0,0.14), 0 4px 18px 3px rgba(0,0,0,0.12)',
        },
        cursor: 'pointer',
    },
    selectedBoxShadow: {
        boxShadow:
            '0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15)',
        backgroundColor: '#4e7e7e',
        '&:hover ': {
            boxShadow:
                '0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15)',
        },
    },
    focusHighlight: {},
    actionArea: {
        backgroundColor: theme.palette.secondary.main,
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        '&:hover $focusHighlight': {
            opacity: 0,
        },
        cursor: 'pointer',
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    cardImage: {
        paddingBottom: theme.spacing(2),
        marginTop: -theme.spacing(1),
    },
    leaveIcon: {
        maxHeight: '24px',
    },
});

class SharedWishlistCard extends Component {
    disableRipple = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    handleRemoveClick = (clickEvent) => {
        const { item, onRemoveClick } = this.props;
        this.disableRipple(clickEvent);
        if (onRemoveClick) {
            onRemoveClick(item);
        }
    };

    renderAction() {
        const { classes } = this.props;
        return (
            <Tooltip
                title={<FormattedMessage id={'share.leave'} />}
                aria-label="delete"
                enterDelay={300}
            >
                <IconButton
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    onClick={this.handleRemoveClick}
                    size="large"
                >
                    <img
                        className={classes.leaveIcon}
                        src={LeaveIcon}
                        alt={'leave wishlist'}
                    />
                </IconButton>
            </Tooltip>
        );
    }

    render() {
        const { classes, item, profile, onItemClick, isSelected } = this.props;
        return (
            <WishCounter count={item.wishCount}>
                <Card
                    className={clsx(
                        classes.card,
                        isSelected && classes.selectedBoxShadow
                    )}
                >
                    <CardContent
                        className={classes.actionArea}
                        onClick={() => onItemClick(item)}
                    >
                        <WishlistCardHeader
                            item={item}
                            profile={profile}
                            isLoading={false}
                            action={this.renderAction()}
                        />
                        <div className={classes.cardImage}>
                            <WishlistCardImage item={item} />
                        </div>
                    </CardContent>
                </Card>
            </WishCounter>
        );
    }
}

export default withStyles(styles)(SharedWishlistCard);
