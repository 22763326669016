import { PhotoCamera } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import ProfilePicture from '../picture/ProfilePicture';

const styles = (theme) => ({
    badge: {
        right: 10,
        bottom: 26,
        padding: '0 4px',
        backgroundColor: '#FBFAE900',
    },
    icon: {
        boxShadow:
            '0 2px 1px -1px rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 1px 3px 0 rgba(0,0,0,0.12)',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: '#90B0B0',
        },
    },
    input: {
        display: 'none',
    },
});

class ProfilePictureWrapper extends Component {
    handleUploadClick = (event) => {
        const [file] = event.target.files;
        this.props.uploadImage(file);
    };

    render() {
        const { classes, profileImage } = this.props;
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Badge
                    badgeContent={
                        <React.Fragment>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="icon-button-file"
                                type="file"
                                onChange={this.handleUploadClick}
                            />
                            <label htmlFor="icon-button-file">
                                <IconButton
                                    size="small"
                                    className={classes.icon}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                >
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                        </React.Fragment>
                    }
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    color="primary"
                    classes={{ badge: classes.badge }}
                >
                    <ProfilePicture imageURL={profileImage} size="big" />
                </Badge>
            </Grid>
        );
    }
}

export default withStyles(styles)(ProfilePictureWrapper);
