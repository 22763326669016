import DesireComperator from '../../../sharedDesires/comperator/DesireComperator';
import {
    CLOSE_CREATE_DIALOG,
    CLOSE_EDIT_DIALOG,
    CLOSE_MOVE_DIALOG,
    CREATE_DESIRE,
    DELETE_DESIRE,
    GET_ALL_DESIRES_ERROR,
    GET_ALL_DESIRES_REQUEST,
    GET_ALL_DESIRES_SUCCESS,
    OPEN_CREATE_DIALOG,
    OPEN_EDIT_DIALOG,
    OPEN_MOVE_DIALOG,
    RESTORE_DESIRE,
    UPDATE_DESIRE,
    UPDATE_DESIRE_ARRAY,
} from '../actions/DesireActionTypes';

const desiresReducer = (
    state = {
        desires: [],
        wishlist: null,
        isLoading: true,
        selectedDesire: null,
        editDialogIsOpen: false,
        createDialogIsOpen: false,
        moveDialogIsOpen: false,
        sharedWith: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_DESIRES_REQUEST:
            return {
                ...state,
                desires: [],
                wishlist: null,
                isLoading: true,
                sharedWith: [],
            };
        case GET_ALL_DESIRES_SUCCESS:
            return {
                ...state,
                desires: action.desires,
                wishlist: action.wishlist,
                isLoading: false,
                sharedWith: action.sharedWith,
            };
        case GET_ALL_DESIRES_ERROR:
            return {
                ...state,
                error: action.error,
                isLoading: false,
                sharedWith: [],
            };
        case OPEN_EDIT_DIALOG:
            return {
                ...state,
                editDialogIsOpen: true,
                selectedDesire: action.desire,
            };
        case CLOSE_EDIT_DIALOG:
            return {
                ...state,
                editDialogIsOpen: false,
                selectedDesire: null,
            };
        case OPEN_CREATE_DIALOG:
            return {
                ...state,
                createDialogIsOpen: true,
            };
        case CLOSE_CREATE_DIALOG:
            return {
                ...state,
                createDialogIsOpen: false,
            };
        case OPEN_MOVE_DIALOG:
            return {
                ...state,
                moveDialogIsOpen: true,
                selectedDesire: action.desire,
            };
        case CLOSE_MOVE_DIALOG:
            return {
                ...state,
                moveDialogIsOpen: false,
                selectedDesire: action.desire ? action.desire : null,
            };
        case CREATE_DESIRE:
            return {
                ...state,
                desires: insertItem(state.desires, action.desire),
            };
        case DELETE_DESIRE:
            return {
                ...state,
                desires: removeItemInArray(state.desires, action.desireId),
            };
        case RESTORE_DESIRE:
            return {
                ...state,
                desires: insertItem(state.desires, action.restoredDesire),
            };
        case UPDATE_DESIRE:
            return {
                ...state,
                desires: updateObjectInArray(state.desires, action.desire),
            };
        case UPDATE_DESIRE_ARRAY:
            return {
                ...state,
                desires: updatePartOfArray(state.desires, action.desires),
            };
        default:
            return state;
    }
};

const insertItem = (array, desire) => {
    const newArray = [...array];
    newArray.push(desire);
    return [
        ...new Set(
            newArray.filter((item) => item).sort(DesireComperator.compare)
        ),
    ];
};

const removeItemInArray = (array, desireIdToRemove) =>
    [...array]
        .filter((item) => item.id !== desireIdToRemove)
        .filter((item) => item)
        .sort(DesireComperator.compare);

const updateObjectInArray = (array, desire) =>
    [...array]
        .filter((item) => item)
        .map((item) => {
            if (item.id !== desire.id) {
                return item;
            }
            return { ...item, ...desire };
        })
        .filter((item) => item)
        .sort(DesireComperator.compare);

const updatePartOfArray = (oldArr, newPartArr) => {
    const arrCopy = [...oldArr].sort(DesireComperator.compare);

    arrCopy.splice(
        oldArr.length - newPartArr.length,
        newPartArr.length,
        ...newPartArr
    );

    if (oldArr.length !== [...new Set(arrCopy.map((i) => i.id))].length) {
        //prevent duplicates
        return oldArr;
    }

    return arrCopy.filter((item) => item).sort(DesireComperator.compare);
};

export default desiresReducer;
