import config from '../../config.js';
import AnalyticsService from '../../consent/service/AnalyticsService';
import {
    firebaseAuthentication,
    firebaseInstance,
} from '../../networking/firebase/FirebaseConfig';
import ImageService from '../../profile/main/services/ImageService';
import SharedWishlistService from '../../sharedWishlist/services/SharedWishlistService';
import WishlistService from '../../wishlist/main/services/WishlistService';
import authenticationErrorMapperInstance from '../mapper/AuthenticationErrorMapper';

class AuthenticationService {
    constructor(firebaseAuthentication, firebaseInstance, errorMapper) {
        this.firebaseAuthentication = firebaseAuthentication;
        this.firebaseInstance = firebaseInstance;
        this.errorMapper = errorMapper;
    }

    initialize = () => {
        return new Promise((resolve) => {
            this.firebaseInstance.auth().onAuthStateChanged((user) => {
                resolve(user);
            });
        });
    };

    login = (email, password) => {
        AnalyticsService.login('E-Mail');
        return this.firebaseInstance
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => user)
            .catch((error) => {
                throw this.errorMapper.map(error);
            });
    };

    loginInWithApple = () => {
        const provider = new firebaseAuthentication.OAuthProvider('apple.com');
        AnalyticsService.login('Apple');
        return this.doSocialLogin(provider);
    };

    loginInWithGoogle = () => {
        const provider = new firebaseAuthentication.GoogleAuthProvider();
        AnalyticsService.login('Google');
        return this.doSocialLogin(provider);
    };

    loginInWithFacebook = () => {
        const provider = new firebaseAuthentication.FacebookAuthProvider();
        AnalyticsService.login('Facebook');
        return this.doSocialLogin(provider);
    };

    doSocialLogin = async (provider) => {
        provider.addScope('email');
        provider?.providerId === 'apple.com' && provider.addScope('name');
        try {
            const result = await this.firebaseInstance
                .auth()
                .signInWithPopup(provider);

            const displayName = result.user.displayName;
            const name =
                displayName?.includes('+') &&
                provider?.providerId === 'apple.com'
                    ? displayName.replace('+', ' ')
                    : displayName;

            const email = result.additionalUserInfo.profile.email;

            result.additionalUserInfo?.isNewUser &&
                this.persistsUser(name, email);

            return result;
        } catch (error) {
            return this.fetchLoginError(error).then((error) => {
                throw this.errorMapper.map(error);
            });
        }
    };

    fetchLoginError = (error) => {
        const email = error.email;
        return this.firebaseInstance
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((method) => {
                switch (method[0]) {
                    case 'password':
                        return {
                            code: 'auth/email-account-exists-with-different-credential',
                        };
                    case 'google.com':
                        return {
                            code: 'auth/google-account-exists-with-different-credential',
                        };
                    case 'facebook.com':
                        return {
                            code: 'auth/facebook-account-exists-with-different-credential',
                        };
                    default:
                        return {
                            code: 'auth/account-exists-with-different-credential',
                        };
                }
            });
    };

    doSocialRegister = (provider, privacyChecked) => {
        if (!privacyChecked) {
            return Promise.reject(
                this.errorMapper.map({ code: 'auth/privacy-policy' })
            );
        }
        return this.doSocialLogin(provider);
    };

    register = async (
        email,
        password,
        repeatedPassword,
        name,
        privacyChecked
    ) => {
        if (name === '') {
            return Promise.reject(
                this.errorMapper.map({ code: 'auth/no-name' })
            );
        }
        if (password !== repeatedPassword) {
            return Promise.reject(
                this.errorMapper.map({ code: 'auth/passwords-not-matching' })
            );
        }
        if (!privacyChecked) {
            return Promise.reject(
                this.errorMapper.map({ code: 'auth/privacy-policy' })
            );
        }

        try {
            const user = await this.firebaseInstance
                .auth()
                .createUserWithEmailAndPassword(email, password);

            await this.persistsUser(name, email);
            await this.sendVerificationMail();

            return user;
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                return this.fetchLoginError({ email: email }).then((error) => {
                    throw this.errorMapper.map(error);
                });
            } else {
                throw this.errorMapper.map(error);
            }
        }
    };

    sendVerificationMail = async () => {
        try {
            await this.firebaseInstance
                .auth()
                .currentUser.sendEmailVerification();

            AnalyticsService.sendEmailVerificationSuccess();
        } catch (error) {
            AnalyticsService.sendEmailVerificationError();
        }
    };

    registerWithApple = (privacyChecked) => {
        const provider = new firebaseAuthentication.OAuthProvider('apple.com');
        return this.doSocialRegister(provider, privacyChecked);
    };

    registerWithGoogle = (privacyChecked) => {
        const provider = new firebaseAuthentication.GoogleAuthProvider();
        return this.doSocialRegister(provider, privacyChecked);
    };

    registerWithFacebook = (privacyChecked) => {
        const provider = new firebaseAuthentication.FacebookAuthProvider();
        return this.doSocialRegister(provider, privacyChecked);
    };

    persistsUser = (displayName, email) => {
        const FACEBOOK_PROVIDER_ID = 'facebook.com';
        const GOOGLE_PROVIDER_ID = 'google.com';
        const currentUser = this.firebaseInstance.auth().currentUser;
        const userId = currentUser.uid;
        const providerId = currentUser.providerData[0]?.providerId ?? '';
        const { appId, clientToken } = config.getFacebookApiToken();
        const profileImageURL =
            providerId === FACEBOOK_PROVIDER_ID
                ? currentUser.photoURL +
                  `?height=250&access_token=${appId}|${clientToken}`
                : currentUser.photoURL;
        const isForeignProvider =
            providerId === GOOGLE_PROVIDER_ID ||
            providerId === FACEBOOK_PROVIDER_ID;
        const socialImageShouldBeUploaded =
            profileImageURL && isForeignProvider;

        return this.firebaseInstance
            .database()
            .ref(`users/${userId}`)
            .set({
                email,
                displayName,
                emailVerified: false,
                id: userId,
                profileImageURL,
            })
            .then(async (result) => {
                if (socialImageShouldBeUploaded) {
                    try {
                        const response = await fetch(profileImageURL, {
                            method: 'GET',
                            mode: 'cors',
                        });
                        const blob = await response.blob();
                        return await ImageService.uploadImage(blob, userId);
                    } catch (error) {
                        console.error(
                            `error persisting third party image: ${error}`
                        );
                    }
                }
                return result;
            })
            .catch((error) => {
                throw this.errorMapper.map(error);
            });
    };

    logout = () => {
        return this.firebaseInstance
            .auth()
            .signOut()
            .catch((error) => {
                throw this.errorMapper.map(error);
            });
    };

    resetPassword = (email) => {
        const auth = this.firebaseInstance.auth();
        return auth.sendPasswordResetEmail(email).catch((error) => {
            throw this.errorMapper.map(error);
        });
    };

    remove = () => {
        const user = firebaseInstance.auth().currentUser;
        if (!user) {
            throw Error('There is no authenticated User');
        }

        WishlistService.getAll(user).then((wishlists) =>
            wishlists.map((list) => WishlistService.remove(user, list))
        );

        SharedWishlistService.getAll(user).then((sharedWishlists) =>
            sharedWishlists.map((sharedWishlist) =>
                SharedWishlistService.removeFromWishlist(user, sharedWishlist)
            )
        );
        return user.delete();
    };

    reauthenticate = (password) => {
        const currentUser = this.firebaseInstance.auth().currentUser;
        const credential =
            this.firebaseAuthentication.EmailAuthProvider.credential(
                currentUser.email,
                password
            );

        return currentUser.reauthenticateWithCredential(credential);
    };

    reauthenticateWithApple = () => {
        const provider = new firebaseAuthentication.OAuthProvider('apple.com');
        return this.reauthenticateSocial(provider);
    };

    reauthenticateWithFacebook = () => {
        const provider = new firebaseAuthentication.FacebookAuthProvider();
        return this.reauthenticateSocial(provider);
    };

    reauthenticateWithGoogle = () => {
        const provider = new firebaseAuthentication.GoogleAuthProvider();
        return this.reauthenticateSocial(provider);
    };

    reauthenticateSocial = (provider) => {
        const currentUser = this.firebaseInstance.auth().currentUser;
        provider.addScope('email');
        provider?.providerId === 'apple.com' && provider.addScope('name');
        return this.firebaseInstance
            .auth()
            .signInWithPopup(provider)
            .then(({ credential }) => {
                return currentUser.reauthenticateWithCredential(credential);
            })
            .catch((error) => {
                return this.fetchLoginError(error).then((error) => {
                    throw this.errorMapper.map(error);
                });
            });
    };
}

export default new AuthenticationService(
    firebaseAuthentication,
    firebaseInstance,
    authenticationErrorMapperInstance
);
