export const REGISTER_OBSERVER_WISHLIST = 'REGISTER_OBSERVER_WISHLIST';
export const UNREGISTER_OBSERVER_WISHLIST = 'UNREGISTER_OBSERVER_WISHLIST';

export const GET_ALL_WISHLIST_REQUEST = 'GET_ALL_WISHLIST_REQUEST';
export const GET_ALL_WISHLIST_SUCCESS = 'GET_ALL_WISHLIST_SUCCESS';
export const GET_ALL_WISHLIST_ERROR = 'GET_ALL_WISHLIST_ERROR';

export const EDIT_WISHLIST_REQUEST = 'EDIT_WISHLIST_REQUEST';
export const EDIT_WISHLIST_SUCCESS = 'EDIT_WISHLIST_SUCCESS';
export const EDIT_WISHLIST_ERROR = 'EDIT_WISHLIST_ERROR';

export const CREATE_WISHLIST_REQUEST = 'CREATE_WISHLIST_REQUEST';
export const CREATE_WISHLIST_SUCCESS = 'CREATE_WISHLIST_SUCCESS';
export const CREATE_WISHLIST_ERROR = 'CREATE_WISHLIST_ERROR';

export const REMOVE_WISHLIST_REQUEST = 'REMOVE_WISHLIST_REQUEST';
export const REMOVE_WISHLIST_SUCCESS = 'REMOVE_WISHLIST_SUCCESS';
export const REMOVE_WISHLIST_ERROR = 'REMOVE_WISHLIST_ERROR';

export const RESTORE_WISHLIST_REQUEST = 'RESTORE_WISHLIST_REQUEST';
export const RESTORE_WISHLIST_SUCCESS = 'RESTORE_WISHLIST_SUCCESS';
export const RESTORE_WISHLIST_ERROR = 'RESTORE_WISHLIST_ERROR';

export const RESET_WISHLIST_STORE = 'RESET_WISHLIST_STORE';
