import {
    CREATE_SHARED_WISHLIST_ERROR,
    CREATE_SHARED_WISHLIST_REQUEST,
    CREATE_SHARED_WISHLIST_SUCCESS,
    GET_ALL_SHARED_WISHLIST_ERROR,
    GET_ALL_SHARED_WISHLIST_REQUEST,
    GET_ALL_SHARED_WISHLIST_SUCCESS,
    REGISTER_OBSERVER_SHARED_WISHLIST,
    REMOVE_SHARED_WISHLIST_ERROR,
    REMOVE_SHARED_WISHLIST_REQUEST,
    REMOVE_SHARED_WISHLIST_SUCCESS,
    RESTORE_SHARED_WISHLIST_ERROR,
    RESTORE_SHARED_WISHLIST_REQUEST,
    RESTORE_SHARED_WISHLIST_SUCCESS,
    UNREGISTER_OBSERVER_SHARED_WISHLIST,
} from './SharedWishlistActionTypes';
import SharedWishlistService from '../../services/SharedWishlistService';
import AnalyticsService from '../../../consent/service/AnalyticsService';

export const registerObserver = (callback) => (dispatch, getState) =>
    new Promise((resolve) => {
        const currentUser = getState().authentication.user;
        dispatch({
            type: REGISTER_OBSERVER_SHARED_WISHLIST,
            currentUser,
        });
        SharedWishlistService.registerObserver(currentUser, callback);
        callback();
        resolve();
    });

export const unregisterObserver = (callback) => (dispatch, getState) =>
    new Promise((resolve) => {
        const currentUser = getState().authentication.user;
        dispatch({
            type: UNREGISTER_OBSERVER_SHARED_WISHLIST,
            currentUser,
        });
        SharedWishlistService.unregisterObserver(currentUser, callback);
        resolve();
    });

export const getAll = () => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: GET_ALL_SHARED_WISHLIST_REQUEST,
        currentUser,
    });
    try {
        const sharedWishlists = await SharedWishlistService.getAll(currentUser);

        const ownerIds = sharedWishlists.map(
            (sharedWishlist) => sharedWishlist.ownerId
        );

        const owners = await SharedWishlistService.getAllOwnersByIds(ownerIds);
        const result = {
            sharedWishlists,
            owners: new Map(owners.map((owner) => [owner.id, owner])),
        };
        dispatch({
            type: GET_ALL_SHARED_WISHLIST_SUCCESS,
            ...result,
        });
        return result;
    } catch (error) {
        dispatch({
            type: GET_ALL_SHARED_WISHLIST_ERROR,
            error,
        });
    }
};

export const create = (model) => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: CREATE_SHARED_WISHLIST_REQUEST,
        model,
        currentUser,
    });
    try {
        const result = await SharedWishlistService.create(currentUser, model);

        dispatch({
            type: CREATE_SHARED_WISHLIST_SUCCESS,
            item: result,
        });

        AnalyticsService.joinWishlist(
            result.title,
            result.image,
            'shared_wishlist'
        );

        return result;
    } catch (error) {
        dispatch({
            type: CREATE_SHARED_WISHLIST_ERROR,
            error,
        });
        return Promise.reject(error);
    }
};

export const remove = (sharedWishlist) => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: REMOVE_SHARED_WISHLIST_REQUEST,
        sharedWishlist,
        currentUser,
    });

    try {
        const result = await SharedWishlistService.remove(
            currentUser,
            sharedWishlist
        );

        dispatch({
            type: REMOVE_SHARED_WISHLIST_SUCCESS,
            item: result,
        });

        AnalyticsService.leaveWishlist(
            sharedWishlist.title,
            sharedWishlist.image,
            'shared_wishlist'
        );
        return result;
    } catch (error) {
        dispatch({
            type: REMOVE_SHARED_WISHLIST_ERROR,
            error,
        });
    }
};

export const restore = (sharedWishlist) => async (dispatch, getState) => {
    const currentUser = getState().authentication.user;
    dispatch({
        type: RESTORE_SHARED_WISHLIST_REQUEST,
        sharedWishlist,
        currentUser,
    });
    try {
        const result = await SharedWishlistService.restore(
            currentUser,
            sharedWishlist
        );

        dispatch({
            type: RESTORE_SHARED_WISHLIST_SUCCESS,
            item: result,
        });
        AnalyticsService.restoreWishlist(
            sharedWishlist.title,
            sharedWishlist.image,
            'shared_wishlist'
        );
        return result;
    } catch (error) {
        dispatch({
            type: RESTORE_SHARED_WISHLIST_ERROR,
            error,
        });
    }
};
