import React, { Component } from 'react';

class DnDImageUpload extends Component {
    state = {
        dragging: false,
    };

    dragCounter = 0;

    dropRef = React.createRef();

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true });
        }
    };

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;

        if (this.dragCounter === 0) {
            this.setState({ dragging: false });
        }
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragging: false });

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const [firstFile] = e.dataTransfer.files;
            this.props.handleDrop(firstFile);
            this.dragCounter = 0;
        }
    };

    componentDidMount() {
        if (this.props.allowUpload) {
            const div = this.dropRef.current;
            div.addEventListener('dragenter', this.handleDragIn);
            div.addEventListener('dragleave', this.handleDragOut);
            div.addEventListener('dragover', this.handleDrag);
            div.addEventListener('drop', this.handleDrop);
        }
    }

    componentWillUnmount() {
        if (this.props.allowUpload) {
            const div = this.dropRef.current;
            div.removeEventListener('dragenter', this.handleDragIn);
            div.removeEventListener('dragleave', this.handleDragOut);
            div.removeEventListener('dragover', this.handleDrag);
            div.removeEventListener('drop', this.handleDrop);
        }
    }

    render() {
        const { dragging } = this.state;
        const { children, allowUpload } = this.props;

        return allowUpload ? (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    opacity: dragging ? 0.3 : 1,
                    border: dragging && 'dashed grey 2px',
                }}
                ref={this.dropRef}
            >
                {children}
            </div>
        ) : (
            children
        );
    }
}
export default DnDImageUpload;
