import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import WishlistImages from '../imageStepper/WishlistImages';

const styles = (theme) => ({
    media: {
        paddingTop: '41.8%',
        backgroundSize: 'contain',
    },
    img: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
});

class WishlistCardImage extends Component {
    state = {
        isLoaded: false,
    };

    onImgLoad = () => {
        this.setState({ isLoaded: true });
    };

    onImgError = () => {
        this.setState({ isLoaded: true });
    };

    render() {
        const { classes, item } = this.props;
        const { isLoaded } = this.state;
        return (
            <React.Fragment>
                <Box display={isLoaded ? 'block' : 'none'}>
                    <CardMedia
                        component={'img'}
                        onLoad={this.onImgLoad}
                        onError={this.onImgError}
                        image={WishlistImages[item.image].imgPath}
                        className={classes.img}
                    />
                </Box>
                <Box display={!isLoaded ? 'block' : 'none'}>
                    <Skeleton animation="wave" variant="rectangular" className={classes.media} />
                </Box>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WishlistCardImage);
