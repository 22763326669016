import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialize } from '../../statemanagement/actions/AuthenticationActions';

class AuthenticationProvider extends Component {
    state = {
        isInitializing: true,
    };

    componentDidMount() {
        const { initialize } = this.props;
        this.startInitializing();
        initialize().then(() => {
            this.finishInitializing();
        });
    }

    startInitializing = () => {
        this.setState({ isInitializing: true });
    };

    finishInitializing = () => {
        this.setState({ isInitializing: false });
    };

    renderWhileInitializing() {
        return <div />;
    }

    renderContent() {
        const { children } = this.props;
        return <React.Fragment>{children}</React.Fragment>;
    }

    render() {
        const { isInitializing } = this.state;
        return isInitializing ? this.renderWhileInitializing() : this.renderContent();
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initialize: () => dispatch(initialize()),
    };
};

export default connect(null, mapDispatchToProps)(AuthenticationProvider);
