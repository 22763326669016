import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    remove,
    restore,
} from '../../../wishlist/main/stateManagement/actions/WishlistActions';
import {
    createDesire,
    deleteDesire,
    editDesire,
    getAllFlat,
    openCreateDialog,
    openEditDialog,
    registerObserver,
    undoDelete,
    unregisterObserver,
    updateDesiresSortIndex,
} from '../../stateManagement/actions/DesireActions';

const withDesires = (WrappedComponent) => {
    class Desires extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            getAllDesires: (wishlistId) => dispatch(getAllFlat(wishlistId)),
            createDesire: (desire) => dispatch(createDesire(desire)),
            deleteDesire: ({ id }) => dispatch(deleteDesire(id)),
            restoreDesire: (desire) => dispatch(undoDelete(desire)),
            editDesire: (desire) => dispatch(editDesire(desire)),
            registerDesireObserver: (wishlistId, callback) =>
                dispatch(registerObserver(wishlistId, callback)),
            unregisterDesireObserver: (wishlistId, callback) =>
                dispatch(unregisterObserver(wishlistId, callback)),
            openEditDialog: (desire) => dispatch(openEditDialog(desire)),
            openCreateDialog: () => dispatch(openCreateDialog()),
            removeWishlist: (wishlist) => dispatch(remove(wishlist)),
            restoreWishlist: (wishlist) => dispatch(restore(wishlist)),
            updateDesiresSortIndex: (wishlistId, array) =>
                dispatch(updateDesiresSortIndex(wishlistId, array)),
        };
    };

    const mapStateToProps = ({
        desires: { wishlist, desires, isLoading, sharedWith },
    }) => {
        return {
            wishlist,
            desires,
            isLoading,
            sharedWith,
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(Desires);
};

export default withDesires;
