import birthday from './resources/wli_birthday.png';
import friendship from './resources/wli_friendship.png';
import school from './resources/wli_school.png';
import baby from './resources/wli_baby.png';
import baby_kids from './resources/wli_birthday_kids.png';
import christmas from './resources/wli_christmas.png';
import general_1 from './resources/wli_general_1.png';
import general_2 from './resources/wli_general_2.png';
import general_3 from './resources/wli_general_3.png';
import general_4 from './resources/wli_general_4.png';
import general_5 from './resources/wli_general_5.png';
import home from './resources/wli_home.png';
import men from './resources/wli_men.png';
import party_1 from './resources/wli_party_1.png';
import party_2 from './resources/wli_party_2.png';
import wedding_1 from './resources/wli_wedding_1.png';
import wedding_2 from './resources/wli_wedding_2.png';
import wedding_3 from './resources/wli_wedding_3.png';
import women from './resources/wli_women.png';

const wishlistImages = {
    BIRTHDAY: {
        label: 'BIRTHDAY',
        imgPath: birthday,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_birthday.jpg',
    },
    FRIENDSHIP: {
        label: 'FRIENDSHIP',
        imgPath: friendship,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_friendship.jpg',
    },
    SCHOOL: {
        label: 'SCHOOL',
        imgPath: school,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_school.jpg',
    },
    BABY: {
        label: 'BABY',
        imgPath: baby,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_baby.jpg',
    },
    BIRTHDAY_KIDS: {
        label: 'BIRTHDAY_KIDS',
        imgPath: baby_kids,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_birthday_kids.jpg',
    },
    CHRISTMAS: {
        label: 'CHRISTMAS',
        imgPath: christmas,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_christmas.jpg',
    },
    GENERAL_1: {
        label: 'GENERAL_1',
        imgPath: general_1,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_general_1.jpg',
    },
    GENERAL_2: {
        label: 'GENERAL_2',
        imgPath: general_2,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_general_2.jpg',
    },
    GENERAL_3: {
        label: 'GENERAL_3',
        imgPath: general_3,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_general_3.jpg',
    },
    GENERAL_4: {
        label: 'GENERAL_4',
        imgPath: general_4,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_general_4.jpg',
    },
    GENERAL_5: {
        label: 'GENERAL_5',
        imgPath: general_5,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_general_5.jpg',
    },
    HOME: {
        label: 'HOME',
        imgPath: home,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_home.jpg',
    },
    MEN: {
        label: 'MEN',
        imgPath: men,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_men.jpg',
    },
    PARTY_1: {
        label: 'PARTY_1',
        imgPath: party_1,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_party_1.jpg',
    },
    PARTY_2: {
        label: 'PARTY_2',
        imgPath: party_2,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_party_2.jpg',
    },
    WEDDING_1: {
        label: 'WEDDING_1',
        imgPath: wedding_1,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_wedding_1.jpg',
    },
    WEDDING_2: {
        label: 'WEDDING_2',
        imgPath: wedding_2,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_wedding_2.jpg',
    },
    WEDDING_3: {
        label: 'WEDDING_3',
        imgPath: wedding_3,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_wedding_3.jpg',
    },
    WOMEN: {
        label: 'WOMEN',
        imgPath: women,
        shareLinkImage:
            'https://app.starsnoopy.de/linksharingresources/wli_women.jpg',
    },
};

export default wishlistImages;
