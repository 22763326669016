import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';

const styles = () => ({
    error: {
        color: '#d32f2fde',
        backgroundColor: '#d32f2f00',
        flexWrap: 'nowrap',
        boxShadow: 'none',
    },
    success: {
        color: '#7ACC4C',
        backgroundColor: '#d32f2f00',
        flexWrap: 'nowrap',
        boxShadow: 'none',
    },
    icon: {
        fontSize: 24,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: '11px',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
    },
});

class UserMessageContentWrapper extends Component {
    render() {
        const { classes, className, message, success, ...other } = this.props;
        const Icon = success ? CheckCircleIcon : ErrorIcon;

        return (
            <SnackbarContent
                className={success ? classes.success : classes.error}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }
}

export default withStyles(styles)(UserMessageContentWrapper);
