import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    remove,
    reset,
} from '../stateManagement/actions/ProfileDeletionActions';
import DeleteProfileDialog from '../../main/components/dialog/delete/DeleteProfileDialog';
import withProfile from '../../main/components/hocs/Profile';

class ProfileDeletion extends Component {
    state = {
        fetchingUser: true,
    };
    componentDidMount() {
        const { getCurrentUserProfile } = this.props;
        getCurrentUserProfile().then(() =>
            this.setState({ fetchingUser: false })
        );
    }

    handleCloseContinueDialog = () => {
        const { reset } = this.props;
        reset();
    };

    handleSubmitContinueDialog = () => {
        const { removeProfile } = this.props;
        removeProfile().then(this.handleCloseDeleteProfileDialog);
    };

    render() {
        const { profile, shouldShowDialog, userMarkedForDeletion } = this.props;
        const { data } = profile;
        const { fetchingUser } = this.state;
        return (
            !fetchingUser && (
                <DeleteProfileDialog
                    open={
                        data
                            ? data.id === userMarkedForDeletion &&
                              shouldShowDialog
                            : false
                    }
                    profile={data}
                    onClose={this.handleCloseContinueDialog}
                    onSubmit={this.handleSubmitContinueDialog}
                />
            )
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeProfile: () => dispatch(remove()),
        reset: () => dispatch(reset()),
    };
};

const mapStateToProps = (state) => {
    return {
        shouldShowDialog: state.profileDeletion.shouldShowDialog,
        userMarkedForDeletion: state.profileDeletion.userMarkedForDeletion,
    };
};

export default withProfile(
    connect(mapStateToProps, mapDispatchToProps)(ProfileDeletion)
);
