import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import withStyles from '@mui/styles/withStyles';
import Fade from '@mui/material/Fade';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(8, 0, 6),
        fontSize: '1rem !important',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
    },
    text: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
});

class SharedDesiresEmptyList extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Container maxWidth="sm">
                    <Fade timeout={200} in={true} mountOnEnter unmountOnExit>
                        <div>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wish.empty'} />
                            </Typography>
                        </div>
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(SharedDesiresEmptyList);
