import React, { Component } from 'react';
import WishlistDialog from '../../main/components/dialog/WishlistDialog';

class EditWishlistDialog extends Component {
    render() {
        const { open, item, onClose, onSubmit } = this.props;
        return (
            <WishlistDialog
                title="wishlist.edit"
                submitButtonLabel="dialog.ok"
                item={item}
                open={open}
                onClose={() => onClose()}
                onSubmit={(model) => onSubmit(item, model)}
            />
        );
    }
}

export default EditWishlistDialog;
