import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Skeleton from '@mui/material/Skeleton';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon from '../../../mixins/components/icons/Icon';
import IC_DELETE from '../../../wishlist/main/components/card/resources/ic_delete.png';
import IC_SHARE from '../../../wishlist/main/components/card/resources/ic_share.png';
import withWishlist from '../../../wishlist/main/hocs/Wishlist';
import ShareLinkDialog from '../../../wishlist/share/components/ShareLinkDialog';
import MovingTextContainer from '../resources/movingTextContainer/MovingTextContainer';

const styles = (theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    shareButton: {
        display: 'flex',
        marginLeft: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontFamily: theme.typography.appBarFontFamily,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
});

class DesireAppbar extends Component {
    state = {
        shareDialogIsOpen: false,
    };

    handleShareClick = () => {
        const { shareWishlist, item, intl } = this.props;
        if (item) {
            shareWishlist(item, intl).then(() => {
                this.setState({
                    shareDialogIsOpen: true,
                });
            });
        }
    };

    handleItemShareCopy = () => {
        const { enqueueSnackbar, intl } = this.props;
        enqueueSnackbar(intl.formatMessage({ id: 'wishlist.copiedLink' }), {
            variant: 'default',
            autoHideDuration: 6000,
        });
    };

    handleItemShareClose = () => {
        this.setState({
            shareDialogIsOpen: false,
        });
    };
    deleteWishlist = () => {
        const { removeWishlistAction } = this.props;
        removeWishlistAction();
    };

    renderAction(action, key) {
        return (
            <Tooltip
                key={key}
                title={action.title}
                aria-label="share"
                enterDelay={300}
            >
                {action.navigateTo ? (
                    <IconButton
                        onMouseDown={this.disableRipple}
                        onTouchStart={this.disableRipple}
                        onClick={action.event}
                        component={Link}
                        to={action.navigateTo.to}
                        size="large">
                        <Icon icon={action.icon.value} alt={action.icon.alt} />
                    </IconButton>
                ) : (
                    <IconButton
                        onMouseDown={this.disableRipple}
                        onTouchStart={this.disableRipple}
                        onClick={action.event}
                        size="large">
                        <Icon icon={action.icon.value} alt={action.icon.alt} />
                    </IconButton>
                )}
            </Tooltip>
        );
    }

    renderActions = () => {
        const actions = [
            {
                title: <FormattedMessage id={'wishlist.share'} />,
                event: () => this.handleShareClick(),
                icon: {
                    value: IC_SHARE,
                    alt: 'icon to share',
                },
            },
            {
                title: <FormattedMessage id={'wishlist.deleteTooltip'} />,
                event: () => this.deleteWishlist(),
                icon: {
                    value: IC_DELETE,
                    alt: 'icon to delete',
                },
                navigateTo: {
                    to: '/wishlist',
                },
            },
        ];

        return (
            <React.Fragment>
                {actions.map((action, key) => this.renderAction(action, key))}
            </React.Fragment>
        );
    };

    renderShareDialog = () => {
        const {
            sharedWishlist: { link },
            item,
        } = this.props;
        const { shareDialogIsOpen } = this.state;
        return (
            <ShareLinkDialog
                link={link}
                open={shareDialogIsOpen}
                onClose={this.handleItemShareClose}
                onCopyClick={this.handleItemShareCopy}
                wishlist={item}
                displayQrCode={true}
            />
        );
    };

    render() {
        const { classes, title, desktop } = this.props;
        const loaded = title !== '';

        return (
            <AppBar position="fixed" color="secondary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        component={Link}
                        to={'/wishlist'}
                        size="large">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {loaded ? (
                            <MovingTextContainer dividerForTiming={5}>
                                {title}
                            </MovingTextContainer>
                        ) : (
                            <Skeleton
                                animation="wave"
                                width={desktop ? '20%' : '40%'}
                                variant="text"
                            />
                        )}
                    </Typography>
                    <div className={classes.shareButton}>
                        {this.renderActions()}
                    </div>
                </Toolbar>
                {this.renderShareDialog()}
            </AppBar>
        );
    }
}

export default withSnackbar(
    injectIntl(withWishlist(withStyles(styles)(DesireAppbar)))
);
