import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { firebaseMessaging } from '../networking/firebase/FirebaseConfig';
import { closeActionSnackbarButton } from './CustomSnackbarProvider';

const styles = {
    pointer: { cursor: 'pointer' },
};

// provider for receiving 'Firebase Cloud Messaging' notifications if tab has focus
class FCMProvider extends Component {
    componentDidMount() {
        firebaseMessaging &&
            firebaseMessaging.onMessage(({ data }) => {
                const { enqueueSnackbar, closeSnackbar } = this.props;
                const {
                    type,
                    wishlist_id,
                    wishlist_title,
                    person_name,
                    wishes_left,
                } = data;

                enqueueSnackbar(
                    this.getMessage(
                        type,
                        wishlist_title,
                        person_name,
                        wishes_left,
                        () => {
                            window.location.href = `/friendswishlist/${wishlist_id}/`;
                            closeSnackbar();
                        }
                    ),
                    {
                        variant: 'info',
                        action: (key) =>
                            closeActionSnackbarButton(() => closeSnackbar(key)),
                    }
                );
            });
    }

    getMessage = (
        type,
        wishlist_title,
        person_name,
        wishes_left,
        onClickNotification
    ) => {
        switch (type) {
            case 'reserved_wish':
                return this.renderReservedOrBoughtMessage(
                    'notification.reservedNoneLeft',
                    'notification.reservedOneLeft',
                    'notification.reservedMultipleLeft',
                    wishes_left,
                    person_name,
                    wishlist_title,
                    onClickNotification
                );
            case 'bought_wish':
                return this.renderReservedOrBoughtMessage(
                    'notification.boughtNoneLeft',
                    'notification.boughtOneLeft',
                    'notification.boughtMultipleLeft',
                    wishes_left,
                    person_name,
                    wishlist_title,
                    onClickNotification
                );
            default:
                return this.renderNewWishMessage(onClickNotification);
        }
    };

    renderNewWishMessage = (onClickNotification) => {
        return (
            <Box
                className={this.props.classes.pointer}
                onClick={onClickNotification}
            >
                <FormattedMessage id={'notification.newWish'} />
            </Box>
        );
    };

    selectByWishesLeft = (
        noneLeftKey,
        oneLeftKey,
        multipleLeftKey,
        wishes_left,
        person_name,
        wishlist_title
    ) => {
        switch (Number(wishes_left)) {
            case 0:
                return (
                    <FormattedMessage
                        id={noneLeftKey}
                        values={{
                            person_name,
                            wishlist_title,
                            wishes_left,
                        }}
                    />
                );
            case 1:
                return (
                    <FormattedMessage
                        id={oneLeftKey}
                        values={{
                            person_name,
                            wishlist_title,
                            wishes_left,
                        }}
                    />
                );

            default:
                return (
                    <FormattedMessage
                        id={multipleLeftKey}
                        values={{
                            person_name,
                            wishlist_title,
                            wishes_left,
                        }}
                    />
                );
        }
    };

    renderReservedOrBoughtMessage = (
        noneLeftKey,
        oneLeftKey,
        multipleLeftKey,
        wishes_left,
        person_name,
        wishlist_title,
        onClickNotification
    ) => {
        return (
            <Box
                className={this.props.classes.pointer}
                onClick={onClickNotification}
            >
                {this.selectByWishesLeft(
                    noneLeftKey,
                    oneLeftKey,
                    multipleLeftKey,
                    wishes_left,
                    person_name,
                    wishlist_title
                )}
            </Box>
        );
    };

    render() {
        return this.props.children;
    }
}

export default withSnackbar(withStyles(styles)(FCMProvider));
