import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import validator from 'validator';
import LogoutLink from '../../../../authentication/components/link/LogoutLink';
import {
    logout,
    sendVerificationMail,
} from '../../../../authentication/statemanagement/actions/AuthenticationActions';
import LinkButton from '../../../../mixins/components/buttons/LinkButton';
import DividerPicture from '../../../../mixins/components/DividerPicture';
import { closeActionSnackbarButton } from '../../../../snackbar/CustomSnackbarProvider';
import { applyShowDialog as applyShowDeletionDialog } from '../../../deletion/stateManagement/actions/ProfileDeletionActions';
import { applyShowDialog } from '../../../edit/stateManagement/actions/ProfileEditActions';
import RenewAuthenticationDialog from '../dialog/renew/RenewAuthenticationDialog';
import withProfile from '../hocs/Profile';
import ProfileForm from './ProfileForm';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    deleteAccount: {
        letterSpacing: '1px',
        fontSize: '0.875rem',
        marginTop: theme.spacing(3),
        cursor: 'pointer',
    },
    deleteAccountText: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    buttonGroup: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        flexDirection: 'row',
        width: '100%',
    },
    width50: {
        width: 'calc(50% - 5px)',
    },
    width100: {
        width: '100%',
    },
    posRelative: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.common.accentColor,
        position: 'absolute',
        top: '40%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
});

let imageFile = null;

const toDataURL = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

class ProfileContent extends Component {
    state = {
        name: '',
        email: '',
        profileImage: '',
        hasChanged: false,
        renewAuthenticationDialogIsOpen: false,
        isSaving: false,
    };

    componentDidMount() {
        this.reset();
    }

    componentWillUnmount() {
        this.props.resetStore();
    }

    reset = () =>
        this.props
            .getCurrentUserProfile()
            .then(({ name, email, profileImage }) => {
                this.setState({
                    ...this.state,
                    name,
                    email,
                    profileImage,
                    hasChanged: false,
                    isSaving: false,
                });
            });

    handleEmailChange = ({ target: { value } }) => {
        const {
            profile: { data },
        } = this.props;
        this.setState({
            email: value,
            hasChanged: value !== data?.email,
        });
    };

    handleNameChange = ({ target: { value } }) => {
        const {
            profile: { data },
        } = this.props;
        this.setState({ name: value, hasChanged: value !== data?.name });
    };

    handleImageChange = async (file) => {
        imageFile = file;
        this.setState({
            profileImage: await toDataURL(file),
            hasChanged: true,
        });
    };

    handleProfileChange = async () => {
        const { name, email, profileImage } = this.state;
        const {
            edit,
            enqueueSnackbar,
            closeSnackbar,
            intl,
            openDialog,
            sendVerificationMail,
            uploadImage,
        } = this.props;

        this.setState({ isSaving: true });

        if (!validator.isURL(profileImage)) {
            try {
                await uploadImage(imageFile);
            } catch (e) {
                console.error(e);
                this.setState({ isSaving: false });
                return;
            }
        }

        try {
            const { updatedEmail } = await edit(name, email);
            if (updatedEmail) {
                await sendVerificationMail();
                enqueueSnackbar(intl.formatMessage({ id: 'auth.verify' }), {
                    variant: 'info',
                    action: (key) =>
                        closeActionSnackbarButton(() => closeSnackbar(key)),
                });
            }
        } catch (error) {
            openDialog();
            this.setState({ isSaving: false });
            return;
        }

        this.reset();
    };

    handleProfileDeletion = () => {
        const {
            logout,
            profileDeletionApllyShowDialog,
            getCurrentUserProfile,
        } = this.props;
        this.setState({ renewAuthenticationDialogIsOpen: false });
        logout();
        getCurrentUserProfile().then((user) =>
            profileDeletionApllyShowDialog(user)
        );
    };

    handleOpenDeleteProfileDialog = () =>
        this.setState({ renewAuthenticationDialogIsOpen: true });

    handleCloseDeleteProfileDialog = () =>
        this.setState({ renewAuthenticationDialogIsOpen: false });

    render() {
        const { classes, profile, error, showUserInfo } = this.props;
        const {
            name,
            email,
            profileImage,
            hasChanged,
            renewAuthenticationDialogIsOpen,
            isSaving,
        } = this.state;

        return (
            <Container component="main" className={classes.root} maxWidth="xs">
                <ProfileForm
                    profileImage={profileImage}
                    uploadImage={this.handleImageChange}
                    name={name}
                    email={email}
                    error={error}
                    showUserInfo={showUserInfo}
                    onNameChange={this.handleNameChange}
                    onEmailChange={this.handleEmailChange}
                />
                <div className={classes.buttonGroup}>
                    <LinkButton
                        outerCSS={classes.width50}
                        outlined
                        route={'/wishlist'}
                        messageId={'dialog.cancel'}
                    />
                    <div className={clsx(classes.width50, classes.posRelative)}>
                        <LinkButton
                            outerCSS={classes.width100}
                            route={'/profile'}
                            onClick={this.handleProfileChange}
                            messageId={'profile.save'}
                            disabled={!hasChanged || isSaving}
                        />
                        {isSaving && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </div>
                </div>
                <DividerPicture />
                <LogoutLink />
                <Box
                    mt={0}
                    color="text.secondary"
                    className={classes.deleteAccount}
                >
                    <Link
                        className={classes.deleteAccountText}
                        onClick={this.handleOpenDeleteProfileDialog}
                    >
                        <FormattedMessage id={'profile.delete'} />
                    </Link>
                </Box>
                <RenewAuthenticationDialog
                    open={renewAuthenticationDialogIsOpen}
                    profile={profile.data}
                    onClose={this.handleCloseDeleteProfileDialog}
                    onSubmit={this.handleProfileDeletion}
                />
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        openDialog: () => dispatch(applyShowDialog()),
        sendVerificationMail: () => dispatch(sendVerificationMail()),
        profileDeletionApllyShowDialog: (user) =>
            dispatch(applyShowDeletionDialog(user)),
    };
};

export default withSnackbar(
    injectIntl(
        connect(
            null,
            mapDispatchToProps
        )(withProfile(withStyles(styles)(ProfileContent)))
    )
);
