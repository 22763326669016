import React, { Component } from 'react';
import WishlistImages from '../../main/components/imageStepper/WishlistImages';
import WishlistDialog from '../../main/components/dialog/WishlistDialog';

const EMPTY_ITEM = {
    title: '',
    note: '',
    image: WishlistImages.BIRTHDAY,
};

class CreateWishlistDialog extends Component {
    render() {
        const { open, onClose, onSubmit } = this.props;
        return (
            <WishlistDialog
                title="wishlist.newWish"
                submitButtonLabel="wishlist.create"
                item={EMPTY_ITEM}
                open={open}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        );
    }
}

export default CreateWishlistDialog;
