import {
    HIDE_PRIMARY_APPBAR,
    SHOW_PRIMARY_APPBAR,
} from '../actions/PrimaryAppBarActionTypes';

const primaryAppbarReducer = (
    state = {
        isVisible: true,
    },
    action
) => {
    switch (action.type) {
        case SHOW_PRIMARY_APPBAR:
            return {
                ...state,
                isVisible: true,
            };
        case HIDE_PRIMARY_APPBAR:
            return {
                ...state,
                isVisible: false,
            };
        default:
            return state;
    }
};

export default primaryAppbarReducer;
