import { adaptV4Theme, createTheme } from '@mui/material/styles';

const theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#FBFAE9',
                contrastText: '#4D4D4D',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main: '#8EBFBF',
                contrastText: '#FBFAE9',
            },
            background: {
                default: '#FBFAE9',
            },
            common: {
                white: '#F4F3FB',
                darkBackground: '#40454D',
                accentColor: '#F47F8C',
                secondaryAccentColor: '#F7F399',
                link: '#3B5998;',
            },
            text: {
                primary: '#4D4D4D',
                secondary: '#FBFAE9',
            },
        },
        typography: {
            sideBarFontFamily: 'Merienda',
            tabFontFamily: 'Merienda',
            appBarSubtitle: 'Merienda',
            appBarFontFamily: '"Merienda One"',
            fontFamily: [
                '"Open Sans"',
                'Roboto',
                'Arial',
                'Raleway',
                '"Merienda One"',
                'Merienda',
            ].join(','),
        },
    })
);

export default theme;
