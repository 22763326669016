import { Box, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import withTheme from '@mui/styles/withTheme';
import React, { Component } from 'react';
import WishlistImages from '../../../../wishlist/main/components/imageStepper/WishlistImages';
import WishCounter from '../../../../wishlist/main/components/wishCounter/WishCounter';
import MovingTextContainer from '../../resources/movingTextContainer/MovingTextContainer';

const styles = (theme) => ({
    item: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        transition: 'box-shadow 0.2 ease-in-out',
        boxShadow:
            '0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12)',
        '&:hover': {
            boxShadow:
                '0 6px 6px -3px rgba(0,0,0,0.2), 0 10px 14px 1px rgba(0,0,0,0.14), 0 4px 18px 3px rgba(0,0,0,0.12)',
        },
        width: '100%',
        height: '80px',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding: '14px',
        borderRadius: '3px',
        border: `3px solid ${theme.palette.primary.main}`,
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        cursor: 'pointer',
        flexDirection: 'row',
    },
    badge: {
        width: '100%',
    },
    itemWrapper: {
        width: '100%',
        backgroundColor: 'white',
    },
    title: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        fontSize: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: '0',
        maxWidth: '100%',
        wordBreak: 'break-all',
    },
    image: {
        maxHeight: '50px',
    },
    textDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexDirection: 'column',
        marginLeft: '7px',
        maxWidth: '100%',
        flex: '1',
        width: '0',
        overflow: 'hidden',
        maxHeight: '100%',
    },
    note: {
        margin: '0',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        maxWidth: '100%',
        whiteSpace: 'nowrap',
    },
});

class WishlistItem extends Component {
    render() {
        const { classes, moveDesire, title, wishCount, note, image } =
            this.props;

        return (
            <WishCounter foreignClassName={classes.badge} count={wishCount}>
                <Tooltip title={title} enterDelay={1000}>
                    <Box className={classes.box} onClick={moveDesire}>
                        <div className={classes.item}>
                            <img
                                className={classes.image}
                                src={WishlistImages[image].imgPath}
                                alt={'wishlist'}
                            />
                            <div className={classes.textDiv}>
                                <p className={classes.title}>{title}</p>
                                {note && (
                                    <MovingTextContainer
                                        foreignClassName={classes.note}
                                        dividerForTiming={5}
                                    >
                                        {note}
                                    </MovingTextContainer>
                                )}
                            </div>
                        </div>
                    </Box>
                </Tooltip>
            </WishCounter>
        );
    }
}

export default withStyles(styles)(withTheme(WishlistItem));
