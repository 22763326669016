import EN from '../locales/EN';
import DE from '../locales/DE';
import FR from '../locales/FR';

export const LocalizationTypes = [
    {
        id: Symbol('en'),
        flag: 'en',
        messages: EN,
        messageId: 'settings.english',
    },
    {
        id: Symbol('de'),
        flag: 'de',
        messages: DE,
        messageId: 'settings.german',
    },
    {
        id: Symbol('fr'),
        flag: 'fr',
        messages: FR,
        messageId: 'settings.french',
    },
];
