import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { emphasize } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    speedDialIcon: {
        color: theme.palette.primary.main,
    },
    root: {
        backgroundColor: theme.palette.common.accentColor,
        '&:hover': {
            backgroundColor: emphasize(theme.palette.common.accentColor, 0.15),
        },
    },
});

class CreateButton extends Component {
    render() {
        const { classes, onClick, isWishlistCreate } = this.props;
        return (
            <Tooltip
                title={
                    <FormattedMessage
                        id={isWishlistCreate ? 'wishlist.add' : 'wish.new'}
                    />
                }
                aria-label="add"
                enterDelay={300}
            >
                <Fab className={classes.root} onClick={onClick}>
                    <AddIcon className={classes.speedDialIcon} />
                </Fab>
            </Tooltip>
        );
    }
}

export default withStyles(styles)(CreateButton);
