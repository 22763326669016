import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ResponsiveFooter from '../wishlist/main/components/ResponsiveFooter';
import IC_ROBIN_404 from './img/ic_robin_404.svg';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    responsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    text: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
        marginBottom: 0,
    },
    redirectToWishlistLink: {
        color: 'inherit',
    },
    ic_robin: {
        padding: '5%',
        display: 'block',
        'margin-left': 'auto',
        'margin-right': 'auto',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
});

class FourZeroFour extends Component {
    render404Text = () => {
        const { classes } = this.props;
        return (
            <div>
                <Typography
                    variant="h6"
                    align="center"
                    color="textPrimary"
                    paragraph
                    className={classes.text}
                >
                    <FormattedMessage
                        id={'404.Oops'}
                        values={{ newLine: <br /> }}
                    />
                </Typography>
                <img
                    className={classes.ic_robin}
                    src={IC_ROBIN_404}
                    alt={'robin'}
                />
                <Typography
                    variant="h6"
                    align="center"
                    color="textPrimary"
                    paragraph
                    className={classes.text}
                >
                    <FormattedMessage
                        id={'404.redirect'}
                        values={{
                            redirectToWishlist: (
                                <Link
                                    to={'/wishlist'}
                                    className={classes.redirectToWishlistLink}
                                >
                                    <FormattedMessage id="404.redirectToWishlist" />
                                </Link>
                            ),
                        }}
                    />
                </Typography>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <main className={classes.responsive}>
                    {this.render404Text()}
                </main>
                <ResponsiveFooter textColor="textPrimary" />
            </div>
        );
    }
}

export default withStyles(styles)(FourZeroFour);
