import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import ProfilePicture from '../../../../profile/main/components/picture/ProfilePicture';

const styles = (theme) => ({
    cardHeader: {
        paddingBottom: theme.spacing(1),
    },
    header: {
        '& .MuiCardHeader-content': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    title: {
        fontWeight: 'bold',
    },
    subtitle: {
        color: '#4D4D4DD1',
    },
    avatar: {
        boxShadow:
            '0 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0 rgba(0,0,0,0.14),0px 1px 3px 0 rgba(0,0,0,0.12)',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
    },
    textOverflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

class WishlistCardHeader extends Component {
    renderLoadingSkeleton() {
        const { classes } = this.props;
        return (
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar} fontSize="large">
                        <PersonIcon />
                    </Avatar>
                }
                title={
                    <Skeleton
                        animation="wave"
                        width={'80%'}
                        height={10}
                        variant="text"
                    />
                }
                subheader={
                    <Skeleton
                        animation="wave"
                        width={'40%'}
                        height={10}
                        variant="text"
                    />
                }
                classes={{
                    title: classes.title,
                    subheader: classes.subtitle,
                }}
                className={classes.cardHeader}
            />
        );
    }

    renderHeader() {
        const { classes, action, profile, item } = this.props;
        const imageURL = profile?.profileImage ?? '';

        return (
            <CardHeader
                classes={{
                    title: clsx(classes.title, classes.textOverflow),
                    subheader: clsx(classes.subtitle, classes.textOverflow),
                }}
                className={classes.header}
                avatar={<ProfilePicture imageURL={imageURL} />}
                action={action}
                title={item.title}
                subheader={profile?.name ?? ''}
            />
        );
    }

    render() {
        const { isLoading } = this.props;
        return isLoading ? this.renderLoadingSkeleton() : this.renderHeader();
    }
}

export default withStyles(styles)(WishlistCardHeader);
