export const REGISTER_OBSERVER_SHARED_WISHLIST = 'REGISTER_OBSERVER_SHARED_WISHLIST';
export const UNREGISTER_OBSERVER_SHARED_WISHLIST = 'UNREGISTER_OBSERVER_SHARED_WISHLIST';

export const GET_ALL_SHARED_WISHLIST_REQUEST = 'GET_ALL_SHARED_WISHLIST_REQUEST';
export const GET_ALL_SHARED_WISHLIST_SUCCESS = 'GET_ALL_SHARED_WISHLIST_SUCCESS';
export const GET_ALL_SHARED_WISHLIST_ERROR = 'GET_ALL_SHARED_WISHLIST_ERROR';

export const CREATE_SHARED_WISHLIST_REQUEST = 'CREATE_SHARED_WISHLIST_REQUEST';
export const CREATE_SHARED_WISHLIST_SUCCESS = 'CREATE_SHARED_WISHLIST_SUCCESS';
export const CREATE_SHARED_WISHLIST_ERROR = 'CREATE_SHARED_WISHLIST_ERROR';

export const REMOVE_SHARED_WISHLIST_REQUEST = 'REMOVE_SHARED_WISHLIST_REQUEST';
export const REMOVE_SHARED_WISHLIST_SUCCESS = 'REMOVE_SHARED_WISHLIST_SUCCESS';
export const REMOVE_SHARED_WISHLIST_ERROR = 'REMOVE_SHARED_WISHLIST_ERROR';

export const RESTORE_SHARED_WISHLIST_REQUEST = 'RESTORE_SHARED_WISHLIST_REQUEST';
export const RESTORE_SHARED_WISHLIST_SUCCESS = 'RESTORE_SHARED_WISHLIST_SUCCESS';
export const RESTORE_SHARED_WISHLIST_ERROR = 'RESTORE_SHARED_WISHLIST_ERROR';

export const RESET_SHARED_WISHLIST_STORE = 'RESET_SHARED_WISHLIST_STORE';

