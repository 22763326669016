import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { styles } from './styles/TextFieldStyles';

class UrlField extends Component {
    render() {
        const {
            classes,
            intl,
            onChange,
            id,
            autoFocus,
            startAdornment,
            endAdornment,
            foreignClassName,
            disabled,
            onEnterClick,
            enterClickCondition,
            onPaste,
            value,
        } = this.props;

        return (
            <TextField
                fullWidth
                id={id}
                className={clsx(
                    foreignClassName,
                    disabled ? classes.inputDisabled : classes.input
                )}
                label={intl.formatMessage({ id: id })}
                autoFocus={autoFocus}
                onChange={onChange}
                inputProps={{
                    disabled,
                    maxLength: 2000,
                }}
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                        enterClickCondition && onEnterClick();
                    }
                }}
                value={value}
                onPaste={onPaste}
                InputProps={{ startAdornment, endAdornment }}
                variant="standard"
            />
        );
    }
}

export default injectIntl(withStyles(styles)(UrlField));
