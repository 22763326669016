import { Fade, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DesiresPage from '../../../desires/components/page/DesiresPage';
import withProfile from '../../../profile/main/components/hocs/Profile';
import { undoActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import WishlistEmptyList from '../../../wishlist/list/components/WishlistEmptyList';
import WishListList from '../../../wishlist/list/components/WishListList';
import CreateWishlistDialog from '../../create/components/CreateWishlistDialog';
import EditWishlistDialog from '../../edit/components/EditWishlistDialog';
import CreateButton from '../../main/components/button/CreateButton';
import withWishlist from '../../main/hocs/Wishlist';
import ShareLinkDialog from '../../share/components/ShareLinkDialog';
import DefaultLayout from './DefaultLayout';

const styles = (theme) => ({
    createButton: {
        position: 'fixed',
        bottom: theme.spacing(6),
        right: theme.spacing(8),
        cursor: 'pointer',
        zIndex: 1,
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(3),
        },
    },
    deleteUndoSnackbarMessageDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '2px',
    },
    deleteUndoSnackbarMessage: {
        maxWidth: '10rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

class WishListPage extends Component {
    state = {
        selectedItem: null,
        createDialogIsOpen: false,
        editDailogIsOpen: false,
        shareDialogIsOpen: false,
        removedItem: null,
    };

    componentDidMount() {
        const { getCurrentUserProfile, registerWishlistObserver } = this.props;
        getCurrentUserProfile();
        registerWishlistObserver(this.handleReload);
    }

    componentWillUnmount() {
        this.props.unregisterWishlistObserver(this.handleReload);
    }

    handleReload = this.props.getAllWishlists;

    handleItemCreate = () =>
        this.setState({
            createDialogIsOpen: true,
        });

    handleItemCreateSubmit = (item) =>
        this.props.createWishlist(item).then(this.handleItemCreateClose);

    handleItemCreateClose = () =>
        this.setState({
            createDialogIsOpen: false,
        });

    handleItemEdit = (item) =>
        this.setState({
            selectedItem: item,
            editDailogIsOpen: true,
        });

    handleItemEditSubmit = (item, model) =>
        this.props.editWishlist(item, model).then(this.handleItemEditClose);

    handleItemEditClose = () => {
        this.setState({
            selectedItem: null,
            editDailogIsOpen: false,
        });
    };

    handleItemShare = (item) => {
        const { shareWishlist, intl } = this.props;
        shareWishlist(item, intl).then(() => {
            this.setState({
                shareDialogIsOpen: true,
            });
        });
    };

    handleItemShareCopy = () => {
        const { enqueueSnackbar, intl } = this.props;

        enqueueSnackbar(intl.formatMessage({ id: 'wishlist.copiedLink' }), {
            variant: 'default',
            autoHideDuration: 6000,
        });
    };

    handleItemShareClose = () =>
        this.setState({
            shareDialogIsOpen: false,
        });

    handleItemRestore = (item) => this.props.restoreWishlist(item);

    handleItemRemove = (item) => {
        const { removeWishlist, enqueueSnackbar, closeSnackbar, wishlist } =
            this.props;

        removeWishlist(item);

        const [removedItem] = wishlist.items.filter(
            (wishlist) => wishlist.id === item.id
        );

        this.setState({ removedItem });

        enqueueSnackbar(this.getUndoDeleteSnackbarMessage(removedItem), {
            variant: 'info',
            autoHideDuration: 6000,
            action: (key) =>
                undoActionSnackbarButton(
                    () => this.handleItemRestore(removedItem),
                    () => closeSnackbar(key)
                ),
        });
    };

    getPageTitle = () => {
        const { classes } = this.props;

        return (
            <div className={classes.titleDiv}>
                <Typography className={classes.title} variant="h4">
                    <FormattedMessage id={'nav.my-wishlist'} />
                </Typography>
            </div>
        );
    };

    renderEditDialog() {
        const { editDailogIsOpen, selectedItem } = this.state;
        return (
            <EditWishlistDialog
                item={selectedItem}
                open={editDailogIsOpen}
                onSubmit={this.handleItemEditSubmit}
                onClose={this.handleItemEditClose}
            />
        );
    }

    renderCreateDialog = () => (
        <CreateWishlistDialog
            open={this.state.createDialogIsOpen}
            onSubmit={this.handleItemCreateSubmit}
            onClose={this.handleItemCreateClose}
        />
    );

    renderCreateButton = () => (
        <Fade timeout={500} in={true}>
            <div
                id="wishlistCreateButton"
                className={this.props.classes.createButton}
            >
                <CreateButton
                    onClick={this.handleItemCreate}
                    isWishlistCreate
                />
            </div>
        </Fade>
    );

    renderShareDialog = () => {
        const {
            sharedWishlist: { link },
            wishlist,
        } = this.props;
        const { shareDialogIsOpen } = this.state;

        const [list] = wishlist.items?.filter(
            ({ shareUrlShort }) => link === shareUrlShort
        );

        return (
            <ShareLinkDialog
                link={link}
                open={shareDialogIsOpen}
                onClose={this.handleItemShareClose}
                onCopyClick={this.handleItemShareCopy}
                wishlist={list}
            />
        );
    };

    getUndoDeleteSnackbarMessage({ title }) {
        const { classes } = this.props;
        return (
            <div className={classes.deleteUndoSnackbarMessageDiv}>
                <FormattedMessage id={'wishlist.wishlist'} />
                <span>{"'"}</span>
                <div className={classes.deleteUndoSnackbarMessage}>{title}</div>
                <span>{"'"}</span>
                <FormattedMessage id={'wishlist.deleted'} />
            </div>
        );
    }

    render() {
        const {
            profile,
            wishlist: { items, loading },
        } = this.props;

        return (
            <Fragment>
                <DefaultLayout
                    titleId="nav.my-wishlist"
                    destination="wishlist"
                    leftItems={items}
                    mainContent={
                        <WishListList
                            profile={profile?.data}
                            items={items}
                            onItemEdit={this.handleItemEdit}
                            onItemShare={this.handleItemShare}
                            onItemRemove={this.handleItemRemove}
                        />
                    }
                    emptyContent={
                        <WishlistEmptyList onCreate={this.handleItemCreate} />
                    }
                    splitContent={<DesiresPage />}
                    loading={loading}
                    itemsLength={items.length}
                    removedItem={this.state.removedItem}
                    callbackOnRemoved={() =>
                        this.setState({ removedItem: null })
                    }
                />

                {items.length > 0 && this.renderCreateButton()}
                {this.renderEditDialog()}
                {this.renderCreateDialog()}
                {this.renderShareDialog()}
            </Fragment>
        );
    }
}

export default withSnackbar(
    injectIntl(withWishlist(withProfile(withStyles(styles)(WishListPage))))
);
