import InputAdornment from '@mui/material/InputAdornment';
import { LockOpen } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import StyledTextField from './base/StyledTextField';

const styles = (theme) => ({
    input: {
        margin: theme.spacing(0, 0, 0, 0),
        padding: theme.spacing(0, 0, 0, 0),
    },
});

class PasswordRepeatField extends Component {
    render() {
        const { classes, onChange, intl } = this.props;

        return (
            <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password_repeat"
                type={'password'}
                id="password_repeat"
                autoComplete="off"
                placeholder={intl.formatMessage({ id: 'auth.password-repeat' })}
                className={classes.input}
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockOpen />
                        </InputAdornment>
                    ),
                }}
                inputProps={{ maxLength: 200 }}
            />
        );
    }
}

export default injectIntl(withStyles(styles)(PasswordRepeatField));
