import React, { Component } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import withStyles from '@mui/styles/withStyles';
import DrawerContent from './DrawerContent';
import DrawerFooter from './DrawerFooter';
import { hide } from '../stateManagement/actions/DrawerActions';
import { connect } from 'react-redux';
import withTheme from '@mui/styles/withTheme';
import MenuItems from '../../model/MenuItems';
import { withRouter } from 'react-router-dom';

const DRAWER_WIDTH = 260;

const styles = (theme) => ({
    drawerPaper: {
        width: DRAWER_WIDTH,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
});

class Drawer extends Component {
    handleCloseDrawer = () => {
        const { hideDrawer } = this.props;
        hideDrawer();
    };

    getSelectedMenuItem = () => {
        const { location } = this.props;
        let result = MenuItems.filter((item) => item.path === location.pathname);
        if (result.length === 0) {
            return null;
        }
        return result[0];
    };

    render() {
        const { container, profile, classes, theme, isVisible } = this.props;
        const direction = theme.direction === 'rtl' ? 'right' : 'left';
        const selectedMenuItem = this.getSelectedMenuItem();
        return (
            <MuiDrawer
                container={container}
                variant="temporary"
                anchor={direction}
                open={isVisible}
                onClose={this.handleCloseDrawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <DrawerContent
                    profile={profile}
                    selectedMenuItem={selectedMenuItem}
                    onMenuItemClick={this.handleCloseDrawer}
                    onProfileCardClick={this.handleCloseDrawer}
                />
                <DrawerFooter textColor="textPrimary" />
            </MuiDrawer>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideDrawer: () => dispatch(hide()),
    };
};

const mapStateToProps = (state) => {
    return {
        isVisible: state.drawer.isVisible,
        profile: state.profile.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(withTheme(Drawer))));
