import {
    ACCEPT_CONSENT,
    DECLINE_CONSENT,
    INIT,
} from '../actions/ConsentActionsTypes';

const consentReducer = (
    state = {
        isAccepted: null,
    },
    action
) => {
    switch (action.type) {
        case ACCEPT_CONSENT:
            return {
                isAccepted: true,
            };
        case DECLINE_CONSENT:
            return {
                isAccepted: false,
            };
        case INIT:
            return {
                isAccepted: true,
            };
        default:
            return state;
    }
};

export default consentReducer;
