import {
    PROFILE_EDIT_RESET,
    PROFILE_EDIT_SHOULD_SHOW_DIALOG,
    PROFILE_ERROR,
} from '../actions/ProfileEditActionTypes';

const profileEditReducer = (
    state = {
        shouldShowDialog: false,
        shouldShowError: false,
    },
    action
) => {
    switch (action.type) {
        case PROFILE_EDIT_SHOULD_SHOW_DIALOG:
            return {
                ...state,
                shouldShowDialog: true,
            };
        case PROFILE_EDIT_RESET:
            return {
                ...state,
                shouldShowDialog: false,
                shouldShowError: false,
            };
        case PROFILE_ERROR:
            return {
                ...state,
                shouldShowError: true,
            };
        default:
            return state;
    }
};

export default profileEditReducer;
