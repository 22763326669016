import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import logo from './resources/starsnoopyLogo.png';

const styles = (theme) => ({
    logo: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
});

class Logo extends Component {
    render() {
        const { classes, intl } = this.props;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <a href={intl.formatMessage({ id: 'starsnoopy.homepage' })}>
                        <img className={classes.logo} src={logo} alt="Logo" />
                    </a>
                </Grid>
            </Grid>
        );
    }
}

export default injectIntl(withStyles(styles)(Logo));
