import { RESET_SHARED_WISHLIST_STORE } from '../../../sharedWishlist/stateManagement/actions/SharedWishlistActionTypes';
import { RESET_WISHLIST_STORE } from '../../../wishlist/main/stateManagement/actions/WishlistActionTypes';
import AuthenticationService from '../../services/AuthenticationService';
import {
    AUTHENTICATION_INITIALIZE_ERROR,
    AUTHENTICATION_INITIALIZE_REQUEST,
    AUTHENTICATION_INITIALIZE_SUCCESS,
    LOGIN_ERROR,
    LOGIN_REQUEST,
    LOGIN_RESET,
    LOGIN_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    REGISTER_ERROR,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
} from './AuthenticationActionTypes';

export const initialize = () => async (dispatch) => {
    dispatch({
        type: AUTHENTICATION_INITIALIZE_REQUEST,
    });
    try {
        const result_1 = await AuthenticationService.initialize();

        dispatch({
            type: AUTHENTICATION_INITIALIZE_SUCCESS,
            user: result_1,
        });

        return result_1;
    } catch (result_2) {
        dispatch({
            type: AUTHENTICATION_INITIALIZE_ERROR,
            error: result_2,
        });
    }
};

export const login = (email, password) => async (dispatch) => {
    dispatch({
        type: LOGIN_REQUEST,
        way: 'standard',
        email,
        password,
    });
    try {
        const result = await AuthenticationService.login(email, password);

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (error) {
        dispatch({
            type: LOGIN_ERROR,
            error,
        });
    }
};

export const loginInWithApple = () => async (dispatch) => {
    dispatch({
        type: LOGIN_REQUEST,
        way: 'apple',
    });
    try {
        const result = await AuthenticationService.loginInWithApple();

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (error) {
        dispatch({
            type: LOGIN_ERROR,
            error,
        });
    }
};

export const loginInWithFacebook = () => async (dispatch) => {
    dispatch({
        type: LOGIN_REQUEST,
        way: 'facebook',
    });
    try {
        const result = await AuthenticationService.loginInWithFacebook();

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (error) {
        dispatch({
            type: LOGIN_ERROR,
            error,
        });
    }
};

export const loginInWithGoogle = () => async (dispatch) => {
    dispatch({
        type: LOGIN_REQUEST,
        way: 'google',
    });

    try {
        const result = await AuthenticationService.loginInWithGoogle();

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (error) {
        dispatch({
            type: LOGIN_ERROR,
            error,
        });
    }
};

export const registerWithApple = (privacyChecked) => async (dispatch) => {
    dispatch({
        type: REGISTER_REQUEST,
        way: 'apple',
    });
    try {
        const result = await AuthenticationService.registerWithApple(
            privacyChecked
        );

        dispatch({
            type: REGISTER_SUCCESS,
            user: result.user,
        });

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (result) {
        dispatch({
            type: REGISTER_ERROR,
            error: result.user,
        });
    }
};

export const registerWithFacebook = (privacyChecked) => async (dispatch) => {
    dispatch({
        type: REGISTER_REQUEST,
        way: 'facebook',
    });
    try {
        const result = await AuthenticationService.registerWithFacebook(
            privacyChecked
        );

        dispatch({
            type: REGISTER_SUCCESS,
            user: result.user,
        });

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (result) {
        dispatch({
            type: REGISTER_ERROR,
            error: result.user,
        });
    }
};

export const registerWithGoogle = (privacyChecked) => async (dispatch) => {
    dispatch({
        type: REGISTER_REQUEST,
        way: 'google',
    });
    try {
        const result = await AuthenticationService.registerWithGoogle(
            privacyChecked
        );

        dispatch({
            type: REGISTER_SUCCESS,
            user: result.user,
        });

        dispatch({
            type: LOGIN_SUCCESS,
            user: result.user,
        });

        return result;
    } catch (result) {
        dispatch({
            type: REGISTER_ERROR,
            error: result.user,
        });
    }
};

export const resetLogin = () => (dispatch) =>
    new Promise(() => {
        return dispatch({
            type: LOGIN_RESET,
        });
    });

export const register =
    (email, password, repeatedPassword, name, privacyChecked) =>
    async (dispatch) => {
        dispatch({
            type: REGISTER_REQUEST,
            way: 'standard',
            email,
            password,
            name,
        });

        try {
            const result = await AuthenticationService.register(
                email,
                password,
                repeatedPassword,
                name,
                privacyChecked
            );

            dispatch({
                type: REGISTER_SUCCESS,
                user: result,
            });

            await dispatch(login(email, password));

            return result;
        } catch (error) {
            dispatch({
                type: REGISTER_ERROR,
                error,
            });
        }
    };

export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT_REQUEST,
    });
    return AuthenticationService.logout()
        .then((result) => {
            dispatch({
                type: LOGOUT_SUCCESS,
            });
            dispatch(resetStore());
            return result;
        })
        .catch((result) => {
            dispatch({
                type: LOGOUT_ERROR,
                error: result,
            });
        });
};

export const resetStore = () => (dispatch) => {
    dispatch({
        type: RESET_WISHLIST_STORE,
    });
    dispatch({
        type: RESET_SHARED_WISHLIST_STORE,
    });
};

export const resetPassword = (email) => (dispatch) => {
    dispatch({
        type: RESET_PASSWORD_REQUEST,
        email,
    });
    return AuthenticationService.resetPassword(email)
        .then((result) => {
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
            });
            return result;
        })
        .catch((result) => {
            dispatch({
                type: RESET_PASSWORD_ERROR,
                error: result,
            });
            throw result;
        });
};

export const reauthenticate = (password) => (dispatch) =>
    AuthenticationService.reauthenticate(password);

export const reauthenticateWithApple = () => (dispatch) =>
    AuthenticationService.reauthenticateWithApple();

export const reauthenticateWithGoogle = () => (dispatch) =>
    AuthenticationService.reauthenticateWithGoogle();

export const reauthenticateWithFacebook = () => (dispatch) =>
    AuthenticationService.reauthenticateWithFacebook();

export const sendVerificationMail = () => (dispatch) =>
    AuthenticationService.sendVerificationMail();
