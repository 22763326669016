import withTheme from '@mui/styles/withTheme';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import withResponsiveInformation from '../../../../../mixins/components/ResponsiveInformation';

const styles = (theme) => ({
    button: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
        letterSpacing: '1px',
    },
    title: {
        color: theme.palette.common.white,
    },
    content: {
        color: theme.palette.common.white,
    },
    email: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
    },
});

const SlideTransition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

const GrowTransition = React.forwardRef((props, ref) => (
    <Grow ref={ref} {...props} />
));

class DeleteProfileDialog extends Component {
    render() {
        const {
            theme,
            classes,
            profile,
            open,
            onClose,
            onSubmit,
            isMobile,
        } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.common.darkBackground,
                    },
                }}
                fullScreen={isMobile}
                TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                }
            >
                <DialogTitle className={classes.title}>
                    <FormattedMessage id={'profileDialog.deleteAccount'} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.email}>
                        <span>{profile?.email ?? ''}</span>
                    </DialogContentText>
                    <DialogContentText className={classes.content}>
                        <FormattedMessage id={'profileDialog.deleteText'} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        autoFocus
                        className={classes.button}
                    >
                        <FormattedMessage id={'dialog.cancel'} />
                    </Button>
                    <Button
                        onClick={onSubmit}
                        autoFocus
                        className={classes.button}
                    >
                        <FormattedMessage id={'dialog.ok'} />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withResponsiveInformation(
    withStyles(styles)(withTheme(DeleteProfileDialog))
);
