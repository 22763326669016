import { Skeleton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import Divider from '../../../../mixins/components/Divider';
import EmailField from '../../../../mixins/components/textFields/EmailField';
import UsernameField from '../../../../mixins/components/textFields/UsernameField';
import UserMessage from '../../../../mixins/components/userMessage/UserMessage';
import ProfilePictureWrapper from '../picture/ProfilePictureWrapper';

const styles = (theme) => ({
    root: {
        flexDirection: 'column',
        width: '100%',
    },
    form: {
        marginTop: theme.spacing(1),
    },
    paper: {
        backgroundColor: theme.palette.primary.main,
    },
    skeletonDiv: {
        height: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    skeletonWidth: {
        width: '90%',
    },
});

class ProfileForm extends Component {
    getSkeleton = () => {
        const { classes } = this.props;
        return (
            <div className={classes.skeletonDiv}>
                <Skeleton className={classes.skeletonWidth} />
            </div>
        );
    };

    render() {
        const {
            classes,
            profileImage,
            name,
            email,
            onNameChange,
            onEmailChange,
            error,
            showUserInfo,
            uploadImage,
        } = this.props;
        const hasError = error != null;

        return (
            <div className={classes.root}>
                <ProfilePictureWrapper
                    profileImage={profileImage}
                    uploadImage={uploadImage}
                />
                <form className={classes.form} noValidate>
                    <Paper className={classes.paper}>
                        {name ? (
                            <UsernameField
                                value={name}
                                onChange={onNameChange}
                            />
                        ) : (
                            this.getSkeleton()
                        )}
                        <Divider />
                        {email ? (
                            <EmailField
                                value={email}
                                onChange={onEmailChange}
                            />
                        ) : (
                            this.getSkeleton()
                        )}
                        <Collapse in={showUserInfo}>
                            <Divider />
                            {hasError ? (
                                <UserMessage messageId={error.messageId} />
                            ) : (
                                <UserMessage
                                    messageId={'profile.success'}
                                    success={true}
                                />
                            )}
                        </Collapse>
                    </Paper>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(ProfileForm);
