import {
    HIDE_PRIMARY_APPBAR,
    SHOW_PRIMARY_APPBAR,
} from './PrimaryAppBarActionTypes';

export const show = () => {
    return {
        type: SHOW_PRIMARY_APPBAR,
    };
};

export const hide = () => {
    return {
        type: HIDE_PRIMARY_APPBAR,
    };
};
