import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

//addLocaleData([...en, ...de]);

class LocalizationProvider extends Component {
    render() {
        const { currentLocale } = this.props;
        return (
            <IntlProvider
                locale={currentLocale.flag}
                messages={currentLocale.messages}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    currentLocale: state.localization,
});

export default connect(mapStateToProps)(LocalizationProvider);
