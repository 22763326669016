import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import UserMessageContentWrapper from './UserMessageContentWrapper';

class UserMessage extends Component {
    render() {
        const { messageId, success } = this.props;
        if (!messageId) {
            return null;
        }
        return (
            <div>
                <UserMessageContentWrapper
                    message={<FormattedMessage id={messageId} />}
                    success={success}
                />
            </div>
        );
    }
}

export default UserMessage;
