import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/AppReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import Configuration from '../../../config';

export default function configureStore(persistedState) {
    return createStore(
        rootReducer,
        Configuration.environment === 'productive'
            ? applyMiddleware(thunkMiddleware)
            : composeWithDevTools(applyMiddleware(thunkMiddleware)),
        persistedState
    );
}
