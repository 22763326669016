import { flow } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import ItemTypes from './ItemTypes';

const itemSource = {
    beginDrag(props) {
        const { content } = props;
        const draggedItem = { ...content };
        let items;
        if (props.selectedItems.find((item) => item.id === props.id)) {
            items = props.selectedItems;
        } else {
            items = [draggedItem];
        }
        const otherItems = items.concat();
        otherItems.splice(
            items.findIndex((c) => c.id === props.id),
            1
        );
        const itemsDragStack = [draggedItem, ...otherItems];
        return { items, itemsDragStack, draggedItem };
    },

    endDrag(props, monitor) {
        props.onDragComplete(monitor.getItem());
    },
};

const itemTarget = {
    hover(props, monitor) {
        const item = monitor.getItem();
        const pointerOffset = monitor.getClientOffset();
        const hoverId = props.content.id;
        props.onMove(item, hoverId, pointerOffset);
    },
};

class DndCard extends Component {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
        content: PropTypes.object.isRequired,
        onMove: PropTypes.func.isRequired,
        onDragStart: PropTypes.func.isRequired,
        onDragComplete: PropTypes.func.isRequired,
        selectedItems: PropTypes.array.isRequired,
    };

    componentDidMount() {
        this.onClick = this.onClick.bind(this);

        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        this.props.connectDragPreview(<div />, {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            captureDraggingState: true,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.isDragging && !prevProps.isDragging) {
            this.props.onDragStart(this.props.item);
        }
    }

    onClick(e) {
        this &&
            this.props.onSelectionChange(this.props.id, e.metaKey, e.shiftKey);
    }

    render() {
        if (this.renderCache) {
            return this.renderCache;
        }

        const { connectDragSource, connectDropTarget, children } = this.props;

        this.renderCache = connectDragSource(
            connectDropTarget(
                <div ref={(node) => (this.node = node)} onClick={this.onClick}>
                    {children}
                </div>
            )
        );

        return this.renderCache;
    }
}

export default flow(
    DragSource(ItemTypes.CARD, itemSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
        item: monitor.getItem(),
    })),
    DropTarget(ItemTypes.CARD, itemTarget, (connect) => ({
        connectDropTarget: connect.dropTarget(),
    }))
)(DndCard);
