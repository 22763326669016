import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import { emphasize } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ShareLinkDialog from '../../../wishlist/share/components/ShareLinkDialog';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(8, 0, 6),
        fontSize: '1rem !important',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
    },
    text: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    container: {
        paddingTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
        letterSpacing: '1px',
        border: '2px solid',
        '&:hover': {
            border: '2px solid',
        },
        '&.MuiButton-colorInherit:hover': {
            color: emphasize(theme.palette.common.darkBackground, 0.15),
        },
        '&.MuiButtonBase-root': {
            color: theme.palette.common.darkBackground,
        },
    },
    '& .MuiButton-outlinedPrimary': {
        color: 'red !important',
    },
    '&. MuiButton-root': {
        color: theme.palette.text.primary,
    },
});

class SharedWishlistEmptyList extends Component {
    state = { shareDialogIsOpen: false };

    render() {
        const { classes } = this.props;
        const { shareDialogIsOpen } = this.state;

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Fade timeout={200} in={true} mountOnEnter unmountOnExit>
                        <Container maxWidth="sm" className={classes.container}>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'share.empty'} />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'share.invite'} />
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.submit}
                                onClick={() =>
                                    this.setState({
                                        shareDialogIsOpen: !shareDialogIsOpen,
                                    })
                                }
                            >
                                <FormattedMessage id="share.inviteButton" />
                            </Button>
                        </Container>
                    </Fade>
                </div>
                <ShareLinkDialog
                    link={window.location.host}
                    open={this.state.shareDialogIsOpen}
                    onClose={() =>
                        this.setState({ shareDialogIsOpen: !shareDialogIsOpen })
                    }
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(SharedWishlistEmptyList);
