import { HIDE_DRAWER, SHOW_DRAWER } from './DrawerActionTypes';

export const show = () => {
    return {
        type: SHOW_DRAWER,
    };
};
export const hide = () => {
    return {
        type: HIDE_DRAWER,
    };
};
