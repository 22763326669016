import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import SharedDesireCard from '../card/SharedDesireCard';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4),
        },
        paddingBottom: theme.spacing(4),
    },
    minPropertysForCard: {
        minWidth: '315px',
        minHeight: '256px',
    },
});

class SharedDesiresList extends Component {
    renderItem(key, item) {
        const { classes, isMarketingWishlist} = this.props;

        return (
            <Fade
                className={classes.minPropertysForCard}
                key={key}
                timeout={500}
                in={Boolean(item)}
            >
                <Grid key={key} item xs={12} sm={6} md={4}>
                    <SharedDesireCard item={item} isMarketingWishlist={isMarketingWishlist}/>
                </Grid>
            </Fade>
        );
    }

    renderItems() {
        const { classes, items } = this.props;

        return (
            <Container className={classes.root} maxWidth="lg">
                <Grid container spacing={5} justifyContent="center">
                    {items.map((item, key) => this.renderItem(key, item))}
                </Grid>
            </Container>
        );
    }

    render() {
        return this.renderItems();
    }
}

export default withStyles(styles)(SharedDesiresList);
