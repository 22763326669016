export const styles = (theme) => ({
    title: {
        color: theme.palette.primary.contrastText,
        fontFamily: "'Merienda One' !important",
        fontSize: '1.25rem',
        '& .MuiDialogTitle-root': {
            paddingBottom: '0 !important',
        },
        marginTop: theme.spacing(1),
    },
    buttons: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    dialogContent: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(1),
    },
    '& .MuiButton-outlinedPrimary': {
        color: theme.palette.common.darkBackground,
    },
    brightness: {
        filter: 'brightness(75%)',
    },
});
