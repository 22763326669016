import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../../mixins/components/icons/Icon';
import WishCounter from '../wishCounter/WishCounter';
import IC_DELETE from './resources/ic_delete.png';
import IC_EDIT from './resources/ic_edit.png';
import IC_SHARE from './resources/ic_share.png';
import WishlistCardHeader from './WishlistCardHeader';
import WishlistCardImage from './WishlistCardImage';

const styles = (theme) => ({
    card: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        padding: '4px',
        transition: 'box-shadow 0.2 ease-in-out',
        boxShadow:
            '0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12)',
        '&:hover': {
            boxShadow:
                '0 6px 6px -3px rgba(0,0,0,0.2), 0 10px 14px 1px rgba(0,0,0,0.14), 0 4px 18px 3px rgba(0,0,0,0.12)',
        },
    },
    selectedBoxShadow: {
        boxShadow:
            '0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15)',
        backgroundColor: '#4e7e7e',
        '&:hover ': {
            boxShadow:
                '0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15)',
        },
    },
    actionArea: {
        backgroundColor: theme.palette.secondary.main,
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        '&:hover $focusHighlight': {
            opacity: 0,
        },
        cursor: 'pointer',
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    focusHighlight: {},
    action: {
        backgroundColor: theme.palette.secondary.main,
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
        borderStyle: 'groove',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderColor: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        padding: '4px',
    },
    media: {
        paddingTop: '41.8%',
        height: 0,
        backgroundSize: 'contain',
    },
    img: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
});

class WishlistCard extends Component {
    disableRipple = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    renderAction(action, key) {
        return (
            <Tooltip
                key={key}
                title={action.title}
                aria-label="share"
                enterDelay={300}
            >
                <IconButton
                    onMouseDown={this.disableRipple}
                    onTouchStart={this.disableRipple}
                    onClick={action.event}
                    size="large"
                >
                    <Icon icon={action.icon.value} alt={action.icon.alt} />
                </IconButton>
            </Tooltip>
        );
    }

    fireDisableRippleClickEvent(clickEvent, eventToFire) {
        const { item } = this.props;
        this.disableRipple(clickEvent);
        if (eventToFire) {
            eventToFire(item);
        }
    }

    renderActions = () => {
        const { classes, onEditClick, onShareClick, onRemoveClick } =
            this.props;
        const actions = [
            {
                title: <FormattedMessage id={'wishlist.share'} />,
                event: (clickEvent) =>
                    this.fireDisableRippleClickEvent(clickEvent, onShareClick),
                icon: {
                    value: IC_SHARE,
                    alt: 'icon to share',
                },
            },
            {
                title: <FormattedMessage id={'wishlist.deleteTooltip'} />,
                event: (clickEvent) =>
                    this.fireDisableRippleClickEvent(clickEvent, onRemoveClick),
                icon: {
                    value: IC_DELETE,
                    alt: 'icon to delete',
                },
            },
            {
                title: <FormattedMessage id={'wishlist.edit'} />,
                event: (clickEvent) =>
                    this.fireDisableRippleClickEvent(clickEvent, onEditClick),
                icon: {
                    value: IC_EDIT,
                    alt: 'icon to edit',
                },
            },
        ];

        return (
            <CardActions disableSpacing className={classes.action}>
                {actions.map((action, key) => this.renderAction(action, key))}
            </CardActions>
        );
    };

    render() {
        const { classes, item, profile, onItemClick, isSelected } = this.props;
        return (
            <WishCounter count={item.wishCount}>
                <Card
                    className={clsx(
                        classes.card,
                        isSelected && classes.selectedBoxShadow
                    )}
                >
                    <CardContent
                        className={classes.actionArea}
                        onClick={() => onItemClick(item)}
                    >
                        <WishlistCardHeader
                            item={item}
                            profile={profile}
                            isLoading={false}
                        />
                        <WishlistCardImage item={item} />
                        {this.renderActions()}
                    </CardContent>
                </Card>
            </WishCounter>
        );
    }
}

export default withStyles(styles)(WishlistCard);
