import AnalyticsService from '../../../consent/service/AnalyticsService';
import { firebaseInstance } from '../../../networking/firebase/FirebaseConfig';
import WishlistCreatedComperator from '../comperator/WishlistCreatedComperator';

class WishlistService {
    constructor(firebaseInstance) {
        this.firebaseInstance = firebaseInstance;
    }

    registerObserver = (user, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`/wishlists/${user.uid}`);
        ref.on('child_changed', callback);
        ref.limitToLast(1).on('child_added', callback);
    };

    unregisterObserver = (user, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`/wishlists/${user.uid}`);
        ref.off('child_changed', callback);
        ref.off('child_added', callback);
    };

    getAll = async (user) => {
        const snapshot = await this.firebaseInstance
            .database()
            .ref(`/wishlists/${user.uid}`)
            .orderByChild('deleted')
            .equalTo(null)
            .once('value');

        const result = snapshot?.val() ?? {};
        return Object.values(result).sort(WishlistCreatedComperator.compare);
    };

    getByUserIdAndId = async (userId, id) => {
        const snapshot = await this.firebaseInstance
            .database()
            .ref(`/wishlists/${userId}/${id}`)
            .once('value');
        return snapshot.val();
    };

    create = async (user, wishlistCreateModel) => {
        const wishlistRef = this.firebaseInstance
            .database()
            .ref(`wishlists/${user.uid}`);
        const firebaseNode = wishlistRef.push();
        const model = {
            id: firebaseNode.key,
            image: wishlistCreateModel.image,
            note: wishlistCreateModel.note,
            title: wishlistCreateModel.title,
            created: Date.now(),
            wishCount: 0,
        };

        await firebaseNode.set(model);
        AnalyticsService.createWishlist(
            model.title,
            model.image,
            'own_wishlist'
        );
        return model;
    };

    edit = async (user, wishlist, wishlistEditModel) => {
        await this.editWishlist(user, wishlist, wishlistEditModel);
        await this.editSharedWishlists(wishlist, wishlistEditModel);
        return { ...wishlist, ...wishlistEditModel };
    };

    editWishlist = async (user, wishlist, wishlistEditModel) => {
        return await this.firebaseInstance
            .database()
            .ref(`/wishlists/${user.uid}/${wishlist.id}`)
            .update({
                image: wishlistEditModel.image,
                note: wishlistEditModel.note,
                title: wishlistEditModel.title,
            });
    };

    editSharedWishlists = async (wishlist, wishlistEditModel) => {
        const userIds = Object.keys(wishlist.sharedWith ?? {});
        const isShared = userIds.length > 0;
        if (isShared) {
            const model = {
                id: wishlist.id,
                wishCount: wishlist.wishCount,
                ...wishlistEditModel,
            };
            return await Promise.all(
                userIds.map((userId) => this.editSharedWishlist(userId, model))
            );
        }
    };

    editSharedWishlist = async (userId, sharedWishlist) => {
        return await this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${userId}/${sharedWishlist.id}`)
            .update(sharedWishlist);
    };

    remove = async (user, wishlist) => {
        await this.removeWishlist(user, wishlist);
        await this.removeSharedWishlists(wishlist);
        return wishlist;
    };

    removeWishlist = async (user, wishlist) => {
        const model = {
            deleted: Date.now(),
        };

        AnalyticsService.deleteWishlist(
            wishlist.title,
            wishlist.image,
            'own_wishlist'
        );

        await this.firebaseInstance
            .database()
            .ref(`wishlists/${user.uid}/${wishlist.id}`)
            .update(model);

        return wishlist;
    };

    removeSharedWishlists = async (sharedWishlist) => {
        const userIds = Object.keys(sharedWishlist.sharedWith ?? {});
        const isShared = userIds.length > 0;
        if (isShared) {
            return await Promise.all(
                userIds.map((userId) =>
                    this.removeSharedWishlist(userId, sharedWishlist)
                )
            );
        }
    };

    removeSharedWishlist = async (userId, sharedWishlist) => {
        const model = {
            deleted: Date.now(),
        };
        return await this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${userId}/${sharedWishlist.id}`)
            .update(model);
    };

    restore = async (user, wishlist) => {
        await this.restoreWishlist(user, wishlist);
        await this.restoreSharedWishlists(wishlist);
        return wishlist;
    };

    restoreWishlist = async (user, wishlist) => {
        const model = {
            deleted: null,
        };

        AnalyticsService.restoreWishlist(
            wishlist.title,
            wishlist.image,
            'own_wishlist'
        );

        await this.firebaseInstance
            .database()
            .ref(`wishlists/${user.uid}/${wishlist.id}`)
            .update(model);
        return wishlist;
    };

    restoreSharedWishlists = async (sharedWishlist) => {
        const userIds = Object.keys(sharedWishlist.sharedWith ?? {});
        const isShared = userIds.length > 0;
        if (isShared) {
            return await Promise.all(
                userIds.map((userId) =>
                    this.restoreSharedWishlist(userId, sharedWishlist)
                )
            );
        }
    };

    restoreSharedWishlist = async (userId, sharedWishlist) => {
        const model = {
            deleted: null,
        };
        return await this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${userId}/${sharedWishlist.id}`)
            .update(model);
    };

    setWishCount = async (userId, wishlistId, wishCount) => {
        await this.firebaseInstance
            .database()
            .ref(`/wishlists/${userId}/${wishlistId}`)
            .update({
                wishCount,
            });
        return wishCount;
    };
}

export default new WishlistService(firebaseInstance);
