export const styles = (theme) => ({
    input: {
        '& label.Mui-focused': {
            color: theme.palette.common.accentColor,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.common.accentColor}`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #4D4D4DC9',
        },
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiFormLabel-root': {
            color: '#4D4D4DC9',
            fontSize: '0.875rem',
        },
    },
    inputDisabled: {
        opacity: 0.5,
        '& label.Mui-focused': {
            color: '#4D4D4DC9',
        },
        '& .MuiInput-underline:after': {
            borderBottom: `1px dotted #4D4D4DC9`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px dotted #4D4D4DC9`,
        },
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiFormLabel-root': {
            color: '#4D4D4DC9',
            fontSize: '0.875rem',
        },
    },
    endAdornmentIcon: {
        height: '12px',
        width: '12px',
    },
});
