import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CreateButton from '../../../wishlist/main/components/button/CreateButton';
import withDesires from '../hocs/Desires';

const styles = (theme) => ({
    heroContent: {
        padding: theme.spacing(0, 0, 6),
        fontSize: '1rem !important',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
    },
    text: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    container: {
        paddingTop: theme.spacing(3),
    },
    speedDialIcon: {
        color: theme.palette.primary.main,
    },
    floatingButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
});

class EmptyList extends Component {
    render() {
        const { classes, openCreateDialog } = this.props;

        return (
            <div className={classes.heroContent}>
                <Container maxWidth="sm" className={classes.container}>
                    <Fade timeout={200} in={true} mountOnEnter unmountOnExit>
                        <div>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wish.empty'} />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wish.pushButton'} />
                            </Typography>
                            <Fade timeout={500} in={true}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    m={1}
                                    p={1}
                                >
                                    <CreateButton onClick={openCreateDialog} />
                                </Box>
                            </Fade>
                        </div>
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default withDesires(withStyles(styles)(EmptyList));
