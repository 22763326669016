import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import Skeleton from '@mui/material/Skeleton';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    input: {
        '& label.Mui-focused': {
            color: theme.palette.grey[500],
        },
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.grey[500]}`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: `2px solid ${theme.palette.grey[500]}`,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: `2px solid ${theme.palette.grey[500]}`,
        },
        marginBottom: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: '#2e3136',
        '& .MuiInputBase-input': {
            color: theme.palette.grey[500],
        },
        '& .MuiInput-root': {
            color: theme.palette.grey[500],
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.grey[500],
            fontSize: '0.875rem',
        },
    },
    button: {
        color: theme.palette.common.secondaryAccentColor,
        fontWeight: 'bold',
        letterSpacing: '1px',
        '& .MuiTouchRipple-root': {
            marginTop: '4px !important',
            marginBottom: '4px',
            color: theme.palette.grey[500],
        },
    },
});

class ReadOnlyField extends Component {
    handleClick = () => {
        const { onCopyClick } = this.props;
        let input = document.getElementById('dynamicLink');
        input.select();
        document.execCommand('copy');
        onCopyClick();
    };

    render() {
        const { classes, value } = this.props;
        return (
            <FormControl fullWidth className={classes.input}>
                {value === '' ? (
                    <Skeleton animation="wave" variant="text" />
                ) : (
                    <Fade in>
                        <TextField
                            id="dynamicLink"
                            fullWidth
                            value={value}
                            readOnly
                            InputProps={{
                                endAdornment: (
                                    <Button
                                        onClick={() => this.handleClick()}
                                        autoFocus
                                        className={classes.button}
                                    >
                                        <FormattedMessage
                                            id={'wishlist.copy'}
                                        />
                                    </Button>
                                ),
                            }}
                        />
                    </Fade>
                )}
            </FormControl>
        );
    }
}

export default withStyles(styles)(ReadOnlyField);
