import { firebaseInstance } from '../../networking/firebase/FirebaseConfig';
import DesireComperator from '../comperator/DesireComperator';

class SharedDesireService {
    constructor(firebaseInstance) {
        this.firebaseInstance = firebaseInstance;
    }

    registerObserver = (ownerId, wishlistId, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${wishlistId}`);
        ref.on('child_changed', callback);
        ref.limitToLast(1).on('child_added', callback);
    };

    unregisterObserver = (ownerId, wishlistId, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${wishlistId}`);
        ref.off('child_changed', callback);
        ref.off('child_added', callback);
    };

    getAll = (ownerId, wishlistId) => {
        return this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${wishlistId}`)
            .orderByChild('deleted')
            .equalTo(null)
            .once('value')
            .then((snapshot) => {
                const result = snapshot.val() ?? {};
                return Object.values(result).sort(DesireComperator.compare);
            });
    };

    getSharedWishlist = (userId, wishlistId) => {
        return this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${userId}/${wishlistId}`)
            .once('value')
            .then((snapshot) => snapshot.val());
    };

    getOwner = (ownerId) => {
        return this.firebaseInstance
            .database()
            .ref(`users/${ownerId}/`)
            .once('value')
            .then((snapshot) => snapshot.val());
    };

    reserved = (ownerId, userId, desire) => {
        let updatedDesireNode = {
            reservedByUserId: userId,
        };
        return this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${desire.wishlistId}/${desire.id}`)
            .update(updatedDesireNode)
            .then(() => {
                return { ...desire, ...updatedDesireNode };
            });
    };

    bought = (ownerId, userId, desire) => {
        let updatedDesireNode = {
            boughtByUserId: userId,
        };
        return this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${desire.wishlistId}/${desire.id}`)
            .update(updatedDesireNode)
            .then(() => {
                return { ...desire, ...updatedDesireNode };
            });
    };

    release = (ownerId, desire) => {
        let updatedDesireNode = {
            reservedByUserId: null,
            boughtByUserId: null,
        };
        return this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${desire.wishlistId}/${desire.id}`)
            .update(updatedDesireNode)
            .then(() => {
                return { ...desire, ...updatedDesireNode };
            });
    };
}

export default new SharedDesireService(firebaseInstance);
