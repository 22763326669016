import { ACCEPT_CONSENT, DECLINE_CONSENT, INIT } from './ConsentActionsTypes';
import { firebaseAnalytics } from '../../../networking/firebase/FirebaseConfig';

export const accept = () => {
    return (dispatch) => {
        localStorage.setItem('AnalyticsConsent', true);
        initAnalytics();
        dispatch({
            type: ACCEPT_CONSENT,
        });
    };
};

export const decline = () => {
    localStorage.setItem('AnalyticsConsent', false);
    firebaseAnalytics.setAnalyticsCollectionEnabled(false);
    return {
        type: DECLINE_CONSENT,
    };
};

export const initAnalytics = () => {
    return (dispatch) => {
        firebaseAnalytics.setAnalyticsCollectionEnabled(true);
        dispatch({
            type: INIT,
        });
    };
};
