import Button from '@mui/material/Button';
import { emphasize } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(2, 0, 1),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.darkBackground,
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: emphasize(
                theme.palette.common.darkBackground,
                0.15
            ),
        },
        padding: '7px',
        marginBottom: theme.spacing(3),
    },

    outlined: {
        margin: theme.spacing(2, 0, 1),
        letterSpacing: '1px',
        border: '2px solid',
        '&:hover': {
            border: '2px solid',
        },
        padding: '7px',
        marginBottom: theme.spacing(3),
    },
});

class LinkButton extends Component {
    render() {
        const {
            classes,
            messageId,
            route,
            onClick,
            disabled,
            outlined,
            outerCSS,
        } = this.props;

        return (
            <Button
                type="submit"
                variant={outlined ? 'outlined' : 'contained'}
                color="primary"
                className={clsx(
                    outlined ? classes.outlined : classes.button,
                    outerCSS
                )}
                onClick={onClick}
                component={Link}
                to={route}
                disabled={disabled}
            >
                <FormattedMessage id={messageId} />
            </Button>
        );
    }
}

export default withStyles(styles)(LinkButton);
