import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../mixins/components/icons/Icon';
import CreateButton from '../../main/components/button/CreateButton';
import IC_SHARE from '../../main/components/card/resources/ic_share.png';

const styles = (theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        fontSize: '1rem !important',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
    },
    text: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        },
    },
    container: {
        paddingTop: theme.spacing(3),
    },
    icon: {
        paddingTop: theme.spacing(1),
    },
});

class WishlistEmptyList extends Component {
    renderAddButton() {
        const { onCreate } = this.props;
        return (
            <Box display="flex" justifyContent="center" m={1} p={1}>
                <CreateButton onClick={onCreate} isWishlistCreate />
            </Box>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.heroContent}>
                <Container maxWidth="lg" className={classes.container}>
                    <Fade timeout={200} in={true} mountOnEnter unmountOnExit>
                        <div>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wishlist.empty'} />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wishlist.start'} />
                            </Typography>
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wishlist.pushButton'} />
                            </Typography>
                            {this.renderAddButton()}
                            <Typography
                                variant="h6"
                                align="center"
                                color="textPrimary"
                                paragraph
                                className={classes.text}
                            >
                                <FormattedMessage id={'wishlist.ps'} />
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.icon}
                            >
                                <Icon
                                    icon={IC_SHARE}
                                    className={classes.icon}
                                    height={40}
                                    width={40}
                                />
                            </Grid>
                        </div>
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(WishlistEmptyList);
