// for deploying to beta
const firebaseBetaConfig = {
    apiKey: 'AIzaSyDEC6rIhU_01YOAgZtg7IIA6MIKTSir5dg',
    authDomain: 'wishlist-a8030.firebaseapp.com',
    databaseURL: 'https://wishlist-a8030.firebaseio.com',
    projectId: 'wishlist-a8030',
    storageBucket: 'wishlist-a8030.appspot.com',
    messagingSenderId: '17878454312',
    appId: '1:17878454312:web:c33a4f44a33a1d40cda51d',
    measurementId: 'G-41P8ZYL02R',
};

const dynamicLinkParamsBeta = {
    apiKey: firebaseBetaConfig.apiKey,
    domainUriPrefix: 'https://starsnoopybeta.page.link',
    androidPackageName: 'de.xroot.wishlist.beta',
    iosBundleIdentifier: 'de.xroot.Wishlist.development',
};

// for deploying to productive
const firebaseProductiveConfig = {
    apiKey: 'AIzaSyAebtfIf9oqMcGgtUlg9ynR067KznCLtp0',
    authDomain: 'starsnoopy-3f761.firebaseapp.com',
    databaseURL: 'https://starsnoopy-3f761.firebaseio.com',
    projectId: 'starsnoopy-3f761',
    storageBucket: 'starsnoopy-3f761.appspot.com',
    messagingSenderId: '233533680217',
    appId: '1:233533680217:web:54cab34b9888a0686edf48',
    measurementId: 'G-ZRLQ6PL307',
};

const dynamicLinkParamsProductive = {
    apiKey: firebaseProductiveConfig.apiKey,
    domainUriPrefix: 'https://starsnoopy.page.link',
    androidPackageName: 'de.xroot.wishlist',
    iosBundleIdentifier: 'de.xroot.Wishlist',
};

const facebookTokenProductive = {
    appId: '265806683784193',
    clientToken: '693ccff28bbab70ddb3b3a8fde99eda8',
};

const facebookTokenBeta = {
    appId: '265810017117193',
    clientToken: '4a8434d753639f95b8f094b4bf60cc70',
};

class Configuration {
    // ATTENTION: browser push-notifications won´t work on beta, ecxept you replace credentials in public/firebase-messaging-sw.js

    constructor() {
        // use 'develop' / 'beta' / 'productive'
        this.environment = 'beta';
        this.version = '2.1';
        this.customUploadAllowed = true;
    }

    isCustomUploadAllowed = () => this.customUploadAllowed;

    getFirebaseConfiguration() {
        switch (this.environment) {
            case 'beta':
            case 'develop':
                return firebaseBetaConfig;
            case 'productive':
                return firebaseProductiveConfig;
            default:
                console.error('could not read firebase config file');
                return null;
        }
    }

    getOwnerId() {
        switch (this.environment) {
            case 'develop':
                return `PMbsTBHXqJfc0n91aCr1wIF9WR03`;
            case 'beta':
                return `PMbsTBHXqJfc0n91aCr1wIF9WR03`;
            case 'productive':
                return `uejEMYgwerequ8cKlYPCGNKC6843`;
            default:
                console.error('could not return ownerId of marketing wishlists');
                return null;
        }
    }

    getLink() {
        switch (this.environment) {
            case 'develop':
                return `http://localhost:3000/`;
            case 'beta':
                return `https://app-beta.starsnoopy.de/wishlist/`;
            case 'productive':
                return `https://app.starsnoopy.de/wishlist/`;
            default:
                console.error('could not return sharing link');
                return null;
        }
    }

    getDynamicLinksParams() {
        switch (this.environment) {
            case 'beta':
            case 'develop':
                return dynamicLinkParamsBeta;
            case 'productive':
                return dynamicLinkParamsProductive;
            default:
                console.error('could not read dynamic links params - object');
                return null;
        }
    }

    getVersion() {
        return this.version;
    }

    getFacebookApiToken() {
        switch (this.environment) {
            case 'beta':
            case 'develop':
                return facebookTokenBeta;
            case 'productive':
                return facebookTokenProductive;
            default:
                console.error('could not read facebook token');
                return null;
        }
    }
}

export default new Configuration();
