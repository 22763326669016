export const REGISTER_OBSERVER_DESIRES = 'REGISTER_OBSERVER_DESIRES';
export const UNREGISTER_OBSERVER_DESIRES = 'UNREGISTER_OBSERVER_DESIRES';
export const GET_ALL_DESIRES_REQUEST = 'GET_ALL_DESIRES_REQUEST';
export const GET_ALL_DESIRES_SUCCESS = 'GET_ALL_DESIRES_SUCCESS';
export const GET_ALL_DESIRES_ERROR = 'GET_ALL_DESIRES_ERROR';
export const OPEN_CREATE_DIALOG = 'OPEN_CREATE_DIALOG';
export const CLOSE_CREATE_DIALOG = 'CLOSE_CREATE_DIALOG';
export const OPEN_EDIT_DIALOG = 'OPEN_EDIT_DIALOG';
export const CLOSE_EDIT_DIALOG = 'CLOSE_EDIT_DIALOG';
export const OPEN_MOVE_DIALOG = 'OPEN_MOVE_DIALOG';
export const CLOSE_MOVE_DIALOG = 'CLOSE_MOVE_DIALOG';
export const OPEN_CREATE_DESIRE_OF_HEART_DIALOG =
    'OPEN_CREATE_DESIRE_OF_HEART_DIALOG';
export const CLOSE_CREATE_DESIRE_OF_HEART_DIALOG =
    'CLOSE_CREATE_DESIRE_OF_HEART_DIALOG';
export const UPDATE_DESIRE = 'UPDATE_DESIRE';
export const UPDATE_DESIRE_ARRAY = 'UPDATE_DESIRE_ARRAY';
export const CREATE_DESIRE = 'CREATE_DESIRE';
export const DELETE_DESIRE = 'DELETE_DESIRE';
export const RESTORE_DESIRE = 'RESTORE_DESIRE';
