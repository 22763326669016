import {
    GET_ALL_WISHLIST_REQUEST,
    GET_ALL_WISHLIST_ERROR,
    GET_ALL_WISHLIST_SUCCESS,
    RESET_WISHLIST_STORE,
    CREATE_WISHLIST_SUCCESS,
} from '../actions/WishlistActionTypes';

const wishlistReducer = (
    state = {
        items: [],
        error: null,
        loading: true,
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_WISHLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_WISHLIST_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false,
            };
        case GET_ALL_WISHLIST_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case RESET_WISHLIST_STORE:
            return {
                ...state,
                items: [],
                error: null,
            };
        case CREATE_WISHLIST_SUCCESS:
            return {
                ...state,
                items: action.items,
            };
        default:
            return state;
    }
};

export default wishlistReducer;
