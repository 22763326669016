import InputAdornment from '@mui/material/InputAdornment';
import { AlternateEmail } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import StyledTextField from './base/StyledTextField';

const styles = (theme) => ({
    input: {
        margin: theme.spacing(0, 0, 0, 0),
        padding: theme.spacing(0, 0, 0, 0),
        '& .Mui-disabled': {
            color: theme.palette.primary.contrastText,
        },
    },
});

class EmailField extends Component {
    render() {
        const { classes, onChange, intl, disabled, value } = this.props;

        return (
            <StyledTextField
                disabled={disabled}
                margin="normal"
                variant="outlined"
                autoFocus
                required
                value={value}
                fullWidth
                id="email"
                name="email"
                className={classes.input}
                autoComplete="email"
                placeholder={intl.formatMessage({ id: 'auth.email' })}
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AlternateEmail />
                        </InputAdornment>
                    ),
                }}
                inputProps={{ maxLength: 200 }}
            />
        );
    }
}

export default injectIntl(withStyles(styles)(EmailField));
