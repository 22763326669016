import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TranslateIcon from '@mui/icons-material/Translate';
import { Fade } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { LocalizationTypes } from '../../../../localization/components/LocalizationTypes';

const styles = (theme) => ({
    menu: {
        '& .MuiPopover-paper': {
            minWidth: '230px',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    item: {
        height: '50px',
    },
});

class PrimaryAppBarMenu extends Component {
    state = {
        localizationSelectionIsOpen: false,
    };

    handleLocalizationChange = (localizationType) => {
        const { onLocalizationChange } = this.props;
        if (onLocalizationChange) {
            onLocalizationChange(localizationType);
        }
    };

    handleToggleLocalizationSelection = () => {
        const { localizationSelectionIsOpen } = this.state;
        this.setState({
            localizationSelectionIsOpen: !localizationSelectionIsOpen,
        });
    };

    redirectToFaq = () => {
        const { localization: { flag = 'en' } = {} } = this.props;
        window
            .open(
                flag === 'de'
                    ? 'https://starsnoopy.de/faq'
                    : 'https://starsnoopy.com/faq',
                '_blank'
            )
            .focus();
    };

    getAbout = (onClose) => {
        return (
            <Link
                to={'/about'}
                style={{
                    textDecoration: 'none',
                    color: 'inherit',
                }}
            >
                <MenuItem className={this.props.classes.item} onClick={onClose}>
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={'settings.about'} />}
                    />
                </MenuItem>
            </Link>
        );
    };

    render() {
        const { classes, anchorEl, onClose, onLogout, closeSnackbar } =
            this.props;
        const { localizationSelectionIsOpen } = this.state;
        return (
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                anchorReference="none"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
                TransitionComponent={Fade}
                className={classes.menu}
                PaperProps={{
                    style: {
                        width: 230,
                        right: 15,
                        top: 50,
                    },
                }}
            >
                <Link
                    to={'/profile'}
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                    }}
                >
                    <MenuItem className={classes.item} onClick={onClose}>
                        <ListItemIcon>
                            <PersonOutlineIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <FormattedMessage id={'settings.profile'} />
                            }
                        />
                    </MenuItem>
                </Link>
                <MenuItem
                    className={classes.item}
                    onClick={this.handleToggleLocalizationSelection}
                >
                    <ListItemIcon>
                        <TranslateIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={'settings.language'} />}
                    />
                    {localizationSelectionIsOpen ? (
                        <ExpandLess />
                    ) : (
                        <ExpandMore />
                    )}
                </MenuItem>
                <Collapse
                    in={localizationSelectionIsOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <Divider />
                    {this.renderLocalizationItems()}
                    <Divider />
                </Collapse>
                <MenuItem className={classes.item} onClick={this.redirectToFaq}>
                    <ListItemIcon>
                        <ContactSupportIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={'settings.faq'} />}
                    />
                </MenuItem>
                {this.getAbout(onClose)}
                <MenuItem
                    className={classes.item}
                    onClick={() => {
                        onLogout();
                        closeSnackbar();
                    }}
                >
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<FormattedMessage id={'settings.logout'} />}
                    />
                </MenuItem>
            </Menu>
        );
    }

    renderLocalizationItem(localizationType) {
        const { classes, localization } = this.props;
        const isActive = localization === localizationType;
        return (
            <ListItem
                key={localizationType.flag}
                button
                className={classes.nested}
                onClick={() => this.handleLocalizationChange(localizationType)}
            >
                <ListItemIcon>
                    {isActive ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <FormattedMessage id={localizationType.messageId} />
                    }
                />
            </ListItem>
        );
    }

    renderLocalizationItems() {
        return (
            <List component="div" disablePadding>
                {LocalizationTypes.map((LocalizationType) =>
                    this.renderLocalizationItem(LocalizationType)
                )}
            </List>
        );
    }
}

export default withSnackbar(withStyles(styles)(PrimaryAppBarMenu));
