import { firebaseInstance } from '../../../networking/firebase/FirebaseConfig';
import Profile from '../model/Profile';
import authenticationErrorMapperInstance from '../../../authentication/mapper/AuthenticationErrorMapper';

class ProfileService {
    constructor(firebaseInstance, errorMapper) {
        this.firebaseInstance = firebaseInstance;
        this.errorMapper = errorMapper;
    }

    getById = (id) => {
        return this.firebaseInstance
            .database()
            .ref(`/users/${id}`)
            .once('value')
            .then((snapshot) => {
                const email = snapshot.val()?.email ?? '';
                const profile = Profile.fromData(snapshot.val() ?? {});
                return this.fetchSignInMethod(email).then((method) => {
                    profile.signInMethod = method;
                    profile.id = id;
                    return profile;
                });
            });
    };

    fetchSignInMethod = (email) => {
        return this.firebaseInstance
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((method) => {
                return method[0];
            })
            .catch(() => {
                return null;
            });
    };

    edit = (name, email, signInMethod) => {
        const currentUser = this.firebaseInstance.auth().currentUser;
        const originalEmail = currentUser.email;
        const changedEmail = originalEmail !== email;

        if (name === '') {
            return Promise.reject(
                this.errorMapper.map({ code: 'auth/no-name' })
            );
        }
        if (changedEmail) {
            return currentUser
                .updateEmail(email)
                .then(() => {
                    return this.update(name, email).then(() => {
                        return { updatedEmail: true };
                    });
                })
                .catch((error) => {
                    throw this.errorMapper.map(error);
                });
        } else {
            return this.update(name, originalEmail).then(() => {
                return { updatedEmail: false };
            });
        }
    };

    update = (name, email) => {
        const currentUser = this.firebaseInstance.auth().currentUser;
        const userId = currentUser.uid;
        return this.firebaseInstance
            .database()
            .ref('users/' + userId)
            .update({
                displayName: name,
                email: email,
            })
            .then(() => {
                return currentUser.updateProfile({
                    displayName: name,
                });
            });
    };
}

export default new ProfileService(
    firebaseInstance,
    authenticationErrorMapperInstance
);
