import Container from '@mui/material/Container';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import ConsentBannerWrapper from '../../../consent/components/ConsentBannerWrapper';
import Footer from '../../../mixins/components/Footer';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';
import { closeActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import withAuthentication from '../hocs/Authentication';
import Login from '../login/Login';
import Logo from '../logo/Logo';
import InfoImage from '../logo/resources/login-info.png';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.main,
    },
    main: {
        marginTop: 'auto',
        [theme.breakpoints.down('960px')]: {
            width: '80%',
            height: '80%',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(0),
            borderRadius: '0px',
            width: '100%',
            height: '100%',
        },
        padding: `${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(
            8
        )} ${theme.spacing(8)}`,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '65px',
        width: '100%',
        height: '100%',
        minWidth: '310px',
        minHeight: '425px',
        maxHeight: '800px',
        maxWidth: '650px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            boxShadow: '10px 10px 7px rgba(0,0,0,0.2)',
        },
    },
    split: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    infoTextSplit: {
        height: '100vh',
        width: '50vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    loginSplit: {
        height: '100vh',
        width: '50vw',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
    },
    logo: {
        maxWidth: '50%',
    },
    infoText: {
        padding: '30px',
        fontSize: '30px',
        color: theme.palette.primary.main,
    },
    footer: {
        marginTop: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            backgroundColor: theme.palette.secondary.main,
        },
    },
});

class LoginPage extends Component {
    componentWillUnmount() {
        this.props.resetPageState();
    }

    handlePageStateChange = () => {
        this.props.resetPageState();
    };

    handleLogin = (model) =>
        (async () => {
            switch (model.way) {
                case 'apple':
                    return await this.handleAppleLogin();
                case 'facebook':
                    return await this.handleFacebookLogin();
                case 'google':
                    return await this.handleGoogleLogin();
                default:
                    return await this.handleStandardLogin(model);
            }
        })()
            .then(this.createDefaultWishlist)
            .catch((exc) => console.error(exc));

    handleAppleLogin = (allowRegistration) =>
        this.props.loginInWithApple(allowRegistration);

    handleFacebookLogin = (allowRegistration) =>
        this.props.loginInWithFacebook(allowRegistration);

    handleGoogleLogin = (allowRegistration) =>
        this.props.loginInWithGoogle(allowRegistration);

    handleStandardLogin = async ({ email, password }) => {
        const { loginStandard, enqueueSnackbar, closeSnackbar, intl } =
            this.props;

        try {
            const result = await loginStandard(email, password);
            !result.user?.emailVerified &&
                enqueueSnackbar(intl.formatMessage({ id: 'auth.verifyHint' }), {
                    variant: 'info',
                    action: (key) =>
                        closeActionSnackbarButton(() => closeSnackbar(key)),
                });

            return result;
        } catch (error) {
            console.error(error);
        }
    };

    createDefaultWishlist = async (result) => {
        const { createDefaultWishlist, intl } = this.props;
        result?.additionalUserInfo?.isNewUser &&
            (await createDefaultWishlist(
                intl.formatMessage({ id: 'wishlist.default' })
            ));
        return result;
    };

    handlePasswordReset = (model) => {
        const { resetPassword, enqueueSnackbar, closeSnackbar, intl } =
            this.props;
        resetPassword(model.email)
            .then(() =>
                enqueueSnackbar(intl.formatMessage({ id: 'auth.verify' }), {
                    variant: 'info',
                    action: (key) =>
                        closeActionSnackbarButton(() => closeSnackbar(key)),
                })
            )
            .catch((e) => console.error(e));
    };

    handleRegister = (model) =>
        (() => {
            switch (model.way) {
                case 'apple':
                    return this.handleAppleRegister(model);
                case 'facebook':
                    return this.handleFacebookRegister(model);
                case 'google':
                    return this.handleGoogleRegister(model);
                default:
                    return this.handleStandardRegister(model);
            }
        })()
            .then(this.createDefaultWishlist)
            .catch((exc) => console.error(exc));

    handleAppleRegister = (model) =>
        this.props.registerWithApple(model.privacyChecked);

    handleFacebookRegister = (model) =>
        this.props.registerWithFacebook(model.privacyChecked);

    handleGoogleRegister = (model) =>
        this.props.registerWithGoogle(model.privacyChecked);

    handleStandardRegister = (model) => {
        const { register, enqueueSnackbar, closeSnackbar, intl } = this.props;
        return register(
            model.email,
            model.password,
            model.repeatedPassword,
            model.name,
            model.privacyChecked
        ).then((result) => {
            if (result) {
                enqueueSnackbar(intl.formatMessage({ id: 'auth.verify' }), {
                    variant: 'info',
                    action: (key) =>
                        closeActionSnackbarButton(() => closeSnackbar(key)),
                });
            }
            return result;
        });
    };

    render() {
        const { classes, login, authentication, width } = this.props;
        if (authentication.isAuthenticated) {
            return <Redirect to="/wishlist" />;
        }

        return (
            <div className={classes.root}>
                <div className={classes.split}>
                    {!(width === 'xs' || width === 'sm') && (
                        <div className={classes.infoTextSplit}>
                            <div className={classes.logo}>
                                <Logo />
                            </div>
                            <div className={classes.infoText}>
                                <FormattedMessage id={'login.info'} />
                            </div>
                            <div>
                                <img src={InfoImage} alt={'info'} />
                            </div>
                        </div>
                    )}
                    <div className={classes.loginSplit}>
                        <Container
                            component="main"
                            className={classes.main}
                            maxWidth="xs"
                        >
                            <Login
                                error={login.error}
                                onPageStateChange={this.handlePageStateChange}
                                onLogin={this.handleLogin}
                                onPasswordReset={this.handlePasswordReset}
                                onRegister={this.handleRegister}
                            />
                        </Container>
                        <div className={classes.footer}>
                            <Footer textColor="textPrimary" />
                        </div>
                    </div>
                </div>

                <ConsentBannerWrapper />
            </div>
        );
    }
}

export default withResponsiveInformation(
    withSnackbar(injectIntl(withAuthentication(withStyles(styles)(LoginPage))))
);
