export const ERRORS = {
    'auth/email-already-in-use': {
        messageId: 'auth.email-already-in-use',
    },
    'auth/invalid-email': {
        messageId: 'auth.invalid-email',
    },
    'auth/weak-password': {
        messageId: 'auth.weak-password',
    },
    'auth/wrong-password': {
        messageId: 'auth/wrong-password',
    },
    'auth/passwords-not-matching': {
        messageId: 'auth.passwords-not-matching',
    },
    'auth/social-authentification-fail': {
        messageId: 'auth.social-authentification-fail',
    },
    'auth/too-many-requests': {
        messageId: 'auth.too-many-requests',
    },
    'auth/user-not-found': {
        messageId: 'auth.user-not-found',
    },
    'auth/no-name': {
        messageId: 'auth.no-name',
    },
    'auth/requires-recent-login': {
        messageId: 'auth.requires-recent',
    },
    'auth/privacy-policy': {
        messageId: 'auth.privacy-error',
    },
    'auth/write-user-fail': {
        messageId: 'auth.write-fail',
    },
    'auth/account-exists-with-different-credential': {
        messageId: 'auth.account-exists',
    },
    'auth/facebook-account-exists-with-different-credential': {
        messageId: 'auth.facebook-account-exists',
    },
    'auth/google-account-exists-with-different-credential': {
        messageId: 'auth.google-account-exists',
    },
    'auth/email-account-exists-with-different-credential': {
        messageId: 'auth.email-account-exists',
    },
    default: {
        messageId: 'auth.error-default',
    },
};

class AuthenticationErrorMapper {
    map(apiError) {
        const result = ERRORS[apiError.code];
        if (!result) {
            console.log(`There is no key for ${apiError.code}`);
            return ERRORS['default'];
        }
        return result;
    }
}

export default new AuthenticationErrorMapper();
