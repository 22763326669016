import { Fade, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import _, { isFinite } from 'lodash';
import React, { PureComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DndCard from './DndCard';

const styles = (theme) => ({
    '@global': {
        '.card-wrapper-dragging': {
            opacity: 0.5,
        },
    },
    pseudo: {
        backgroundColor: '#F47F8C',
        borderRadius: '4px',
        opacity: 0.8,
    },
    minWidthForCard: {
        minWidth: '317px',
    },
});

const getNodeClientBounds = (node) => {
    const el = node.nodeType === 1 ? node : node.parentElement;
    if (!el) {
        return null;
    }
    return el.getBoundingClientRect();
};

const backend = HTML5Backend;
const gridContainerId = 'dnd-grid-container';
const dropPreviewId = 'drop-target-preview';
const cardContainerIdPattern = 'dnd-grid-card-container-';

class DndContainer extends PureComponent {
    _mounted = false;
    updateSortIndexes = false;

    state = {
        selectedItems: [],
        selectedItemsIds: [],
        draggedItemsIds: [],
        insertIndex: -1,
        items: this.props.items,
        insertAt: { id: null, left: false },
    };

    componentDidMount() {
        this._mounted = true;
        this._mounted &&
            this.setState({
                selectedItems: [],
                selectedItemsIds: [],
                draggedItemsIds: [],
                insertIndex: -1,
                items: [...this.props.items],
            });

        this.onItemMove = this.onItemMove.bind(this);
        this.onItemDragStart = this.onItemDragStart.bind(this);
        this.onItemDragComplete = this.onItemDragComplete.bind(this);
        this.onItemSelectionChange = this.onItemSelectionChange.bind(this);
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate() {
        if (
            !_.isEqual(
                this.state.items.map(({ id }) => id),
                this.props.items.map(({ id }) => id)
            )
        ) {
            this.setState({
                items: [...this.props.items],
            });
        }
    }

    componentDidCatch(error, errorInfo) {
        console.warn({ error, errorInfo });
    }

    onItemDragStart(dragItem) {
        try {
            document.body.style.cursor = 'grab';

            const unvisibleEl = document.getElementById(
                `${cardContainerIdPattern}${dragItem.draggedItem.id}`
            );
            unvisibleEl.style.display = 'none';

            const items = this.state.items.slice();

            Array.from(this.container.childNodes).map(
                (child, i) => (items[i].bounds = getNodeClientBounds(child))
            );

            this._mounted &&
                this.setState({
                    items,
                    selectedItems: dragItem.items,
                    selectedItemsIds: dragItem.items.map((c) => c.id),
                    draggedItemsIds: dragItem.items.map((c) => c.id),
                    activeItemId: dragItem.draggedItem.id,
                });
        } catch (e) {
            console.warn(`error while DnDonStart: ${e}`);
        }
    }

    onItemMove(dragItem, hoverId, { x }) {
        try {
            const items = this.props.items.slice();

            const hoverIndex = items.findIndex((el) => el.id === hoverId);
            const hoverItem = items[hoverIndex];

            const el = document.getElementById(`desire-card-${hoverItem.id}`);
            const rect = el.getClientRects()[0];
            const midX = rect.width / 2 + rect.left;

            const left = x < midX;

            let insertIndex;

            if (hoverIndex === -1) {
                insertIndex = -1;
            } else if (hoverIndex === items.length - 1) {
                insertIndex = left ? items.length - 2 : items.length - 1;
            } else if (dragItem.draggedItem.sortIndex === items.length - 1) {
                insertIndex = hoverIndex;
            } else if (
                hoverIndex >=
                    items.length - 1 - dragItem.draggedItem.sortIndex &&
                items.length % 2 === 0
            ) {
                insertIndex = hoverIndex;
            } else {
                insertIndex = left ? hoverIndex : hoverIndex + 1;
            }

            const insertAt = {
                id: hoverItem.id,
                left,
            };

            this._mounted &&
                this.setState({
                    insertIndex,
                    hoveredItemIndex: hoverIndex,
                    insertAt,
                });
        } catch (e) {
            console.warn(`error while DnDonMove: ${e}`);
        }
    }

    onItemDragComplete(dragItem) {
        try {
            document.body.style.cursor = 'auto';

            const unvisibleEl = document.getElementById(
                `${cardContainerIdPattern}${dragItem.draggedItem.id}`
            );
            unvisibleEl.style.display = 'block';
            unvisibleEl.style.opacity = 1;

            const changes = {
                draggedItemsIds: [],
                insertIndex: -1,
                hoveredItemIndex: -1,
                items: this.props.items,
                insertAt: { id: null, left: false },
            };

            if (dragItem) {
                const { insertIndex } = this.state;
                const { items } = this.props;
                const { draggedItem } = dragItem;

                const dropIndex = (() => {
                    try {
                        return [
                            ...(document.getElementById(gridContainerId)
                                .childNodes ?? []),
                        ]
                            .filter((el) => el)
                            .map((el) => el.getAttribute('id'))
                            .filter(
                                (id) =>
                                    id !==
                                    `${cardContainerIdPattern}${draggedItem.id}`
                            )
                            .indexOf(`${dropPreviewId}`);
                    } catch (e) {
                        console.error(`error on dropping item: ${e}`);
                        return;
                    }
                })();

                if (
                    -1 === insertIndex ||
                    dropIndex < 0 ||
                    dropIndex > items.length - 1
                ) {
                    this._mounted && this.setState(changes);
                    return;
                }

                if (dropIndex >= 0 && dropIndex < items.length) {
                    // first logic
                    const orderedArr = items.filter(
                        (item) => item.id !== draggedItem.id
                    );

                    orderedArr.splice(dropIndex, 0, draggedItem);

                    // reverse for sort
                    changes.items = orderedArr.map((item, i) => {
                        item.sortIndex = items.length - 1 - i;
                        return item;
                    });

                    this.props.updateSortIndex(
                        changes.items.slice(dropIndex <= 0 ? 0 : dropIndex - 1)
                    );
                } else {
                    // fallback logic
                    const orderedArr = items.filter(
                        (item) => item.id !== draggedItem.id
                    );

                    orderedArr.splice(insertIndex, 0, draggedItem);

                    changes.items = orderedArr.map((item, i) => {
                        item.sortIndex = items.length - 1 - i;
                        return item;
                    });

                    if (
                        items.length !==
                        [...new Set(changes.items.map((i) => i.id))].length
                    ) {
                        this._mounted &&
                            this.setState({
                                ...changes,
                                items: this.props.items,
                            });
                        return;
                    }

                    this.props.updateSortIndex(
                        changes.items.slice(
                            insertIndex <= 0 ? 0 : insertIndex - 1
                        )
                    );
                }
            }
            this._mounted && this.setState(changes);
        } catch (e) {
            console.warn(`error while DnDonComplete: ${e}`);
        }
    }

    onItemSelectionChange(itemId, cmdKeyActive, shiftKeyActive) {
        try {
            let selectedItemsIds = [];
            let activeItemId;

            const items = this.state.items.slice();
            let previousSelectedItemsIds = this.state.selectedItemsIds.slice();
            let previousActiveItemId = this.state.activeItemId;

            if (cmdKeyActive) {
                if (
                    previousSelectedItemsIds.indexOf(itemId) > -1 &&
                    itemId !== previousActiveItemId
                ) {
                    selectedItemsIds = previousSelectedItemsIds.filter(
                        (id) => id !== itemId
                    );
                } else {
                    selectedItemsIds = [...previousSelectedItemsIds, itemId];
                }
            } else if (shiftKeyActive && itemId !== previousActiveItemId) {
                const activeItemIndex = items.findIndex(
                    (c) => c.id === previousActiveItemId
                );
                const itemIndex = items.findIndex((c) => c.id === itemId);
                const lowerIndex = Math.min(activeItemIndex, itemIndex);
                const upperIndex = Math.max(activeItemIndex, itemIndex);
                selectedItemsIds = items
                    .slice(lowerIndex, upperIndex + 1)
                    .map((c) => c.id);
            } else {
                selectedItemsIds = [itemId];
                activeItemId = itemId;
            }

            const selectedItems = items.filter((c) =>
                selectedItemsIds.includes(c.id)
            );

            const changes = {
                selectedItems,
                selectedItemsIds,
            };
            if (activeItemId) {
                changes.activeItemId = activeItemId;
            }
            this._mounted && this.setState(changes);
        } catch (e) {
            console.warn(`error while DnDonChange: ${e}`);
        }
    }

    getPseudoElement = (position, id) => {
        const el = document.getElementById(`desire-card-${id}`);
        return (
            <Fade
                id={dropPreviewId}
                key={`fade-pseudo-${position}-${id}`}
                timeout={200}
                in={true}
            >
                <Grid
                    item
                    key={`grid-pseudo-item-${position}-${id}`}
                    xs={12}
                    sm={6}
                    md={4}
                >
                    <div
                        key={`pseudo-${position}-${id}`}
                        className={this.props.classes.pseudo}
                        style={{
                            width: el.clientWidth,
                            height: el.clientHeight,
                        }}
                    />
                </Grid>
            </Fade>
        );
    };

    render() {
        const { draggedItemsIds, selectedItems, insertAt } = this.state;
        const { items, classes, children } = this.props;

        return (
            <DndProvider backend={backend}>
                <Grid
                    id={gridContainerId}
                    container
                    spacing={5}
                    justifyContent="center"
                    alignItems="center"
                    ref={(el) => {
                        this.container = el;
                    }}
                >
                    {items
                        .map((item, i) => {
                            if (!item.sortIndex && !isFinite(item.sortIndex)) {
                                this.updateSortIndexes = true;
                                item.sortIndex = items.length - 1 - i;
                            }

                            const isDragging = draggedItemsIds.length > 0;

                            const shouldInsertOnLeft =
                                insertAt.id === item.id &&
                                isDragging &&
                                insertAt.left;

                            const shouldInsertOnRight =
                                insertAt.id === item.id &&
                                isDragging &&
                                !insertAt.left;

                            return (
                                <React.Fragment
                                    key={`dnd-item-${
                                        item.id +
                                        item.title +
                                        item.imageUrl +
                                        item.note
                                    }`}
                                >
                                    {shouldInsertOnLeft &&
                                        this.getPseudoElement('left', item.id)}

                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        className={classes.minWidthForCard}
                                        id={`${cardContainerIdPattern}${item.id}`}
                                    >
                                        <DndCard
                                            content={item}
                                            selectedItems={selectedItems}
                                            onMove={this.onItemMove}
                                            onDragStart={this.onItemDragStart}
                                            onDragComplete={
                                                this.onItemDragComplete
                                            }
                                            onSelectionChange={
                                                this.onItemSelectionChange
                                            }
                                        >
                                            {React.Children.only(
                                                React.cloneElement(children, {
                                                    item,
                                                })
                                            )}
                                        </DndCard>
                                    </Grid>
                                    {shouldInsertOnRight &&
                                        this.getPseudoElement('right', item.id)}
                                </React.Fragment>
                            );
                        })
                        .map((renderTree) => {
                            this.updateSortIndexes &&
                                this.props
                                    .updateSortIndex(this.props.items)
                                    .then(() => {
                                        this.updateSortIndexes = false;
                                    });
                            return renderTree;
                        })}
                </Grid>
            </DndProvider>
        );
    }
}

export default withStyles(styles)(DndContainer);
