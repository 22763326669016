import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProfilePicture from '../picture/ProfilePicture';

const styles = (theme) => ({
    container: {
        wordBreak: 'break-word',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
    },
    name: {
        fontWeight: 'bold',
    },
    email: {
        fontSize: '0.9rem',
    },
    textOverflow: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    cardAction: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    card: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        boxShadow: 'none',
    },
    iconBig: {
        fontSize: 50,
    },
});

class ProfileCard extends Component {
    render() {
        const { classes, onActionAreaClick, profile } = this.props;

        return (
            <div>
                <Card className={classes.card}>
                    <CardActionArea
                        className={classes.cardAction}
                        component={Link}
                        to={'/profile'}
                        onClick={onActionAreaClick}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {profile?.image ?? false ? (
                                <ProfilePicture
                                    imageURL={profile.profileImage}
                                    size="big"
                                />
                            ) : (
                                <PersonIcon className={classes.iconBig} />
                            )}
                        </Grid>
                        <Container className={classes.container}>
                            <Typography
                                className={clsx(
                                    classes.name,
                                    classes.textOverflow
                                )}
                                variant="subtitle1"
                                align="center"
                            >
                                {profile?.name ?? 'User'}
                            </Typography>
                            <Typography
                                className={clsx(
                                    classes.email,
                                    classes.textOverflow
                                )}
                                variant="subtitle1"
                                align="center"
                                gutterBottom
                            >
                                {profile?.email ?? ''}
                            </Typography>
                        </Container>
                    </CardActionArea>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(ProfileCard);
