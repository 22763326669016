import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import withTheme from '@mui/styles/withTheme';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../../../../mixins/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../mixins/components/buttons/SecondaryButton';
import DialogDivider from '../../../../mixins/components/divider/DialogDivider';
import withResponsiveInformation from '../../../../mixins/components/ResponsiveInformation';
import WishlistImages from '../imageStepper/WishlistImages';
import WishlistImageStepper from '../imageStepper/WishlistImageStepper';
import NoteField from '../textFields/NoteField';
import TitleField from '../textFields/TitleField';

const SlideTransition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

const GrowTransition = React.forwardRef((props, ref) => (
    <Grow ref={ref} {...props} />
));

const styles = (theme) => ({
    title: {
        color: theme.palette.primary.contrastText,
        fontFamily: "'Merienda One' !important",
        fontSize: '1.25rem',
        '& .MuiDialogTitle-root': {
            paddingBottom: '0 !important',
        },
        marginTop: theme.spacing(1),
    },
    content: {
        color: theme.palette.common.white,
    },
    buttons: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    dialogContent: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
    },
});

const CLEARED_STATE = {
    title: '',
    note: '',
    image: WishlistImages.BIRTHDAY.label,
};

class WishlistDialog extends Component {
    state = CLEARED_STATE;

    clearState() {
        this.setState(CLEARED_STATE);
    }

    handleTitleChange = ({ target }) => {
        this.setState({ title: target.value });
    };

    handleNoteChange = ({ target }) => {
        this.setState({ note: target.value });
    };

    handleImageChange = (image) => {
        this.setState({ image: image });
    };

    handleCancelClick = () => {
        const { onClose } = this.props;
        onClose();
        this.clearState();
    };

    handleSubmitClick = () => {
        const { onSubmit } = this.props;
        const { title, note, image } = this.state;
        const result = {
            title,
            note,
            image,
        };
        onSubmit(result);
        this.clearState();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevItem = prevProps.item;
        const currentItem = this.props.item;
        const itemChanged = currentItem !== prevItem;
        if (itemChanged && currentItem) {
            const item = {
                title: currentItem.title,
                note: currentItem.note,
                image: currentItem.image,
            };
            this.setState(item);
        }
    }

    render() {
        const { theme, classes, open, onClose, isMobile } = this.props;
        const { title, note, image } = this.state;
        const disableSubmitButton = title === '';
        return (
            <Dialog
                open={open}
                onClose={() => onClose()}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        maxWidth: isMobile ? '100%' : '400px',
                        maxHeight: 'none',
                    },
                }}
                fullScreen={isMobile}
                TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                }
            >
                <DialogTitle>
                    <Typography align="center" className={classes.title}>
                        <FormattedMessage id={this.props.title} />
                    </Typography>
                    <DialogDivider />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form noValidate>
                        <TitleField
                            value={title}
                            onChange={this.handleTitleChange}
                        />
                        <NoteField
                            value={note}
                            onChange={this.handleNoteChange}
                        />
                    </form>
                    <Grid container direction="row" justifyContent="center">
                        <WishlistImageStepper
                            fullScreen={isMobile}
                            preSelectedImage={image}
                            onImageChange={this.handleImageChange}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} className={classes.buttons}>
                        <Grid item xs={6}>
                            <SecondaryButton
                                text="wishlist.cancel"
                                onClick={this.handleCancelClick}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryButton
                                text={this.props.submitButtonLabel}
                                onClick={this.handleSubmitClick}
                                disabled={disableSubmitButton}
                            />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withResponsiveInformation(
    withStyles(styles)(withTheme(WishlistDialog))
);
