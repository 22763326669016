import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContentWrapper from '../../../app/components/AppContentWrapper';

class ProtectedRoute extends Component {
    renderRedirect(props) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}
            />
        );
    }

    renderComponent(props) {
        const { component: Component } = this.props;
        return (
            <AppContentWrapper>
                <Component {...props} />
            </AppContentWrapper>
        );
    }

    render() {
        const { authentication, component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props) =>
                    authentication.isAuthenticated ? this.renderComponent(props) : this.renderRedirect(props)
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
