import {
    GET_PROFILE_BY_ID_ERROR,
    GET_PROFILE_BY_ID_REQUEST,
    GET_PROFILE_BY_ID_SUCCESS,
    EDIT_SUCCESS,
    EDIT_ERROR,
    RESET,
    EDIT_REQUEST,
} from './ProfileActionTypes';
import ProfileService from '../../services/ProfileService';
import ImageService from '../../services/ImageService';

const getById = (id) => (dispatch) => {
    dispatch({
        type: GET_PROFILE_BY_ID_REQUEST,
    });
    return ProfileService.getById(id)
        .then((profile) => {
            dispatch({
                type: GET_PROFILE_BY_ID_SUCCESS,
                profile,
            });
            return profile;
        })
        .catch((error) => {
            dispatch({
                type: GET_PROFILE_BY_ID_ERROR,
                error,
            });
        });
};

export const getCurrentUserProfile = () => {
    return (dispatch, getState) => {
        const currentUser = getState().authentication.user;
        return dispatch(getById(currentUser.uid));
    };
};

export const edit = (name, email) => {
    return (dispatch, getState) => {
        const signInMethod = getState().profile.data.signInMethod;
        if (name === null && email === null) {
            name = getState().profile.changedName;
            email = getState().profile.changedEmail;
        }
        dispatch({
            type: EDIT_REQUEST,
            name: name,
            email: email,
        });
        return ProfileService.edit(name, email, signInMethod)
            .then((result) => {
                dispatch({
                    type: EDIT_SUCCESS,
                });
                dispatch(getCurrentUserProfile());
                return result;
            })
            .catch((error) => {
                if (error.messageId === 'auth.requires-recent') {
                    return Promise.reject({ updatedEmail: false });
                } else {
                    dispatch({
                        type: EDIT_ERROR,
                        error: error,
                    });
                    return Promise.reject(error);
                }
            });
    };
};

export const reset = () => (dispatch) => {
    return dispatch({
        type: RESET,
    });
};

export const uploadImage = (file) => {
    return (dispatch, getState) => {
        const currentUser = getState().authentication.user;

        return ImageService.uploadImage(file, currentUser.uid)
            .then(() => {
                return dispatch(getById(currentUser.uid));
            })
            .catch((error) => {
                console.error(
                    'could not persist image in firebase storage',
                    error
                );
            });
    };
};

export const getProfile = (userId) => {
    return getById(userId);
};
