import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
    input: {
        '& label.Mui-focused': {
            color: theme.palette.common.secondaryAccentColor,
        },
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.common.secondaryAccentColor}`,
        },
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #4D4D4DC9',
        },
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiFormLabel-root': {
            color: '#4D4D4DC9',
            fontSize: '0.875rem',
        },
    },
});

class TitleField extends Component {
    render() {
        const { classes, intl, onChange, defaultValue, value } = this.props;

        return (
            <TextField
                fullWidth
                className={classes.input}
                label={intl.formatMessage({ id: 'wishlist.name' })}
                autoFocus
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
                inputProps={{ maxLength: 200 }}
                variant="standard"
            />
        );
    }
}

export default injectIntl(withStyles(styles)(TitleField));
