import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/messaging';
import config from '../../config';

const firebaseConfig = config.getFirebaseConfiguration();

export const firebaseInstance = firebase.initializeApp(firebaseConfig);
export const firebaseAuthentication = firebase.auth;
export const firebaseFunctions = firebaseInstance.functions('europe-west3');
export const firebaseAnalytics = firebase.analytics();
export const firebaseMessaging =
    firebase.messaging.isSupported() && firebase.messaging();

const baseDb = firebase.database();
export const db = baseDb;
