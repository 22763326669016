import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontFamily: theme.typography.appBarFontFamily,
    },
});

class AboutAppbar extends Component {
    render() {
        const { classes } = this.props;
        return (
            <AppBar position='absolute' color='primary'>
                <Toolbar>
                    <IconButton
                        edge='start'
                        className={classes.menuButton}
                        color='inherit'
                        aria-label='menu'
                        component={Link}
                        to={'/wishlist'}
                        size="large">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant='h6' className={classes.title}>
                        <FormattedMessage id={'settings.about'} />
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(styles)(AboutAppbar);
