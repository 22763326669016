import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    bought,
    getAllFlat,
    registerObserver,
    release,
    reserved,
    unregisterObserver,
    removeWishlist,
    affiliateUrl,
} from '../../stateManagement/actions/SharedDesireActions';
import { restore } from '../../../sharedWishlist/stateManagement/actions/SharedWishlistActions';

const withSharedDesires = (WrappedComponent) => {
    class SharedDesires extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            getAllSharedDesires: (wishlistId) =>
                dispatch(getAllFlat(wishlistId)),
            registerSharedDesireObserver: (wishlistId, callback) =>
                dispatch(registerObserver(wishlistId, callback)),
            unregisterSharedDesireObserver: (wishlistId, callback) =>
                dispatch(unregisterObserver(wishlistId, callback)),
            reserved: (desire) => dispatch(reserved(desire)),
            bought: (desire) => dispatch(bought(desire)),
            release: (desire) => dispatch(release(desire)),
            removeFriendsWishlist: (sharedWishlist) =>
                dispatch(removeWishlist(sharedWishlist)),
            restoreFriendsWishlist: (sharedWishlist) =>
                dispatch(restore(sharedWishlist)),
            affiliateUrl: (url) => dispatch(affiliateUrl(url)),
        };
    };

    const mapStateToProps = (state) => {
        return {
            sharedDesires: state.sharedDesires,
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(SharedDesires);
};

export default withSharedDesires;
