import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import {
    CircularProgress,
    IconButton,
    Slide,
    Tooltip,
    Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';
import ResponsiveFooter from '../../../wishlist/main/components/ResponsiveFooter';
import Resizer from '../../main/components/Resizer';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
    },
    responsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    titleDiv: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '70%',
        marginBottom: '5vh',
        wordBreak: 'break-all',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    title: {
        fontFamily: theme.typography.appBarSubtitle,
        color: theme.palette.primary.contrastText,
        userSelect: 'none',
    },
    loadingAnimation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingColor: {
        color: '#4D4D4D',
    },
    spacer: {
        minHeight: '14vh',
        [theme.breakpoints.down('xs')]: {
            minHeight: '10vh',
        },
    },
    createButton: {
        position: 'fixed',
        bottom: theme.spacing(6),
        right: theme.spacing(8),
        cursor: 'pointer',
        zIndex: 1,
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(3),
        },
    },
    splitChild: {
        height: '100vh',
        width: '50%',
        overflowY: 'scroll',
        float: 'left',

        //disable scrollbars
        '-ms-overflow-style': 'none' /* IE and Edge */,
        scrollbarWidth: 'none' /* Firefox */,
        '&::-webkit-scrollbar': {
            display: 'none' /* Chrome */,
        },
    },
    closeSplit: {
        position: 'fixed',
        top: '80px',
        right: '20px',
        color: '#FBFAE9',
        zIndex: '10',
    },
    redirect: {
        position: 'fixed',
        top: '80px',
        right: '60px',
        color: '#FBFAE9',
        zIndex: '10',
    },
    splitChildRight: {
        flex: '1 1 0%',
        height: '100vh',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
    },
    wishlistTitle: {
        position: 'sticky',
        width: '100%',
        height: '33px',
        top: '88px',
        zIndex: '10',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    wishlistTileShadow: {
        position: 'sticky',
        boxShadow: '#8EBFBF 0px 40px 60px 50px',
        width: '100%',
        height: '33px',
        top: '27px',
        zIndex: '10',
    },
    wishlistTitleSplit: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        marginRight: '120px',
        marginLeft: '120px',
        userSelect: 'none',
        color: '#FBFAE9',
        fontFamily: theme.typography.appBarSubtitle,
        fontSize: '25px',
    },
});

class DefaultLayout extends Component {
    state = {
        clickedForSplit: null,
    };

    componentDidUpdate() {
        const { removedItem, callbackOnRemoved, leftItems } = this.props;
        const { clickedForSplit } = this.state;

        const [item] = leftItems.filter(({ id }) => id === clickedForSplit?.id);
        if (clickedForSplit && item && clickedForSplit.note !== item.note) {
            this.setState({ clickedForSplit: item });
        }

        if (removedItem?.id ?? 0 === clickedForSplit?.id ?? 1) {
            this.setState({ clickedForSplit: null });
            callbackOnRemoved();
        }
    }

    getPageTitle = () => {
        const { classes, titleId } = this.props;
        return (
            <div className={classes.titleDiv}>
                <Typography className={classes.title} variant="h4">
                    <FormattedMessage id={titleId} />
                </Typography>
            </div>
        );
    };

    onItemClick = (clickedItem) => {
        const { history, width, destination } = this.props;
        const { clickedForSplit } = this.state;
        if (
            clickedForSplit?.id === clickedItem?.id ||
            width === 'xs' ||
            width === 'sm'
        ) {
            history.push(`/${destination}/${clickedItem.id}`);
        } else {
            window.history.replaceState(
                null,
                '',
                `/${destination}/${clickedItem.id}`
            );
            this.setState({ clickedForSplit: clickedItem });
        }

        if (destination === 'wishlist') {
            const createWishlistButton = document.getElementById(
                'wishlistCreateButton'
            );
            createWishlistButton.style.display = 'none';
        }
    };

    renderList = () => {
        const { classes, loading, itemsLength, mainContent, emptyContent } =
            this.props;
        const { clickedForSplit } = this.state;

        if (loading) {
            return (
                <div className={classes.loadingAnimation}>
                    <CircularProgress
                        className={classes.loadingColor}
                        disableShrink
                    />
                </div>
            );
        } else if (itemsLength > 0) {
            return (
                <div className={classes.content}>
                    {this.getPageTitle()}
                    {React.cloneElement(mainContent, {
                        onItemClick: this.onItemClick,
                        selectedItem: clickedForSplit,
                    })}
                </div>
            );
        } else {
            return (
                <div className={classes.content}>
                    {this.getPageTitle()}
                    {React.cloneElement(emptyContent)}
                </div>
            );
        }
    };

    closeSplit = () => {
        this.setState({
            clickedForSplit: null,
        });

        if (this.props.destination === 'wishlist') {
            const createWishlistButton = document.getElementById(
                'wishlistCreateButton'
            );
            createWishlistButton.style.display = 'inline-block';
        }

        window.history.replaceState(null, '', `/${this.props.destination}`);
    };

    render() {
        const { classes, loading, splitContent } = this.props;
        const { clickedForSplit } = this.state;

        return (
            <div className={classes.container}>
                <div
                    className={
                        clickedForSplit ? classes.splitChild : classes.container
                    }
                >
                    <div className={classes.root}>
                        {!loading && <div className={classes.spacer} />}
                        <main className={classes.responsive}>
                            {this.renderList()}
                        </main>
                        {!clickedForSplit && (
                            <ResponsiveFooter textColor="textPrimary" />
                        )}
                    </div>
                </div>
                {clickedForSplit && (
                    <Fragment>
                        <Resizer />
                        <Slide direction="left" in={true}>
                            <div
                                key={
                                    clickedForSplit
                                        ? '' +
                                          clickedForSplit.id +
                                          clickedForSplit.note
                                        : 'clickedFoSplit'
                                }
                                className={clsx(
                                    classes.splitChild,
                                    classes.splitChildRight
                                )}
                            >
                                <div className={classes.wishlistTileShadow} />
                                <div className={classes.wishlistTitle}>
                                    <Tooltip
                                        title={clickedForSplit.title}
                                        enterDelay={800}
                                    >
                                        <div
                                            className={
                                                classes.wishlistTitleSplit
                                            }
                                        >
                                            {clickedForSplit.title}
                                        </div>
                                    </Tooltip>
                                </div>
                                {React.cloneElement(splitContent, {
                                    wishlistId: clickedForSplit.id,
                                    disableAppbar: true,
                                    disableFooter: true,
                                })}

                                <Tooltip
                                    title={
                                        <FormattedMessage
                                            id={'app.redirectToWishlist'}
                                        />
                                    }
                                    aria-label="redirect"
                                    enterDelay={300}
                                >
                                    <IconButton
                                        className={classes.redirect}
                                        onClick={() =>
                                            this.onItemClick(clickedForSplit)
                                        }
                                        size="large"
                                    >
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <FormattedMessage id={'app.close'} />
                                    }
                                    aria-label="redirect"
                                    enterDelay={300}
                                >
                                    <IconButton
                                        className={classes.closeSplit}
                                        onClick={this.closeSplit}
                                        size="large"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Slide>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(
    withResponsiveInformation(withStyles(styles)(DefaultLayout))
);
