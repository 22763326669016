import Button from '@mui/material/Button';
import { emphasize } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import IconWrapper from './IconWrapper';
import icon from './resources/googleIcon.png';

const styles = (theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: theme.palette.common.darkBackground,
        backgroundColor: '#FFFFFF',
        padding: '7px',
        justifyContent: 'left',
        paddingLeft: '24px',
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: emphasize('#FFFFFF', 0.15),
        },
    },
    icon: {
        img: {
            width: '18px',
            height: '18px',
        },
    },
    text: {
        marginLeft: '2px',
    },
});

class GoogleButton extends Component {
    render() {
        const { classes, onClick } = this.props;

        return (
            <Button
                fullWidth
                variant="contained"
                className={classes.button}
                onClick={onClick}
                startIcon={
                    <IconWrapper
                        icon={icon}
                        iconHint="Google Icon"
                        className={classes.icon}
                    />
                }
            >
                <span className={classes.text}>
                    <FormattedMessage id={'auth.signin-with-google'} />
                </span>
            </Button>
        );
    }
}

export default withStyles(styles)(GoogleButton);
