import {
    LOGIN_RESET,
    LOGIN_ERROR,
    REGISTER_ERROR,
    RESET_PASSWORD_ERROR,
} from '../actions/AuthenticationActionTypes';

const loginReducer = (
    state = {
        error: null,
    },
    action
) => {
    switch (action.type) {
        case LOGIN_RESET:
            return {
                ...state,
                error: null,
            };
        case LOGIN_ERROR:
        case REGISTER_ERROR:
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};

export default loginReducer;
