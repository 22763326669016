import { CHANGE_LOCALIZATION } from './LocalizationActionTypes';
import serviceInstance from '../../service/LocalizationService';

export const changeLocalization = (value) => {
    return (dispatch) => {
        return serviceInstance.change(value).then(() => {
            dispatch({
                type: CHANGE_LOCALIZATION,
                value,
            });
        });
    };
};
