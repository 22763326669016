import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withPrimaryAppbar from '../../../menu/appBar/primaryAppBar/components/hocs/PrimaryAppbar';
import SharedWith from '../../../mixins/components/SharedWith';
import { undoActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import ResponsiveFooter from '../../../wishlist/main/components/ResponsiveFooter';
import SharedDesiresAppbar from '../appBar/SharedDesiresAppbar';
import withSharedDesires from '../hocs/SharedDesires';
import SharedDesiresEmptyList from '../list/SharedDesiresEmptyList';
import SharedDesiresList from '../list/SharedDesiresList';
import Configuration from '../../../config'

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.main,
    },
    responsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    noteDiv: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '70%',
        marginBottom: '5vh',
        wordBreak: 'break-all',
        fontFamily: theme.typography.appBarSubtitle,
        color: theme.palette.primary.main,
        flexDirection: 'column',
    },
    loadingAnimation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spacer: {
        minHeight: '15vh',
        [theme.breakpoints.down('xs')]: {
            minHeight: '10vh',
        },
    },
});

class SharedDesiresPage extends Component {
    componentDidMount() {
        const { match, registerSharedDesireObserver, history, wishlistId } =
            this.props;
        registerSharedDesireObserver(
            wishlistId || match.params.wishlistId,
            this.handleReload
        )
            .then(this.handleReload())
            .catch(() => history.push('/friendswishlist'));
    }

    componentWillUnmount() {
        const { match, unregisterSharedDesireObserver, history, wishlistId } =
            this.props;
        unregisterSharedDesireObserver(
            wishlistId || match.params.wishlistId,
            this.handleReload
        ).catch(() => history.push('/friendswishlist'));
    }

    handleReload = () => {
        const { match, getAllSharedDesires, wishlistId } = this.props;
        getAllSharedDesires(wishlistId || match.params.wishlistId);
    };

    isNoteValidForDisplay = (note) =>
        note && note.replace(/\s/g, 'X').length > 0;

    handleItemRemove = () => {
        const {
            removeFriendsWishlist,
            sharedDesires,
            enqueueSnackbar,
            closeSnackbar,
        } = this.props;
        const { wishlist } = sharedDesires;

        const ownerName = sharedDesires?.owner?.displayName ?? '';

        removeFriendsWishlist(wishlist);
        enqueueSnackbar(
            this.getUndoDeleteSnackbarMessage(wishlist, ownerName),
            {
                variant: 'info',
                action: (key) =>
                    undoActionSnackbarButton(
                        () => this.handleItemRestore(wishlist),
                        () => closeSnackbar(key)
                    ),
            }
        );
    };

    handleItemRestore = (item) => {
        this.props.restoreFriendsWishlist(item);
    };

    getUndoDeleteSnackbarMessage({ title }, ownerName) {
        return (
            <div>
                <span id="message-id">
                    <FormattedMessage id={'wishlist.wishlist'} />
                </span>
                <span>&nbsp;'{title}'&nbsp;</span>
                <span>
                    <FormattedMessage id={'share.of'} />
                </span>
                <span>&nbsp;{ownerName}&nbsp;</span>
                <span>
                    <FormattedMessage id={'share.left'} />
                </span>
            </div>
        );
    }

    getNote = () => {
        const {
            classes,
            sharedDesires: { wishlist },
        } = this.props;

        if (this.isNoteValidForDisplay(wishlist?.note ?? '')) {
            return (
                <div className={classes.noteDiv}>
                    <Typography variant="h5">{wishlist.note}</Typography>
                </div>
            );
        }
    };

    loadingAnimation = () => {
        const { classes } = this.props;
        return (
            <div className={classes.loadingAnimation}>
                <CircularProgress disableShrink />
            </div>
        );
    };

    renderList = (isMarketingWishlist = false) => {
        const {
            classes,
            sharedDesires: { isLoading, sharedDesires, sharedWith },
        } = this.props;

        if (isLoading) {
            return this.loadingAnimation();
        } else if (sharedDesires.length > 0) {
            return (
                <div className={classes.content}>
                    {!isMarketingWishlist && sharedWith.length > 0 && (
                        <SharedWith sharedWith={sharedWith} isEmpty={false} />
                    )}
                    {this.getNote()}
                    <SharedDesiresList items={sharedDesires} isMarketingWishlist={isMarketingWishlist} />
                </div>
            );
        } else {
            return (
                <div className={classes.content}>
                    {sharedWith.length > 0 && (
                        <SharedWith sharedWith={sharedWith} isEmpty={true} />
                    )}
                    {this.getNote()}
                    <SharedDesiresEmptyList />
                </div>
            );
        }
    };

    render() {
        const { classes, sharedDesires, disableAppbar, disableFooter } =
            this.props;

        const isMarketingWishlist = sharedDesires?.owner?.id ===  Configuration.getOwnerId();
       Configuration.getOwnerId();

        return (
            <div>
                {!disableAppbar && (
                    <SharedDesiresAppbar
                        wishlist={sharedDesires.wishlist}
                        owner={sharedDesires.owner}
                        removeFriendsWishlist={this.handleItemRemove}
                    />
                )}
                <div className={classes.root}>
                    {!sharedDesires.isLoading && (
                        <div className={classes.spacer} />
                    )}
                    <main className={classes.responsive}>
                        {this.renderList(isMarketingWishlist)}
                    </main>
                    {!disableFooter && (
                        <ResponsiveFooter textColor="textPrimary" />
                    )}
                </div>
            </div>
        );
    }
}

export default withSnackbar(
    withRouter(
        withSharedDesires(
            withPrimaryAppbar(withStyles(styles)(SharedDesiresPage))
        )
    )
);
