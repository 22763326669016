import {
    PROFILE_EDIT_SHOULD_SHOW_DIALOG,
    PROFILE_EDIT_RESET,
    PROFILE_ERROR,
} from './ProfileEditActionTypes';

export const applyShowDialog = () => {
    return {
        type: PROFILE_EDIT_SHOULD_SHOW_DIALOG,
    };
};

export const reset = () => {
    return {
        type: PROFILE_EDIT_RESET,
    };
};

export const showError = () => {
    return {
        type: PROFILE_ERROR,
    };
};
