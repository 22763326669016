import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withConsentBanner from './hocs/ConsentBanner';
import ConsentBanner from './ConsentBanner';

class ConsentBannerWrapper extends Component {
    render() {
        const { children } = this.props;
        return (
            <React.Fragment>
                {children}
                <ConsentBanner />
            </React.Fragment>
        );
    }
}

export default withConsentBanner(withRouter(ConsentBannerWrapper));
