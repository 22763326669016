import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createDefaultWishlist } from '../../../wishlist/main/stateManagement/actions/WishlistActions';
import {
    login,
    loginInWithApple,
    loginInWithFacebook,
    loginInWithGoogle,
    register,
    registerWithFacebook,
    registerWithGoogle,
    registerWithApple,
    resetLogin,
    resetPassword,
} from '../../statemanagement/actions/AuthenticationActions';

const withAuthentication = (WrappedComponent) => {
    class Authentication extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state) => {
        return {
            authentication: state.authentication,
            login: state.login,
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            resetPageState: () => dispatch(resetLogin()),
            loginStandard: (email, password) =>
                dispatch(login(email, password)),
            loginInWithApple: () => dispatch(loginInWithApple()),
            loginInWithFacebook: () => dispatch(loginInWithFacebook()),
            loginInWithGoogle: () => dispatch(loginInWithGoogle()),
            register: (
                email,
                password,
                repeatedPassword,
                name,
                privacyChecked
            ) =>
                dispatch(
                    register(
                        email,
                        password,
                        repeatedPassword,
                        name,
                        privacyChecked
                    )
                ),
            registerWithApple: (privacyChecked) =>
                dispatch(registerWithApple(privacyChecked)),
            registerWithGoogle: (privacyChecked) =>
                dispatch(registerWithGoogle(privacyChecked)),
            registerWithFacebook: (privacyChecked) =>
                dispatch(registerWithFacebook(privacyChecked)),
            resetPassword: (email) => dispatch(resetPassword(email)),
            createDefaultWishlist: (name) =>
                dispatch(createDefaultWishlist(name)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
};

export default withAuthentication;
