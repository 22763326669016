import { withStyles } from '@mui/styles';
import React, { Component } from 'react';

const styles = () => ({
    divider: {
        border: 0,
        height: 0,
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        margin: 0,
    },
});

class Divider extends Component {
    render() {
        const { classes } = this.props;
        return <hr className={classes.divider} />;
    }
}

export default withStyles(styles)(Divider);
