import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { styles } from './styles/TextFieldStyles';

class WishField extends Component {
    render() {
        const {
            classes,
            intl,
            onChange,
            id,
            defaultValue,
            inputRef,
            autoFocus,
            foreignClassName,
            value,
        } = this.props;

        return (
            <TextField
                id={id}
                inputRef={inputRef}
                autoFocus={autoFocus}
                fullWidth
                className={
                    foreignClassName
                        ? clsx(foreignClassName, classes.input)
                        : classes.input
                }
                label={intl.formatMessage({ id: id })}
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
                inputProps={{ maxLength: 2000 }}
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        ev.preventDefault();
                    }
                }}
                InputLabelProps={{
                    shrink: !!value,
                }}
                variant="standard"
            />
        );
    }
}

export default injectIntl(withStyles(styles)(WishField));
