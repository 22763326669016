import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import SharedDesiresPage from '../../../sharedDesires/components/page/SharedDesiresPage';
import { undoActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import DefaultLayout from '../../../wishlist/page/components/DefaultLayout';
import withSharedWishlist from '../hocs/SharedWishlist';
import SharedWishlistEmptyList from '../list/SharedWishlistEmptyList';
import SharedWishListList from '../list/SharedWishListList';

class SharedWishListPage extends Component {
    state = { removedItem: null };

    componentDidMount() {
        this.props.registerSharedWishlistObserver(this.handleReload);
    }

    componentWillUnmount() {
        this.props.unregisterSharedWishlistObserver(this.handleReload);
    }

    handleItemRemove = (item) => {
        const {
            removeSharedWishlist,
            enqueueSnackbar,
            closeSnackbar,
            sharedWishlist,
        } = this.props;

        const [removedItem] = sharedWishlist.sharedWishlists.filter(
            (wishlist) => wishlist.id === item.id
        );

        this.setState({ removedItem });

        const ownerName = sharedWishlist?.owners?.get(item.ownerId)?.name ?? '';

        removeSharedWishlist(removedItem);
        enqueueSnackbar(
            this.getUndoDeleteSnackbarMessage(removedItem, ownerName),
            {
                variant: 'info',
                action: (key) =>
                    undoActionSnackbarButton(
                        () => this.handleItemRestore(removedItem),
                        () => closeSnackbar(key)
                    ),
            }
        );
    };

    handleItemRestore = (item) => {
        this.props.restoreSharedWishlist(item);
    };

    handleReload = this.props.getAllSharedWishlists;

    getUndoDeleteSnackbarMessage({ title }, ownerName) {
        return (
            <div>
                <span id="message-id">
                    <FormattedMessage id={'wishlist.wishlist'} />
                </span>
                <span>&nbsp;'{title}'&nbsp;</span>
                <span>
                    <FormattedMessage id={'share.of'} />
                </span>
                <span>&nbsp;{ownerName}&nbsp;</span>
                <span>
                    <FormattedMessage id={'share.left'} />
                </span>
            </div>
        );
    }

    render() {
        const {
            sharedWishlist: { owners, sharedWishlists, loading },
        } = this.props;

        return (
            <DefaultLayout
                titleId="nav.shared-wishlist"
                destination="friendswishlist"
                leftItems={sharedWishlists}
                mainContent={
                    <SharedWishListList
                        owners={owners}
                        items={sharedWishlists}
                        onItemRemove={this.handleItemRemove}
                    />
                }
                emptyContent={<SharedWishlistEmptyList />}
                splitContent={<SharedDesiresPage />}
                loading={loading}
                itemsLength={sharedWishlists.length}
                removedItem={this.state.removedItem}
                callbackOnRemoved={() => this.setState({ removedItem: null })}
            />
        );
    }
}

export default withSnackbar(withSharedWishlist(SharedWishListPage));
