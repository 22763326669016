import React, { Component } from 'react';

class Icon extends Component {
    render() {
        const styles = {
            img: {
                width: this.props.width ? this.props.width + 'px' : '24px',
                height: this.props.height ? this.props.height + 'px' : '24px',
                verticalAlign: 'sub',
            },
        };
        return (
            <img
                style={styles.img}
                src={this.props.icon}
                alt={this.props.alt}
            />
        );
    }
}

export default Icon;
