import { CardHeader, Divider, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import validator from 'validator';
import AnalyticsService from '../../../consent/service/AnalyticsService';
import withResponsiveInformation from '../../../mixins/components/ResponsiveInformation';
import { affiliateUrl } from '../../../sharedDesires/stateManagement/actions/SharedDesireActions';
import { undoActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import {
    deleteDesire,
    openEditDialog,
    openMoveDesireDialog,
    undoDelete,
} from '../../stateManagement/actions/DesireActions';
import DEAFULT_IMAGE from '../resources/placeholder/wish_placeholder@3x.png';
import MenuListComposition from './MenuListComposition';

const styles = (theme) => ({
    card: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: '100%',
    },
    cardWithCorner: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: '100%',
        '&::before': {
            content: '""',
            position: 'relative',
            margin: '-20px',
            width: '40px',
            height: '40px',
            transform: 'rotate(45deg)',
            backgroundColor: '#F47F8C',
            float: 'right',
            'z-index': 1,
        },
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    header: {
        '& .MuiCardHeader-content': {
            display: 'grid',
        },
        '& .MuiCardHeader-title': {
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            overflow: 'hidden',
            fontSize: '0.9rem',
            display: '-webkit-box',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
            '-moz-box-orient': 'vertical',
        },
        '& .MuiCardHeader-action': {
            margin: theme.spacing(0),
        },
        '& .MuiSnackbarContent-message': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(0),
    },
    link_label: {
        color: 'black',
        fontStyle: 'italic',
        fontSize: 'small',
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
});

class DesireCard extends Component {
    state = {
        open: true,
        noteVisible: false,
    };

    onDeleteOpenSnackbar = (desire, handleItemRestore) => {
        const { enqueueSnackbar, closeSnackbar, intl } = this.props;

        enqueueSnackbar(intl.formatMessage({ id: 'wish.undoSnackbar' }), {
            variant: 'info',
            autoHideDuration: 6000,
            action: (key) =>
                undoActionSnackbarButton(
                    () => handleItemRestore(desire),
                    () => closeSnackbar(key),
                ),
        });
    };

    handleDeleteClick = () => {
        const { item: desire } = this.props;
        this.setState({ open: false });
        this.onDeleteOpenSnackbar(desire, () => this.handleItemRestore(desire));
    };

    handleEditClick = () => {
        const { openEditDesireDialog, item: desire } = this.props;
        openEditDesireDialog(desire);
    };

    handleMoveClick = () => {
        const { openMoveDesireDialog, item: desire } = this.props;
        openMoveDesireDialog(desire);
    };

    handleExit = () => {
        const { item: desire, deleteDesire } = this.props;
        deleteDesire(desire);
    };

    handleDesireClick = () => {
        const { item: desire, affiliate } = this.props;

        AnalyticsService.viewWish(
            desire.title,
            desire.url,
            'own_wish',
            'external',
        );
        validator.isURL(desire?.url ?? '') &&
        window.open(affiliate(desire.url));
    };

    handleItemRestore = (item) => this.props.restoreDesire(item);

    handleBuyClick = () => {
        const { item: desire, affiliate } = this.props;
        window.open(affiliate(desire.url));
    };

    getBaseUrl = (url) => {
        try {
            const urlObject = new URL(url);
            let hostname = urlObject.hostname;
            let parts = hostname.split('.');
            let baseName = parts[parts.length - 2];
            let tld = parts[parts.length - 1];
            if (baseName.toLowerCase() === 'amzn' || baseName.toLowerCase() === 'a' || baseName.toLowerCase() === 'amazon') {
                baseName = 'Amazon';
            }
            return baseName + (baseName.toLowerCase() !== 'amazon' ? '.' + tld : '');
        } catch (error) {
            console.error('Invalid URL', error);
            return '';
        }
    };

    getActions = () => [
        {
            id: 'wish.edit',
            onClick: this.handleEditClick,
        },
        {
            id: 'wish.delete',
            onClick: this.handleDeleteClick,
        },
        {
            id: 'wish.move',
            onClick: this.handleMoveClick,
        },
        {
            id: 'wish.buy',
            onClick: this.handleBuyClick,
        },
    ];

    render() {
        const { classes, item: desire } = this.props;
        const { noteVisible } = this.state;

        const date = new Date();
        date.setDate(date.getDate() - 3);

        const isNewItem = desire.created > date;
        const baseUrl = this.getBaseUrl(desire.url);

        return (
            <Fade
                timeout={500}
                in={this.state.open}
                mountOnEnter
                unmountOnExit
                onExited={this.handleExit}
            >
                <Card
                    className={
                        isNewItem ? classes.cardWithCorner : classes.card
                    }
                    elevation={5}
                    id={`desire-card-${desire.id}`}
                >
                    <CardActionArea onClick={this.handleDesireClick}>
                        {!desire ? (
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                className={classes.cardMedia}
                            />
                        ) : (
                            <CardMedia
                                className={classes.cardMedia}
                                image={
                                    desire.imageUrl
                                        ? desire.imageUrl
                                        : DEAFULT_IMAGE
                                }
                                title={desire && desire.title}
                            />
                        )}
                    </CardActionArea>
                    <Divider />
                    <Tooltip
                        title={
                            desire && noteVisible ? desire.note : desire.title
                        }
                        enterDelay={1000}
                    >
                        <div>
                            {baseUrl !== '' ? (
                                    <div className={classes.link_label}>
                                        <FormattedMessage id={'desireLink'} /> {baseUrl}
                                    </div>
                                ) :
                                (<div className={classes.link_label}>
                                    <FormattedMessage id={'desireOfHeart.name'} />
                                </div>)
                            }
                            <CardHeader
                                className={classes.header}
                                sx={{
                                    cursor: 'default',
                                    '& .MuiCardHeader-title': {
                                        fontWeight:
                                            desire && noteVisible
                                                ? 'normal'
                                                : 'bold',
                                    },
                                }}
                                title={
                                    desire && noteVisible
                                        ? desire.note
                                        : desire.title
                                }
                                action={
                                    <MenuListComposition
                                        desire={desire}
                                        actions={this.getActions()}
                                        noteVisible={noteVisible}
                                        handleNoteClick={() =>
                                            this.setState({
                                                noteVisible: !noteVisible,
                                            })
                                        }
                                    />
                                }
                            />
                        </div>
                    </Tooltip>
                </Card>
            </Fade>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDesire: (desire) => dispatch(deleteDesire(desire)),
        restoreDesire: (desire) => dispatch(undoDelete(desire)),
        openEditDesireDialog: (desire) => dispatch(openEditDialog(desire)),
        affiliate: (url) => dispatch(affiliateUrl(url)),
        openMoveDesireDialog: (desire) =>
            dispatch(openMoveDesireDialog(desire)),
    };
};

function mapStateToProps(state) {
    return {
        userId: state.authentication.user.uid,
    };
}

export default withResponsiveInformation(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(injectIntl(withSnackbar(withStyles(styles)(DesireCard)))),
);
