import {
    GET_PROFILE_BY_ID_ERROR,
    GET_PROFILE_BY_ID_SUCCESS,
    EDIT_ERROR,
    EDIT_SUCCESS,
    RESET,
    EDIT_REQUEST,
} from '../actions/ProfileActionTypes';
import Profile from '../../model/Profile';

const profileReducer = (
    state = {
        data: Profile.createDefault(),
        error: null,
        showUserInfo: false,
        changedName: null,
        changedEmail: null,
    },
    action
) => {
    switch (action.type) {
        case GET_PROFILE_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.profile,
            };
        case GET_PROFILE_BY_ID_ERROR:
            return {
                ...state,
                data: null,
            };
        case EDIT_REQUEST:
            return {
                ...state,
                changedName: action.name,
                changedEmail: action.email,
            };
        case EDIT_SUCCESS:
            return {
                ...state,
                error: null,
                showUserInfo: true,
                changedName: null,
                changedEmail: null,
            };
        case EDIT_ERROR:
            return {
                ...state,
                error: action.error,
                showUserInfo: true,
            };
        case RESET:
            return {
                ...state,
                error: null,
                showUserInfo: false,
            };
        default:
            return state;
    }
};

export default profileReducer;
