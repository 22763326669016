import React, { Component } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import withStyles from '@mui/styles/withStyles';
import withTheme from '@mui/styles/withTheme';
import WishlistImages from './WishlistImages';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
    },
    img: {
        display: 'block',
        overflow: 'hidden',
        width: '100%',
    },
    footer: {
        background: '#FBFAE900',
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        '& .MuiMobileStepper-dot': {
            width: 7,
            height: 7,
        },
    },
});

class WishlistImageStepper extends Component {
    state = {
        activeStep: 0,
        images: WishlistImages,
    };

    componentDidMount() {
        this.preselctImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.preSelectedImage !== prevProps.preSelectedImage) {
            this.preselctImage();
        }
    }

    preselctImage() {
        const { preSelectedImage } = this.props;
        const { images } = this.state;
        if (!preSelectedImage) {
            return;
        }
        const activeStep = Object.keys(images).indexOf(preSelectedImage);
        this.setState({ activeStep: activeStep });
    }

    handleNext = () => {
        const { activeStep } = this.state;
        this.setImageByStepIndex(activeStep + 1);
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setImageByStepIndex(activeStep - 1);
    };

    handleStepChange = (step) => {
        this.setImageByStepIndex(step);
    };

    setImageByStepIndex(stepIndex) {
        const { images } = this.state;
        this.handleImageChange(Object.keys(images)[stepIndex]);
        this.setState({ activeStep: stepIndex });
    }

    handleImageChange(image) {
        const { onImageChange } = this.props;
        if (onImageChange) {
            onImageChange(image);
        }
    }

    renderNextButton() {
        const { theme } = this.props;
        const { images } = this.state;
        const imagesLength = Object.keys(images).length;
        const isRightDirection = theme.direction === 'rtl';
        const isLastStep = this.state.activeStep === imagesLength - 1;
        return (
            <Button
                size="small"
                onClick={this.handleNext}
                disabled={isLastStep}
            >
                <FormattedMessage id={'wishlist.next'} />
                {isRightDirection ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </Button>
        );
    }

    renderBackButton() {
        const { theme } = this.props;
        const isRightDirection = theme.direction === 'rtl';
        const isFirstStep = this.state.activeStep === 0;
        return (
            <Button
                size="small"
                onClick={this.handleBack}
                disabled={isFirstStep}
            >
                {isRightDirection ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
                <FormattedMessage id={'wishlist.back'} />
            </Button>
        );
    }

    render() {
        const { classes, theme, fullScreen } = this.props;
        const { images } = this.state;
        const imagesLength = Object.keys(images).length;
        return (
            <div className={classes.root}>
                <Grid container >
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.activeStep}
                        onChangeIndex={this.handleStepChange}
                        enableMouseEvents
                        animateHeight={false}
                        className={classes.view}
                    >
                        {this.renderImages()}
                    </SwipeableViews>
                </Grid>
                <MobileStepper
                    steps={imagesLength}
                    position="static"
                    variant={fullScreen ? 'text' : 'dots'}
                    className={classes.footer}
                    activeStep={this.state.activeStep}
                    nextButton={this.renderNextButton()}
                    backButton={this.renderBackButton()}
                />
            </div>
        );
    }

    renderImages() {
        const { classes } = this.props;
        const { activeStep, images } = this.state;
        return Object.keys(images).map((key, index) => (
            <div key={key}>
                {Math.abs(activeStep - index) <= 2 ? (
                    <img
                        className={classes.img}
                        src={images[key].imgPath}
                        alt={key}
                    />
                ) : null}
            </div>
        ));
    }
}

export default withStyles(styles)(withTheme(WishlistImageStepper));
