import Button from '@mui/material/Button';
import { emphasize } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(2, 0, 1),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.darkBackground,
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: emphasize(
                theme.palette.common.darkBackground,
                0.15
            ),
        },
        padding: '7px',
    },
});

class PrimaryButton extends Component {
    render() {
        const { classes, text, onClick, disabled } = this.props;

        return (
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onClick}
                disabled={disabled}
            >
                <FormattedMessage id={text} />
            </Button>
        );
    }
}

export default withStyles(styles)(PrimaryButton);
