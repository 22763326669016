import Container from '@mui/material/Container';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import DesireCard from '../card/DesireCard';
import { default as DnDContainer } from '../dragAndDrop/DndContainer';
import withDesires from '../hocs/Desires';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4),
        },
        paddingBottom: theme.spacing(4),
    },
});

class DesiresList extends Component {
    render() {
        const { classes, items, updateDesiresSortIndex, wishlist } = this.props;
        return (
            <Container className={classes.root} maxWidth="lg">
                <DnDContainer
                    items={items}
                    updateSortIndex={(array) =>
                        updateDesiresSortIndex(wishlist.id, array)
                    }
                >
                    <DesireCard />
                </DnDContainer>
            </Container>
        );
    }
}

export default withDesires(injectIntl(withStyles(styles)(DesiresList)));
