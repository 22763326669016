import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import withPrimaryAppbar from '../../../../menu/appBar/primaryAppBar/components/hocs/PrimaryAppbar';
import ResponsiveFooter from '../../../../wishlist/main/components/ResponsiveFooter';
import ProfileEdit from '../../../edit/components/ProfileEdit';
import ProfileAppbar from '../appBar/ProfileAppbar';
import ProfileContent from '../content/ProfileContent';

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.main,
    },
    responsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(5),
        },
        marginTop: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
});

class ProfilePage extends Component {
    componentDidMount() {
        this.props.hidePrimaryAppBar();
    }

    componentWillUnmount() {
        this.props.showPrimaryAppBar();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <main className={classes.responsive}>
                    <ProfileAppbar />
                    <ProfileContent />
                    <ProfileEdit />
                </main>
                <ResponsiveFooter textColor="textPrimary" />
            </div>
        );
    }
}

export default withPrimaryAppbar(withStyles(styles)(ProfilePage));
