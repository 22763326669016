import {
    PROFILE_DELETION_RESET,
    PROFILE_DELETION_SHOULD_SHOW_DIALOG,
} from '../actions/ProfileDeletionActionTypes';

const profileDeletionReducer = (
    state = {
        shouldShowDialog: false,
        userMarkedForDeletion: null,
    },
    action
) => {
    switch (action.type) {
        case PROFILE_DELETION_SHOULD_SHOW_DIALOG:
            const { userMarkedForDeletion } = action;
            const { id } = userMarkedForDeletion;
            return {
                ...state,
                shouldShowDialog: true,
                userMarkedForDeletion: id,
            };
        case PROFILE_DELETION_RESET:
            return {
                ...state,
                shouldShowDialog: false,
                userMarkedForDeletion: null,
            };
        default:
            return state;
    }
};

export default profileDeletionReducer;
