import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { emphasize } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import withConsentBanner from './hocs/ConsentBanner';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            bottom: 90,
        },
        '& .MuiSnackbarContent-message': {
            [theme.breakpoints.down('xs')]: {
                fontSize: 15,
            },
        },
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0 10px',
    },
    button: {
        fontSize: 12,
    },
    snackbar: {
        maxWidth: '40vh',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
        },
    },
    accept: {
        marginRight: '20px',
        border: `1px solid ${theme.palette.common.accentColor}`,
        color: theme.palette.common.accentColor,
        '&:hover': {
            color: emphasize(theme.palette.secondary.main),
        },
    },
    decline: {
        color: 'white',
        marginRight: '7px',
    },
});

const SlideTransition = (props) => <Slide {...props} direction="up" />;

class ConsentBanner extends Component {
    state = {
        isAccepted:
            JSON.parse(localStorage.getItem('AnalyticsConsent')) ?? null,
    };
    componentDidMount() {
        const { initAnalytics } = this.props;
        const { isAccepted } = this.state;
        if (isAccepted) {
            initAnalytics();
        }
    }

    handleAcceptButtonClick = () => {
        const { accept } = this.props;
        this.setState({
            isAccepted: true,
        });
        accept();
    };

    handleDeclineButtonClick = () => {
        const { decline } = this.props;
        this.setState({
            isAccepted: false,
        });
        decline();
    };

    render() {
        const { classes } = this.props;
        const { isAccepted } = this.state;

        return (
            <Snackbar
                className={clsx(classes.root, classes.snackbar)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={isAccepted === null}
                autoHideDuration={null}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                TransitionComponent={SlideTransition}
                key={'AnalyticsConsentBanner'}
                message={
                    <span id="message-id" className={classes.message}>
                        <FormattedMessage id={'analytics.text'} />
                    </span>
                }
                action={[
                    <Button
                        key="accept"
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={this.handleAcceptButtonClick}
                        className={clsx(classes.button, classes.accept)}
                    >
                        <FormattedMessage id={'analytics.accept'} />
                    </Button>,
                    <Button
                        key="decline"
                        size="small"
                        onClick={this.handleDeclineButtonClick}
                        className={clsx(classes.button, classes.decline)}
                    >
                        <FormattedMessage id={'analytics.decline'} />
                    </Button>,
                ]}
            />
        );
    }
}

export default withConsentBanner(withStyles(styles)(ConsentBanner));
