import {
    AmazonCoUkAssociateId,
    AmazonUsAssociateId,
    AmazonItAssociateId,
    AmazonFrAssociateId,
    AmazonEsAssociateId,
    AmazonDeAssociateId,
    VigLinkAPIKey,
    SkimlinksId,
} from './AffiliateConfiguration';
//region private internal
//region Amazon
const AMAZON_URL_PATTERN = /^https?:\/\/(www.amazon.([^/]{2,}))\/([\S]+\/)*?(dp|gp\/product)\/(\w+\/)?(\w{10})(.*)$/;
const AMAZON_SHORTLINK_PATTERN = /^https:\/\/amzn\./;
const TOP_LEVEL_DOMAIN_GROUP = 2;
const MARKET_PLACE = 1;
const PartnerTagMap = Object.freeze({
    'co.uk': AmazonCoUkAssociateId,
    fr: AmazonFrAssociateId,
    es: AmazonEsAssociateId,
    it: AmazonItAssociateId,
    de: AmazonDeAssociateId,
    com: AmazonUsAssociateId,
});

const constructAmazonUrl = ({ origin, pathname, search }, partnerId) => {
    const parameters = new Map(
        search
            .substr(1)
            .split('&')
            .map((pair) => pair.split('='))
            .filter(([key]) => key)
    );
    parameters.set('tag', partnerId);
    return `${origin}${pathname}?${Array.from(parameters.entries())
        .map((entry) => entry.join('='))
        .join('&')}`;
};
//endregion

//region VigLink
const VIG_LINK_PATTERN = RegExp(
    '(alibaba\\.com|' +
        'alternate\\.(ch|nl)|' +
        'amazon\\.(co\\.jp|in)|' +
        'americanas\\.com\\.br|' +
        'argos\\.co\\.uk|' +
        'auchan\\.(fr|ru)|' +
        'bonprix\\.(de|com\\.tr|co\\.uk|fr|ru|ua)|' +
        'boostmobile\\.com|' +
        'boulanger\\.(fr|com)|' +
        'buscape\\.com\\.br|' +
        'casasbahia\\.com\\.br|' +
        'conrad\\.(at|biz|ch|com|fr|nl|pl|se)|' +
        'conrad-security\\.de|' +
        'currys\\.co\\.uk|' +
        'cyberstorethomann\\.co\\.uk|' +
        'dafiti\\.(cl|com\\.ar|com\\.co|com\\.br)|' +
        'dafitisports\\.com\\.br|' +
        'dawanda\\.com|' +
        'dicksmith\\.com\\.au|' +
        'dm\\.de|' +
        'douglas\\.de|' +
        'ebay\\.(com\\.au|in)|' +
        'eduscho\\.at|' +
        'esprit-online-shop\\.com|' +
        'esprit\\.cn|' +
        'extra\\.com\\.br|' +
        'firstcry\\.com|' +
        'hm\\.com|' +
        'hollisterco\\.com|' +
        'homedepot\\.ca|' +
        'idealo\\.de|' +
        'ikea\\.de|' +
        'jabong\\.com|' +
        'king-jouet\\.com|' +
        'koovs\\.com|' +
        'laredoute\\..*|' +
        'letyshops\\.ru|' +
        'lidl\\.de|' +
        'limeroad\\.com|' +
        'magazineluiza\\.com\\.br|' +
        'maisonsdumonde\\.com|' +
        'mediamarkt\\..*|' +
        'moebelix\\.at|' +
        'mrvoonik\\.com|' +
        'mvideo\\.ru|' +
        'myntra\\.com|' +
        'naketano\\.com|' +
        'newegg\\.com|' +
        'netshoes\\.com\\.(ar|br|mx)|' +
        'otto\\..*|' +
        'ottoversand\\.at|' +
        'ozon\\.ru|' +
        'pepperfry\\.com|' +
        'ponparemall\\.com|' +
        'pontofrio\\.com\\.br|' +
        'priceminister\\.(com|es)|' +
        'quelle\\..*|' +
        'qoo10\\.(com|jp|my)|' +
        'redcoon\\.(fr|pt|be|es|it|nl)|' +
        'rueducommerce\\.(com|fr)|' +
        'samsonite\\..*|' +
        'shop-list\\.com|' +
        'shopclues\\.com|' +
        'shoptime\\.com\\.br|' +
        'shworoomprive\\.(com|nl)|' +
        'snapdeal\\.com|' +
        'sportscheck\\.com|' +
        'submarino\\.com\\.br|' +
        'svyaznoy\\.ru|' +
        'tchibo\\.(de|ch|hu)|' +
        'theiconic\\..|' +
        'thesource\\.ca|' +
        'universal\\.at|' +
        'upc\\.(ch|pl)|' +
        'voonik\\.com|' +
        'vseinstrumenti\\.ru|' +
        'windeln\\.de|' +
        'xxxlutz\\.at|' +
        'zalando\\.(de|fr))'
);
const VIGLINK = `https://redirect.viglink.com/?key=${VigLinkAPIKey}&u=`;
const constructVigLink = (url) => `${VIGLINK}${encodeURI(url)}`;
//endregion

//region SkimLink
const SKIMLINK = `https://go.skimresources.com/?id=${SkimlinksId}&url=`;
const constructSkimLink = (url) => `${SKIMLINK}${encodeURI(url)}`;
//endregion
//endregion

/**
 * Pick up a matching affiliate network and construct a affiliated URL.
 *
 * Result is undefined if input is no valid URL.
 * @param {string} url
 * @returns {undefined|{affiliateUrl: string, originalUrl: string, network: string}}
 */
export const affiliate = (url) => {
    if (AMAZON_SHORTLINK_PATTERN.test(url)) {
        console.log("Gültiger Amazon Short-Link");
    } else {
        console.log("Ungültiger Amazon Short-Link");
    }

    try {
        const parsedUrl = new URL(url);
        const match = AMAZON_URL_PATTERN.exec(url);
        if (match !== null) {
            const partnerId = PartnerTagMap[match[TOP_LEVEL_DOMAIN_GROUP]];
            if (partnerId) {
                return {
                    originalUrl: url,
                    affiliateUrl: constructAmazonUrl(parsedUrl, partnerId),
                    network: match[MARKET_PLACE],
                };
            }
        }
        if (VIG_LINK_PATTERN.exec(url)) {
            return {
                originalUrl: url,
                affiliateUrl: constructVigLink(url),
                network: 'Vig',
            };
        } else {
            return {
                originalUrl: url,
                affiliateUrl: constructSkimLink(url),
                network: 'Skim',
            };
        }
    } catch (e) {
        console.warn(e);
        return { originalUrl: url };
    }
};

export const affiliateNetwork = (url) => {
    if (VIG_LINK_PATTERN.exec(url)) {
        return 'viglinks';
    } else if (!AMAZON_URL_PATTERN.exec(url)) {
        return 'skimlinks';
    } else {
        return 'not_available';
    }
};

export const shouldBeAffiliatedWithReferer = (url) =>
    AMAZON_URL_PATTERN.exec(url);
