import { Collapse } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NoteField from '../textFields/NoteField';

const styles = (theme) => ({
    container: {
        marginTop: '10px',
    },
    openNoteFieldText: {
        display: 'inline-block',
        cursor: 'pointer',
    },
});

class NoteGroup extends Component {
    state = { noteFieldIsOpen: false, autoFocus: true };

    componentDidMount() {
        /\S/.test(this.props.noteValue) &&
            this.setState({ noteFieldIsOpen: true, autoFocus: false });
    }
    render() {
        const { classes, noteValue, onNoteChange } = this.props;
        const { noteFieldIsOpen, autoFocus } = this.state;

        return (
            <div className={classes.container}>
                <Collapse in={!noteFieldIsOpen}>
                    <div
                        className={classes.openNoteFieldText}
                        onClick={() =>
                            this.setState({
                                noteFieldIsOpen: !noteFieldIsOpen,
                            })
                        }
                    >
                        <FormattedMessage id={'wish.createNoteText'} />
                    </div>
                </Collapse>
                <Collapse in={noteFieldIsOpen}>
                    {noteFieldIsOpen && (
                        <NoteField
                            id="wish.createNote"
                            className
                            value={noteValue}
                            autoFocus={autoFocus}
                            foreignClassName={classes.urlFieldMarginBottom}
                            onChange={({ target: { value } }) =>
                                onNoteChange(value)
                            }
                            onCancelClick={() => {
                                document.getElementById(
                                    'wish.createNote'
                                ).value = '';
                                onNoteChange('');

                                this.setState({
                                    noteFieldIsOpen: !noteFieldIsOpen,
                                });
                            }}
                        />
                    )}
                </Collapse>
            </div>
        );
    }
}

export default withStyles(styles)(NoteGroup);
