import AccessibilityIcon from '@mui/icons-material/Accessibility';
import FacebookIcon from '@mui/icons-material/Facebook';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HomeIcon from '@mui/icons-material/Home';
import PolicyIcon from '@mui/icons-material/Policy';
import ShareIcon from '@mui/icons-material/Share';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { emphasize } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import config from '../../config';
import {
    accept,
    decline,
} from '../../consent/stateManagement/actions/ConsentActions';
import withPrimaryAppbar from '../../menu/appBar/primaryAppBar/components/hocs/PrimaryAppbar';
import ShareLinkDialog from '../../wishlist/share/components/ShareLinkDialog';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        width: '40%',
        [theme.breakpoints.down('lg')]: {
            width: '50%',
        },
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    secondaryButton: {
        minWidth: '250px',
        textAlign: 'right',
        letterSpacing: '1px',
        border: '2px solid',
        backgroundColor: 'transparent',
        '&:hover': {
            border: '2px solid',
        },
        '&.MuiButtonBase-root': {
            color: theme.palette.common.darkBackground,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flex: '1',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    primaryButton: {
        minWidth: '250px',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.darkBackground,
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: emphasize(
                theme.palette.common.darkBackground,
                0.15
            ),
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flex: '1',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    listHeaderText: {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.appBarSubtitle,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: 'x-large',
        },
    },
    headerItem: {
        marginBottom: '5%',
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(7),
        },
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    version: {
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    facebookButton: {
        minWidth: '250px',
        color: theme.palette.common.white,
        backgroundColor: '#3B5998',
        padding: '7px',
        justifyContent: 'center',
        paddingLeft: '22px',
        letterSpacing: '1px',
        '&:hover': {
            backgroundColor: emphasize('#3B5998', 0.15),
        },
        [theme.breakpoints.down('xs')]: {
            flex: '1',
        },
    },
    facebookButtonIcon: {
        fontSize: '24px !important',
    },
    item: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
});

class AboutContent extends Component {
    state = {
        shareDialogIsOpen: false,
        consent: localStorage.getItem('AnalyticsConsent') === 'true',
    };
    getHeader = () => {
        const { classes } = this.props;
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemText className={classes.headerItem}>
                        <Typography
                            className={classes.listHeaderText}
                            variant="h4"
                            align="center"
                        >
                            <FormattedMessage id={'about.starsnoopy'} />
                        </Typography>
                        <div className={classes.version}>
                            <FormattedMessage
                                id={'about.version'}
                                values={{
                                    versionNumber: config.getVersion(),
                                }}
                            />
                        </div>
                    </ListItemText>
                </ListItem>
            </div>
        );
    };

    getFacebook = () => {
        const { classes } = this.props;
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemIcon className={classes.item}>
                        <FacebookIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.item}>
                        <FormattedMessage id={'about.facebook'} />
                    </ListItemText>
                    <Button
                        variant="contained"
                        className={classes.facebookButton}
                        startIcon={
                            <FacebookIcon
                                className={classes.faebookButtonIcon}
                            />
                        }
                        onClick={() =>
                            window.open(
                                'https://www.facebook.com/starsnoopy/',
                                '_blank'
                            )
                        }
                    >
                        <FormattedMessage id={'about.gotToFacebook'} />
                    </Button>
                </ListItem>
            </div>
        );
    };

    getTermsOfUse = () => {
        const { classes } = this.props;
        // missing depend on localizatin
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemIcon className={classes.item}>
                        <AccessibilityIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.item}>
                        <FormattedMessage id={'about.termsOfUse'} />
                    </ListItemText>
                    <Button
                        variant="outlined"
                        className={classes.secondaryButton}
                        onClick={() => this.redirectToTerms()}
                    >
                        <FormattedMessage id={'about.gotoTermsOfUse'} />
                    </Button>
                </ListItem>
            </div>
        );
    };

    redirectToTerms = () => {
        const { localization } = this.props;
        const flag = localization?.flag ?? 'en';
        const faqSite =
            flag === 'de'
                ? 'https://starsnoopy.de/nutzungsbedingungen'
                : 'https://starsnoopy.com/terms-of-use';
        const win = window.open(faqSite, '_blank');
        win.focus();
    };

    getTracking = () => {
        const { classes } = this.props;
        const { consent } = this.state;
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemIcon className={classes.item}>
                        <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <FormattedMessage id={'about.tracking'} />
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Switch
                            checked={consent ?? false}
                            onChange={this.handleConsentChange}
                            name="Analytics"
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        );
    };

    handleConsentChange = () => {
        const { accept, decline } = this.props;
        const { consent } = this.state;
        consent ? decline() : accept();
        this.setState({
            ...this.state,
            consent: !consent,
        });
    };

    getStarSnoopyPage = () => {
        const { classes } = this.props;
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemIcon className={classes.item}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.item}>
                        <FormattedMessage id={'about.starSnoopyHomepage'} />
                    </ListItemText>
                    <Button
                        variant="outlined"
                        className={classes.secondaryButton}
                        onClick={() => this.redirectToStarsnoopy()}
                    >
                        <FormattedMessage id={'about.gotToStarSnoopy'} />
                    </Button>
                </ListItem>
            </div>
        );
    };

    redirectToStarsnoopy = () => {
        const { localization } = this.props;
        const flag = localization?.flag ?? 'en';
        const faqSite =
            flag === 'de'
                ? 'https://starsnoopy.de/'
                : 'https://starsnoopy.com/';
        const win = window.open(faqSite, '_blank');
        win.focus();
    };

    getFeedback = () => {
        const { classes } = this.props;
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemIcon className={classes.item}>
                        <FeedbackIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.item}>
                        <FormattedMessage id={'about.feedback'} />
                    </ListItemText>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        href="mailto:feedback@starsnoopy.de"
                    >
                        <FormattedMessage id={'about.sendFeedback'} />
                    </Button>
                </ListItem>
            </div>
        );
    };

    getShare = () => {
        const { classes } = this.props;
        return (
            <div>
                <ListItem className={classes.flexContainer}>
                    <ListItemIcon className={classes.item}>
                        <ShareIcon />
                    </ListItemIcon>
                    <ListItemText className={classes.item}>
                        <FormattedMessage id={'about.share'} />
                    </ListItemText>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        onClick={() =>
                            this.setState({
                                ...this.state,
                                shareDialogIsOpen: true,
                            })
                        }
                    >
                        <FormattedMessage id={'about.share'} />
                    </Button>
                </ListItem>
            </div>
        );
    };

    handleItemShareClose = () => {
        this.setState({
            ...this.state,
            shareDialogIsOpen: false,
        });
    };

    render() {
        const { classes } = this.props;
        const { shareDialogIsOpen } = this.state;
        return (
            <div className={classes.root}>
                <ShareLinkDialog
                    link={window.location.host}
                    open={shareDialogIsOpen}
                    onClose={this.handleItemShareClose}
                />
                <div className={classes.flex}>
                    <List className={classes.list}>
                        {this.getHeader()}
                        {this.getShare()}
                        {this.getStarSnoopyPage()}
                        {this.getFacebook()}
                        {this.getTermsOfUse()}
                        {this.getFeedback()}
                        {this.getTracking()}
                    </List>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        analyticsConsent: state.analyticsConsent,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        accept: () => dispatch(accept()),
        decline: () => dispatch(decline()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPrimaryAppbar(withStyles(styles)(AboutContent)));
