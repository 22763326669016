import IC_LIST from './resources/menuIcons/ic_list.png';
import IC_SHARE from './resources/menuIcons/ic_share.png';

const menuItems = [
    {
        titleMessageId: 'nav.my-wishlist',
        icon: IC_LIST,
        iconHint: 'icon for wishlist',
        path: '/wishlist',
    },
    {
        titleMessageId: 'nav.shared-wishlist',
        icon: IC_SHARE,
        iconHint: 'icon for shared wishlist',
        path: '/friendswishlist',
    },
];

export default menuItems;
