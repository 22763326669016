import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { LockOpen, Visibility, VisibilityOff } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import StyledTextField from './base/StyledTextField';

const styles = (theme) => ({
    input: {
        margin: theme.spacing(0, 0, 0, 0),
        padding: theme.spacing(0, 0, 0, 0),
    },
});

class PrimaryPasswordField extends Component {
    state = {
        showPassword: false,
    };

    onShowPasswordClick = () =>
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword,
        });

    render() {
        const { classes, onChange, intl } = this.props;
        const { showPassword } = this.state;

        return (
            <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder={intl.formatMessage({ id: 'auth.password' })}
                className={classes.input}
                autoComplete="current-password"
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockOpen />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <IconButton onClick={this.onShowPasswordClick} size="large">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ),
                }}
                inputProps={{ maxLength: 200 }}
            />
        );
    }
}

export default injectIntl(withStyles(styles)(PrimaryPasswordField));
