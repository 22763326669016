import React, { Component } from 'react';

class IconWrapper extends Component {
    render() {
        const styles = {
            img: {
                width: '20px',
                height: '20px',
            },
        };
        return (
            <img
                style={styles.img}
                src={this.props.icon}
                alt={this.props.alt}
            />
        );
    }
}

export default IconWrapper;
