import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles/TextFieldStyles';

class NoteField extends Component {
    render() {
        const {
            classes,
            id,
            defaultValue,
            onChange,
            value,
            inputRef,
            autoFocus,
            foreignClassName,
            onCancelClick,
        } = this.props;

        return (
            <TextField
                id={id}
                inputRef={inputRef}
                autoFocus={autoFocus}
                fullWidth
                className={clsx(foreignClassName, classes.input)}
                label={<FormattedMessage id={id} />}
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
                inputProps={{ maxLength: 2000 }}
                onKeyPress={(ev) => ev.key === 'Enter' && ev.preventDefault()}
                InputLabelProps={{ shrink: !!value }}
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <Tooltip
                            title={<FormattedMessage id={'wish.removeNote'} />}
                            enterDelay={300}
                        >
                            <IconButton
                                className={classes.endAdornmentIcon}
                                onClick={onCancelClick}
                                size="large"
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    ),
                }}
            />
        );
    }
}

export default withStyles(styles)(NoteField);
