import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import SharedWishlistCard from '../card/SharedWishlistCard';

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4),
        },
        paddingBottom: theme.spacing(4),
    },
});

class SharedWishListList extends Component {
    state = {
        markedAsRemoved: null,
    };

    handleMarkAsRemoved = (item) => this.setState({ markedAsRemoved: item });

    handleRemove = (item) => this.props.onItemRemove(item);

    renderItem(key, item) {
        const { owners, onItemClick, selectedItem } = this.props;
        const { markedAsRemoved } = this.state;
        const willbeRemoved = markedAsRemoved === item;
        const profile = owners.get(item.ownerId);
        return (
            <Fade
                key={key}
                timeout={500}
                in={!willbeRemoved}
                onExited={() => this.handleRemove(item)}
            >
                <Grid style={{ minWidth: '314px' }} item xs={12} sm={6} md={4}>
                    <SharedWishlistCard
                        profile={profile}
                        item={item}
                        isSelected={item.id === selectedItem?.id ?? null}
                        onRemoveClick={() => this.handleMarkAsRemoved(item)}
                        onItemClick={onItemClick}
                    />
                </Grid>
            </Fade>
        );
    }

    render() {
        const { classes, items } = this.props;
        return (
            <Container className={classes.root} maxWidth="lg">
                <Grid container spacing={4} justifyContent="center">
                    {items.map((item, key) => this.renderItem(key, item))}
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(SharedWishListList);
