export class DynamicLinkCreator {
    createInfoObjectFromParameters(dynamicLinkParameter) {
        return {
            dynamicLinkInfo: {
                domainUriPrefix: dynamicLinkParameter.domainUriPrefix,
                link: dynamicLinkParameter.link,
                androidInfo: {
                    androidPackageName: dynamicLinkParameter.androidPackageName,
                },
                iosInfo: {
                    iosBundleId: dynamicLinkParameter.iosBundleId,
                },
                socialMetaTagInfo: {
                    socialTitle: dynamicLinkParameter.socialTitle,
                    socialDescription: dynamicLinkParameter.socialDescription,
                    socialImageLink: dynamicLinkParameter.socialImageLink, // at least 300x200 px, and less than 300 KB
                },
                /*
                utm_source: dynamicLinkParameter.utmSource,
                utm_medium: dynamicLinkParameter.utmMedium,
                utm_content: dynamicLinkParameter.utmContent,
                 */
            },
        };
    }
}
