import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import DrawerListItem from './DrawerListItem';
import ProfileCard from '../../../profile/main/components/card/ProfileCard';
import MenuTabSliderItems from '../../model/MenuItems';

const styles = (theme) => ({
    toolbar: theme.mixins.toolbar,
    paper: {
        flexDirection: 'column',
    },
    list: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
});

class DrawerContent extends Component {
    renderMenuItem(item) {
        const { selectedMenuItem, onMenuItemClick } = this.props;
        const isActive = selectedMenuItem === item;
        return (
            <DrawerListItem
                active={isActive}
                item={item}
                key={item.titleMessageId}
                onClick={() => onMenuItemClick(item)}
            />
        );
    }

    render() {
        const { classes, profile, onProfileCardClick } = this.props;
        return (
            <div>
                <div className={classes.toolbar} />
                <div className={classes.paper}>
                    <ProfileCard profile={profile} onActionAreaClick={onProfileCardClick} />
                </div>
                <List className={classes.list}>{MenuTabSliderItems.map((item) => this.renderMenuItem(item))}</List>
            </div>
        );
    }
}

export default withStyles(styles)(DrawerContent);
