import { Fade, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import withPrimaryAppbar from '../../../menu/appBar/primaryAppBar/components/hocs/PrimaryAppbar';
import SharedWith from '../../../mixins/components/SharedWith';
import { undoActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import CreateButton from '../../../wishlist/main/components/button/CreateButton';
import ResponsiveFooter from '../../../wishlist/main/components/ResponsiveFooter';
import ResponsiveAppbar from '../appBar/responsiveWrapper/ResponsiveAppbar';
import AutoPasteWrapper from '../dialog/create/AutoPasteWrapper';
import CreateDesireDialog from '../dialog/create/CreateDesireDialog';
import EditDesireDialog from '../dialog/edit/EditDesireDialog';
import MoveDesireDialog from '../dialog/move/MoveDesireDialog';
import withDesires from '../hocs/Desires';
import DesiresList from '../list/DesiresList';
import EmptyList from '../list/EmptyList';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.main,
    },
    responsive: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(0),
        },
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
    },
    noteDiv: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '70%',
        marginBottom: '5vh',
        wordBreak: 'break-all',
        fontFamily: theme.typography.appBarSubtitle,
        color: theme.palette.primary.main,
        flexDirection: 'column',
    },
    loadingAnimation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spacer: {
        minHeight: '15vh',
        [theme.breakpoints.down('xs')]: {
            minHeight: '10vh',
        },
    },
    createButton: {
        position: 'fixed',
        bottom: theme.spacing(6),
        right: theme.spacing(8),
        cursor: 'pointer',
        zIndex: 1,
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(2),
            right: theme.spacing(3),
        },
    },
    deletUndoSnackbarMessageDiv: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '2px',
    },
    deleteUndoSnackbarMessage: {
        maxWidth: '300px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

class DesiresPage extends Component {
    componentDidMount() {
        const { match, registerDesireObserver, history, wishlistId } =
            this.props;
        registerDesireObserver(wishlistId || match.params.id, this.handleReload)
            .then(() => {
                this.handleReload();
            })
            .catch(() => history.push('/wishlist'));
        this.handleReload();
    }

    componentWillUnmount() {
        const {
            match,
            unregisterDesireObserver,
            showPrimaryAppBar,
            history,
            wishlistId,
        } = this.props;
        unregisterDesireObserver(
            wishlistId || match.params.id,
            this.handleReload
        )
            .then(() => showPrimaryAppBar())
            .catch(() => history.push('/wishlist'));
    }

    handleReload = () => {
        const { match, wishlistId, getAllDesires } = this.props;
        getAllDesires(wishlistId || match.params.id);
    };

    isNoteValidForDisplay = (note) =>
        note && note.replace(/\s/g, 'X').length > 0;

    handleItemRestore = (item) => {
        this.props.restoreWishlist(item);
    };

    getUndoDeleteSnackbarMessage({ title }, ownerName) {
        return (
            <div>
                <span id="message-id">
                    <FormattedMessage id={'wishlist.wishlist'} />
                </span>
                <span>&nbsp;'{title}'&nbsp;</span>
                <span>
                    <FormattedMessage id={'share.of'} />
                </span>
                <span>&nbsp;{ownerName}&nbsp;</span>
                <span>
                    <FormattedMessage id={'share.left'} />
                </span>
            </div>
        );
    }

    getNote = () => {
        const { classes, wishlist } = this.props;

        if (this.isNoteValidForDisplay(wishlist?.note ?? '')) {
            return (
                <div className={classes.noteDiv}>
                    <Typography variant="h5">{wishlist.note}</Typography>
                </div>
            );
        }
    };

    loadingAnimation = () => {
        const { classes } = this.props;
        return (
            <div className={classes.loadingAnimation}>
                <CircularProgress disableShrink />
            </div>
        );
    };

    renderList = () => {
        const { classes, isLoading, desires, sharedWith } = this.props;

        if (isLoading) {
            return this.loadingAnimation();
        } else if (desires.length > 0) {
            return (
                <div className={classes.content}>
                    {sharedWith.length > 0 && (
                        <SharedWith
                            className={classes.sharedWith}
                            sharedWith={sharedWith}
                            isEmpty={false}
                        />
                    )}
                    {this.getNote()}
                    <DesiresList
                        items={desires}
                        reloadPage={this.handleReload}
                    />
                </div>
            );
        } else {
            return (
                <div className={classes.content}>
                    {sharedWith.length > 0 && (
                        <SharedWith
                            className={classes.sharedWith}
                            sharedWith={sharedWith}
                            isEmpty={true}
                        />
                    )}
                    {this.getNote()}
                    <EmptyList />
                </div>
            );
        }
    };

    handleWishlistRemove = () => {
        const { removeWishlist, wishlist, enqueueSnackbar, closeSnackbar } =
            this.props;
        removeWishlist(wishlist).then(() =>
            enqueueSnackbar(this.getUndoDeleteSnackbarMessage(), {
                variant: 'info',
                action: (key) =>
                    undoActionSnackbarButton(
                        () => this.handleWishlistRestore(wishlist),
                        () => closeSnackbar(key)
                    ),
            })
        );
    };

    handleWishlistRestore = (wishlist) => {
        this.props.restoreWishlist(wishlist);
    };

    getUndoDeleteSnackbarMessage = () => {
        const {
            wishlist: { title },
        } = this.props;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '2px',
                }}
            >
                <FormattedMessage id={'wishlist.wishlist'} />
                <span>{"'"}</span>
                <div
                    style={{
                        maxWidth: '10rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {title}
                </div>
                <span>{"'"}</span>
                <FormattedMessage id={'wishlist.deleted'} />
            </div>
        );
    };

    render() {
        const {
            classes,
            wishlist,
            desires,
            isLoading,
            disableAppbar,
            disableFooter,
            openCreateDialog,
        } = this.props;
        return (
            <div>
                {wishlist && !disableAppbar && (
                    <ResponsiveAppbar
                        title={wishlist.title}
                        wishlist={wishlist}
                        removeWishlistAction={() => this.handleWishlistRemove()}
                    />
                )}
                <div className={classes.root}>
                    {!isLoading && <div className={classes.spacer} />}
                    <main className={classes.responsive}>
                        {this.renderList()}
                        {desires.length > 0 && (
                            <Fade timeout={500} in={true}>
                                <div className={classes.createButton}>
                                    <CreateButton onClick={openCreateDialog} />
                                </div>
                            </Fade>
                        )}
                    </main>
                    {!disableFooter && (
                        <ResponsiveFooter textColor="textPrimary" />
                    )}
                </div>
                {wishlist && (
                    <div>
                        <EditDesireDialog
                            wishlistId={wishlist.id}
                            desires={desires}
                        />
                        <AutoPasteWrapper>
                            <CreateDesireDialog wishlistId={wishlist.id} />
                        </AutoPasteWrapper>
                        <MoveDesireDialog wishlistId={wishlist.id} />
                    </div>
                )}
            </div>
        );
    }
}

export default withSnackbar(
    withRouter(withDesires(withPrimaryAppbar(withStyles(styles)(DesiresPage))))
);
