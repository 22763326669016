import { jsPDF } from 'jspdf';
import starsnoopyLogo from '../../../authentication/components/logo/resources/starsnoopyLogo.png';
import background from '../../../resources/Background/background_noise.jpg';
import { meriendaBase64 } from './MeriendaBase64.js';

const imageCompression = 'MEDIUM';
const imageFormat = 'PNG';

export const createPdf = (wishlistName = 'StarSnoopy', link) =>
    new Promise(async (resolve, reject) => {
        try {
            const doc = new jsPDF({ compress: true });

            doc.addFileToVFS('Merienda-Regular.ttf', meriendaBase64);
            doc.addFont('Merienda-Regular.ttf', 'merienda', 'normal');

            doc.setFont('merienda');
            doc.setFontSize(30);
            doc.setTextColor('#FBFAE9');

            const backgroundDataUrl = await imgToBase64(background);
            doc.addImage(
                backgroundDataUrl,
                imageFormat,
                0,
                0,
                doc.internal.pageSize.width,
                doc.internal.pageSize.height,
                '',
                imageCompression
            );

            const logoDataURL = await imgToBase64(starsnoopyLogo);
            doc.addImage(
                logoDataURL,
                imageFormat,
                xOffset(150, doc),
                10,
                150,
                45,
                '',
                imageCompression
            );

            const wishlistNameWidth = getTextWidth(wishlistName, doc);
            doc.text(wishlistName, xOffset(wishlistNameWidth, doc), 80);

            const qrCodeCanvas = document.getElementById('shareLinkQrCode');
            doc.addImage(
                qrCodeCanvas,
                imageFormat,
                xOffset(doc.internal.pageSize.getWidth() - 50, doc),
                95,
                doc.internal.pageSize.getWidth() - 50,
                doc.internal.pageSize.getWidth() - 50,
                '',
                imageCompression
            );

            doc.setFontSize(20);

            const linkWidth = getTextWidth(link, doc);
            doc.text(
                link,
                xOffset(linkWidth, doc),
                doc.internal.pageSize.getHeight() - 20
            );

            doc.setDocumentProperties({
                author: 'app.starsnoopy.de',
                creator: 'app.starsnoopy.de',
            });

            resolve(doc.save(`StarSnoopy_invite_${new Date().getTime()}.pdf`));
        } catch (e) {
            reject(e);
        }
    });

const xOffset = (elementWidth, doc) => {
    return (doc.internal.pageSize.width - elementWidth) / 2;
};

const getTextWidth = (text, doc) => {
    return (
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor
    );
};

const imgToBase64 = async (imageSrc) => {
    const img = new Image();
    img.src = imageSrc;

    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    const logoCanvas = document.createElement('canvas');
    logoCanvas.width = img.width;
    logoCanvas.height = img.height;
    const logoContext = logoCanvas.getContext('2d');
    logoContext.drawImage(img, 0, 0);
    return logoCanvas.toDataURL();
};
