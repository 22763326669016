import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Collapse, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import { withStyles, withTheme } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../../config';
import DnDImageUpload from '../dragAndDrop/DnDropImageUpload';

const styles = (theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
        marginTop: theme.spacing(3),
    },
    rootMobile: {
        maxWidth: '100%',
        flexGrow: 1,
        marginTop: theme.spacing(3),
    },
    img: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    imgMobile: {
        objectFit: 'contain',
        width: '100%',
        maxHeight: '45vh',
    },
    footer: {
        background: '#FBFAE900',
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        '& .MuiMobileStepper-dot': {
            width: 7,
            height: 7,
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.common.accentColor,
        },
    },
    footerMobile: {
        background: '#FBFAE900',
        paddingBottom: theme.spacing(0),
        marginLeft: '-8px',
        marginRight: '-8px',
        '& .MuiMobileStepper-dot': {
            width: 7,
            height: 7,
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: theme.palette.common.accentColor,
        },
    },
    view: {
        height: 255,
        width: 400,
    },
    viewMobile: {
        display: 'contents',
    },
    uploadDiv: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.primary.contrastText,
        'text-align': 'center',
    },
    uploadIcon: {
        width: '50%',
        height: '50%',
    },
    buttonColor: { color: theme.palette.primary.contrastText },
    uploadButtonDiv: {
        marginTop: '10px',
        marginRight: '24px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        '& .MuiButton-root': {
            color: 'gray',
        },
    },
});

class Stepper extends Component {
    upload = (file) => {
        const reader = new FileReader();

        reader.onload = () => {
            const { result } = reader;
            /data:image\/(png|jpe?g);base64/.test(result) &&
                this.props.addPictureToState(result);
        };

        if (file) {
            /\.(jpe?g|png)$/i.test(file.name) && reader.readAsDataURL(file);
        } else {
            const uploadComponent = document.getElementById('uploadFile');

            function handleFiles() {
                const [file] = this.files;
                /\.(jpe?g|png)$/i.test(file.name) && reader.readAsDataURL(file);
            }

            uploadComponent.addEventListener('change', handleFiles);

            uploadComponent.click();
        }
    };

    render() {
        const {
            classes,
            theme,
            fullScreen,
            images,
            activeStep,
            onNext,
            onBack,
            jumpTo,
        } = this.props;

        const isCustomUploadAllowed = config.isCustomUploadAllowed();

        const maxSteps = isCustomUploadAllowed
            ? images.length + 1
            : images.length;

        return (
            <div className={fullScreen ? classes.rootMobile : classes.root}>
                <Grid
                    container
                    className={fullScreen ? classes.viewMobile : classes.view}
                >
                    <DnDImageUpload
                        handleDrop={this.upload}
                        allowUpload={isCustomUploadAllowed}
                    >
                        {activeStep + 1 === maxSteps &&
                        isCustomUploadAllowed ? (
                            <div
                                onClick={() => this.upload()}
                                className={classes.uploadDiv}
                            >
                                <CloudUploadIcon
                                    className={classes.uploadIcon}
                                />
                                <Typography variant="h6">
                                    <FormattedMessage id="wish.uploadImage" />
                                </Typography>
                                <input
                                    type="file"
                                    id="uploadFile"
                                    accept="image/png, image/jpeg"
                                    style={{ display: 'none' }}
                                />
                            </div>
                        ) : (
                            <img
                                className={
                                    fullScreen ? classes.imgMobile : classes.img
                                }
                                src={images[activeStep]}
                                alt={'wish'}
                            />
                        )}
                    </DnDImageUpload>
                </Grid>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant={fullScreen || maxSteps > 5 ? 'text' : 'dots'}
                    className={
                        fullScreen ? classes.footerMobile : classes.footer
                    }
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={onNext}
                            disabled={activeStep === maxSteps - 1}
                            className={classes.buttonColor}
                        >
                            <FormattedMessage id={'wishlist.next'} />
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={onBack}
                            disabled={activeStep === 0}
                            className={classes.buttonColor}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            <FormattedMessage id={'wishlist.back'} />
                        </Button>
                    }
                />
                <Collapse
                    in={isCustomUploadAllowed && activeStep !== maxSteps - 1}
                >
                    <div className={classes.uploadButtonDiv}>
                        <Button
                            variant="text"
                            color="secondary"
                            className={classes.submit}
                            onClick={() => {
                                jumpTo(maxSteps - 1 - activeStep);
                            }}
                        >
                            <FormattedMessage id="wishlist.updateOwnImageQuestion" />
                        </Button>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default withStyles(styles)(withTheme(Stepper));
