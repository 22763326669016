import {
    PROFILE_DELETION_ERROR,
    PROFILE_DELETION_REQUEST,
    PROFILE_DELETION_RESET,
    PROFILE_DELETION_SHOULD_SHOW_DIALOG,
    PROFILE_DELETION_SUCCESS,
} from './ProfileDeletionActionTypes';
import AuthenticationService from '../../../../authentication/services/AuthenticationService';
import { logout } from '../../../../authentication/statemanagement/actions/AuthenticationActions';

export const applyShowDialog = (userMarkedForDeletion) => {
    return {
        type: PROFILE_DELETION_SHOULD_SHOW_DIALOG,
        userMarkedForDeletion,
    };
};

export const reset = () => {
    return {
        type: PROFILE_DELETION_RESET,
    };
};

export const remove = () => (dispatch) => {
    dispatch({
        type: PROFILE_DELETION_REQUEST,
    });
    return AuthenticationService.remove()
        .then((profile) => {
            dispatch({
                type: PROFILE_DELETION_SUCCESS,
                profile,
            });
            return dispatch(logout());
        })
        .catch((error) => {
            dispatch({
                type: PROFILE_DELETION_ERROR,
                error,
            });
        });
};
