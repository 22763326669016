import { firebaseAnalytics } from '../../networking/firebase/FirebaseConfig';
import {
    affiliateNetwork,
    shouldBeAffiliatedWithReferer,
} from '../../service/AffiliateService';

class AnalyticsService {
    constructor() {
        this.consent = !!localStorage.getItem('AnalyticsConsent');
    }

    notEmptyFn = (str) => /^\s*$/.test(str);

    logToAnalytics = (eventName, parameter) => {
        this.consent && firebaseAnalytics.logEvent(eventName, parameter);
    };

    // --- general events

    login = (method) => {
        this.logToAnalytics('login', { method });
    };

    sendEmailVerificationSuccess = () => {
        this.logToAnalytics('send_email_verification_success');
    };

    sendEmailVerificationError = () => {
        this.logToAnalytics('send_email_verification_error');
    };

    // freunde einladen? ist das für uns überhaupt notwendig?
    // inviteFriends = () => {
    //     this.logToAnalytics('invite_friends', {value = 1})
    // }

    // --- wish list events

    createWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('create_wishlist', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    viewWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('view_item_list', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    deleteWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('delete_wishlist', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    restoreWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('restore_wishlist', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    shareWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('share', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    joinWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('join_wishlist', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    leaveWishlist = (item_id, item_category, content_type) => {
        this.logToAnalytics('leave_wishlist', {
            item_id: item_id.slice(0, 36),
            item_category,
            content_type,
        });
    };

    // --- wish events

    addToWishlist = (
        item_id,
        item_location_id,
        content_type,
        item_category,
        origin
    ) => {
        this.logToAnalytics('add_to_wishlist', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
            item_category,
            origin,
        });
    };

    deleteWish = (item_id, item_location_id, content_type) => {
        this.logToAnalytics('delete_wish', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
        });
    };

    restoreWish = (item_id, item_location_id, content_type) => {
        this.logToAnalytics('restore_wish', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
        });
    };

    viewWish = (item_id, url, content_type, destination) => {
        this.logToAnalytics('view_item', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(url) ? new URL(url).hostname : '',
            content_type,
            destination,
            affiliate_network: affiliateNetwork(url),
            affiliated: shouldBeAffiliatedWithReferer(url),
        });
    };

    reserveWish = (item_id, item_location_id, content_type) => {
        this.logToAnalytics('reserve_wish', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
        });
    };

    releaseWish = (item_id, item_location_id, content_type) => {
        this.logToAnalytics('release_wish', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
        });
    };

    markAsBought = (item_id, item_location_id, content_type, value = 1) => {
        this.logToAnalytics('ecommerce_purchase', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
            value,
        });
    };

    removeMarkAsBought = (
        item_id,
        item_location_id,
        content_type,
        value = 1
    ) => {
        this.logToAnalytics('purchase_refund', {
            item_id: item_id.slice(0, 36),
            item_location_id: this.notEmptyFn(item_location_id)
                ? new URL(item_location_id).hostname
                : '',
            content_type,
            value,
        });
    };
}

export default new AnalyticsService();
