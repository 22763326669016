import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../../authentication/statemanagement/actions/AuthenticationActions';
import { changeLocalization } from '../../../../localization/stateManagment/actions/LocalizationActions';
import {
    hide,
    show,
} from '../../../drawer/stateManagement/actions/DrawerActions';
import MenuItems from '../../../model/MenuItems';
import MenuTabSlider from './menuTabSlider/MenuTabSlider';
import PrimaryAppBarMenu from './PrimaryAppBarMenu';

const styles = (theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    xsAppbarTitle: {
        fontFamily: theme.typography.appBarSubtitle,
        color: theme.palette.text.primary,
        'font-size': '1.25em',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
});

class PrimaryAppbar extends Component {
    state = {
        menuAnchorElement: null,
    };

    getSelectedMenuItem = () => {
        const { location } = this.props;
        let result = MenuItems.filter(
            (item) => item.path === location.pathname
        );
        if (result.length === 0) {
            return null;
        }
        return result[0];
    };

    handleToolbarButtonClick = (event) => {
        const { isDrawerVisible, showDrawer, hideDrawer } = this.props;
        if (isDrawerVisible) {
            hideDrawer();
        } else {
            showDrawer();
        }
    };

    handleMenuClick = (event) => {
        this.setState({ menuAnchorElement: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ menuAnchorElement: null });
    };

    handleLogout = () => {
        const { logout } = this.props;
        logout();
    };

    handleLocalizationChange = (locale) => {
        const { changeLocalization } = this.props;
        changeLocalization(locale);
    };

    render() {
        const { classes, localization } = this.props;
        const { menuAnchorElement } = this.state;
        const selectedMenuItem = this.getSelectedMenuItem();
        if (selectedMenuItem === null) {
            return null;
        }
        return (
            <AppBar>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleToolbarButtonClick}
                        className={classes.menuButton}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <MenuTabSlider selectedItem={selectedMenuItem} />
                    <div className={classes.xsAppbarTitle}>
                        <FormattedMessage
                            id={selectedMenuItem?.titleMessageId ?? ' '}
                        />
                    </div>
                    <div className={classes.toolbarButtons}>
                        <Tooltip
                            title={
                                <FormattedMessage id={'wishlist.settings'} />
                            }
                            aria-label="settings"
                            enterDelay={300}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open settings"
                                aria-controls="settings-menu"
                                aria-haspopup="true"
                                onClick={this.handleMenuClick}
                                size="large">
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
                <PrimaryAppBarMenu
                    localization={localization}
                    anchorEl={menuAnchorElement}
                    onClose={this.handleMenuClose}
                    onLogout={this.handleLogout}
                    onLocalizationChange={this.handleLocalizationChange}
                />
            </AppBar>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showDrawer: () => dispatch(show()),
        hideDrawer: () => dispatch(hide()),
        logout: () => dispatch(logout()),
        changeLocalization: (locale) => dispatch(changeLocalization(locale)),
    };
};

const mapStateToProps = (state) => {
    return {
        localization: state.localization,
        isDrawerVisible: state.drawer.isVisible,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(PrimaryAppbar)));
