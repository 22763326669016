import { isFinite } from 'lodash';

class DesireComperator {
    compare = (wishlistA, wishlistB) => {
        if (isFinite(wishlistA.sortIndex) && isFinite(wishlistB.sortIndex)) {
            if (wishlistA.sortIndex === wishlistB.sortIndex) {
                return wishlistB.created - wishlistA.created;
            } else {
                return wishlistB.sortIndex - wishlistA.sortIndex;
            }
        } else {
            return wishlistB.created - wishlistA.created;
        }
    };
}

export default new DesireComperator();
