import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { closeActionSnackbarButton } from './CustomSnackbarProvider';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import storeDe from '../resources/PlayStoreIcons/google-play-badge-de.png';
import storeEn from '../resources/PlayStoreIcons/google-play-badge-en.png';
import storeFr from '../resources/PlayStoreIcons/google-play-badge-fr.png';
import { loadLocalization } from '../localization/service/LocalizationService';
import PushConsent from '../notification/component/PushConsent';

const handleClickedPlayStoreAd = () => {
    localStorage.setItem('sawPlayStoreAd', 1);
    window.location.href =
        'https://play.google.com/store/apps/details?id=de.xroot.wishlist';
};

const storeButton = (closeSnackbar) => {
    const classes = makeStyles({
        playStore: {
            maxHeight: '70px',
            cursor: 'pointer',
        },
    })();

    return (
        <Fragment>
            <img
                className={classes.playStore}
                src={selectPlayStoreImage()}
                alt={'Google Play Store'}
                onClick={() => {
                    handleClickedPlayStoreAd();
                    closeSnackbar();
                }}
            />
        </Fragment>
    );
};

const selectPlayStoreImage = () => {
    const { flag } = loadLocalization();
    switch (flag) {
        case 'de':
            return storeDe;
        case 'fr':
            return storeFr;
        default:
            return storeEn;
    }
};

class Notifications extends Component {
    componentDidMount() {
        navigator.userAgent.toLowerCase().indexOf('android') > -1 &&
            this.showPlayStoreAd();
    }

    showPlayStoreAd = () => {
        const { enqueueSnackbar, closeSnackbar } = this.props;

        !localStorage.getItem('sawPlayStoreAd') &&
            enqueueSnackbar(
                <Box onClick={handleClickedPlayStoreAd}>
                    <FormattedMessage id={'app.playStore'} />
                </Box>,
                {
                    variant: 'info',
                    action: (key) => (
                        <Fragment>
                            {storeButton(() => closeSnackbar(key))}
                            {closeActionSnackbarButton(() => {
                                localStorage.setItem('sawPlayStoreAd', 1);
                                closeSnackbar(key);
                            })}
                        </Fragment>
                    ),
                }
            );
    };

    render() {
        return <PushConsent>{this.props.children}</PushConsent>;
    }
}

export default withSnackbar(Notifications);
