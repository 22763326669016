import AssignmentIcon from '@mui/icons-material/Assignment';
import ErrorIcon from '@mui/icons-material/Error';
import { Button, IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import withTheme from '@mui/styles/withTheme';
import { Box } from '@mui/system';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import validator from 'validator';
import PrimaryButton from '../../../../mixins/components/buttons/PrimaryButton';
import DialogDivider from '../../../../mixins/components/divider/DialogDivider';
import withResponsiveInformation from '../../../../mixins/components/ResponsiveInformation';
import { firebaseFunctions } from '../../../../networking/firebase/FirebaseConfig';
import DesireCreationModel from '../../../model/DesireCreationModel';
import {
    closeCreateDialog,
    createDesire,
} from '../../../stateManagement/actions/DesireActions';
import CancelButton from '../CancelButton';
import Stepper from '../Stepper';
import UrlField from '../textFields/UrlField';
import WishField from '../textFields/WishField';
import NoteGroup from './NoteGroup';

const SlideTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GrowTransition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const styles = (theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttons: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    buttonProgress: {
        color: theme.palette.common.accentColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
    title: {
        color: theme.palette.primary.contrastText,
        fontFamily: "'Merienda One' !important",
        fontSize: '1.25rem',
        '& .MuiDialogTitle-root': {
            paddingBottom: '0 !important',
        },
        marginTop: theme.spacing(1),
    },
    errorIcon: {
        color: '#cc5c5cba',
        fontSize: '20px',
        marginRight: '8px',
    },
    errorMessage: {
        color: '#cc5c5cba',
    },
    urlFieldMarginBottom: {
        marginBottom: '3px',
    },
    marginTopWishFieldOnError: {
        marginTop: '10px',
    },
    stepperContainer: {
        marginRight: theme.spacing(3),
    },
    secondStep: {
        marginTop: '10px',
    },
    allowButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: '#e87985',
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        minWidth: '80px',
        fontSize: '14px',
    },
    denyButton: {
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        marginLeft: '10px',
    },
    clipboardText: {
        display: 'flex',
    },
    onDisabled: {
        color: 'black',
    },
    disableHover: {
        pointerEvents: 'none',
    },
    desireOfHeartButton: { color: theme.palette.primary.contrastText },
    desireOfHeartButtonDiv: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        '& .MuiButton-root': {
            color: 'gray',
        },
    },
    desireOfHeart404ScrapperText: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
});

const initialDialogState = {
    url: '',
    title: '',
    images: [],
    note: '',
    disableButton: true,
    firstStep: true,
    isLoading: false,
    activeStep: 0,
    urlError: false,
    desireOfHeartDialog: false,
};

const createEmptyWish = {
    ...initialDialogState,
    disableButton: false,
    firstStep: false,
};

const imgPath = `${window.location.origin}/desireOfHeart/herzenswuensche_`;
const desireOfHeartImages = [
    `${imgPath}ausflug.jpg`,
    `${imgPath}kochen.jpg`,
    `${imgPath}spieleabend.jpg`,
    `${imgPath}basteln.png`,
    `${imgPath}lesestoff.png`,
];

class CreateDesireDialog extends Component {
    state = initialDialogState;

    static urlRegex = /^https?:\/\//i;
    static onionRegex = /^.*\.onion.*$/;

    componentDidMount() {
        this._isMounted = true;
        this.props.getClipboardReadPermissionAndParse(this.parseUrl);
    }

    componentWillUnmount() {
        this._isMounted = false;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const {
            getClipboardReadPermissionAndParse,
            wantsToReadClipboard,
            dialogIsOpen,
        } = this.props;

        const shouldReloadDialog = dialogIsOpen && !prevProps.dialogIsOpen;

        if (shouldReloadDialog && this._isMounted) {
            this.resetDialog();

            wantsToReadClipboard &&
                getClipboardReadPermissionAndParse(this.parseUrl);
        }
    }

    resetDialog = () => this.setState(initialDialogState);

    handleImageChange = (num) =>
        this.setState({ activeStep: this.state.activeStep + num });

    handleUrlChange = ({ target: { value } }) =>
        this.setState({ url: value, urlError: false });

    handleWishChange = ({ target: { value } }) =>
        this.setState({
            title: value,
            disableButton: !value,
        });

    handleCloseDialog = () => {
        this.resetDialog();
        this.props.closeDialog();
    };

    handlePrimaryButtonClick = () => {
        if (this.state.firstStep) {
            this.parseUrl();
        } else {
            const {
                id: userId,
                wishlistId,
                createDesire,
                enqueueSnackbar,
            } = this.props;
            const { images, activeStep, title, url, note } = this.state;
            let imageUrl = null;

            if (images.length > 0 && activeStep < images.length) {
                imageUrl = images[activeStep];
            }

            const openableUrl = CreateDesireDialog.urlRegex.test(url)
                ? url
                : `http://${url}`;

            const desireCreationModel = new DesireCreationModel(
                title,
                userId,
                wishlistId,
                openableUrl,
                imageUrl,
                null,
                null,
                note && /\S/.test(note) ? note : null
            );

            this.setState({ isLoading: true });

            createDesire(desireCreationModel).then(() => {
                enqueueSnackbar(<FormattedMessage id={'wish.created'} />, {
                    variant: 'default',
                    autoHideDuration: 6000,
                });
                this.setState({ isLoading: false });
                this.handleCloseDialog();
            });
        }
    };

    parseUrl = (urlParam) => {
        const { url } = this.state;
        if (!validator.isURL(urlParam ?? url)) {
            return;
        }

        this.setState({
            isLoading: true,
            disableButton: true,
        });

        const parseUrl = firebaseFunctions.httpsCallable('parseUrlCall');
        parseUrl({ url: urlParam ?? url, puppeteerDisabled: false })
            .then(({ data }) => {
                if (!data) {
                    this.setState({ ...createEmptyWish, url });
                    return Promise.reject('data is empty');
                }
                this.setState({
                    url: urlParam ?? url,
                    firstStep: false,
                    isLoading: false,
                    title: data.title,
                    images:
                        !data.images || data.images?.length === 0
                            ? desireOfHeartImages
                            : data.images,
                    urlError: false,
                    disableButton: false,
                });
                document.getElementById('wish.wish').focus?.();
                document.getElementById('wish.url').disabled = true;
                return Promise.resolve();
            })
            .catch((error) => {
                console.error(`could not parse url: ${error}`);
                this.setState({
                    url: urlParam ?? url,
                    isLoading: false,
                    urlError: true,
                });
            });
    };

    onPaste = (event) => {
        if (!this.state.wantsToReadClipboard) {
            event.preventDefault();
        }
        const url = event.clipboardData.getData('text/plain');
        if (validator.isURL(url) && !CreateDesireDialog.onionRegex.test(url)) {
            this.setState({ ...this.state, url });
            this.parseUrl(url);
        }
    };


    onUpload = (uploadUrl) => {
        if (!this.state.images.includes(uploadUrl)) {
            const images = [...this.state.images, uploadUrl];
            this.setState({
                images,
                activeStep: images.length - 1,
            });
        }
    };

    openDesireOfHeartDialog = () => {
        this.setState({
            url: '',
            firstStep: false,
            images: desireOfHeartImages,
            desireOfHeartDialog: true,
            urlError: false,
        });
    };

    render() {
        const {
            theme,
            classes,
            dialogIsOpen,
            isMobile,
            wantsToReadClipboard,
            clipboardReadSupported,
            getClipboardReadPermissionAndParse,
        } = this.props;
        const {
            isLoading,
            title,
            images,
            activeStep,
            urlError,
            firstStep,
            url,
            disableButton,
            note,
            desireOfHeartDialog,
        } = this.state;

        const primaryButtonIsDisabled = firstStep
            ? !validator.isURL(url) || CreateDesireDialog.onionRegex.test(url)
            : disableButton;

        return (
            <Dialog
                open={dialogIsOpen}
                onClose={this.handleCloseDialog}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.primary.main,
                        width: isMobile ? '100%' : '400px',
                        maxHeight: 'none',
                    },
                }}
                fullScreen={isMobile}
                TransitionComponent={
                    isMobile ? SlideTransition : GrowTransition
                }
            >
                <DialogTitle>
                    <Typography align="center" className={classes.title}>
                        <FormattedMessage id={'wish.new'} />
                    </Typography>
                    <DialogDivider />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form autoComplete="off">
                        <div>
                            <Collapse in={!desireOfHeartDialog}>
                                <UrlField
                                    foreignClassName={clsx(
                                        classes.urlFieldMarginBottom,
                                        !firstStep && classes.disableHover
                                    )}
                                    autoFocus={firstStep}
                                    disabled={!firstStep}
                                    onChange={this.handleUrlChange}
                                    id={
                                        firstStep
                                            ? 'wish.insertUrl'
                                            : 'wish.url'
                                    }
                                    onPaste={this.onPaste}
                                    enterClickCondition={
                                        !(primaryButtonIsDisabled || isLoading)
                                    }
                                    onEnterClick={this.handlePrimaryButtonClick}
                                    value={url ?? ''}
                                    startAdornment={
                                        urlError && (
                                            <ErrorIcon
                                                className={classes.errorIcon}
                                            />
                                        )
                                    }
                                    endAdornment={
                                        clipboardReadSupported &&
                                        wantsToReadClipboard && (
                                            <Tooltip
                                                title={
                                                    <FormattedMessage
                                                        id={'app.insertUrl'}
                                                    />
                                                }
                                                enterDelay={300}
                                            >
                                                <IconButton
                                                    className={
                                                        !firstStep
                                                            ? classes.onDisabled
                                                            : ''
                                                    }
                                                    onClick={
                                                        getClipboardReadPermissionAndParse
                                                    }
                                                    size="large"
                                                >
                                                    <AssignmentIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                />
                            </Collapse>
                            <Collapse in={urlError}>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    className={classes.errorMessage}
                                >
                                    <FormattedMessage
                                        id={'wishlist.404scrapper'}
                                    />
                                    <br />
                                    <Box
                                        className={
                                            classes.desireOfHeart404ScrapperText
                                        }
                                        onClick={this.openDesireOfHeartDialog}
                                    >
                                        <FormattedMessage
                                            id={
                                                'wishlist.orCreateDesireOfHeart'
                                            }
                                        />
                                    </Box>
                                </Typography>
                            </Collapse>
                            <Collapse in={firstStep && !isLoading}>
                                <div className={classes.desireOfHeartButtonDiv}>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        className={classes.desireOfHeartButton}
                                        onClick={this.openDesireOfHeartDialog}
                                    >
                                        <FormattedMessage id="wishlist.noURLQuestion" />
                                    </Button>
                                </div>
                            </Collapse>
                        </div>
                        <Collapse in={!firstStep}>
                            <div className={classes.secondStep}>
                                <WishField
                                    foreignClassName={clsx(
                                        classes.urlFieldMarginBottom,
                                        urlError &&
                                            classes.marginTopWishFieldOnError
                                    )}
                                    onChange={this.handleWishChange}
                                    id="wish.wish"
                                    value={title}
                                />
                            </div>
                            <NoteGroup
                                noteValue={note}
                                onNoteChange={(note) => this.setState({ note })}
                            />

                            <Grid
                                container
                                className={(() => {
                                    if (isMobile)
                                        return classes.stepperContainer;
                                })()}
                                direction="row"
                                justifyContent="center"
                            >
                                <Stepper
                                    fullScreen={isMobile}
                                    images={images}
                                    activeStep={activeStep}
                                    addPictureToState={this.onUpload}
                                    onNext={() => this.handleImageChange(1)}
                                    onBack={() => this.handleImageChange(-1)}
                                    jumpTo={this.handleImageChange}
                                />
                            </Grid>
                        </Collapse>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        className={classes.buttons}
                        sx={{ marginTop: firstStep ? '-45px' : '-25px' }}
                    >
                        <Grid item xs={6}>
                            <CancelButton
                                text="wishlist.cancel"
                                onClick={this.handleCloseDialog}
                                color="secondary"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.wrapper}>
                                <PrimaryButton
                                    text={
                                        firstStep
                                            ? 'wish.continue'
                                            : 'wishlist.create'
                                    }
                                    onClick={this.handlePrimaryButtonClick}
                                    disabled={
                                        primaryButtonIsDisabled || isLoading
                                    }
                                />
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        id: state.authentication.user.uid,
        dialogIsOpen: state.desires.createDialogIsOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeDialog: () => dispatch(closeCreateDialog()),
        createDesire: (model) => dispatch(createDesire(model)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withSnackbar(
        withResponsiveInformation(
            withStyles(styles)(withTheme(CreateDesireDialog))
        )
    )
);
