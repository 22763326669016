import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';

const styles = (theme) => ({
    avatarBig: {
        marginBottom: theme.spacing(2),
        width: 80,
        height: 80,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
    },
    avatarSmall: {
        boxShadow:
            '0 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0 rgba(0,0,0,0.14),0px 1px 3px 0 rgba(0,0,0,0.12)',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
    },
    iconBig: {
        fontSize: 50,
    },
});

class ProfilePicture extends Component {
    render() {
        const { classes, imageURL = '', size } = this.props;
        return (
            <Avatar
                className={
                    size === 'big' ? classes.avatarBig : classes.avatarSmall
                }
                fontSize="large"
                src={imageURL}
            >
                {!imageURL && (
                    <PersonIcon
                        className={size === 'big' ? classes.iconBig : ''}
                    />
                )}
            </Avatar>
        );
    }
}

export default withStyles(styles)(ProfilePicture);
