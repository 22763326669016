export const AUTHENTICATION_INITIALIZE_REQUEST = 'AUTHENTICATION_INITIALIZE_REQUEST';
export const AUTHENTICATION_INITIALIZE_SUCCESS = 'AUTHENTICATION_INITIALIZE_SUCCESS';
export const AUTHENTICATION_INITIALIZE_ERROR = 'AUTHENTICATION_INITIALIZE_ERROR';

export const LOGIN_RESET = 'LOGIN_RESET';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
