import { firebaseInstance } from '../../networking/firebase/FirebaseConfig';
import Profile from '../../profile/main/model/Profile';
import WishlistCreatedComperator from '../../wishlist/main/comperator/WishlistCreatedComperator';
import WishlistService from '../../wishlist/main/services/WishlistService';

class SharedWishlistService {
    constructor(firebaseInstance, wishlistService) {
        this.firebaseInstance = firebaseInstance;
        this.wishlistService = wishlistService;
    }

    registerObserver = (user, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`/sharedwishlists/${user.uid}`);
        ref.on('child_changed', callback);
        ref.limitToLast(1).on('child_added', callback);
    };

    unregisterObserver = (user, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`/sharedwishlists/${user.uid}`);
        ref.off('child_changed', callback);
        ref.off('child_added', callback);
    };

    getAll = async (user) => {
        const snapshot = await this.firebaseInstance
            .database()
            .ref(`/sharedwishlists/${user.uid}`)
            .orderByChild('deleted')
            .equalTo(null)
            .once('value');
        const result = snapshot.val() ?? {};
        return Object.values(result).sort(WishlistCreatedComperator.compare);
    };

    getAllOwnersByIds = (ownerIds) => {
        const ownerIdsUnique = Array.from(new Set(ownerIds));
        return Promise.all(
            ownerIdsUnique.map((ownerId) =>
                this.firebaseInstance
                    .database()
                    .ref(`/users/${ownerId}`)
                    .once('value')
                    .then((snapshot) => Profile.fromData(snapshot.val() ?? {}))
            )
        );
    };

    create = async (user, creationModel) => {
        const ownerId = creationModel.ownerId;

        const isOwnWishlist = ownerId === user.uid;

        if (isOwnWishlist) {
            return Promise.reject('adding-own-wishlist');
        } else {
            const wishlist = await this.wishlistService.getByUserIdAndId(
                creationModel.ownerId,
                creationModel.wishlistId
            );

            if (!wishlist.deleted) {
                await this.addUserToWishlistSharedWith(
                    user,
                    ownerId,
                    wishlist.id
                );
                await this.createOrUpdateSharedWishlist(
                    user,
                    ownerId,
                    wishlist
                );
                return wishlist;
            }
        }
    };

    addUserToWishlistSharedWith = (user, ownerId, wishlistId) => {
        let model = {};
        model[user.uid] = true;
        if (user.uid === ownerId) {
            return;
        }
        return this.firebaseInstance
            .database()
            .ref(`/wishlists/${ownerId}/${wishlistId}/sharedWith`)
            .update(model)
            .then(() => true);
    };

    createOrUpdateSharedWishlist = (user, ownerId, wishlist) => {
        const model = {
            id: wishlist.id,
            image: wishlist.image,
            note: wishlist.note,
            title: wishlist.title,
            wishCount: wishlist.wishCount,
            created: wishlist.created,
            ownerId,
            deleted: null,
        };
        return this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${user.uid}/${wishlist.id}`)
            .update(model);
    };

    remove = async (user, sharedWishlist) =>
        await Promise.all([
            this.removeFromSharedWishlist(user, sharedWishlist),
            this.removeFromWishlist(user, sharedWishlist),
        ]);

    removeFromWishlist = (user, sharedWishlist) => {
        let model = {};
        model[user.uid] = null;
        return this.firebaseInstance
            .database()
            .ref(
                `/wishlists/${sharedWishlist.ownerId}/${sharedWishlist.id}/sharedWith`
            )
            .update(model);
    };

    removeFromSharedWishlist = (user, sharedWishlist) => {
        const model = {
            deleted: Date.now(),
        };
        return this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${user.uid}/${sharedWishlist.id}`)
            .update(model);
    };

    restore = async (user, sharedWishlist) =>
        await Promise.all([
            this.restoreFromSharedWishlist(user, sharedWishlist),
            this.restoreFromWishlist(user, sharedWishlist),
        ]);

    restoreFromWishlist = (user, sharedWishlist) => {
        let model = {};
        model[user.uid] = true;
        return this.firebaseInstance
            .database()
            .ref(
                `/wishlists/${sharedWishlist.ownerId}/${sharedWishlist.id}/sharedWith`
            )
            .update(model);
    };

    restoreFromSharedWishlist = (user, sharedWishlist) => {
        const model = {
            deleted: null,
        };
        return this.firebaseInstance
            .database()
            .ref(`sharedwishlists/${user.uid}/${sharedWishlist.id}`)
            .update(model);
    };

    setWishCount = async (userId, wishlistId, wishCount) => {
        await this.firebaseInstance
            .database()
            .ref(`/sharedwishlists/${userId}/${wishlistId}`)
            .update({
                wishCount,
            });
        return wishCount;
    };
}

export default new SharedWishlistService(firebaseInstance, WishlistService);
