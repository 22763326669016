import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    submit: {
        margin: theme.spacing(2, 0, 1),
        letterSpacing: '1px',
        border: '2px solid',
        '&:hover': {
            border: '2px solid',
        },
    },
});

class SecondaryButton extends Component {
    render() {
        const { classes, text, onClick } = this.props;

        return (
            <Button
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={onClick}
            >
                <FormattedMessage id={text} />
            </Button>
        );
    }
}

export default withStyles(styles)(SecondaryButton);
