import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { withStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import withResponsiveInformation from '../mixins/components/ResponsiveInformation';
import FCMProvider from './FCMProvider';
import Notifications from './Notifications';

const styles = (theme) => ({
    pinkSnackbar: {
        backgroundColor: `${theme.palette.common.accentColor} !important`,
    },
});

// usage:
//    enqueueSnackbar('some message', {
//         action: (key) => closeActionSnackbarButton(() => closeSnackbar(key))});
export const closeActionSnackbarButton = (closeSnackbar) => {
    const classes = makeStyles({
        closeButton: {
            fill: 'white',
        },
    })();
    return (
        <Fragment>
            <IconButton onClick={() => closeSnackbar()} size="large">
                <SvgIcon
                    className={classes.closeButton}
                    component={CloseIcon}
                />
            </IconButton>
        </Fragment>
    );
};

// usage:
//    enqueueSnackbar('some message', {
//         action: (key) => undoActionSnackbarButton(someUndoFn, () => closeSnackbar(key))});
export const undoActionSnackbarButton = (undoAction, closeSnackbar) => {
    const classes = makeStyles({
        undoButton: {
            color: '#F7F399',
            fontSize: 12,
        },
    })();
    return (
        <Fragment>
            <Button
                key="undo"
                className={classes.undoButton}
                color="secondary"
                size="small"
                onClick={() => {
                    undoAction();
                    closeSnackbar();
                }}
            >
                <FormattedMessage id={'app.undo'} />
            </Button>
        </Fragment>
    );
};

class CustomSnackbarProvider extends Component {
    render() {
        const { isMobile, children, classes } = this.props;
        return (
            <SnackbarProvider
                classes={{
                    variantInfo: classes.pinkSnackbar,
                }}
                anchorOrigin={{
                    vertical: isMobile ? 'top' : 'bottom',
                    horizontal: isMobile ? 'center' : 'left',
                }}
                dense={isMobile}
                maxSnack={isMobile ? 1 : 4}
                autoHideDuration={null}
            >
                <FCMProvider>
                    <Notifications>{children}</Notifications>
                </FCMProvider>
            </SnackbarProvider>
        );
    }
}

export default withResponsiveInformation(
    withStyles(styles)(CustomSnackbarProvider)
);
