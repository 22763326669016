import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AppConstants from '../../app/constants/AppConstants';
import Copyright from './Copyright';

const styles = (theme) => ({
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
    },
});

class Footer extends Component {
    render() {
        const { classes, textColor } = this.props;

        return (
            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Copyright textColor={textColor} />
                </Container>
                <Container maxWidth="sm">
                    <Typography
                        variant="body2"
                        color={textColor}
                        align="center"
                    >
                        <Link
                            color={textColor}
                            href={AppConstants.imprint}
                            rel="noopener noreferrer"
                            target="_blank"
                            underline="hover"
                        >
                            {<FormattedMessage id={'auth.imprint'} />}
                        </Link>
                        {'  |  '}
                        <Link
                            color={textColor}
                            href={AppConstants.privacyPolicy}
                            rel="noopener noreferrer"
                            underline="hover"
                            target="_blank"
                        >
                            {<FormattedMessage id={'auth.privacy-policy'} />}
                        </Link>
                    </Typography>
                </Container>
            </footer>
        );
    }
}

export default withStyles(styles)(Footer);
