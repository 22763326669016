import { isFinite } from 'lodash';
import { firebaseInstance } from '../../networking/firebase/FirebaseConfig';
import ImageService from '../../profile/main/services/ImageService';
import DesireComperator from '../../sharedDesires/comperator/DesireComperator';

class DesireService {
    constructor(firebaseInstance) {
        this.firebaseInstance = firebaseInstance;
    }

    registerObserver = (ownerId, id, callbackOnChanged) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${id}`);
        ref.on('child_changed', (changes) => {
            callbackOnChanged(changes.val());
        });
    };

    unregisterObserver = (ownerId, id, callback) => {
        const ref = this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${id}`);
        ref.off('child_changed', callback);
    };

    getAll = (ownerId, wishlistId) => {
        return this.firebaseInstance
            .database()
            .ref(`desires/${ownerId}/${wishlistId}`)
            .orderByChild('deleted')
            .equalTo(null)
            .once('value')
            .then((snapshot) => {
                const result = snapshot.val() ?? {};
                return Object.values(result).sort(DesireComperator.compare);
            });
    };

    createDesire = async ({
        backgroundColor,
        created,
        deleted,
        imageUrl,
        title,
        url,
        wishlistId,
        userId,
        boughtByUserId,
        reservedByUserId,
        note,
        sortIndex,
    }) => {
        const desireReference = this.firebaseInstance
            .database()
            .ref(`desires/${userId}/${wishlistId}`);

        const newDesireNode = desireReference.push();

        if (/data:image\/(png|jpe?g);base64/.test(imageUrl)) {
            const res = await fetch(imageUrl);
            const blob = await res.blob();

            imageUrl = await ImageService.uploadDesireImage(
                new File([blob], `${newDesireNode.key}.png`, {
                    type: 'image/png',
                }),
                userId,
                newDesireNode.key
            );
        }

        const model = {
            id: newDesireNode.key,
            backgroundColor,
            created,
            deleted,
            imageUrl,
            title,
            url,
            wishlistId,
            sortIndex,
        };

        if (boughtByUserId) {
            model.boughtByUserId = boughtByUserId;
        }

        if (reservedByUserId) {
            model.reservedByUserId = reservedByUserId;
        }

        if (/\S/.test(note)) {
            model.note = note;
        }

        return newDesireNode.set(model).then(() => model);
    };

    editDesire = (userId, wishlistId, desireId, title, imageUrl, note) => {
        const updatedDesireNode = {
            title,
            imageUrl,
            note: note && /\S/.test(note) ? note : null,
        };

        return this.firebaseInstance
            .database()
            .ref(`desires/${userId}/${wishlistId}/${desireId}`)
            .update(updatedDesireNode);
    };

    deleteDesire = (userId, wishlistId, desireId) => {
        const updatedDesireNode = {
            deleted: Date.now(),
        };
        return this.firebaseInstance
            .database()
            .ref(`desires/${userId}/${wishlistId}/${desireId}`)
            .update(updatedDesireNode);
    };

    undoDelete = (userId, wishlistId, desireId) => {
        const updatedDesireNode = {
            deleted: null,
        };

        return this.firebaseInstance
            .database()
            .ref(`desires/${userId}/${wishlistId}/${desireId}`)
            .update(updatedDesireNode);
    };

    updateDesiresSortIndex = (userId, wishlistId, desireArray) => {
        return new Promise(async (resolve, reject) => {
            const updates = {};
            try {
                desireArray.forEach((desire) => {
                    updates[
                        `desires/${userId}/${wishlistId}/${desire.id}/sortIndex`
                    ] = desire.sortIndex;
                });
                if (
                    Object.values(updates).filter((value) => isFinite(value))
                        .length === desireArray.length
                ) {
                    await this.firebaseInstance
                        .database()
                        .ref()
                        .update(updates);
                    resolve(desireArray);
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw 'missing number value';
                }
            } catch (exception) {
                reject(exception);
            }
        });
    };
}

export default new DesireService(firebaseInstance);
