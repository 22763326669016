import { combineReducers } from 'redux';
import LocalizationReducer from '../../../localization/stateManagment/reducers/LocalizationReducer';
import ProfileReducer from '../../../profile/main/stateManagment/reducers/ProfileReducer';
import WishlistReducer from '../../../wishlist/main/stateManagement/reducers/WishlistReducer';
import DesiresReducer from '../../../desires/stateManagement/reducers/DesiresReducer';
import SharedWishlistReducer from '../../../sharedWishlist/stateManagement/reducers/SharedWishlistReducer';
import WishlistShareReducer from '../../../wishlist/share/stateManagement/reducers/WishlistShareReducer';
import AuthenticationReducer from '../../../authentication/statemanagement/reducers/AuthenticationReducer';
import LogoutReducer from '../../../authentication/statemanagement/reducers/LogoutReducer';
import LoginReducer from '../../../authentication/statemanagement/reducers/LoginReducer';
import PrimaryAppBarReducer from '../../../menu/appBar/primaryAppBar/stateManagement/reducers/PrimaryAppBarReducer';
import DrawerReducer from '../../../menu/drawer/stateManagement/reducers/DrawerReducer';
import ProfileDeletionReducer from '../../../profile/deletion/stateManagement/reducers/ProfileDeletionReducer';
import ProfileEditReducer from '../../../profile/edit/stateManagement/reducers/ProfileEditReducer';
import SharedDesiresReducer from '../../../sharedDesires/stateManagement/reducers/SharedDesiresReducer';
import ConsentReducer from '../../../consent/stateManagement/reducers/ConsentReducer';

const appReducer = combineReducers({
    authentication: AuthenticationReducer,
    analyticsConsent: ConsentReducer,
    login: LoginReducer,
    logout: LogoutReducer,
    profileDeletion: ProfileDeletionReducer,
    profileEdit: ProfileEditReducer,
    localization: LocalizationReducer,
    drawer: DrawerReducer,
    primaryAppBar: PrimaryAppBarReducer,
    profile: ProfileReducer,
    wishlist: WishlistReducer,
    sharedWishlist: SharedWishlistReducer,
    sharedDesires: SharedDesiresReducer,
    desires: DesiresReducer,
    wishlistShare: WishlistShareReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
