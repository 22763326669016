import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    edit,
    getCurrentUserProfile,
} from '../../main/stateManagment/actions/ProfileActions';
import {
    reset,
    showError,
} from '../stateManagement/actions/ProfileEditActions';
import ReenterPasswordDialog from './dialog/ReenterPasswordDialog';
import {
    reauthenticate,
    sendVerificationMail,
    reauthenticateWithGoogle,
    reauthenticateWithFacebook,
    reauthenticateWithApple,
} from '../../../authentication/statemanagement/actions/AuthenticationActions';
import { injectIntl } from 'react-intl';
import { closeActionSnackbarButton } from '../../../snackbar/CustomSnackbarProvider';
import { withSnackbar } from 'notistack';

class ProfileEdit extends Component {
    componentDidMount() {
        this.props.getCurrentUserProfile();
    }

    handleClose = () => {
        this.props.reset();
    };

    handleSubmit = (password) => {
        const { showError, reauthenticate } = this.props;
        reauthenticate(password)
            .then(() => this.afterReauthentication())
            .catch(() => showError());
    };

    handleAppleSubmit = () => {
        const { showError, reauthenticateWithApple } = this.props;
        reauthenticateWithApple()
            .then(() => this.afterReauthentication())
            .catch(() => showError());
    };

    handleFacebookSubmit = () => {
        const { showError, reauthenticateWithFacebook } = this.props;
        reauthenticateWithFacebook()
            .then(() => this.afterReauthentication())
            .catch(() => showError());
    };

    handleGoogleSubmit = () => {
        const { showError, reauthenticateWithGoogle } = this.props;
        reauthenticateWithGoogle()
            .then(() => this.afterReauthentication())
            .catch(() => showError());
    };

    afterReauthentication = () => {
        const {
            editProfile,
            enqueueSnackbar,
            closeSnackbar,
            intl,
            sendVerificationMail,
        } = this.props;
        return editProfile(null, null).then((result) => {
            result?.updatedEmail &&
                sendVerificationMail().then(() =>
                    enqueueSnackbar(intl.formatMessage({ id: 'auth.verify' }), {
                        variant: 'info',
                        action: (key) =>
                            closeActionSnackbarButton(() => closeSnackbar(key)),
                    })
                );

            this.handleClose();
        });
    };

    render() {
        const { profile, shouldShowDialog, error } = this.props;
        return (
            <ReenterPasswordDialog
                open={shouldShowDialog}
                profile={profile}
                onClose={this.handleClose}
                onSubmit={this.handleSubmit}
                error={error}
                signInMethod={profile.signInMethod}
                handleFacebookSubmit={this.handleFacebookSubmit}
                handleGoogleSubmit={this.handleGoogleSubmit}
                handleAppleSubmit={this.handleAppleSubmit}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentUserProfile: () => dispatch(getCurrentUserProfile()),
        reset: () => dispatch(reset()),
        reauthenticate: (password) => dispatch(reauthenticate(password)),
        reauthenticateWithApple: () => dispatch(reauthenticateWithApple()),
        reauthenticateWithGoogle: () => dispatch(reauthenticateWithGoogle()),
        reauthenticateWithFacebook: () =>
            dispatch(reauthenticateWithFacebook()),
        sendVerificationMail: () => dispatch(sendVerificationMail()),
        showError: () => dispatch(showError()),
        editProfile: (name, email) => dispatch(edit(name, email)),
    };
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile.data,
        shouldShowDialog: state.profileEdit.shouldShowDialog,
        error: state.profileEdit.shouldShowError,
    };
};

export default withSnackbar(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProfileEdit))
);
