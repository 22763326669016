import { HIDE_DRAWER, SHOW_DRAWER } from '../actions/DrawerActionTypes';

const drawerReducer = (
    state = {
        isVisible: false,
    },
    action
) => {
    switch (action.type) {
        case SHOW_DRAWER:
            return {
                ...state,
                isVisible: true,
            };
        case HIDE_DRAWER:
            return {
                ...state,
                isVisible: false,
            };
        default:
            return state;
    }
};

export default drawerReducer;
