const en = {
    'analytics.accept': 'accept',
    'analytics.decline': 'decline',
    'analytics.text':
        "Hi, yes StarSnoopy also uses cookies and analysis technologies. That's really important because it's the only way we can continually improve your user experience. Thanks for your support ❤",

    '404.Oops':
        'Oops! {newLine} What happend here? No problem, I will check this for you!',
    '404.redirect': 'Here you get back to {redirectToWishlist}.',
    '404.redirectToWishlist': 'your wish list page',
    'about.version': 'Version {versionNumber}',
    'about.starsnoopy': 'StarSnoopy',
    'about.facebook': 'Facebook',
    'about.gotToFacebook': 'to Facebook',
    'about.termsOfUse': 'Terms of Use',
    'about.gotoTermsOfUse': 'Terms of Use',
    'about.starSnoopyHomepage': 'StarSnoopy Website',
    'about.gotToStarSnoopy': 'Website',
    'about.feedback': 'Feedback',
    'about.sendFeedback': 'send Feedback',
    'about.share': 'Recommend',
    'about.tracking': 'Use analytical technologies',

    'auth.login': 'sign in',
    'auth.login-with-questionmark': 'sign in?',
    'auth.register': 'sign up',
    'auth.register-with-questionmark': 'Sign up?',
    'auth.password-reset': 'reset',
    'auth.signin-with-apple': 'sign in with apple',
    'auth.signin-with-facebook': 'sign in with facebook',
    'auth.signin-with-google': 'sign in with google',
    'auth.forgot-password': 'FORGOT YOUR PASSWORD?',
    'auth.name': 'Name',
    'auth.email': 'E-mail',
    'auth.password': 'Password',
    'auth.password-repeat': 'Repeat password',
    'auth.privacy-policy': 'privacy policy',
    'auth.imprint': 'imprint',
    'auth.account-exists':
        'An account already exists with the same email address but different sign-in credentials.',
    'auth.google-account-exists':
        'A google account already exists with the same email address but different sign-in credentials.',
    'auth.email-account-exists':
        'An email/password account already exists with the same email address but different sign-in credentials.',
    'auth.facebook-account-exists':
        'A facebook account already exists with the same email address but different sign-in credentials.',
    'auth/wrong-password': 'The email address or password is incorrect.',

    'auth.email-already-in-use':
        'The email address is already being used by another account.',
    'auth.invalid-email': 'The entered e-mail address is invalid.',
    'auth.weak-password': 'The password must be at least 6 characters long.',
    'auth.passwords-not-matching': "The passwords don't match.",
    'auth.social-authentification-fail': 'Login failed.',
    'auth.too-many-requests':
        'We have blocked all requests from this device due to unusual activity. Try again later.',
    'auth.user-not-found': 'User not found.',
    'auth.error-default': 'Login failed.',
    'auth.no-name': 'Please enter your name.',
    'auth.write-fail': 'Oops! Something went wrong.',

    'auth.privacy-label-1': 'Yes, I have read the',
    'auth.privacy-label-2': 'and agree.',
    'auth.privacy-policy-login': 'privacy policy',
    'auth.terms-of-use': 'terms of use',

    'auth.privacy-error': 'Please agree to the privacy policy.',
    'auth.verify': 'confirmation mail was sent',
    'auth.verifyHint':
        'Ready for great wish magic? The only thing missing is a confirmation of your e-mail address!',

    'login.info': 'Wish, share, surprise!',

    'desireOfHeart.name': `Heart's desire`,
    'desireOfHeart.new': `New heart's desire`,
    'desireOfHeart.create': `Create heart's desire`,

    'nav.my-wishlist': 'My wishlists',
    'nav.shared-wishlist': 'Wishes of my friends',
    'nav.profile': 'Profile',

    'settings.german': 'Deutsch',
    'settings.english': 'English',
    'settings.french': 'Français',
    'settings.logout': 'Logout',
    'settings.language': 'Language',
    'settings.profile': 'Profile',
    'settings.faq': 'FAQ',
    'settings.about': 'About',

    'sharedWishlistCard.bought': 'bought by {username}',
    'sharedWishlistCard.reserved': 'reserved by {username}',
    'desireLink': 'Link to',

    'passwordDialog.title': 'An e-mail was sent to you.',
    'passwordDialog.content':
        'Click on the link in this e-mail to reset your password and then return here to log in.',
    'passwordDialog.email-send': 'E-mail sent',
    'dialog.ok': 'ok',
    'dialog.cancel': 'cancel',

    'profile.done': 'done',
    'profile.logout': 'Logout',
    'profile.delete': 'delete account',
    'profile.success': 'Your profile information was successfully changed.',
    'profile.edit': 'Change e-mail',
    'profile.password': 'wrong password',
    'profile.save': 'save',

    'delete.timeout': 'Timeout. Please repeat the process.',
    'delete.fail': 'Oops! Something went wrong.',

    'profileDialog.deleteAccount': 'Delete account',
    'profileDialog.operation':
        'For this sensitive operation you have to sign in again.',
    'profileDialog.deleteText':
        'Do you really want to delete your StarSnoopy account? This action cannot be undone!',
    'profileDialog.editText':
        'For this sensitive operation you have to enter your password again:',
    'profileDialog.editTextSocial':
        'For this sensitive operation you have to log in again.',

    'wishlist.default': 'My first wish list',
    'wishlist.add': 'add wishlist',
    'wishlist.newWish': 'New wishlist',
    'wishlist.name': 'Wishlist name',
    'wishlist.note': 'Wishlist note',
    'wishlist.cancel': 'cancel',
    'wishlist.create': 'create',
    'wishlist.next': 'next',
    'wishlist.back': 'back',
    'wishlist.error': 'Please enter a wish list name.',
    'wishlist.save': 'save',
    'wishlist.changeWish': 'Edit wishlist',
    'wishlist.wishlist': 'wishlist',
    'wishlist.deleted': 'deleted',
    'wishl.note': 'note: ',

    'wishlist.edit': 'edit wishlist',
    'wishlist.deleteTooltip': 'delete wishlist',
    'wishlist.share': 'share wishlist',
    'wishlist.shareOnFacebook': 'share on Facebook',
    'wishlist.shareOnTwitter': 'share on Twitter',
    'wishlist.shareWithMail': 'share with Mail',
    'wishlist.shareWithMailSubject': 'My Wishlist',
    'wishlist.shareWithMailBody': 'Take a look on my wishlist on {link}!',
    'wishlist.settings': 'settings',
    'wishlist.empty': 'So many wonderful wishes and still no wish list?',
    'wishlist.start': "Let's get right on it!",
    'wishlist.pushButton':
        'Click on the + symbol to create your first wish list! ',
    'wishlist.ps':
        'P.S. you can share wish lists with your loved ones! Just look for this symbol.',
    'wishlist.shareTitle': 'Share',
    'wishlist.copy': 'copy',
    'wishlist.copiedLink': 'Link copied to clipboard',
    'wishlist.shareDownloadQrCode': 'Download QR code',
    'wishlist.shareCopyQrCode': 'Copy QR code to clipboard',
    'wishlist.copiedQR': 'QR code copied to clipboard',
    'wishlist.downloadQRCodeAsPdf': 'download QR Code as PDF',
    'wishlist.showQRCode': 'Show QR Code',
    'wishlist.hideQRCode': 'Hide QR Code',
    'wishlist.qrCodeText':
        'Send this QR code for your wish list to your friends and family, or print it out:',
    'wishlist.404scrapper':
        'Something must have gone wrong. Try it with another link!',
    'wishlist.orCreateDesireOfHeart': 'Or create a wish without an URL!',
    'wishlist.socialMetaTagTitle': 'Join wish list | "{wishlistName}"',
    'wishlist.socialMetaTagDescription':
        'Wishes come true when they are shared.',
    'wishlist.addingOwnWishlist': "You can't join your own wish list.",
    'wishlist.invalidUrl': 'invalid URL',
    'wishlist.updateOwnImageQuestion': 'No suitable image found?',
    'wishlist.noURLQuestion': 'No URL to the wish?',

    'wish.edit': 'Edit wish',
    'wish.delete': 'Delete wish',
    'wish.buy': 'Buy now',
    'wish.new': 'New wish',
    'wish.created': 'Wish created',
    'wish.moved': 'Wish moved to {title}',
    'wish.newDesireOfHeart': `New heart's desire`,
    'wish.url': 'URL',
    'wish.insertUrl': 'Insert URL',
    'wish.wish': 'Wish',
    'wish.create': 'add wish',
    'wish.continue': 'continue',
    'wish.empty': 'This wishlist is still empty.',
    'wish.pushButton': 'To make a new wish, press +',
    'wish.undoSnackbar': 'wish deleted',
    'wish.move': 'Move wish',
    'wish.uploadImage': 'Click here to upload your own picture',
    'wish.createNoteText': '+ Add note',
    'wish.createNote': 'Add note',
    'wish.removeNote': 'Remove note',
    'wish.displayNote': 'Show note',
    'wish.displayTitle': 'Show wish name',
    'wish.displayMenu': 'Show menu',

    'share.left': 'left',
    'share.of': 'of',
    'share.empty': 'No one has shared their wish list with you.',
    'share.invite': 'Invite friends to use StarSnoopy.',
    'share.inviteButton': 'Invite friends',
    'share.leave': 'leave wishlist',
    'share.reserve': 'Reserve',
    'share.buyed': 'Mark as purchased',
    'share.release': 'Release',

    'starsnoopy.homepage': 'https://www.starsnoopy.com/',

    'app.changeLanguage': 'Change Language',
    'app.default': '',
    'app.undo': 'undo',
    'app.allow': 'Ok!',
    'app.deny': 'Rather not',
    'app.insertUrl': 'insert URL from clipboard',
    'app.askForClipboardReadPermissionQuestion':
        'Create wishes even easier! May we help you?',
    'app.askForClipboardReadPermissionExplanation':
        'To do this, we just need to be able to retrieve links from your clipboard.',
    'app.playStore':
        'By the way, StarSnoopy is also available as an app! Get it for free!',
    'app.redirectToWishlist': 'Go to wish list',
    'app.close': 'Close',

    'notification.consent':
        'Stay up to date about the latest wishes of your beloved. Just enable notifications.',
    'notification.accept': 'Enable',

    'notification.newWish': 'Wishlist updates',

    'notification.reservedNoneLeft': `{person_name} has reserved a wish from the {wishlist_title} list. There are no unfulfilled wishes left.`,
    'notification.reservedOneLeft': `{person_name} has reserved a wish from the {wishlist_title} list. There is only {wishes_left} unfulfilled wish left.`,
    'notification.reservedMultipleLeft': `{person_name} has reserved a wish from the {wishlist_title} list. There are only {wishes_left} unfulfilled wishes left.`,

    'notification.boughtNoneLeft': `{person_name} has fulfilled a wish from the {wishlist_title} list. There are no unfulfilled wishes left.`,
    'notification.boughtOneLeft': `{person_name} has fulfilled a wish from the {wishlist_title} list. There is only {wishes_left} unfulfilled wish left.`,
    'notification.boughtMultipleLeft': `{person_name} has fulfilled a wish from the {wishlist_title} list. There are only {wishes_left} unfulfilled wishes left.`,
};

export default en;
